import { GET_BASIC_BUYER_DETAIL_FAIL, GET_BASIC_BUYER_DETAIL_REQUEST, GET_BASIC_BUYER_DETAIL_SUCCESS } from "../../constants/getBasicBuyer"
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token

export function BasicBuyerDetailAction ({buyerId,page,limit}){
     return async (dispatch, getState) => {
        dispatch({type: GET_BASIC_BUYER_DETAIL_REQUEST})
        
         try {
          
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/buyer_orders?buyer_id=${buyerId}&page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                }
            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_BASIC_BUYER_DETAIL_FAIL, payload: data.message})
            }
            dispatch({type: GET_BASIC_BUYER_DETAIL_SUCCESS, payload: data.results})
         } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: GET_BASIC_BUYER_DETAIL_FAIL, payload: message})
         }
     }
}