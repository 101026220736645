import { useContext } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/Admin/AdminAction";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../AppContext";

const DropDown = () => {
    const {toggleDropDown} = useContext(AppContext)
    const history =  useHistory()
    const dispatch = useDispatch()
    const logoutHandler = () => {
        dispatch(logout());
        history.push("/login")
      }
      
    return (
        <div className={toggleDropDown ? `dropdown open_dropdown `: `dropdown`}>
            <div className="dropdown-items">
                <div className="admin__logout" onClick={logoutHandler}>Logout</div>
            </div>
        </div>
    )
}

export default DropDown;