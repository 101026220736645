import React, { useContext, useEffect, useState } from 'react'
import {BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { useHistory} from 'react-router-dom';
import moment from 'moment';
import { AppContext } from '../../AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { ReturnList } from '../../actions/Admin/RefundAction';

const ReturnListSection = () => {
    const {itemLimit,setItemLimit } = useContext(AppContext);
    const [returnItem,setReturnItem] = useState([])
    const dispatch = useDispatch();
    const history = useHistory(null);

    const {return_list, return_list_docs,  return_list_error} = useSelector(state => state.returnList)

  useEffect(() => {
    const offset = 1;
    const limit = +itemLimit;
    const data = {offset, limit}
   dispatch(ReturnList(data))
   
}, [dispatch, itemLimit]);

const handleNextPageReturn = (page_number) => {
    const offset = page_number;
    const limit = +itemLimit;
    const data = { offset, limit };
    dispatch(ReturnList(data));
}

const totalPagesReturn = return_list && return_list.totalPages;
const pageReturn = return_list && return_list.page;
const prevPageReturn = return_list && return_list.prevPage;
const nextPageReturn = return_list && return_list.nextPage;
const hasNextPageReturn = return_list && return_list.hasNextPage;
const hasPrevPageReturn = return_list && return_list.hasPrevPage;


  return (
    <>
          {return_list_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{return_list_error || return_list_error.message }</p>
                </div>
            }
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                    <div className="header_order_title">RETURN REQUESTS</div>
                </div>
                <div className="card-body">
                {
             return_list_docs &&  return_list_docs.length > 0 ? 
                    <div className="table-responsive" >
                        <table className="table seller_table" align="middle">

                                <thead className="tborder table-header" align="middle">
                                    <tr className="align_student_table">
                                        <th scope="col" className="header__name">#ID</th>
                                        <th scope="col" className="header__name">Complaint Subject</th>
                                        <th scope="col" className="header__status">Name</th>
                                        <th scope="col" className="header__userId">Email Address</th>
                                        <th scope="col" className="header_phone">Date/Time.</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                      
                             
                                <tbody align="middle">
                                   {return_list_docs &&  return_list_docs.map((ret, index) => (
                                    <tr key={ret._id}>
                                        <td className="item_status">{ret.ticket_id}</td>
                                        <td className="item_phone item_complaint">{ret && ret.subject}</td>
                                        <td className="item_userId">{ret.full_name  || "User"}</td>
                                        <td  className="item_email email-underline"><a href={`mailto:${ret.email}`}>{ret.email}</a></td>
                                        {/* <td className="item_phone">{ret.account_number}</td>
                                        <td className="item_phone">{ret.bank_name}</td> */}
                                        <td className="item_phone item_date">{moment(ret.createdAt).format("DD/MM/YYYY, h:mm a")}</td>
                                        <td className="item item_proceed" onClick={() => {history.push("/dashboard/refund?return=modal_return")}}><button>view details</button></td>
                                    </tr>
                                           ))
                                        }
                                </tbody>
                       
                        
            </table>
            
            </div>
            :  <h2 className="text-center no-record-found mt-4">No Records Found</h2>
        }
        </div>
            <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                <div className="page_number">{return_list_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                    <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                </div>
                {totalPagesReturn > 1 ?
                <div className="paginate_reuse">
                    <button className="left-chevron" onClick={() => {hasPrevPageReturn && handleNextPageReturn(prevPageReturn);}}><BsChevronLeft /></button><button className="middle-chevron">{pageReturn}</button><button className="right-chevron" onClick={() => {hasNextPageReturn && handleNextPageReturn(nextPageReturn && nextPageReturn)}}><BsChevronRight /></button>
                </div> : null
                }
            </div>   
        </div>
    </>
  )
}

export default ReturnListSection