import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const Graph = ({chat_data}) => {
    
    const chartData = chat_data !== undefined && chat_data
    return (
        <div style={{width: "100%", height: "100%", maxHeight: "220px", padding: "0"}}>
              <Line data={{labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],   
                 datasets: [{
                    label: false,
                    data: chartData,
                    borderColor: '#E10707',
                    borderWidth: 1,
                    fill: false,
                    radius: 0,
                }]
        }}
        
        options={{
            
            responsive: true,
            scales: {
                y: {
                  beginAtZero: true
                },
                x: {
                    beginAtZero: true
                }
              },
            plugins: {
                legend:{
                    display: false
                }
                
            }
         
      }}
         style={{width: "100%", height: "150px"}}
        
        >
            
        </Line>
        </div> 
    )
}

export default Graph;