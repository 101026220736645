import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {BsChevronLeft, BsChevronRight,BsChevronUp, BsChevronDown} from "react-icons/bs";
import { RecentOrdersAction } from "../../actions/Admin/sales/RecentOrdersAction";
import { AppContext } from "../../AppContext";
const SaleTable = () => {
    const {itemLimit, setItemLimit, recentTransaction,toggleRecentTransactions} = useContext(AppContext);
    const [preShipmentItems, setPreShipmentItems] = useState([]);
    const [allRecentOrders, setAllRecentOrders] = useState([]);
    const [itemId, setItemId] = useState("");
    const [expandRecentDetails, setExpandRecentDetails] = useState("");
    const dispatch = useDispatch()
    const {list_recent_orders,list_recent_orders_docs,list_recent_orders_error} = useSelector(state => state.recentOrdersReducer);
    const format = new Intl.NumberFormat('en-US');
    const expandRecentTransactions = (index) => {
        if(expandRecentDetails === index){
            return setExpandRecentDetails(null)
        }
        setExpandRecentDetails(index)
    }
    const data = preShipmentItems && preShipmentItems.filter(item => item._id === itemId && itemId)[0];
    const trackingId = allRecentOrders && allRecentOrders.way_bill;
   
    const storeName = data && data.store_name;
    const prodImg = data && data.product_image;
    const alphaCode = data && data.alphaCode;
    const itemValue = data && data.ItemValue;
    const itemSize = data && data.size;
    const itemColor = data && data.color;
    const quantity = data && data.Quantity;
    const orderStatus = data && data.status

    const textStyle = orderStatus === "accepted" ? "text-order" : orderStatus === "cancelled" ?"text-rejected" : orderStatus === "delivered" ? "text-delivered" : orderStatus === "pending" ? "text-pending" : orderStatus === "processing" ? "text-processing" : "";



    const handleNextPage = (page_number) => {
        const offset = page_number;
        const limit = +itemLimit;
        const data = { offset, limit };
        dispatch(RecentOrdersAction(data));
    };

    const totalPages = list_recent_orders && list_recent_orders.totalPages;
    const page = list_recent_orders && list_recent_orders.page;
    const prevPage = list_recent_orders && list_recent_orders.prevPage;
    const nextPage = list_recent_orders && list_recent_orders.nextPage;



    
    return (
    <div className="card card_wrapper">
            <div className="data_heading">
            <div className="header_order_title">RECENT ORDERS</div>
        </div>
            <div className="card-body sub-category-content-body">
            {list_recent_orders_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{list_recent_orders_error || list_recent_orders_error.message }</p>
                </div>
            }
            {list_recent_orders_docs && list_recent_orders_docs.length > 0 ? 
                <div>
                        {
                list_recent_orders_docs.map((data, index) => {
                    const totalPrice = (data?.totalCost + data?.ShippingFee)
                    return <div key={data._id + `a-${index}`}>
                            <div className="todays_order_content">
                            <div className="todays_ordered_prod_name"><strong>Transaction id</strong> {data._id}</div>
                                <div className="todays_ordered_prod_name"><strong>Buyer </strong>{data.ReceiverName}</div>
                                <div className="todays_ordered_prod_name"><strong>Merchant </strong>{data.SenderName}</div>
                                <div className="todays_ordered_prod_name"><strong>Total Price </strong>&#x20A6;{format.format(totalPrice)}</div>
                                <div className="todays_ordered_prod_name"><strong>Payment method </strong>{data.IsCashOnDelivery ? "Cash on delivery" : "Cash"}</div>
                                <div className="todays_ordered_prod_name">{moment(data.createdAt).format("D/MM/YYYY h:mm a")}</div>
                                {recentTransaction === index ?  <BsChevronUp onClick={() => toggleRecentTransactions(index)}/> : <BsChevronDown onClick={() => {toggleRecentTransactions(index); setPreShipmentItems(data.PreShipmentItems); setAllRecentOrders(data); setExpandRecentDetails("")}}/> }
                            </div>
                            <div className={recentTransaction  === index ? "todays_order_list_content show" : "todays_order_list_content"} >
                                {preShipmentItems && preShipmentItems.map((item,index) => (
                                    <>
                                    <div key={item._id + "-alpha"}>
                                    <div className="todays_order_content">
                                <div className="todays_ordered_prod_name"><div>Product Name</div> <div>{item && item.ItemName}</div></div>
                                <div className="todays_ordered_prod_name"><div>Category</div><div> {item.category}</div></div>
                                <div className="todays_ordered_prod_name"><div>Quantity</div><div>{item.Quantity}</div></div>
                                
                                <div className="todays_ordered_prod_name"><div>Store Name</div> <div>{item.store_name}</div></div>
                                <div className="todays_ordered_prod_name"><div>Buyer ID</div> <div>{allRecentOrders && allRecentOrders._id}</div></div>
                                <div className="todays_ordered_prod_name">
                                    <div>Status</div><div>{allRecentOrders.status}</div>
                                </div>
                                {
                                    expandRecentDetails === index ? <button className="view_details" onClick={() => {expandRecentTransactions(index);}}>Minimize</button> : <button className="view_details" onClick={() => {expandRecentTransactions(index); setItemId(item._id)}}>View details</button>
                                }
                                
                            </div>
                            <div className={expandRecentDetails  === index ? "todays_order_list_content todays_order_list_content_details show" : "todays_order_list_content todays_order_list_content_details"} >
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-4 col-lg-4">
                                    {
                                        data && prodImg &&
                                    
                                            <div className=" img-col-size-sec">
                                            <div className="image-data recent-order-img">
                                                <img src={prodImg} alt={prodImg}/>
                                            </div>
                                            <div className="color-size-todays-sec">
                                            {data && itemColor &&                                                 
                                                <div> 
                                                    <div className="product-color-oms">Color</div>
                                                    <div style={{backgroundColor: itemColor, width: "25px", height: "25px", borderRadius: "50%", border: "1px solid #333333a0"}}><span></span></div>
                                                </div>
                                                }
                                                {data && itemSize && 
                                                    <div>
                                                            <div className="product-color-oms">Size</div>
                                                        <div style={{border: "1px solid #333333a0", width: "auto", height: "auto", borderRadius: "2px", textAlign: "center", padding: "3px"}}><span className="size-oms">{itemSize}</span></div>
                                                    </div>
                                                }
                                            </div>
                                        
                                            </div>
                                        }
                                    </div>
                                    <div className="col-8 col-lg-8">
                                            <div className="table-responsive">
                                            <table className="table">
                                            <thead className="thead-light">
                                                <tr align="middle">
                                                    <th scope="col" className="waybill-header-details">Waybill No.</th>
                                                    <th scope="col" className="waybill-header-details">Item price</th>
                                                    <th scope="col" className="waybill-header-details">Alpha code</th>
                                                    <th scope="col" className="waybill-header-details">Shipping fee</th>
                                                    <th scope="col" className="waybill-header-details">Store Name</th>
                                                </tr>
                                            </thead>
                                            <tbody align="middle">
                                                <tr>
                                                    <td className="waybill-no-details">{trackingId ? trackingId : "-" }</td>
                                                    <td className="waybill-no-details">&#x20A6;{format.format(itemValue)}</td>
                                                    <td className="waybill-no-details">{alphaCode}</td>
                                                    <td className="waybill-no-details">&#x20A6;{format.format(allRecentOrders && allRecentOrders.ShippingFee)}</td>
                                                    <td className="waybill-no-details">{ storeName}</td>
                                                    
                                                </tr>
                                                
                                            </tbody>
                                            </table>
                                            </div>
                                            <div className="table-responsive">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr align="middle">
                                                        <th scope="col" className="waybill-header-details">Order Qty</th>
                                                        <th scope="col" className="waybill-header-details">Payment Type</th>
                                                        <th scope="col" className="waybill-header-details">Order Status</th>
                                                        <th scope="col" className="waybill-header-details">Client Name</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody align="middle">
                                                    <tr>
                                                        <td className="waybill-no-details">{data ? quantity : "-"}</td>
                                                        <td className="waybill-no-details payment-type">{allRecentOrders  && allRecentOrders.IsCashOnDelivery? "Cash on Delivery" : "Cash"}</td>
                                                        <td className="waybill-no-details"><span className={textStyle}>{orderStatus}</span></td>
                                                        
                                                        <td className="waybill-no-details">{allRecentOrders && allRecentOrders.ReceiverName}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                            </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead className="thead-light">
                                                <tr  align="left">
                                                    <th scope="col" className="waybill-header-details">Merchant phone No.</th>
                                                    <th scope="col" className="waybill-header-details">Merchant email Address</th>
                                                    <th scope="col" className="waybill-header-details">Pickup Address</th>
                                                    {allRecentOrders.isDropOff && <th scope="col" className="waybill-header-details">Preshipment code</th>}
                                                </tr>
                                            </thead>
                                            <tbody align="middle">
                                                <tr>
                                                    <td className="waybill-no-details">{allRecentOrders && allRecentOrders.SenderPhoneNumber}</td>
                                                    <td className="waybill-no-details email-address">
                                                        {allRecentOrders && allRecentOrders.SenderEmail === "" ? "-" :
                                                        <a href={`mailto:${allRecentOrders && allRecentOrders.SenderEmail}`}>{allRecentOrders && allRecentOrders.SenderEmail}</a>}</td>
                                                    <td className="waybill-no-details">{allRecentOrders && allRecentOrders.SenderAddress}</td>
                                                    {allRecentOrders.isDropOff && <td className="waybill-no-details">{allRecentOrders && allRecentOrders.preShipmentCode}</td>}
                                                </tr>
                                                
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead className="thead-light">
                                                <tr  align="left">
                                                    <th scope="col" className="waybill-header-details">Receiver phone No.</th>
                                                    <th scope="col" className="waybill-header-details">Receiver email Address</th>
                                                    <th scope="col" className="waybill-header-details">Delivery Address</th>
                                                </tr>
                                            </thead>
                                            <tbody align="middle">
                                                <tr>
                                                    <td className="waybill-no-details">{allRecentOrders && allRecentOrders.ReceiverPhoneNumber}</td>
                                                    <td className="waybill-no-details email-address"><a href={`mailto:${allRecentOrders && allRecentOrders.ReceiverEmail}`}>{allRecentOrders && allRecentOrders.ReceiverEmail}</a></td>
                                                    <td className="waybill-no-details">{allRecentOrders && allRecentOrders.ReceiverAddress}</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                             </div>
                          </div>
                         </div>
                            <hr />
                            </div>
                            </div>
                        </>
                    )) 
                            
                                
                                }
                            </div>
                    </div>
                   })
                }
                    
                </div> : <h2 className="text-center">No data was found</h2>
                }

                <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                            
                            <div className="page_number">Item per page {" "}{" "}
                                <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                            </div>
                        { totalPages > 1 ? 
                            <div className="paginate_reuse">
                                <button className="left-chevron" onClick={() => {handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage);}}><BsChevronRight /></button>
                            </div>: null
                        }
                        </div>  
            </div>
        </div>
    )
}

export default SaleTable;