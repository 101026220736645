import { GET_REVENUE_DATA_FAIL, GET_REVENUE_DATA_REQUEST, GET_REVENUE_DATA_SUCCESS } from "../../../constants/dashboard/revenueDataConstant"

const initialState = {
     revenue_data_loading: false,
     revenue_data: {},
     revenue_data_error: ""

}

export const revenueDataReducer = (state=initialState, action) => {
     const {type, payload} = action;
     switch(type){
          case GET_REVENUE_DATA_REQUEST:
               return {
                    ...state,
                    revenue_data_loading: true
               }
          case GET_REVENUE_DATA_SUCCESS:
               return {
                    ...state,
                    revenue_data_loading: false,
                    revenue_data: payload

               }
          case GET_REVENUE_DATA_FAIL:
               return {
                    ...state,
                    revenue_data_loading: false,
                    revenue_data_error: payload
               }
          default:
               return state;
     }
}