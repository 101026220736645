import { CATEGORY_CREATE_FAIL, CATEGORY_CREATE_REQUEST, CATEGORY_CREATE_SUCCESS, CATEGORY_DELETE_FAIL, CATEGORY_DELETE_REQUEST, CATEGORY_DELETE_SUCCESS,CATEGORY_EDIT_REQUEST,CATEGORY_EDIT_SUCCESS,CATEGORY_GENDER_CREATE_FAIL, CATEGORY_GENDER_CREATE_REQUEST, CATEGORY_GENDER_CREATE_SUCCESS, CATEGORY_GENDER_LIST_FAIL, CATEGORY_GENDER_LIST_REQUEST, CATEGORY_GENDER_LIST_SUCCESS, CATEGORY_GET_FAIL, CATEGORY_GET_REQUEST, CATEGORY_GET_SUCCESS, CATEGORY_LIST_FAIL, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, CATEGORY_SIZE_CREATE_FAIL, CATEGORY_SIZE_CREATE_REQUEST, CATEGORY_SIZE_CREATE_SUCCESS, CATEGORY_SIZE_LIST_FAIL, CATEGORY_SIZE_LIST_REQUEST, CATEGORY_SIZE_LIST_SUCCESS, CATEGORY_TYPE_CREATE_FAIL, CATEGORY_TYPE_CREATE_REQUEST, CATEGORY_TYPE_CREATE_SUCCESS, CATEGORY_TYPE_LIST_FAIL, CATEGORY_TYPE_LIST_REQUEST, CATEGORY_TYPE_LIST_SUCCESS, CATEGORY_EDIT_FAIL, CATEGORY_STATUS_UPDATE_REQUEST, CATEGORY_STATUS_UPDATE_SUCCESS, CATEGORY_STATUS_UPDATE_FAIL, CATEGORY_TYPE_GET_REQUEST, CATEGORY_TYPE_GET_SUCCESS, CATEGORY_TYPE_GET_FAIL, CATEGORY_TYPE_EDIT_REQUEST, CATEGORY_TYPE_EDIT_SUCCESS, CATEGORY_TYPE_EDIT_FAIL, CATEGORY_TYPE_DELETE_REQUEST, CATEGORY_TYPE_DELETE_SUCCESS, CATEGORY_TYPE_DELETE_FAIL, SUB_CATEGORY_REMOVE_REQUEST, SUB_CATEGORY_REMOVE_SUCCESS, SUB_CATEGORY_REMOVE_FAIL } from "../../constants/productCategoryConstant";

const initialState = {
    category_loading: false,
    category_success: false,
    category_docs: [],
    types: [],
    type_loading: false,
    types_docs: [],
    type: {},
    type_success: false,
    type_remove_loading: false,
    type_remove: {},
    type_remove_error: "",
    type_remove_success: false,
    type_error: "",
    categories: [],
    category: {},
    error: "",
}

//Size Reducer
export const createSizeReducer = (state={}, action) => {
    switch(action.type){
        case CATEGORY_SIZE_CREATE_REQUEST:
            return {
                size_create_loading: true
            }
        case CATEGORY_SIZE_CREATE_SUCCESS:
            return {
                ...state,
                size_create_loading: false,
                size_create_result: action.payload
            }
        case CATEGORY_SIZE_CREATE_FAIL:
            return {
                ...state,
                size_create_loading: false,
                size_create_error: action.payload
            }
        default:
            return state;
    }
}

export const listSizeReducer = (state={list_sizes: []}, action) => {
    switch(action.type){
        case CATEGORY_SIZE_LIST_REQUEST:
            return {
                size_list_loading: true
            }
        case CATEGORY_SIZE_LIST_SUCCESS:
            return {
                ...state,
                size_list_loading: false,
                list_sizes: action.payload
            }
        case CATEGORY_SIZE_LIST_FAIL:
            return {
                ...state,
                size_list_loading: false,
                size_list_error: action.payload
            }
        default:
            return state;
    }
}


//Gender Reducer
export const createGenderReducer = (state={}, action) => {
    switch(action.type){
        case CATEGORY_GENDER_CREATE_REQUEST:
            return {
                gender_create_loading: true
            }
        case CATEGORY_GENDER_CREATE_SUCCESS:
            return {
                ...state,
                gender_create_loading: false,
                gender_create_result: action.payload
            }
        case CATEGORY_GENDER_CREATE_FAIL:
            return {
                ...state,
                gender_create_loading: false,
                gender_create_error: action.payload
            }
        default:
            return state;
    }
}

export const listGenderReducer = (state={list_genders: []}, action) => {
    switch(action.type){
        case CATEGORY_GENDER_LIST_REQUEST:
            return {
                gender_list_loading: true
            }
        case CATEGORY_GENDER_LIST_SUCCESS:
            return {
                ...state,
                gender_list_loading: false,
                list_genders: action.payload
            }
        case CATEGORY_GENDER_LIST_FAIL:
            return {
                ...state,
                gender_list_loading: false,
                gender_list_error: action.payload
            }
        default:
            return state;
    }
}

//Type Reducer
export const createTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_TYPE_CREATE_REQUEST:
            return {
                ...state,
                type_loading: true
            }
        case CATEGORY_TYPE_CREATE_SUCCESS:
            return {
                ...state,
                type_loading: false,
                types: action.payload.results,
                types_docs: state.types_docs.concat(action.payload),
                type_success: true
            }
        case CATEGORY_TYPE_CREATE_FAIL:
            return {
                ...state,
                type_loading: false,
                type_error: action.payload, 
                type_success: false
            }
        default:
            return state;
    }
}

export const listTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_TYPE_LIST_REQUEST:
            return {
                ...state,
                type_loading: true
            }
        case CATEGORY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                type_loading: false,
                types_docs: action.payload.results.docs,
                types: action.payload.results,
                type_success: true
            }
        case CATEGORY_TYPE_LIST_FAIL:
            return {
                ...state,
                type_loading: false,
                type_error: action.payload,
                type_success: false
            }
        default:
            return state;
    }
}


export const getTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_TYPE_GET_REQUEST:
            return {
                ...state,
                type_loading: true
            }
        case CATEGORY_TYPE_GET_SUCCESS:
            return {
                ...state,
                type_loading: false,
                type: action.payload,
                type_success: true
            }
        case CATEGORY_TYPE_GET_FAIL:
            return {
                ...state,
                type_loading: false,
                type_error: action.payload,
                type_success: false
            }
        default:
            return state;
    }
}

export const editTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_TYPE_EDIT_REQUEST:
            return {
                ...state,
                type_loading: true
            }
        case CATEGORY_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                type_loading: false,
                type: action.payload,
                type_success: true
            }
        case CATEGORY_TYPE_EDIT_FAIL:
            return {
                ...state,
                type_loading: false,
                type_error: action.payload,
                type_success: false
            }
        default:
            return state;
    }
}

export const deleteTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_TYPE_DELETE_REQUEST:
            return {
                ...state,
                type_loading: true
            }
        case CATEGORY_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                type_loading: false,
                types_docs: state.types_docs.filter((c) => c._id !== action.payload._id),
                type_success : true
            }
        case CATEGORY_TYPE_DELETE_FAIL:
            return {
                ...state,
                type_loading: false,
                type_error: action.payload,
                type_success: false
            }
        default:
            return state;
    }
}

export const removeTypeReducer = (state=initialState, action) => {
    switch(action.type){
        case SUB_CATEGORY_REMOVE_REQUEST:
            return {
                ...state,
                type_remove_loading: true
            }
        case SUB_CATEGORY_REMOVE_SUCCESS:
            return {
                ...state,
                type_remove_loading: false,
                type_remove: action.payload,
                type_remove_success : true
            }
        case SUB_CATEGORY_REMOVE_FAIL:
            return {
                ...state,
                type_remove_loading: false,
                type_remove_error: action.payload,
                type_remove_success: false
            }
        default:
            return state;
    
    }
}


// Category Reducer

export const createCategoryReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_CREATE_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case CATEGORY_CREATE_SUCCESS:
            return {
                ...state,
               category_loading: false,
               category_docs: state.category_docs.concat(action.payload),
               categories: action.payload.results,
               category_success: true
            }
        case CATEGORY_CREATE_FAIL:
            return {
                ...state,
                category_loading: false,
                error: action.payload,
                category_success: false
            }
        default:
            return state;
    }
}

export const listCategoryReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_LIST_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                category_loading: false,
                categories: action.payload.results,
                category_docs: action.payload.results.docs,
                category_success: true
            }
        case CATEGORY_LIST_FAIL:
            return {
                ...state,
                category_loading: false,
                error: action.payload,
                category_success: false
            }
        default:
            return state;
    }
}


export const getCategoryReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_GET_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case CATEGORY_GET_SUCCESS:
            return {
                ...state,
                category_loading: false,
                category: action.payload,
                category_success: true
            }
        case CATEGORY_GET_FAIL:
            return {
                ...state,
                category_loading: false,
                error: action.payload,
                category_success: false
            }
        default:
            return state;
    }
}

export const editCategoryReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_EDIT_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                category_loading: false,
                category : action.payload,
                category_success: true
            }
        case CATEGORY_EDIT_FAIL:
            return {
                ...state,
                category_loading: false,
                error: action.payload,
                category_success: false
            }
        default:
            return state;
    }
}

export const deleteCategoryReducer = (state=initialState, action) => {
    switch(action.type){
        case CATEGORY_DELETE_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case CATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                category_loading: false,
                category_success: true,
                categories: action.payload.results,
                category_docs: state.category_docs.filter((c) => c._id !== action.payload._id)
            }
        case CATEGORY_DELETE_FAIL:
            return {
                ...state,
                category_loading: false,
                error: action.payload,
                category_success: false
            }
        default:
            return state;
    }
}


export const categoryStatusReducer = (state={}, action) => {
    switch(action.type){
        case CATEGORY_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                category_status_loading: true
            }
        case CATEGORY_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                cateory_status_loading: false,
                status: action.payload
            }
        case CATEGORY_STATUS_UPDATE_FAIL:
            return {
                ...state,
                category_status_loading: false,
                category_status_error: action.payload
            }
        default:
            return state;
    }
}