export const LIST_RECENT_ORDERS_REQUEST = "LIST_RECENT_ORDERS_REQUEST";
export const LIST_RECENT_ORDERS_SUCCESS = "LIST_RECENT_ORDERS_SUCCESS";
export const LIST_RECENT_ORDERS_FAIL = "LIST_RECENT_ORDERS_FAIL";

export const GET_RECENT_ORDERS_REQUEST = "GET_RECENT_ORDERS_REQUEST";
export const GET_RECENT_ORDERS_SUCCESS = "GET_RECENT_ORDERS_SUCCESS";
export const GET_RECENT_ORDERS_FAIL = "GET_RECENT_ORDERS_FAIL";

export const GET_PRODUCT_STATISTICS_REQUEST = "GET_PRODUCT_STATISTICS_REQUEST";
export const GET_PRODUCT_STATISTICS_SUCCESS = "GET_PRODUCT_STATISTICS_SUCCESS";
export const GET_PRODUCT_STATISTICS_FAIL = "GET_PRODUCT_STATISTICS_FAIL";
