import {  WAITLIST_GET_FAIL, WAITLIST_GET_REQUEST, WAITLIST_GET_SUCCESS  } from "../../../constants/waitList"

const initialState = {
    waitlist_loading: false,
    waitlist_success: false,
    waitlist_docs: [],
    waitlists: [],
    waitlist: {},
    error: "",
}


export const getWaitlist = (state=initialState, action) => {
   switch(action.type){
       case WAITLIST_GET_REQUEST: 
           return {
            ...state,
            waitlist_loading : true
           }
       case WAITLIST_GET_SUCCESS:
           return {
               ...state,
               waitlist_loading : false,
               waitlist_success : true,
               waitlist_docs: action.payload.results.docs,
               waitlists: action.payload.results
           }
       case WAITLIST_GET_FAIL:
           return {
               ...state,
               waitlist_loading : false,
               waitlist_success : false,
               error: action.payload,
           }
       default:
           return state
   }
} 
