import {useEffect, useState} from "react";
import { Switch, Alert } from 'antd';
import {BsFillPlusCircleFill} from "react-icons/bs";
import { FaEdit } from 'react-icons/fa';
import "./payment.css";
import { FaTrashAlt } from 'react-icons/fa';
import CreatePaymentMethod from './CreatePaymentMethod';
import EditPaymentMethod from './EditPaymentMethod';
import DeleteModal from '../Reusables/DeleteModal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Loaders from '../Reusables/Loader';
import { deletePaymentGatewayAction, getPaymentGatewayAction, paymentGatewayListAction } from "../../actions/Admin/PaymentGateway";

const PaymentMethods = () => {
    const history = useHistory();
    const search = useLocation().search;
    const createPayment = new URLSearchParams(search).get("create");
    const editPayment = new URLSearchParams(search).get("edit");
    const deletePayment = new URLSearchParams(search).get("delete");
    const [paymentId, setPaymentId] = useState("");
    const dispatch = useDispatch(null);

    const {payment_list_loading, payment_gateways, payment_list_error} = useSelector(state => state.paymentGatewayList);
    const { delete_gateway_loading, success} = useSelector(state => state.deletePaymentGateway)

    const [successful, setSuccessful] = useState(false)
    const handleDelete = (e) => {
        e.preventDefault()
        dispatch(deletePaymentGatewayAction(paymentId));
   
    }
    useEffect(() => {
       dispatch(paymentGatewayListAction())
    }, [dispatch])

    useEffect(() => {
        setSuccessful(success)
    }, [success])

    useEffect(() => {
        dispatch(getPaymentGatewayAction(paymentId))
    }, [dispatch, paymentId])
    useEffect(() => {
        const successCheck = setTimeout(() => {
            setSuccessful(false)
        }, 3000);
        return () => clearTimeout(successCheck)
    }, [successful])

    return (
        <>  
             {  payment_list_loading && payment_list_loading ?
                    <div style={{textAlign: "center", margin: "3rem auto 5rem auto", display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center"}}><Loaders loading={payment_list_loading} color="#000000" size={50}/></div> : 
                payment_list_error && payment_list_error ?   <Alert message="Failed"
                description={payment_list_error}
                type="error"
                closable
                className="my-3"
                />  :
                <div className="card card_wrapper  seller_table_wrapper mt-4">
            
                    <div>
                    <div className="payment_title_header">
                        <div className="header_order_title">PAYMENT METHODS</div>   
                        <div className="btn_add_new" onClick={() => history.push("?create=new")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New</span></div>
                            
                    </div>
                    <div className="card-body">
                    {
                           payment_gateways.length > 0 ?  <div className="table-responsive">
                       
                           <table className="table seller_table">
       
                               
                           <thead className="tborder table-header" align="middle">
                               <tr className="align_student_table">
                                   <th scope="col"  className="header___name">Display Image</th>
                                   <th scope="col" className="header___status">Name</th>
                                   <th scope="col" className="header__userId">Status</th>
                                   <th scope="col" className="header_phone">Edit</th>
                                   <th scope="col" className="header_email">Delete</th>
                               </tr>
                           </thead>
                              {payment_gateways && payment_gateways &&  payment_gateways.map(payment => (
                                   <tbody align="middle" key={payment._id}>
                                   <tr>
    
                                            <td><img src={`${payment.image_url}`} alt={payment.name}  className="payment-img"/></td>
                                
                                       <td className="item">{payment.name.charAt(0).toUpperCase() + payment.name.slice(1)}</td>
                                       <td className="item"><Switch defaultChecked  /></td>
                                       <td className="item">
                                           <div className="deactivate toggle-class" onClick={() => {history.push("?edit=edit");setPaymentId(payment._id)}}>
                                               <FaEdit />
                                           </div>
                                       </td>
                                       <td  className="item">
                                           <div className="text-danger" onClick={() => {history.push("?delete=yes"); setPaymentId(payment._id)}}>
                                               <FaTrashAlt className="deletebtn"/>
                                           </div>
                                       </td>
                                   </tr>
                                   
                               </tbody>
                              )) }
                               
                   </table>
                   </div> : <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                           
                        }
                    </div>
                  
                            
                {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Paginations/></div>   */}
            </div> 
            {createPayment === "new" && <CreatePaymentMethod/>}

            {editPayment === "edit" && <EditPaymentMethod id={paymentId} />}
            {deletePayment === "yes" &&
                <DeleteModal header={"DELETE PAYMENT METHOD"}>
                    <h3 className="delete-header">Are you sure you want to delete this Payment method?</h3>
                    <div className="cta-btn-delete">
                        <button className="delete-confirm" onClick={handleDelete}>{delete_gateway_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={delete_gateway_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => history.push("/dashboard/payment-methods")}>CANCEL</button>
                    </div>
                </DeleteModal>
            }
     </div>  
      }
       
        
      </>
    )
}

export default PaymentMethods