
import { LIST_RECENT_ORDERS_FAIL, LIST_RECENT_ORDERS_SUCCESS, LIST_RECENT_ORDERS_REQUEST} from "../../../constants/sales/recentOrdersConstant";

const initialState = {
    list_recent_orders_error: "",
    list_recent_orders_loading: false,
    list_recent_orders: [],
    list_recent_orders_docs: [],
    list_recent_orders_doughnut_data: [],
    list_recent_orders_total_revenue: 0,
    get_product_statistics_error: "",
    get_product_statistics_loading: false,
    get_product_statistics: {}
}

export const recentOrdersReducer = (state=initialState, action) => {
        const {type, payload} = action;
        switch(type){
            case LIST_RECENT_ORDERS_REQUEST:
                return {
                    ...state,
                    list_recent_orders_loading: true
                }
            case LIST_RECENT_ORDERS_SUCCESS:
                return {
                    ...state,
                    list_recent_orders_loading: false,
                    list_recent_orders: payload.recentOrders,
                    list_recent_orders_docs: payload.recentOrders.docs,
                    list_recent_orders_doughnut_data: payload.doughnutData,
                    list_recent_orders_total_revenue: payload.totalRevenue
                }
            case LIST_RECENT_ORDERS_FAIL:
                return {
                    ...state,
                    list_recent_orders_loading: false,
                    list_recent_orders_error: payload
                }
            default:
                return state
        }
}
