import {useState} from "react";
import { useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import {FaEdit, FaTrashAlt} from "react-icons/fa";


const SubCategory = ({selected, type, setId,setCatName, setSubDeleteSuccess, setTypeDeleteError}) => {
    const history = useHistory()
    const [toggleSubCategory, setToggleSubCategory] = useState(true)
    function onChange(checked) {
        setToggleSubCategory(checked)
      }
  
    return (
        <div>
            {type ? <>
                <div className="sub-category-header">
                    <div className="sub-category-grid">Sub- Categories</div>
                    <div className="sub-category-grid">Status</div>
                    <div className="sub-category-grid">Edit</div>
                    <div className="sub-category-grid">Delete</div>
                </div>
                <hr />
                <div className="sub-border">
                {type  && type.type.map(({name, _id}) => (
                    <div  key={_id}>
                        <div className="sub-grid-item">
                            <div className={toggleSubCategory? "toggle-class  sub-items": "toggle-class deactivate  sub-items"}>{name}</div> 
                            <div  className={toggleSubCategory? "toggle-class  sub-items": "toggle-class deactivate  sub-items"}><Switch defaultChecked onChange={onChange} />&nbsp;&nbsp;<span>{toggleSubCategory? "Active": "Inactive"}</span></div>
                            <div className="sub-items deactivate toggle-class" onClick={() => {history.push("/dashboard/categories?type=edit-type"); setId(_id); setCatName(name); setTypeDeleteError(""); setSubDeleteSuccess("")}}><FaEdit /></div>
                            <div className="text-danger text-center" style={{cursor: "pointer"}} onClick={() =>{history.push(`/dashboard/categories?id=${_id}-sub-category-delete`); setId(_id); setCatName(name); setSubDeleteSuccess("")}}><FaTrashAlt /></div>
                        </div>
                        <hr /> 
                   </div> 
                ))}
                </div>  
                </> : ""}
        </div>
    )
}

export default SubCategory;