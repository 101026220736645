import { EARNING_CHART_FAIL, EARNING_CHART_REQUEST, EARNING_CHART_SUCCESS, EARNING_LIST_FAIL, EARNING_LIST_REQUEST, EARNING_LIST_SUCCESS, STORE_EARNING_LIST_FAIL, STORE_EARNING_LIST_REQUEST, STORE_EARNING_LIST_SUCCESS, TOTAL_EARNING_FAIL, TOTAL_EARNING_REQUEST, TOTAL_EARNING_SUCCESS, TRANSACTION_LIST_FAIL, TRANSACTION_LIST_REQUEST, TRANSACTION_LIST_SUCCESS } from "../../../constants/transactionsConstant"

const initialState = {
    transactions_loading: false,
    earning_loading: false,
    store_earning_loading: false,
    earning_chart_loading: false,
    total_earning_loading: false,
    transaction_success: false,
    transactions_list: [],
    transactions_list_docs: [],
    earning_lists: [],
    earning_lists_docs: [],
    store_earning_lists: [],
    store_earning_lists_docs: [],
    store_earning_withdraw_transactions: [],
    earning_chart: {},
    total_earning: {},
    transaction_error: "",
    earning_error: "",
    store_earning_error: "",
    earning_chart_error: "",
    total_earning_error: "",

}


export const transactionList = (state=initialState,action) => {
    switch(action.type){
        case TRANSACTION_LIST_REQUEST:
            return {
                ...state,
                transactions_loading: true,
                transaction_success: false,
            }
        case TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                transactions_loading: false,
                transaction_success: true,
                transactions_list: action.payload,
                transactions_list_docs: action.payload.docs
            }
        case TRANSACTION_LIST_FAIL:
            return {
                ...state,
                transactions_loading: false,
                transaction_success: false,
                transaction_error: action.payload
            }
        default:
            return state
    }
}

export const earningList = (state=initialState,action) => {
    switch(action.type){
        
        case EARNING_LIST_REQUEST:
            return {
                ...state,
                earning_loading: true
            }
           
        case EARNING_LIST_SUCCESS:
            return {
                ...state,
                earning_loading: false,
                earning_lists: action.payload,
                earning_lists_docs: action.payload.docs
            }
        case EARNING_LIST_FAIL:
            return {
                ...state,
                earning_loading: false,
                earning_error: action.payload
            }
        default:
            return state
    }
}



export const storeEarningList = (state=initialState,action) => {
    switch(action.type){
        
        case STORE_EARNING_LIST_REQUEST:
            return {
                ...state,
                store_earning_loading: true
            }
           
        case STORE_EARNING_LIST_SUCCESS:
            return {
                ...state,
                store_earning_loading: false,
                store_earning_lists: action.payload,
                store_earning_withdraw_transactions: action.payload.withdraw_transactions,
                store_earning_lists_docs: action.payload.withdraw_transactions.docs
            }
        case STORE_EARNING_LIST_FAIL:
            return {
                ...state,
                store_earning_loading: false,
                store_earning_error: action.payload
            }
        default:
            return state
    }
}

export const earningChart = (state=initialState,action) => {
    switch(action.type){
        
        case EARNING_CHART_REQUEST:
            return {
                ...state,
                earning_chart_loading: true
            }
           
        case EARNING_CHART_SUCCESS:
            return {
                ...state,
                earning_chart_loading: false,
                earning_chart: action.payload,
                
            }
        case EARNING_CHART_FAIL:
            return {
                ...state,
                earning_chart_loading: false,
                earning_chart_error: action.payload
            }
        default:
            return state
    }
}


export const totalEarning = (state=initialState,action) => {
    switch(action.type){
        
        case TOTAL_EARNING_REQUEST:
            return {
                ...state,
                total_earning_loading: true
            }
           
        case TOTAL_EARNING_SUCCESS:
            return {
                ...state,
                total_earning_loading: false,
               total_earning: action.payload,
                
            }
        case TOTAL_EARNING_FAIL:
            return {
                ...state,
                total_earning_loading: false,
                total_earning_error: action.payload
            }
        default:
            return state
    }
}
