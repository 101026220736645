import { useState } from "react";
import { useHistory } from 'react-router-dom';
import Loaders from "../Reusables/Loader";
import { useDispatch, useSelector } from 'react-redux';
import { SizeCreateAction } from './../../actions/Admin/ProductCategoryAction';

const SizeModal = () => {
     const [size, setSize] = useState("");
     const [quantity, setQuantity] = useState("");
     const dispatch = useDispatch();
     const history = useHistory(null);

     const {size_create_loading, size_create_error, size_create_result} = useSelector(state => state.createCategorySize);
     const dataToSubmit = {size, quantity};
     
     const handleSizeCreate = (e) => {
           e.preventDefault();
           dispatch(SizeCreateAction(dataToSubmit))
           setQuantity(null)
           setSize(null)
     }
     
    return (
        <div className="modal_for_type">
              <div className="card type_modal">
              <div className="data_heading">
                <div className="header_order_title type_header">Create Size</div>
              </div>
              <form className="add_type_form" onSubmit={handleSizeCreate}>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Size</label>
                      <input type="text" className="type_input" onChange={(e) => setSize(e.target.value)} value={size}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Quantity</label>
                      <input type="text"  className="type_input" onChange={(e) => setQuantity(e.target.value)} value={quantity}/>
                    </div>
                  </div>
                  
                    <ul className="mt-4 remove-bullet-points text-danger text-center">
                          {size_create_error?.errors && size_create_error?.errors.map((error, index) => (
                              <li key={index}>{error.msg}</li>
                          ))}
                      </ul>   
     
                  <p className="text-success text-center">{size_create_result && size_create_result.message}</p>
                  <div className="cta_btn">
                    <button className="type_button">{size_create_loading ? <Loaders loading={size_create_loading} color="#ffffff" size={20}/> : "submit"}</button>
                    <button className="type_cancel_button" onClick={() => history.push("/dashboard/categories/add")}>Cancel</button>
                  </div>
              </form>
           </div>

      
        </div>
    )
}


export default SizeModal