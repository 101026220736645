import { useState } from "react";
import { useHistory } from 'react-router-dom';
import Loaders from "../Reusables/Loader";
import { useSelector, useDispatch } from 'react-redux';
import { GenderCreateAction } from './../../actions/Admin/ProductCategoryAction';

const GenderModal = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState(""); 
    const history = useHistory(null)
    const {gender_create_loading, gender_create_error, gender_create_result} = useSelector(state => state.createCategoryGender);

    const dataToSubmit = {name}

    const handleCreateGender = (e) => {
        e.preventDefault();
        dispatch(GenderCreateAction(dataToSubmit))
     }

    return (
        <div className="modal_for_type">
              <div className="card type_modal">
              <div className="data_heading">
                <div className="header_order_title type_header">ADD GENDER FIELD</div>
              </div>
              <form className="add_type_form" onSubmit={handleCreateGender}>
                      <label htmlFor="category" className="type_label">Input Gender</label>
                      <input type="text" className="type_input" onChange={(e) => setName(e.target.value)} value={name}/>
                  <div className="text-danger text-center">
                    <ul className="mt-4 remove-bullet-points">
                        {gender_create_error?.errors && gender_create_error?.errors.map((error, index) => (
                            <li key={index}>{error.msg}</li>
                         ))}
                      
                    </ul>
                  </div>
                  <p className="text-danger text-center">{gender_create_error && gender_create_error.message}</p>
                  <p className="text-success text-center">{gender_create_result && gender_create_result.message}</p>
                  <div className="cta_btn">
                    <button className="type_button">{gender_create_loading ? <Loaders loading={gender_create_loading} color="#ffffff" size={20}/> : "submit"}</button>
                    <button className="type_cancel_button" onClick={() => history.push("/dashboard/categories/add")}>Cancel</button>
                  </div>
              </form>
           </div>

        </div>
    )
}


export default GenderModal