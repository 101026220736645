import { WAITLIST_GET_FAIL, WAITLIST_GET_REQUEST, WAITLIST_GET_SUCCESS } from "../../constants/waitList";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token

export function GetWaitlist ({page, limit}) {
  return async (dispatch, getState) => {
    dispatch({type: WAITLIST_GET_REQUEST})
    
    try {
    
    const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/waitlist/list?page=${page}&limit=${limit}`, {
      method: "GET", 
      headers: {
        "Content-Type": "application/json",
        ACCEPT: "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    const data = await res.json();
    if(data.error){
         return dispatch({type: WAITLIST_GET_FAIL, payload: data.message})
    }
    dispatch({type: WAITLIST_GET_SUCCESS, payload: data})
    } catch (error) {
      const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
        dispatch({type: WAITLIST_GET_FAIL, payload: message})
    }
  }
}