import { useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import moment from 'moment';
import SortDropDown from '../Orders/SortDropdown';
import {BsFunnel,BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { BasicBuyersAction } from '../../actions/Admin/CustomerDBAction';
import { AppContext } from '../../AppContext';
import "./customer.css";
import { UserSearch } from '../../actions/Admin/search/SearchAction';
const BuyerTable = ({match, basic_buyers_users_data_docs, basic_buyers_users_data, basic_buyer_error}) => {
     const {itemLimit,setItemLimit, setPageNumber, pageNumber, searchTerm} = useContext(AppContext);
     const {user_search_docs,user_search} = useSelector(state => state.userSearchReducer)
     const searchBuyers = searchTerm !== "" ?  user_search_docs : basic_buyers_users_data_docs; 
     const dispatch = useDispatch(null)
    const handleNextPage = (page_number) => {
        const page = page_number;
        const limit = +itemLimit;
        const data = { page, limit };
        dispatch(BasicBuyersAction(data))
    };

    const totalPages = searchTerm !== "" ? (user_search && user_search.totalPages) : (basic_buyers_users_data  && basic_buyers_users_data.totalPages);
  const page = searchTerm !== "" ? (user_search && user_search.page) : (basic_buyers_users_data  && basic_buyers_users_data.page);
  const prevPage = searchTerm !== "" ? (user_search && user_search.prevPage) : (basic_buyers_users_data  && basic_buyers_users_data.prevPage);
  const nextPage = searchTerm !== "" ? (user_search && user_search.nextPage) : (basic_buyers_users_data  && basic_buyers_users_data.nextPage);
  const limitItem = searchTerm !== "" ? (user_search && user_search.totalDocs) : (basic_buyers_users_data  && basic_buyers_users_data.totalDocs);

const hasNextPage = basic_buyers_users_data && basic_buyers_users_data.hasNextPage;
const hasPrevPage = basic_buyers_users_data && basic_buyers_users_data.hasPrevPage



  const search = useMemo(() => {
    const page = pageNumber;
    const limit = limitItem || itemLimit;
    const data = {searchTerm, page, limit}
    if(searchTerm){
        dispatch(UserSearch(data))
    }
}, [dispatch, searchTerm, itemLimit,limitItem, pageNumber]);
  useEffect(() => search, [search])

  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
   
}, [page, setPageNumber])
    return (
        <>
         {basic_buyer_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{basic_buyer_error || basic_buyer_error.message }</p>
                </div>
            }
       
                    <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                        <div className="header_order_title">BASIC BUYERS</div>
                    
                        <div className="button_sort"><BsFunnel className="icon_one"/><span>  <SortDropDown /></span></div>
                    </div>
                    <div className="card-body">
                    {searchBuyers && searchBuyers.length > 0 ? 
                           <div className="table-responsive">
                           <table className="table seller_table">
       
                               
                           <thead className="tborder table-header" align="left">
                               <tr className="align_student_table">
                                   <th scope="col"  className="header___name">Name</th>
                                   <th scope="col" className="header___status">Status</th>
                                   <th scope="col" className="header__userId">User ID</th>
                                   <th scope="col" className="header_phone">Phone Number</th>
                                   <th scope="col" className="header_email">Email Address</th>
                                   <th scope="col" className="header__orders">Orders</th>
                                   <th scope="col" className="header_last_transaction">Last Transaction</th>
                                   <th scope="col"></th>
                               </tr>
                           </thead>
                            {searchBuyers && searchBuyers.map((basic_user) => {
                                    
                                  return <tbody align="left" key={basic_user._id}>
                                         <tr className="item_suspend_active">
                                       <td><Link to={`${match.url}/basic-buyers-details/${basic_user._id}`} className="item_name_link">{`${basic_user.first_name} ${basic_user.last_name}`}</Link></td>
                                       <td className={basic_user.status === "active" ? "item__active": "item__inactive"}>{basic_user.status.charAt(0).toUpperCase() + basic_user.status.substr(1)}</td>
                                       <td className="item">{basic_user._id}</td>
                                       <td className="item">{basic_user.phone}</td>
                                       <td  className="item">{basic_user.email}</td>
                                       <td className="item">{basic_user?.order_info.length === 0 ? "0" : basic_user?.order_info.length}</td>
                                       <td className="item">{basic_user?.order_info.length === 0 ? "-" :  moment(basic_user?.order_info[0].date).format("DD/MM/YYYY, h:mma")}</td>
                                   </tr>
                                  </tbody>
                         })}
                                     </table>
                   </div> : <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                 }
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                    <div className="page_number">{basic_buyers_users_data_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                    </div>
                    {totalPages > 1 ?
                    <div className="paginate_reuse">
                        <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                    </div> : null
                    }
                </div>   
            </div>
           
        </>
    )
}

export default BuyerTable

