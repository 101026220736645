import { useContext } from 'react';
import { FaTimes } from "react-icons/fa";
import { BsArrowUpRight, BsArrowDownLeft } from 'react-icons/bs';
import moment from 'moment';
import { AppContext } from "../../AppContext";

const TransactionDetail = () => {
    const {setShowTransactionModal, transactionInfo,nFormatter,numberSeperator} = useContext(AppContext);
    const {trxn_type, date,amount,narrative,account_number,bank_name} = transactionInfo && transactionInfo
    
    return (
        <div className="transaction_detail_modal">
        <div className="card transaction_modal">
        <div className="transaction_detail_heading">
          <div className="header_order_title type_header transaction_header">
              <div className="modal-closebtn"  onClick={() => setShowTransactionModal(false)}>
                    <FaTimes className="closebtn"/>
              </div>
              <div className="header-transaction-details">
                  <div className="transaction-type-detail type-of-transaction">{trxn_type === "debit" ? <BsArrowDownLeft className="withdraw-icon"/> : <BsArrowUpRight className="sale-icon"/>}</div>
                  <div className="transaction-text">{trxn_type === "debit" ? "Withdrawal" : "Credit"}</div>
                  <div className="transaction-date">{moment(date).format("MMM, D/MM/YYYY")}</div>
              </div>
          </div>
        </div>
        <div className="card-body transaction-card-body">
            <div className="transaction-amount">
                <div className="transaction-figure">
                     &#x20A6; {amount.length > 7 ? nFormatter(amount) : numberSeperator(amount) }
                </div>
                <div className="trans-description">
                     {narrative}
                </div>
            </div>
            <div className="transaction-account">
                <div className="account-placeholder">
                       Account Number
                </div>
                <div className="account-number">
                    {account_number === "" ? "None Provided" : account_number}
                </div>
            </div>
            <div className="transaction-bank">
                <div className="bank-placeholder">
                      Recipient Bank
                </div>
                <div className="recipient-bank">
                    {bank_name === "" ? "None Provided" : bank_name}
                </div>
            </div>
        </div>
       
     </div>


  </div>
    )
}

export default TransactionDetail;