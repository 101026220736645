import { useHistory } from "react-router-dom"
const AddPermission = () => {
    const history = useHistory()
    return (
        <div className="payment_modal">
        <div className="card payment_add_method">
          <div className="data_heading">
            <div className="header_order_title type_header">ADD NEW PERMISSION</div>
          </div>
         
            <form className="add_payment_form">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label htmlFor="category" className="payment_label">Enter permission name</label>
                    <input type="text" className="payment_input"/>
                  </div>
                </div>
                
                <div className="cta_btn">
                  <button className="type_button">SAVE</button>
                  <button className="type_cancel_button" onClick={() => {history.push("/dashboard/settings/permissions")}}>CANCEL</button>
                </div>
            </form>
       
      </div>
</div>
    )
}

export default AddPermission