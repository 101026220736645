import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import SingleStore from "./SingleStoreDetails";
import {FaArrowLeft} from "react-icons/fa";
import { BiDownload } from "react-icons/bi";
import DownloadCSVModal from "../Reusables/DownloadCSVModal";
import { AppContext } from "../../AppContext";
import { getStoreAction } from "../../actions/Admin/StoreAction"

 const StoreOwnerItem = ({showDownloadModal, openDownloadModal, setShowDownloadModal}) => {
     const {id} = useParams()
     const { handleActiveTab} = useContext(AppContext);
     const history = useHistory(null);
     const dispatch = useDispatch();
     const {error, store} = useSelector(state => state.getStore);
     const storeName = store && store.results && store.results.name;
     const storeImage = store && store.results && store.results.image_url;
     const noOfSales = store && store.results && store.results.no_of_sales;
     const totalVisits = store && store.results && store.results.visit && store.results.visit.length;
     const products = store && store.results && store.results.products;
     const totalUploads = products && products?.length;


     useEffect(() => {
        dispatch(getStoreAction(id))
     }, [id, dispatch]);

     return (
        <div>
          {error ? <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{error || error.message }</p>
                </div> :   
          <>   
            <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div>
                        <div className="data_heading store-owner-page"  onClick={() => {history.push("/dashboard/customer-database"); handleActiveTab(1)}}>
                            <FaArrowLeft style={{cursor: "pointer"}}/><div className="header_order_title store-owner-header">STORE OWNER</div>
                        
                        </div>
                        <div className="card-body sub-category-content-body">
                            {(store && store.results !== null) || (store && store.results?.length > 0) ? <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                   <div className="store-img-name">
                                       <div className="image-div"><img src={storeImage || "/alpha-favicon.png"} alt="store-icon"/></div>
                                       <div className="store-name-div">
                                           <span>Store Name</span>
                                           <span>{storeName}</span>
                                       </div>
                                   </div>
                                   <button className="inventory-btn" onClick={openDownloadModal}><BiDownload />Download Inventory</button>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div className="card">
                                        <div className="card-body body-of-card card-prod-upload">
                                            <h6>TOTAL NUMBER OF PRODUCTS UPLOADED</h6><br />
                                            <p>{`${totalUploads}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                   <div className="card">
                                        <div className="card-body body-of-card card-prod-sold">
                                            <h6>TOTAL NUMBER OF PRODUCTS SOLD</h6><br />
                                            <p>{noOfSales}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div className="card">
                                        <div className="card-body body-of-card card-store-visit">
                                            <h6>TOTAL NUMBER OF STORE VISITS</h6><br />
                                            <p>{totalVisits}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> : <h4 className="text-danger text-center">No Item found!</h4>}
                      
                        </div>
                    
                </div> 
            </div>
            <SingleStore products={products}/>
            {showDownloadModal && <DownloadCSVModal setShowDownloadModal={setShowDownloadModal}/>}
            </>
        }
        </div>
     )
 }

 export default StoreOwnerItem