import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../AppContext";
import { GetWaitlist } from './../../actions/Admin/GetWaitlist';
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import moment from "moment";
import Loaders from "../Reusables/Loader";

const WaitList = () => {
    const dispatch = useDispatch(null);
    const { itemLimit, setItemLimit } = useContext(AppContext)
    const {waitlist_docs,waitlists, error,waitlist_loading} = useSelector(state => state.getWaitlist);
    useEffect(() => {
        const page = 1;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(GetWaitlist(data))
       
  }, [dispatch, itemLimit]);

  const handleNextPage = (page_number) => {
    const page = page_number;
    const limit = +itemLimit;
    const data = { page, limit };
    dispatch(GetWaitlist(data));
    
};
const totalPages = waitlists && waitlists.totalPages;
const page = waitlists && waitlists.page;
const prevPage = waitlists && waitlists.prevPage;
const nextPage = waitlists && waitlists.nextPage;
const hasNextPage = waitlists && waitlists.hasNextPage;
const hasPrevPage = waitlists && waitlists.hasPrevPage
    return (
        <>
              {
                    error && error ? <div className="view_admins_error"> <p className="mt-1">{error}</p></div> :
                    <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                        <div className="header_order_title role_header">WaitList</div>
                    </div>
                    <div className="card-body">
                             {
                                 waitlist_docs.length > 0 ? <div className="table-responsive">
                                 <table className="table">
                                     <thead className="tborder" align="middle">
                                             <tr className="align_student_table">
                                                 <th scope="col" className="header___id">Referene ID</th>
                                                 <th scope="col" className="header___email">Email Address</th>
                                                 <th scope="col" className="header___phone">Date created</th>
                                             </tr>
                                     </thead>
                                     <tbody align="middle">
                                     {waitlist_docs &&  waitlist_docs.map(waitlist => (
                                         <tr key={waitlist._id} className="align_student_table" align="middle">
                                         <td className="item">{waitlist._id}</td>
                                         <td className="item">{waitlist.email}</td>
                                         <td className="item">{moment(waitlist.createdAt).format("D/MM/YYYY h:mm a")}</td>
                                         
                                     </tr>
                                     ))}
                                     </tbody>
                                 </table>
                             </div> :  <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                             }
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                        <div className="page_number">{waitlist_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                            <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                        </div>
                        {totalPages > 1 ?
                        <div className="paginate_reuse">
                            <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{waitlist_loading ? <div style={{display: "flex", justifyContent: "center", width: "100%", alignContent: "center", height: "auto"}}><Loaders loading={waitlist_loading} color="#000000" size={10} /></div> : page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                        </div> : null
                               }
                    </div>   
                    </div>

              }
        </>
       
    )
}
export default WaitList;