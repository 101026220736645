import React, { useContext, useRef, useState,useEffect } from 'react';
import "./coupon.css";
import { useCreateCouponMutation, useDeleteCouponMutation, useListCouponsQuery, useListPromotionsQuery } from '../../actions/Admin/coupon/Coupon';
import { AppContext } from '../../AppContext';
import { handleAlphaError } from '../../utils/handleAlphaError';
import handlePagination from '../../utils/Pagination';
import Loaders from '../Reusables/Loader';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import moment from 'moment';
import { IoTrashOutline } from "react-icons/io5";
import  DatePicker from 'react-datepicker';
import { HiOutlineChevronDown } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";

function Coupon(){
    const deleteRef = useRef();
    const addRef = useRef();
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [discountType, setDiscountType] = useState("");
    const [influencer, setInfluencer] = useState("");
    const [discount, setDiscount] = useState(0);
    const [couponCode, setCouponCode] = useState("")
    const [couponId, setCouponId] = useState({id: "", code: ""})

    const {itemLimit, setItemLimit, pageNumber, setPageNumber} = useContext(AppContext);
    const {isError, error, data, isFetching, isLoading} = useListCouponsQuery({page: pageNumber, limit: itemLimit});
    const {data:promoData, error:promoError, isError: isPromoError, isLoading: isPromoLoading} = useListPromotionsQuery();
    const [createCoupon, {isLoading: isCouponLoading, isError: isCouponError,data: couponData, isSuccess, error: couponError, reset}] = useCreateCouponMutation();
    const [deleteCoupon, {isLoading: isDeleteCouponLoading, isError: isDeleteCouponError, isSuccess: isDeleteCouponSuccess, error: deleteCouponError, reset: resetDelete}] = useDeleteCouponMutation()

    const errorMessage = handleAlphaError(isError,error, "data");
    const errorMessage2 = handleAlphaError(isPromoError,promoError, "data");
    const couponErrorMessage = handleAlphaError(isCouponError,couponError, "data");
    const deleteCouponErrorMessage = handleAlphaError(isDeleteCouponError,deleteCouponError, "data");
    const results = data?.results;
    const docs = results?.docs;    

    const promoDocs =  promoData?.results

    const hasNextPage = results?.hasNextPage;
    const hasPrevPage = results?.hasPrevPage;
    const page = results?.page;
    const totalPages = results?.totalPages;

    const dataToSubmit = {
         expiry_date: moment(startDate).format("YYYY-MM-DD"),
        influencer,
        coupon_code: couponCode,
        discount_pencentage: Number(discount),
        type: discountType
    }

    const resetData = () => {
        deleteRef?.current?.close();
        addRef?.current?.close();
        setAddModal(false);
        setDeleteModal(false);
        setInfluencer("");
        setDiscount(0);
        setDiscountType("");
        setCouponCode("")
        setStartDate(new Date());
        reset();
        setCouponId({id: "", code: ""});
        resetDelete()
    }

const handleCreateCoupon = async (e) => {
    e.preventDefault();
    if(discountType && discount && influencer){
        await createCoupon(dataToSubmit)
    }
}
   
const handleDeleteCoupon = async (e) => {
    const {id} = couponId
    e.preventDefault();
    if(id){
       await deleteCoupon(id)
    }
}

const handleAddModal = () => {
    setAddModal(true)
}
const handleDeleteModal = (id, code) => {
    setCouponId({id: id, code});
     setDeleteModal(true)
}



const itemSize = () => {
    if(docs?.length > 1){
        return "Items"
    }else {
        return "Item"
    }
}
    useEffect(() => {
        if(deleteModal && deleteRef?.current){
            deleteRef?.current?.showModal()
        }else {
            deleteRef?.current?.close();
    
        }
    }, [deleteModal]);
    useEffect(() => {
    const close = (e) => {
      if(e.key === "Escape"){
        deleteRef?.current?.close();
    
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    },[]);



    useEffect(() => {
        if(addModal && addRef?.current){
            addRef?.current?.showModal()
        }else {
            addRef?.current?.close();
    
        }
    }, [addModal]);
    useEffect(() => {
    const close = (e) => {
      if(e.key === "Escape"){
        addRef?.current?.close();
    
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    },[]);



    useEffect(() => {
        deleteRef?.current?.close();
        addRef?.current?.close();
        setAddModal(false);
        setDeleteModal(false)
    }, [])
    useEffect(() => {
        if(isSuccess){
            setInfluencer("");
            setDiscount(0);
            setDiscountType("");
            setCouponCode("")
            setStartDate(new Date());
        }
    }, [isSuccess])
    
  return (
    <>
        <div className="card card_wrapper  seller_table_wrapper mt-4">
            <div className="data_heading">
                <div className="header_order_title role_header">COUPONS</div>
                <button className='btn__add_new-coupon' onClick={handleAddModal}>ADD NEW COUPON</button>
            </div>
            <div className="card-body">
                { 
                  isLoading ? (<div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>) :
                 isError ?
               ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>) :

                    <div className="table-responsive">
                        {
                            docs?.length > 0 ? <table className="table">
                            <thead className="tborder" align="">
                                    <tr className="align_student_table">
                                        <th scope="col" className="header___id code">Coupon code</th>
                                        <th scope="col" className="header___email code">Discount name</th>
                                        <th scope="col" className="header___phone code">Start Date</th>
                                        <th scope="col" className="header___id code">Expiry Date</th>
                                        <th scope="col" className="header___email code">Discount %</th>
                                        <th scope="col" className="header___phone code">Discount type</th>
                                        <th scope="col" className="header___phone code">Status</th>
                                        <th scope="col" className="header___phone code"></th>
                                    </tr>
                            </thead>
                            <tbody align=""> 
                                    {
                                    docs?.map(({_id,expiry_date,discount_pencentage,influencer, code, createAt}) => (
                                            <tr key={_id}>
                                                <td>
                                                    {code}
                                                </td>
                                                <td>
                                                    {influencer}
                                                </td>
                                                <td>
                                                {moment(createAt).format("DD/MM/YYYY")}
                                                </td>
                                                <td>
                                                    {moment(expiry_date).format("DD/MM/YYYY")}
                                                </td>
                                                <td>
                                                    {discount_pencentage}%
                                                </td>
                                                <td>
                                                    -
                                                </td>
                                                <td className='text-success'>
                                                   -
                                                </td>
                                                <td onClick={() => {handleDeleteModal(_id, code)}}>
                                                    <IoTrashOutline size={18} color='#ED3237' className='cursor-pointer'/>
                                                </td>
                                                
                                            </tr>
                                    )) 
                                    }
                            
                            </tbody>
                        </table> : <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                        }
                            
                    </div>
                }
            
            </div>
        
        <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                
            <div className="page_number">{itemSize()} per page {" "}{" "}
                <input type="number" value={itemLimit} onChange={(e) => {setItemLimit(e.target.value); setPageNumber(1)}} min={0}/>
            </div>
            {totalPages > 1 ?
            <div className="paginate_reuse">
                <button disabled={isFetching || !hasPrevPage} className="left-chevron" onClick={() => {hasPrevPage && handlePagination("prevPage", hasNextPage, hasPrevPage, setPageNumber)}}><BsChevronLeft /></button><button className="middle-chevron">{isLoading ? <div style={{display: "flex", justifyContent: "center", width: "100%", alignContent: "center", height: "auto"}}><Loaders loading={isLoading} color="#000000" size={10} /></div> : page}</button><button disabled={isFetching || !hasNextPage} className="right-chevron" onClick={() => {hasNextPage && handlePagination("nextPage", hasNextPage, hasPrevPage, setPageNumber)}}><BsChevronRight /></button>
            </div> : null
                }
        </div>   
        </div>
        {
            addModal &&  <dialog ref={addRef} className='px-0 dialog-box' data-testid="add-coupon-modal">
                <div className='w-full  '>
                    <div className='add__new_coupon-header'>
                       <div className='coupon-header-add text-center'>ADD NEW COUPON</div>
                       <div className='cancel-op' onClick={resetData} data-testid="close-modal"><FaTimes size={15}/></div>
                    </div>
                    <hr className='box-hr'/>
                    <form className='dialog__content_box' onSubmit={handleCreateCoupon}>
                        <div className="discount_name-amount">
                             <div className='discount'>
                                 <label>Influencer Name</label>
                                 <input type="text" value={influencer} aria-label='influencer' name='influencer' onChange={(e) => setInfluencer(e.target.value)}/>
                             </div>
                             <div className='discount'>
                                 <label>Coupon Code</label>
                                 <input type="text" value={couponCode} aria-label='coupon_code' name="coupon_code" onChange={(e) => setCouponCode(e.target.value)}/>
                             </div>
                             <div className='discount'>
                                 <label>Discount Amount (%)</label>
                                 <input type="text" value={discount} aria-label='discount-percentage' name="discount_pencentage" onChange={(e) => setDiscount(e.target.value)}/>
                             </div>
                        </div>
                        <div className="date__discount">
                        
                            <div className='end-date'>
                                <div>
                                   <label>Expiry Date</label>
                                   <div className='calender-style'>
                                    <DatePicker 
                                        selected={startDate}
                                        value={startDate} 
                                        onChange={(date) => setStartDate(date)}
                                        closeOnScroll={true}
                                        dateFormat={"yyyy-MM-dd"}
                                        />
                                       <div className='chev-down'>
                                          <HiOutlineChevronDown />
                                       </div>
                                    </div>
                                </div>
                             
                            </div>
                            <div>
                            { 
                           isPromoLoading ? (<div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isPromoLoading} color="#000000" size={20} /></div>) :  isPromoError ? (<p className='text-center text-danger login__error-msg'>{promoError?.hasOwnProperty("data") ? JSON.parse(errorMessage2)["message"] : JSON.parse(errorMessage2)}</p>) :
                            <div className='discount-type'>
                                <label  htmlFor="discountType">Discount Type</label>
                                <select id="discountType" onChange={(e) => setDiscountType(e.target.value)} aria-label='discount-type' style={{display: "block"}}>
                                    <option value="" data-testid="select-option">Choose discount type</option>
                                    {
                                        promoDocs && promoDocs.map(({_id, 
                                            name}) => (
                                            <option data-testid="select-option" key={_id} value={name}>{name}</option>
                                        ))
                                    }
                                    
                                    
                                </select>
                            </div>
                            }
                            </div>
                        </div>
                      
                        
                        <button className='btn__add_new-coupon text-uppercase'>
                            {
                                 isCouponLoading ? <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isCouponLoading} color="#FFFFFF" size={20} /></div> : "Generate code"
                            } 
                        </button>
                    
                    </form>
                    <div className='success-n-failed'>
                        {
                             isCouponError && <p className='text-center text-danger login__error-msg'>{isCouponError?.hasOwnProperty("data") ? JSON.parse(couponErrorMessage)["message"] : JSON.parse(couponErrorMessage)}</p>
                        }
                        {
                            isSuccess && <p className='text-center text-success'>{couponData.message}</p>
                        }
                     </div>
                </div>
            
             </dialog>
        }


{
            deleteModal &&  <dialog ref={deleteRef} className='px-0 coupon-box'>
                <div className='w-full  '>
                    <div className='add__new_coupon-header'>
                       <div className='coupon-header-add text-center'>DELETE COUPON</div>
                       <div className='cancel-op' onClick={resetData}><FaTimes size={15}/></div>
                    </div>
                    <hr className='box-hr'/>
                    <div className='content'>
                        <div className='text-center'>Are you sure you want to delete {couponId.code}?</div>
                        {
                             isDeleteCouponError && <small className='text-center text-danger'>{isDeleteCouponError?.hasOwnProperty("data") ? JSON.parse(deleteCouponErrorMessage)["message"] : JSON.parse(deleteCouponErrorMessage)}</small>
                        }
                        {
                            isDeleteCouponSuccess && <small className='text-success'>Successfully deleted</small>
                        }
                    
                        <div className={`action-buttons ${isDeleteCouponError && isDeleteCouponSuccess ? "" : "py-2 mt-2"}`}>
                            <button disabled={isDeleteCouponLoading || isDeleteCouponSuccess} onClick={handleDeleteCoupon}>
                                {
                                    isDeleteCouponLoading ? "Wait..." : "Yes"
                                } 
                            </button> 
                            <button disabled={isDeleteCouponLoading} onClick={resetData}>{isDeleteCouponSuccess ? "Close" : "No"}</button>
                        </div>
                    </div>
                </div>
            
             </dialog>
        }
    </>
  )
}

export default Coupon