import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from './../../../helpers/getUrl';
const token = localData && localData.token


export const couponApi = createApi({
        reducerPath: "couponApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
        
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
          }}),
          tagTypes: ["Coupon"],
        endpoints: (builder) => ({
            listCoupons: builder.query({
                query: ({page, limit}) => ({
                    url: `/coupons/list/?page=${page}&limit=${limit}`,
                    method: "get",
                }),
               providesTags: ["Coupon"]
            }),
            createCoupon: builder.mutation({
                query: data => ({
                    url: "/coupons/create",
                    method: "post",
                    body: data
                }),
              invalidatesTags: ["Coupon"]
            }),
            deleteCoupon: builder.mutation({
                query: id => ({
                    url: `/coupons/delete/?id=${id}`,
                    method: "delete",
                }),
                invalidatesTags: ["Coupon"]
            }),
            listPromotions: builder.query({
                query: () => ({
                    url: "/setting/promotion/list",
                    method: "get"
                }),
                providesTags: ["Coupon"]
            }) 
        })
});
export const {useListCouponsQuery, useCreateCouponMutation, useDeleteCouponMutation, useListPromotionsQuery} = couponApi