import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Switch } from 'antd';
import {BsFillPlusCircleFill, BsChevronLeft, BsChevronRight} from "react-icons/bs";

import {FaEdit, FaTrashAlt} from "react-icons/fa";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io"

import SubCategory from './SubCategory';
import { useSelector,useDispatch  } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import { DeleteCategoryAction, ListCategoryAction, CategoryStatusAction, DeleteTypeAction, RemoveTypeAction  } from '../../actions/Admin/ProductCategoryAction';
import { AppContext } from '../../AppContext';
import "./product.css";
import EditTypeModal from './EditType';
import DeleteModal from '../Reusables/DeleteModal';
import Loaders from '../Reusables/Loader';
const AllProducts = () => {
    const [id, setId] = useState("");
    const [catName, setCatName] = useState("")
    const {selected, toggleAccordion, itemLimit, setItemLimit, toggleStatus, selectedStatus, editProductId, setEditProoductId} = useContext(AppContext);
    

    const dispatch = useDispatch();
    const history = useHistory(null)
    const {error,category_docs, categories } = useSelector(state => state.categoryList);
    const [categoryListError, setCategoryListError] = useState("");

    const { type_remove_success, type_remove_error, type_remove_loading} = useSelector(state => state.removeTypeReducer
        );
    const {status} = useSelector(state=> state.categoryStatus)
    const {error: category_delete_error, category_success: success_delete, category_loading} = useSelector(state => state.categoryDelete);
    const [statusError, setStatusError] = useState("")
    const [toggle, setToggle] = useState(false)
    const [categoryDeleteError, setCategoryDeleteError] = useState("");
    const [typeDeleteError, setTypeDeleteError] = useState('')
    const [deleteSuccess, setDeleteSuccess] = useState("");
    const [subDeleteSuccess, setSubDeleteSuccess] = useState("")
    const search = useLocation().search;

    const typeCheck = new URLSearchParams(search).get("type");
    const categoryDelete = new URLSearchParams(search).get("id");

    const checkCategoryDel = categoryDelete && categoryDelete.slice(25);
    const checkSubCatDel = categoryDelete && categoryDelete.slice(25);

      function handleStatus(id, statusNow){
          const categoryId = id;
          const status = statusNow
          const data = {categoryId, status}
        if(categoryId){
            dispatch(CategoryStatusAction(data))
        }
      }
     const handleCategoryDelete = (categoryId) => {
            if(categoryId){
                dispatch(DeleteCategoryAction(categoryId))
            }
            
     }

     const handleTypeDelete = (subCategoryId) => {
         if(subCategoryId){
             dispatch(RemoveTypeAction(subCategoryId))
         }
     }

     const refetch = () => {
        if(success_delete){
            const page = 1;
            const limit = 10;
            const data = {page, limit}
           dispatch(ListCategoryAction(data))  
         }

     }
   
  
    useEffect(() => {
        setCategoryListError(error || error?.message)
    }, [error]);
    useEffect(() => {
        setCategoryDeleteError(category_delete_error?.message)
    }, [category_delete_error]);

    useEffect(() => {
        setTypeDeleteError(type_remove_error?.message)
    }, [type_remove_error]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCategoryListError("");
            setCategoryDeleteError("");
            setStatusError("");
            setTypeDeleteError("")
        }, 4000);
        return () => clearTimeout(timer)
     }, [category_delete_error, type_remove_error])


    useEffect(() => {
        const page = 1;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(ListCategoryAction(data))
       
  }, [dispatch, itemLimit]);

  
  const handleNextPage = (page_number) => {
      const page = page_number;
      const limit = +itemLimit;
      const data = { page, limit };
      dispatch(ListCategoryAction(data));
  };




  const totalPages = categories && categories.totalPages;
  const page = categories && categories.page;
  const prevPage = categories && categories.prevPage;
  const nextPage = categories && categories.nextPage;
  const hasNextPage = categories && categories.hasNextPage;
  const hasPrevPage = categories && categories.hasPrevPage

  useEffect(() => {
    if(success_delete){
        setDeleteSuccess("Successfully deleted!")
    }
}, [success_delete]);
useEffect(() => {
    if(type_remove_success){
        setSubDeleteSuccess("Successfully deleted!")
    }
}, [type_remove_success]);


useEffect(() => {
     if(error && error.message && error.message.includes("Invalid token")){
         window.location.href = "/login"
     }
}, [error])
  
   
 
    return (
        <div>
            
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div>
                    <div className="data_heading_product">
                        <div className="header_order_title">PRODUCT CATEGORY</div>
                        <div className="add-category">
                            <div className="view-sub-categories" onClick={() => history.push("/dashboard/categories/sub-categories")}>View Sub-ategories</div>
                            <div className="ctas_button_product"> 
                                    <div className="btn_add_category" onClick={() => history.push("/dashboard/categories/add")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New Category </span></div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="card-body sub-category-content-body">
                            
                            <div className="product-header">
                                    <div className="header_grid text-center">Category Name</div>
                                    <div className="header_grid text-center">Sub- Categories</div>
                                    <div className="header_grid text-center">Status</div>
                                    <div className="header_grid text-center">Edit</div>
                                    <div className="header_grid text-center">View Sub-Category</div>
                                    <div className="header_grid text-center">Delete</div>
                            </div>
                            {statusError && <p>{statusError && statusError.message}</p>}
                            
                                    <div>
                                    {
                                        category_docs && category_docs?.length > 0 ? category_docs.map((category, index) => {
                                            return <div  key={category._id}>
                                            <div className="product-listing">
                                            <div className={category.active === true ? "toggle-class text-center": "toggle-class deactivate text-center"}>{category.name}</div>
                                            <div className={category.active === true ? "toggle-class text-center": "toggle-class deactivate text-center"}>{category?.type.length}</div>
                                            
                                                <div className="text-center">
                                                    <Switch onChange={() => {category.active === true ? setToggle(false) : setToggle(true)}} onClick={()=> {handleStatus(category._id, toggle);  toggleStatus(index)}} defaultChecked={category.active ? category.active : toggle}/><span className={category.active === true ? "toggle-class ": "toggle-class deactivate"}>&nbsp;&nbsp;{category.active === true ? "Active": "Inactive"}</span></div>
                                                    
                                            
                                                <div className="deactivate toggle-class text-center"><FaEdit onClick={() => {history.push("/dashboard/categories/edit"); setEditProoductId(category._id)}}/></div>
                                                <div className="deactivate toggle-class text-center">
                                                {selected === index ? <IoIosArrowUp onClick={(e) => {toggleAccordion(index); e.stopPropagation();}}/> : <IoIosArrowDown onClick={(e) => {toggleAccordion(index); e.stopPropagation();}}/> }
                                                </div>
                                                <div className="text-danger text-center" style={{cursor: "pointer"}}><FaTrashAlt  onClick={() => {history.push(`/dashboard/categories?id=${category._id}-category-delete`); setCatName(category.name); setId(category._id); setDeleteSuccess("")}}/></div>
                                            
                                        </div>
                                        <hr />
                                        <div className={selected === index ? "subcategory_content show" : "subcategory_content"} >
                                            <SubCategory type={category} setId={setId} setCatName={setCatName} setSubDeleteSuccess={setSubDeleteSuccess} setTypeDeleteError={setTypeDeleteError}/>
                                        </div>
                                        </div>
                                        } ) : <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                                    }
                                    
                                </div>
                                {categoryListError  &&
                                    <Alert message="Failed"
                                    description={categoryListError}
                                    type="error"
                                    closable
                                    className="my-3"
                                    /> 
                                }
                                  
                            </div>
                            
                            <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                               <div className="page_number">{category_docs?.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                                   <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                               </div>
                               {totalPages > 1 ?
                                <div className="paginate_reuse">
                                    <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                                </div> : null
                              }
                           </div>   
                    </div> 
            </div>
            {typeCheck && typeCheck === "edit-type" && <EditTypeModal />}

            { checkCategoryDel && checkCategoryDel === "category-delete" && 
            
                <DeleteModal header={`DELETE CATEGORY - ${catName}/${id}`}>
                    <h3 className="delete-header">Are you sure you want to delete this category?</h3>
                    {deleteSuccess && <p className="text-success text-center">{deleteSuccess}</p> }
                    {categoryDeleteError && <p className="text-center text-danger">{categoryDeleteError}</p>}
                    <div className="cta-btn-delete">
                        <button className="delete-confirm" onClick={() => handleCategoryDelete(id)}>{category_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={category_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => {history.push("/dashboard/categories"); setDeleteSuccess(""); refetch(); setCategoryListError(""); setCategoryDeleteError("")}}>{setDeleteSuccess ? "CLOSE" : "CANCEL"}</button>
                    </div>
               </DeleteModal>
            }
          {checkSubCatDel && checkSubCatDel === "sub-category-delete"  &&
                <DeleteModal header={`DELETE CATEGORY - ${catName}/${id}`}>
                <h3 className="delete-header">Are you sure you want to delete this sub-category?</h3>
                {subDeleteSuccess && <p className="text-success text-center">{subDeleteSuccess}</p> }
                {typeDeleteError && <p className="text-center text-danger">{typeDeleteError}</p>}
                <div className="cta-btn-delete">
                    <button className="delete-confirm" onClick={() => {handleTypeDelete(id)}}>{type_remove_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={type_remove_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => {history.push("/dashboard/categories"); setSubDeleteSuccess(""); refetch(); setTypeDeleteError("")}}>{setSubDeleteSuccess ? "CLOSE" : "CANCEL"}</button>
                </div>
           </DeleteModal> 
           }
         
        </div>
    )
}

export default AllProducts