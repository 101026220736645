import { GET_REVENUE_DATA_FAIL, GET_REVENUE_DATA_REQUEST, GET_REVENUE_DATA_SUCCESS } from "../../../constants/dashboard/revenueDataConstant";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from "../../../helpers/getUrl";
const token = localData && localData.token

export function RevenueDataAction (range) {
    return async(dispatch,getState) => {
        dispatch({type: GET_REVENUE_DATA_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/renue_data?range=${range}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_REVENUE_DATA_FAIL, payload: data.message})  
            }
            dispatch({type: GET_REVENUE_DATA_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_REVENUE_DATA_FAIL, payload: message})
        }
    }
}