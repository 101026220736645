import React, {useEffect, useContext, useMemo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { ListDisApprovedProducts } from '../../actions/Admin/products/Products';
import { AppContext } from '../../AppContext';
import { ProductSearch } from '../../actions/Admin/search/SearchAction';

const RejectedProduct = () => {
    const dispatch = useDispatch()
    const {disapproved_product_length, list_disapproved_products,list_disapproved_products_docs, list_disapproved_error} = useSelector(state => state.listDisApprovedProduct);
    const {product_search,product_search_docs,product_search_error} = useSelector(state => state.productSearchReducer);
    const {itemLimit, setItemLimit,pageNumber, setPageNumber, searchTerm} = useContext(AppContext);

    const listRejectedProducts = searchTerm !== "" ?  product_search_docs : list_disapproved_products_docs; 

    useEffect(() => {
        const page = pageNumber;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(ListDisApprovedProducts(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
      const page = page_number;
      const limit = +itemLimit;
      const data = { page, limit };
      dispatch(ListDisApprovedProducts(data));
  };


  const totalPages = searchTerm !== "" ? (product_search && product_search.totalPages) : (list_disapproved_products && list_disapproved_products.totalPages);
  const page = searchTerm !== "" ? (product_search && product_search.page) : (list_disapproved_products && list_disapproved_products.page);
  const prevPage = searchTerm !== "" ? (product_search && product_search.prevPage) : (list_disapproved_products && list_disapproved_products.prevPage);
  const nextPage = searchTerm !== "" ? (product_search && product_search.nextPage) : (list_disapproved_products && list_disapproved_products.nextPage);
  const limitItem = searchTerm !== "" ? (product_search && product_search.totalDocs) : (list_disapproved_products && list_disapproved_products.totalDocs);

  const searchProduct = useMemo(() => {
    const page = pageNumber;
    const limit = limitItem || itemLimit;
    const data = {page, limit, searchTerm}
    if(searchTerm){
        dispatch(ProductSearch(data))
    }
}, [dispatch, searchTerm, itemLimit,limitItem]);
useEffect(() => searchProduct, [searchProduct])

  
  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
  }, [page])
  return (
    <div className="mt-4 card card_wrapper  seller_table_wrapper">
        <div className="data_heading">
           <div className="header-title">DISAPPROVED UPLOADS <span>({disapproved_product_length})</span></div>
        </div>
        <div className="card-body sub-category-content-body">
        {list_disapproved_error && !list_disapproved_products_docs && <h3 className="text-danger mt-2 text-center">{list_disapproved_error}</h3>}
        {listRejectedProducts  && listRejectedProducts.length <= 0 && !list_disapproved_error && <h2 className="text-center no-record-found">No Records Found</h2>}
             <div className="table-responsive">
                <table className="table">
                    {
                        listRejectedProducts && listRejectedProducts.length > 0 && product_search_error === "" &&
                        <thead align="middle">
                            <tr className="validate-product__sec">
                                <th scope="col">Alpha Code</th>
                                <th scope="col">Product name</th>
                                <th scope="col">Category</th>
                                <th scope="col">Store Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Location</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                    }
                    {
                        product_search_error ? <p className="text-danger mt-2 text-center">{product_search_error}</p> :
                        <tbody align="middle">
                            {
                                
                                product_search_error === "" && listRejectedProducts && listRejectedProducts.length > 0 && listRejectedProducts.map(({alphaCode,_id,productName,categoryName, store:{name},updatedAt,neighborhood, locality}) => (
                                    <tr className="validate-product__body" key={_id}>
                                            <td>{alphaCode}</td>
                                            <td>{productName}</td>
                                            <td>{categoryName}</td>
                                            <td>{name}</td>
                                            <td>{moment(updatedAt).format("DD/MM/YYYY")}</td>
                                            <td>{moment(updatedAt).format("h:mm a")}</td>
                                            {neighborhood === "" ? <td>{locality}</td> : <td>{neighborhood}, {locality}</td>}
                                            <td><Link to={`/dashboard/product/rejected/details/${_id}`}>View details</Link></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                      }
                </table>
                <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                        <div className="page_number">Item per page {" "}{" "}
                            <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                        </div>
                    { totalPages > 1 ? 
                        <div className="paginate_reuse">
                            <button className="left-chevron" onClick={() => {handleNextPage(prevPage); }}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                        </div>: null
                    }
                </div>  
             </div>
         </div>
    </div>
  )
}

export default RejectedProduct