import { useEffect, useContext, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SortDropDown from '../Orders/SortDropdown';
import {BsFunnel, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { StoreListAction, StoreOwnersList } from '../../actions/Admin/StoreAction';
import "./customer.css";
import { StoreSearch } from '../../actions/Admin/search/SearchAction';

const SellerTable = ({match}) => {
    const dispatch = useDispatch();  
    const {itemLimit, setItemLimit, searchTerm, pageNumber, setPageNumber} = useContext(AppContext);
    const {store_owners_list,store_owners_list_docs, store_owner_error} = useSelector(state => state.storeOwnersList);
    const {stores} = useSelector(state => state.listStores);

    
const {store_search_docs,store_search} = useSelector(state => state.storeSearchReducer)
const searchStores = searchTerm !== "" ?  store_search_docs : store_owners_list_docs; 

const storeName = (id) => {
    return stores && stores?.find(store => store?.owner === id)?.name
};

const storePicture = (id) => {
    return stores && stores?.find(store => store.owner === id)?.image_url
}
const merchantPhone = (id)  => {
     return store_owners_list_docs && store_owners_list_docs?.find(store => store._id === id)?.phone
}
const merchantEmail = (id)  => {
    return store_owners_list_docs && store_owners_list_docs?.find(store => store._id === id)?.email
}
 const storeList = useMemo(() => {
    dispatch(StoreListAction())
 }, [dispatch]);

 useEffect(() => storeList, [storeList]);
    useEffect(() => {
          const page = pageNumber;
          const limit = +itemLimit;
          const data = {page, limit}
         dispatch(StoreOwnersList(data))
         
    }, [dispatch, itemLimit, pageNumber]);
    
    const handleNextPage = (page_number) => {
        const page = page_number;
        const limit = +itemLimit;
        const data = { page, limit };
        dispatch(StoreOwnersList(data));
    };

const totalPages = searchTerm !== "" ? (store_search && store_search.totalPages) : (store_owners_list  && store_owners_list.totalPages);
  const page = searchTerm !== "" ? (store_search && store_search.page) : (store_owners_list  && store_owners_list.page);
  const prevPage = searchTerm !== "" ? (store_search && store_search.prevPage) : (store_owners_list  && store_owners_list.prevPage);
  const nextPage = searchTerm !== "" ? (store_search && store_search.nextPage) : (store_owners_list  && store_owners_list.nextPage);
  const limitItem = searchTerm !== "" ? (store_search && store_search.totalDocs) : (store_owners_list  && store_owners_list.totalDocs);

 const hasNextPage = store_owners_list && store_owners_list.hasNextPage;
const hasPrevPage = store_owners_list && store_owners_list.hasPrevPage



  const search = useMemo(() => {
    const page = pageNumber;
    const limit = limitItem || itemLimit;
    const data = {searchTerm, page, limit}
    if(searchTerm){
        dispatch(StoreSearch(data))
    }
}, [dispatch, searchTerm, itemLimit,limitItem, pageNumber]);
  useEffect(() => search, [search])

  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
   
}, [page, setPageNumber])
   
    return (
        <>
          {store_owner_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{store_owner_error || store_owner_error.message }</p>
                </div>
            }
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                    <div className="header_order_title">STORE OWNERS</div>
                    
                    <div className="button_sort"><BsFunnel className="icon_one"/><span>  <SortDropDown /></span></div>
                </div>
                <div className="card-body">
                {
            searchStores && searchStores.length > 0 ? 
                    <div className="table-responsive" >
                        <table className="table seller_table" align="middle">

                        <thead className="tborder table-header" align="middle">
                        <tr className="align_student_table">
                            <th scope="col" className="header__name">Store name</th>
                            <th scope="col" className="header__status">Status</th>
                            <th scope="col" className="header__userId">User ID</th>
                            <th scope="col" className="header_phone">Phone Number</th>
                            <th scope="col" className="header_email">Email Address</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        {
                              searchStores && searchStores.map((owner) => (
                                <tbody key={owner._id} align="middle">
                                <tr className={owner.status  === "active" ? "item_suspend_active table_text" : "item_suspend_inactive table_text"}>
                                    <td className="item_name"><img src={(storePicture(owner._id) || owner.image_url) || "/alpha-favicon.png"} className="store-logo" alt="store-logo"/>&nbsp;&nbsp;<Link className="item__name" to={`${match.url}/storeowner-details/${owner._id}`}>{storeName(owner._id) || owner.name}</Link></td>
                                    <td className="item_status">{owner.status}</td>
                                    <td className="item_userId">{owner._id}</td>
                                    <td className="item_phone">{merchantPhone(owner.owner) || owner.phone}</td>
                                    <td  className="item_email">{ merchantEmail(owner.owner) || owner.email}</td>
                                    <td className={owner.status  === "active" ? "item_suspend" : "item_suspended"}><button>Suspend</button></td>
                                    <td className={owner.status  === "active" ? "item_deactivate": "item_deactivated"}><button>Deactivate</button></td>
                                </tr>
                    </tbody>
                              ))
                        }
                        
            </table>
            
            </div>
            :  <h2 className="text-center no-record-found mt-4">No Records Found</h2>
        }
        </div>
            <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                <div className="page_number">{store_owners_list_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                    <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                </div>
                {totalPages > 1 ?
                <div className="paginate_reuse">
                    <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}} ><BsChevronRight /></button>
                </div> : null
                }
            </div>   
        </div>

            
       

        </>
    )
}

export default SellerTable

SellerTable.propTypes = {
    header_title: PropTypes.string
  };


