import React from 'react';
import {FaPlayCircle} from "react-icons/fa"

const ImageSlider = ({setOpenGallery,setCurrent, newImage}) => {

  return (
    <div className="thumbnail-images-styles" style={{cursor: "pointer"}}>
       {newImage && newImage.map((img, index) => (
          <div  key={img.id} className="img-sect">
                 {img && img.url && (img.url?.endsWith(".jpg") || img.url?.endsWith(".jpeg") || img.url?.includes(".png") || img.url?.includes(".svg") || img.url?.includes(".gif")) ? 
                        <div className='img-thumbnail' onClick={() => {setCurrent(index); setOpenGallery(true)}}  >
                            <img src={img.url} alt={img.url}/>
                            <div className='overlay overlay-left'>
                                 <div className='overlay-text'>Click to view</div>
                            </div>
                        </div> :  
                        <div className='video-thumbnail' onClick={() => {setCurrent(index); setOpenGallery(true)}}  >
                            <video muted>
                                <source src={img.url} />
                            </video>
                            <div><FaPlayCircle /></div>
                        </div>}
         </div>
       ))}

    </div>
  )
}

export default ImageSlider