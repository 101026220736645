import Loaders from "../Reusables/Loader";

const RoleModal = ({showModal, loading, handleRoleCreate, name, handleChange, category, }) => {
    return (
        <>
        {showModal ? 
         
              <div className="card category_modal">
              <div className="data_heading">
                <div className="header_order_title role_header">{category}</div>
              </div>
              <form className="add_category_form" onSubmit={handleRoleCreate}>
                  <label htmlFor="category" className="role_label">Input Category Name</label>
                  <input type="text" id="category" className="add_category_input" onChange={(e) => handleChange(e)} value={name}/>
                  <button className="role_button">{loading ? <Loaders loading={loading} color="#ffffff" size={20}/> : "submit"}</button>
              </form>
           </div>
          : null
       }

        </>
    )
}

export default RoleModal