export const GET_SALES_OVERVIEW_REQUEST = "GET_SALES_OVERVIEW_REQUEST";
export const GET_SALES_OVERVIEW_SUCCESS = "GET_SALES_OVERVIEW_SUCCESS";
export const GET_SALES_OVERVIEW_FAIL = "GET_SALES_OVERVIEW_FAIL";

// export const GET_MONTHLY_SALES_OVERVIEW_REQUEST = "GET_MONTHLY_SALES_OVERVIEW_REQUEST";
// export const GET_MONTHLY_SALES_OVERVIEW_SUCCESS = "GET_MONTHLY_SALES_OVERVIEW_SUCCESS";
// export const GET_MONTHLY_SALES_OVERVIEW_FAIL = "GET_MONTHLY_SALES_OVERVIEW_FAIL";

// export const GET_WEEKLY_SALES_OVERVIEW_REQUEST = "GET_WEEKLY_SALES_OVERVIEW_REQUEST";
// export const GET_WEEKLY_SALES_OVERVIEW_SUCCESS = "GET_WEEKLY_SALES_OVERVIEW_SUCCESS";
// export const GET_WEEKLY_SALES_OVERVIEW_FAIL = "GET_WEEKLY_SALES_OVERVIEW_FAIL";