import React, {useState, useEffect, useMemo, useContext} from 'react';
import { Switch,Link, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {FiArchive} from "react-icons/fi";
import {GoVerified} from "react-icons/go";
import {MdCancel} from "react-icons/md";
import NewProduct from './NewProduct';
import ApprovedProducts from './ApprovedProducts';
import RejectedProduct from './RejectedProduct';
import ListProduct from './ListProduct';
import { ListApprovedProducts, ListDisApprovedProducts, ListProducts, NewProductsList } from '../../actions/Admin/products/Products';
import { AppContext } from '../../AppContext';
import "./prod-validation.css";
import { clearProductSearchState } from '../../actions/Admin/search/SearchAction'

const ProductValidation = (props) => {
    const {itemLimit, pageNumber, setPageNumber, setSearchTerm}= useContext(AppContext)
    const [index, setIndex] = useState(1);
    const {all_products_length} = useSelector(state => state.listProductReducer);
    const {new_products_length} = useSelector(s => s.newProductListReducer);
    const {approved_product_length} = useSelector(state => state.listApprovedProduct);
    const {disapproved_product_length} = useSelector(state => state.listDisApprovedProduct);
    const {pathname} = useLocation();
    const dispatch = useDispatch()
    const {match} = props;
    const activeTab = index === 1  ? "validation-tabs active-tab" :  index === 2 ? "validation-tabs active-tab-approved" : index === 3 ? "validation-tabs active-tab-rejected" : index === 4 ? "validation-tabs active-tab-all"  : "validation-tabs";
    
    const urlMemo = useMemo(() => () => {
        if(pathname === "/dashboard/product/validation" ||  pathname === "/dashboard/product/validation/new"){
            setIndex(1)
        }else if(pathname === "/dashboard/product/validation/approved"){
           setIndex(2)
        }else if(pathname === "/dashboard/product/validation/rejected"){
           setIndex(3)
        }else if(pathname === "/dashboard/product/validation/all"){
             setIndex(4)
        }
  }, [pathname]);
  useEffect(() => {
    const page = pageNumber;
    const limit = +itemLimit;
    const data = {page, limit}
   dispatch(ListProducts(data))
   
}, [dispatch, itemLimit, pageNumber]);
useEffect(() => {
  const page = pageNumber;
  const limit = +itemLimit;
  const data = {page, limit}
 dispatch(NewProductsList(data))
 
}, [dispatch, itemLimit, pageNumber]);

useEffect(() => {
  const page = pageNumber;
  const limit = +itemLimit;
  const data = {page, limit}
 dispatch(ListApprovedProducts(data))
 
}, [dispatch, itemLimit, pageNumber]);
useEffect(() => {
  const page = pageNumber;
  const limit = +itemLimit;
  const data = {page, limit}
 dispatch(ListDisApprovedProducts(data))
 
}, [dispatch, itemLimit, pageNumber]);

    useEffect(() => {
           urlMemo()
    }, [urlMemo])
  return (
    <div className='container validation-container'>
      <div className={activeTab}>
            <Link to="/dashboard/product/validation/new" onClick={() => {setIndex(1); setPageNumber(1); setSearchTerm(""); dispatch(clearProductSearchState())}}><div className="icon-one"><FiArchive /></div><div><div className="active-one">NEW UPLOADS</div><div className="badge one">({new_products_length})</div></div></Link>
            <Link to="/dashboard/product/validation/approved" onClick={() => {setIndex(2); setPageNumber(1); setSearchTerm(""); dispatch(clearProductSearchState())}}><div className="icon-two"><GoVerified /></div><div><div className="active-two">APPROVED UPLOADS</div><div className="badge two">({approved_product_length})</div></div></Link>
            <Link  to="/dashboard/product/validation/rejected" onClick={() => {setIndex(3); setPageNumber(1); setSearchTerm(""); dispatch(clearProductSearchState())}}><div className="icon-three"><MdCancel /></div><div><div className="active-three">DISAPPROVED UPLOADS</div><div className="badge three">({disapproved_product_length})</div></div></Link>
            <Link to="/dashboard/product/validation/all" onClick={() => {setIndex(4); setPageNumber(1); setSearchTerm(""); dispatch(clearProductSearchState())}}><div className="icon-four"><FiArchive /></div><div><div className="active-four">ALL PRODUCTS UPLOADED</div><div className="badge four"> ({all_products_length})</div></div></Link>
     </div>
     <Switch>
         <Route exact path={`${match.url}`} render={(props) => <NewProduct {...props} />} />
         <Route path={`${match.url}/new`} render={(props) => <NewProduct {...props} />} />
         <Route path={`${match.url}/approved`} render={(props) => <ApprovedProducts {...props} />} />
         <Route path={`${match.url}/rejected`} render={(props) => <RejectedProduct {...props} />} />
         <Route path={`${match.url}/all`} render={(props) => <ListProduct {...props} />} />
     </Switch>
    </div>
  )
}

export default ProductValidation