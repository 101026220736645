import { BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import "./settings.css";
const Settings = ({match}) => {
    
    return (
        <div className="card card_wrapper  seller_table_wrapper mt-4">         
            <div className="data_heading">
                <div className="header_order_title role_header">SETTINGS</div>   
            </div>
            <div className="card-body permission-n-cancellation">
                <Link to={`${match.url}/reasons`}>
                    <div className="order-cancellation">
                        <p>Order Cancellation Reason</p>
                        <span><BsChevronRight /></span>
                    </div>
                </Link>
                <Link to={`${match.url}/permissions`}>
                    <div className="permissions">
                        <p>Permissions</p>
                        <span><BsChevronRight /></span>
                    </div>
                </Link>
                <Link to={`${match.url}/vehicle-types`}>
                    <div className="permissions">
                        <p>Create/Edit Vehicle Types</p>
                        <span><BsChevronRight /></span>
                    </div>
                </Link>
                <Link to={`${match.url}/eta`}>
                    <div className="permissions">
                        <p>Create ETAs</p>
                        <span><BsChevronRight /></span>
                    </div>
                </Link>
                <Link to={`${match.url}/product/checklists`}>
                    <div className="permissions">
                        <p>Product Upload Criteria</p>
                        <span><BsChevronRight /></span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Settings