import { CLEAR_TRACKER_ACTION, TRACK_ITEM_FAILED, TRACK_ITEM_REQUEST, TRACK_ITEM_SUCCESS } from "../../constants/trackerConstant";
import { getUrl } from "../../helpers/getUrl";

export function TrackOrderAction(trackingId) {
      return async (dispatch) => {
        dispatch({type: TRACK_ITEM_REQUEST});
        
        try {
          const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/track_shipment?waybill=${trackingId}`, {
              method: "GET",
              headers: {
                  ACCEPT: "application/json",
                  "Content-Type": "application/json",
                
              }
          })
          const data  = await res.json()
          if(data.code === 400){
            dispatch({type: TRACK_ITEM_FAILED, payload: data.results.ShortDescription})
          }
          if(data.code === 200 && data.results?.Code === "400"){
              dispatch({type: TRACK_ITEM_FAILED, payload: data.results.ShortDescription})
              
          }else if(data.code === 200 && data.results.Code === "200"){
              dispatch({type: TRACK_ITEM_SUCCESS, payload: data}) 
          }
          
        } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
          dispatch({type: TRACK_ITEM_FAILED, payload: message})
        }
      }
}  
export const clearTrackerState = () => async (dispatch) => {
  dispatch({type: CLEAR_TRACKER_ACTION})
}