
import HashLoader from "react-spinners/HashLoader";
const Loaders = ({loading, color, size, position}) => {
    return (
        <div className={position}>
            <HashLoader color={color} loading={loading} size={size}/>
        </div>
    )
}

export default Loaders;