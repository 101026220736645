import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { AdminDeleteReducer, AdminFetchReducer, adminForgotPasswordReducer, adminLoginReducer, adminResetPasswordReducer, CreateAdminReducer, GetAdminReducer, UpdateAdminReducer, UpdateAdminRoleReducer } from "../reducer/Admin/AdminReducer";
import { DeleteRoleReducer, GetRoleReducer, RoleCreateReducer, RoleListReducer, UpdateRoleReducer } from "../reducer/Admin/RoleReducer";
import { getBasicMerchantReducer, getClassMerchantReducer } from "../reducer/Admin/GetMerchants";
import { StoreOwnersReducer, BasicBuyerReducer } from "../reducer/Admin/CustomerDBReducer";
import { categoryStatusReducer, createCategoryReducer, createSizeReducer, deleteCategoryReducer, deleteTypeReducer, editCategoryReducer, editTypeReducer, getCategoryReducer, getTypeReducer, listCategoryReducer, listGenderReducer, listSizeReducer, listTypeReducer, removeTypeReducer } from "../reducer/Admin/ProductCategoryReducer";
import { createTypeReducer, createGenderReducer } from './../reducer/Admin/ProductCategoryReducer';
import { createPaymentGateway, listPaymentGateway, getPaymentGateway, updatePaymentGateway, paymentGatewayDelete } from './../reducer/Admin/PaymentGateway';
import { listOrders, getOrder, pendingPayment, pendingOrderVerify, updateOrderStatus } from './../reducer/Admin/Order/OrderReducer';
import { listStores,  getStore, storeOwnersList } from './../reducer/Admin/Store/StoreReducer';
import { newOrders, processingOrders, shippedOrders, deliveredOrders, declinedOrders } from './../reducer/Admin/Order/OrderByStatusReducer';
import { trackerReducer } from './../reducer/Admin/Tracker/Tracker';
import { getWaitlist } from './../reducer/Admin/WaitList/WaitList';
import { basicBuyerDetailsReducer } from './../reducer/Admin/BasicBuyer';
import { refundList, refundComplete, returnList } from './../reducer/Admin/Refund/RefundReducer';
import { transactionList, earningList, storeEarningList, earningChart, totalEarning } from './../reducer/Admin/Transactions/Transactions';
import {listReasonsReducer,createReasons,createVehicleType, createEta,updateEta,deleteEta} from "./../reducer/Admin/Reasons/ReasonsReducer"
import { revenueDataReducer } from './../reducer/Admin/Dashboard/RevenueDataReducer';
import { salesByCategoryReducer } from './../reducer/Admin/Dashboard/SalesByCategory';
import { topSellingProductReducer } from './../reducer/Admin/Dashboard/TopSellingProducts';
import { todaysTransactionsReducer } from './../reducer/Admin/Dashboard/TodaysTransactionReducer';
import { recentOrdersReducer } from './../reducer/Admin/Sales/RecentOrdersReducer';
import { salesOverviewReducer } from './../reducer/Admin/Sales/SalesOverviewReducer';
import { listProductReducer, listApprovedProduct, newProductListReducer, updateProductViewReducer, getProductDetails, listDisApprovedProduct, createUploadCriteriaReducer, listUploadCriteriaReducer, getComplianceCriteriaReducer, deleteComplianceCriteriaReducer, updateComplianceCriteriaReducer,updateComplianceStatusReducer,deleteProductReducer } from './../reducer/Admin/Product/ProductReducer';
import { orderSearchReducer, productSearchReducer, userSearchReducer, storeSearchReducer } from './../reducer/Admin/Searches/SearchReducer';
import { couponApi } from "../actions/Admin/coupon/Coupon";
import { productStatsApi, recentOrdersApi } from "../actions/Admin/sales/RecentOrdersAction";
import { salesOverviewApi } from "../actions/Admin/sales/SalesOverviewActions";


const reducer = combineReducers({
    gigAuth: adminLoginReducer,
    createRole: RoleCreateReducer,
    listRole: RoleListReducer,
    getRole: GetRoleReducer,
    UpdateRoleReducer, 
    updateRole: UpdateRoleReducer,
    deleteRole: DeleteRoleReducer,
    createAdmin: CreateAdminReducer,
    fetchAdmin: AdminFetchReducer,
    getAdmin:GetAdminReducer,
    updateAdmin: UpdateAdminReducer,
    deleteAdmin: AdminDeleteReducer,
    updateAdminRole: UpdateAdminRoleReducer,
    passwordForgot: adminForgotPasswordReducer,
    passwordReset: adminResetPasswordReducer,
    getClassedMerchant: getClassMerchantReducer,
    getBasicMerchant: getBasicMerchantReducer,
    getStoreOwners: StoreOwnersReducer,
    createCategoryType: createTypeReducer,
    listCategoryTypes: listTypeReducer,
    typeGet: getTypeReducer,
    editType: editTypeReducer,
    deleteType: deleteTypeReducer,
    createCategorySize: createSizeReducer,
    listCategorySize: listSizeReducer,
    createCategoryGender: createGenderReducer,
    listCategoryGender: listGenderReducer,
    categoryCreate: createCategoryReducer,
    categoryList: listCategoryReducer,
    categoryDelete: deleteCategoryReducer,
    categoryEdit: editCategoryReducer,
    categoryGet: getCategoryReducer,
    categoryStatus : categoryStatusReducer,
    createPaymentGateway,
    paymentGatewayList: listPaymentGateway,
    paymentGatewayGet: getPaymentGateway,
    paymentGatewayEdit: updatePaymentGateway,
    deletePaymentGateway: paymentGatewayDelete,
    listOrders,
    getOrder,
    listStores,
    getStore,
    newOrders,
    processingOrders,
    shippedOrders,
    deliveredOrders,
    declinedOrders,
    trackerReducer,
    removeTypeReducer,
    getWaitlist,
    BasicBuyerReducer,
   storeOwnersList,
   basicBuyerDetailsReducer,
    refundList,
    transactionList,
    listReasonsReducer,
    createReasons,
    createVehicleType,
    createEta,
    updateEta,
    deleteEta,
    revenueDataReducer,
    salesByCategoryReducer,
    topSellingProductReducer,
    todaysTransactionsReducer,
    recentOrdersReducer,
     salesOverviewReducer,
    earningList,
    storeEarningList,
    earningChart,
    totalEarning,
    refundComplete,
    returnList,
    listProductReducer,
    listApprovedProduct,
    newProductListReducer,
    updateProductViewReducer,
    getProductDetails,
    listDisApprovedProduct,
    createUploadCriteriaReducer,
    listUploadCriteriaReducer,
    getComplianceCriteriaReducer,
    deleteComplianceCriteriaReducer,
    updateComplianceCriteriaReducer,
    updateComplianceStatusReducer,
    deleteProductReducer,
    orderSearchReducer,
    productSearchReducer,
    userSearchReducer,
    storeSearchReducer,
    pendingPayment,
    pendingOrderVerify,
    updateOrderStatus,
    [couponApi.reducerPath] : couponApi.reducer,
    [recentOrdersApi.reducerPath] : recentOrdersApi.reducer,
    [salesOverviewApi.reducerPath] : salesOverviewApi.reducer,
    [productStatsApi.reducerPath] : productStatsApi.reducer, 
    
})

const store = configureStore({
     reducer,
    //  initialState,
     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(couponApi.middleware, recentOrdersApi.middleware, salesOverviewApi.middleware, productStatsApi.middleware)
}

)


export default store;