import { DECLINED_ORDERS_FAILED, DECLINED_ORDERS_REQUEST, DECLINED_ORDERS_SUCCESS, DELIVERED_ORDERS_FAILED, DELIVERED_ORDERS_REQUEST, DELIVERED_ORDERS_SUCCESS, NEW_ORDERS_FAILED, NEW_ORDERS_REQUEST, NEW_ORDERS_SUCCESS, PROCESSING_ORDERS_FAILED, PROCESSING_ORDERS_REQUEST, PROCESSING_ORDERS_SUCCESS, SHIPPED_ORDERS_FAILED, SHIPPED_ORDERS_REQUEST, SHIPPED_ORDERS_SUCCESS } from '../../constants/orderConstants';
import { localData } from '../../helpers/auth/auth';
import { getUrl } from '../../helpers/getUrl';
const token = localData && localData.token

export function newOrdersAction ({offset,limit}){
    return async(dispatch,getState) => {
      dispatch({type: NEW_ORDERS_REQUEST});
      
        try {
            
  
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/list_by_status?status=ordered&offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ACCEPT": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
        if(data.error){
            return dispatch({type: NEW_ORDERS_FAILED, payload: data.message})
            }
            dispatch({type: NEW_ORDERS_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: NEW_ORDERS_FAILED, payload: message})
        }
    }
} 

export function processingOrdersAction ({offset,limit}) {
  return async(dispatch,getState) => {
    dispatch({type: PROCESSING_ORDERS_REQUEST});
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/list_by_status?status=processing&offset=${offset}&limit=${limit}`, {
            method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ACCEPT": "application/json",
                    "Authorization": `Bearer ${token}`
                }
        })
        
        const data = await res.json()
       if(data.error){
           return dispatch({type: PROCESSING_ORDERS_FAILED, payload: data.message})
        }
        dispatch({type: PROCESSING_ORDERS_SUCCESS, payload: data.results})
    } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: PROCESSING_ORDERS_FAILED, payload: message})
    }
  }
}

export function shippedOrdersAction ({offset,limit}) { 
    return async(dispatch,getState) => {
        dispatch({type: SHIPPED_ORDERS_REQUEST});
    try {
      
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/list_by_status?status=shipped&offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "ACCEPT": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json()
       if(data.error){
           return dispatch({type: SHIPPED_ORDERS_FAILED, payload: data.message})
        }
        dispatch({type: SHIPPED_ORDERS_SUCCESS, payload: data.results})
    } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: SHIPPED_ORDERS_FAILED, payload: message})
    }
  }
}


export function deliveredOrdersAction ({offset,limit}){
    return async(dispatch,getState) => {
        dispatch({type: DELIVERED_ORDERS_REQUEST});
        try {
        
  ;

            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/list_by_status?status=delivered&offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ACCEPT": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json()
        if(data.error){
            return dispatch({type: DELIVERED_ORDERS_FAILED, payload: data.message})
            }
            dispatch({type: DELIVERED_ORDERS_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: DELIVERED_ORDERS_FAILED, payload: message})
        }
    }
}


export function declinedOrdersAction ({offset,limit}){ 
    return async(dispatch,getState) => {
    dispatch({type: DECLINED_ORDERS_REQUEST});
        try {
  
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/list_by_status?status=declined&offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                }})
            const data = await res.json();
        if(data.error){
            return dispatch({type: DECLINED_ORDERS_FAILED, payload: data.message})
            }
            dispatch({type: DECLINED_ORDERS_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: DECLINED_ORDERS_FAILED, payload: message})
        }
    }
}
