class Auth {
    static authenticateUser(token) {
      localStorage.setItem('gigAdminInfo', token);
    }
  
    static isUserAuthenticated() {
      return localStorage.getItem('gigAdminInfo') !== null;
    }
  
    static deauthenticateUser() {
      localStorage.removeItem('gigAdminInfo');
    }
  
    static getToken() {
      return localStorage.getItem('gigAdminInfo');
    }
  }
  
  export default Auth;
  