import { useSelector, useDispatch } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import { useSalesOverviewQuery } from "../../actions/Admin/sales/SalesOverviewActions";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

const LineChart = () => {
    const {data} = useSalesOverviewQuery({})
    const dispatch = useDispatch()
    const {sales_overview_loading,sales_overview_error,sales_overview} = useSelector(state => state.salesOverviewReducer);
  
    return (
        <div style={{width: "100%", height: "100%", padding: ".5rem 0"}}>
            {sales_overview_error && <p>{sales_overview_error || sales_overview_error.message}</p>}
        <Line data={{
            labels: ["0", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            datasets: [
                {
                    label: 'Data One',
                    data: [85, 35, 95, 65, 70, 20, 50, 51],
                    backgroundColor: "rgba(51, 51, 51, 0.6)",
                    borderColor: 'rgba(51, 51, 51, 0.6)',
                    borderWidth: 1,
                    fill: true,
               },
                {
                    label: 'Data two',
                    data: [35, 55, 10, 65, 50, 45, 50, 10],
                    backgroundColor: "rgba(237, 50, 55, 0.6)",
                    borderColor: 'rgba(237, 50, 55, 0.6)',
                    borderWidth: 1,
                    fill: true,
                }

     ]}} 
      
     options={{
       responsive: true,
       maintainAspectRatio : false,
      plugins: {
        legend:{
            display: false
        }
        
    }
      
    }
     
    } 
     />
     </div>
    )
}

export default LineChart