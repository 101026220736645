import { GET_BASIC_MERCHANT_FAIL, GET_BASIC_MERCHANT_REQUEST, GET_BASIC_MERCHANT_SUCCESS } from "../../constants/getBasicMerchant";
import { GET_CLASS_MERCHANT_FAIL, GET_CLASS_MERCHANT_REQUEST, GET_CLASS_MERCHANT_SUCCESS } from "../../constants/getClassMerchant";

export const getBasicMerchantReducer = (state={basic_merchant: []}, action) => {
    switch(action.type){
        case GET_BASIC_MERCHANT_REQUEST:
            return {
                basic_merchant_loading: true
            }
        case GET_BASIC_MERCHANT_SUCCESS:
            return {
                ...state,
                basic_merchant_loading: false,
                basic_merchant: action.payload

            }
        case GET_BASIC_MERCHANT_FAIL:
            return {
                ...state,
                basic_merchant_loading: false,
                basic_merchant_error: action.payload
            }
        default:
            return state
    }
}

export const getClassMerchantReducer = (state={classed_merchant: []}, action) => {
      switch(action.type){
          case GET_CLASS_MERCHANT_REQUEST:
              return {
                  classed_merchant_loading: true
              }
          case GET_CLASS_MERCHANT_SUCCESS:
              return {
                  ...state,
                  classed_merchant_loading: false,
                  classed_merchant: action.payload
              }
          case GET_CLASS_MERCHANT_FAIL:
              return {
                  ...state,
                  classed_merchant_loading: false,
                  classed_merchant_error: action.payload
              }
           default:
               return state
      }
}