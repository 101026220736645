import { CLEAR_COMPLETE_REFUND, COMPLETE_REFUND_FAIL, COMPLETE_REFUND_REQUEST, COMPLETE_REFUND_SUCCESS, REFUND_LIST_FAIL, REFUND_LIST_REQUEST, REFUND_LIST_SUCCESS, RETURN_LIST_FAIL, RETURN_LIST_REQUEST, RETURN_LIST_SUCCESS } from "../../constants/refundConstant"
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token

export function RefundList({offset, limit}){
    return async (dispatch, getState) => {
        dispatch({type: REFUND_LIST_REQUEST})
        
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/refund/list?offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: REFUND_LIST_FAIL, payload: data.message})
            }
            dispatch({type: REFUND_LIST_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: REFUND_LIST_FAIL, payload: message})
        }
    }
}

export function CompleteRefund(item_id){
    return async(dispatch, getState) => {
        dispatch({type: COMPLETE_REFUND_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/refund/complete`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(item_id)
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: COMPLETE_REFUND_FAIL, payload: data.message})
            }
            dispatch({type: COMPLETE_REFUND_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: COMPLETE_REFUND_FAIL, payload: message})
          
        }
    }
}
export function ReturnList({offset, limit}){
    return async (dispatch, getState) => {
        dispatch({type: RETURN_LIST_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/return/list?offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: RETURN_LIST_FAIL, payload: data.message})
            }
            dispatch({type: RETURN_LIST_SUCCESS, payload: data.results});
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: RETURN_LIST_FAIL, payload: message})
        }
    }
}

export const clearRefundState = () => async (dispatch) => {
    dispatch({type: CLEAR_COMPLETE_REFUND});
}