import {useState} from "react"
import AllTransaction from "./AllTransactions";
import Earnings from "./Earnings";
// import SellerTable from "./SellerTable";
import "./transaction.css"
import ViewTransactions from "./ViewTransactions";
// import Buyer from "./Buyer";
// import StoreOwners from "./StoreOwners";
// import BuyerTable from "./BuyerTable";


const TransactionPage = ({match}) => {
    const [activeTransactionTab, setActiveTransactionTab] = useState(1);
    const [viewTransactionsShow, setViewTransactionsShow] = useState(false);
    const handleActiveTransactionTab = (tab) => {
        setActiveTransactionTab(tab)
    }

    const handleViewTransactions = () => {
        setViewTransactionsShow(prev => !prev)
    }
    return (
        <>
        {viewTransactionsShow ? "" : 
           <div className="customers_container">
            <ul>
                <li className={activeTransactionTab === 1 ? "tabs_active" : "tab_color"} onClick={() => handleActiveTransactionTab(1)}>TRANSACTIONS</li>
                <li className={activeTransactionTab === 2 ? "tabs_active" : "tab_color"} onClick={() => handleActiveTransactionTab(2)}>EARNINGS</li>
            </ul>
          </div>
        }
        {
            viewTransactionsShow ? <ViewTransactions setViewTransactionsShow={setViewTransactionsShow}  handleActiveTransactionTab={handleActiveTransactionTab}/> : <div>
        
            {
               activeTransactionTab === 1 && <AllTransaction />
            }
            {
                activeTransactionTab === 2 && <Earnings handleViewTransactions={handleViewTransactions} match={match}/>
            }
            </div>
        }
        
        
        </>
    )
}


export default TransactionPage;