import {useHistory} from "react-router-dom"
import {IoMdClose} from "react-icons/io"
const CloseButton = ({showModal, setShowModal, close}) => {
    const history = useHistory()
 return (
    <> {showModal ? <div className="close_overlay" onClick={() => {history.push(close); setShowModal(false)}}><IoMdClose /></div>:null}

    </>
 )
}

export default CloseButton;