import { useEffect, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { AppContext } from "../../AppContext";
import AllOrders from "./AllOrder";
import DeclinedOrders from "./DeclinedOrders";
import DeliveredOrders from "./DeliveredOrders";
import NewOrders from "./NewOrders";
import ProcessingOrders from "./ProcessingOrders";
import ShippedOrders from "./ShippedOrders";
import "./tabs.css";
import "./tracker.css";
import FailedOrder from './FailedOrder';
import { clearProductSearchState } from "../../actions/Admin/search/SearchAction";

const Tabs = () => {
    const dispatch = useDispatch()
    const { error} = useSelector(state => state.listOrders);
    const {activeTabOrder,handleActiveTabOrder, setSearchTerm} = useContext(AppContext)
  

    useEffect(() => {
        if(error && error.message && error.message.includes("Invalid token"))  {
          window.location.href = "/login";
        }
   }, [error])

    return <div>
        <div className="tabs_container">
            <ul>
                <li className={activeTabOrder === 1 ? "tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(1); setSearchTerm(""); dispatch(clearProductSearchState())}}>All</li>
                <li className={activeTabOrder === 2 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(2); setSearchTerm(""); dispatch(clearProductSearchState())}}>New</li>
                <li className={activeTabOrder === 3 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(3); setSearchTerm(""); dispatch(clearProductSearchState())}}>Processing</li>
                <li className={activeTabOrder === 4 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(4); setSearchTerm(""); dispatch(clearProductSearchState())}}>Shipped</li>
                <li className={activeTabOrder === 5 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(5); setSearchTerm(""); dispatch(clearProductSearchState())}}>Delivered</li>
                <li className={activeTabOrder === 6 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(6); setSearchTerm(""); dispatch(clearProductSearchState())}}>Declined</li>
                <li className={activeTabOrder === 7 ? "tab_color tabs_active" : "tab_color"} onClick={() => {handleActiveTabOrder(7); setSearchTerm(""); dispatch(clearProductSearchState())}}>unVerified Orders</li>
            </ul>
        </div>
        {activeTabOrder === 1 && (<AllOrders />)}
        {activeTabOrder === 2 && (<NewOrders />)}
        {activeTabOrder === 3 && (<ProcessingOrders />)}
        {activeTabOrder === 4 && (<ShippedOrders />)}
        {activeTabOrder === 5 && <DeliveredOrders />}
        {activeTabOrder === 6 && (<DeclinedOrders />)}
        {activeTabOrder === 7 && (<FailedOrder />)}
    </div>
}

export default Tabs;