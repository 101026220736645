import { useEffect, useContext, useState } from "react";
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { AdminRoleDelete, AdminRoleList, AdminRoleUpdate } from "../../actions/Admin/AdminRoleAction";
import {FaEdit} from "react-icons/fa";
import {BiTrash} from "react-icons/bi"
import Loaders from "../Reusables/Loader";

import { listAdmins } from "../../actions/Admin/AdminAction";
import CloseButton from "./CloseButton";
import UpdateModal from "./UpdateModal";
import { AppContext } from './../../AppContext';
import "./role.css";
import DeleteModal from "../Reusables/DeleteModal";
const ListCategories = ({handleActiveTab, showModal,setShowModal,openModal, name, setName, categoryId, setCategoryId}) => {
     const [itemDetails, setItemsDetail] = useState("")
     const {itemLimit, openDeleteModal, setOpenDeleteModal} = useContext(AppContext)
    const dispatch = useDispatch()
    const {error, loading,roles} = useSelector(state => state.listRole);
    const {admins, admins_docs} = useSelector(state => state.fetchAdmin);
    const {error:delete_error, role: delete_role, loading: delete_loading}  = useSelector(state => state.deleteRole)
    const history = useHistory();
 
     const handleChange = (e) => {
         setName(e.target.value)
     }


     const handleRoleDelete = (id) => {
         if(id){
            dispatch(AdminRoleDelete(id));    
         }
      
    }
    

    const getUserTypeLength = (user) => {
        const res = admins_docs && admins_docs.length > 0 && admins_docs.filter(data =>  data.role.name  === user);
        return res && res.length;
    }


    useEffect(() => {
         dispatch(AdminRoleList())    
    }, [dispatch])
    useEffect(() => {
        const page = 1;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(listAdmins(data))
       
  }, [dispatch, itemLimit]);
  const handleNextPage = (page_number) => {
    const page = page_number;
    const limit = +itemLimit;
    const data = { page, limit };
    dispatch(listAdmins(data));
};

const totalPages = admins && admins.totalPages;
const page = admins && admins.page;
const prevPage = admins && admins.prevPage;
const nextPage = admins && admins.nextPage;
const hasNextPage = admins && admins.hasNextPage;
const hasPrevPage = admins && admins.hasPrevPage

    return (
        <>
        <div className="role_container  mt-4">
            <div className="card card_wrapper  seller_table_wrapper">
                <div className="data_heading">
                    <div className="header_order_title role_header">MANAGE CATEGORY</div>
                
                    <button className="admin_add" onClick={() => {handleActiveTab(0); history.push("/dashboard/roles")}}><span>View Roles</span></button>
                </div>
                <div className="card__role_tile">
                    <div>Role Categories</div>
                    <div>N0. of Users</div>
                </div>
                <div className="card-body">
                   {loading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={loading} color="#000000" size={50} /></div>}
                   {error && <h5 className="text-center py-4 text-danger">{error.message}</h5>}
                   {/* {get_role_error && <h5 className="text-center py-4 text-danger">{get_role_error}</h5>} */}
                  
                   {roles && roles.results ? roles.results.map(role => (
                       <>
                        <div key={role._id} className="role__details">
                            <div className="category_name_user">
                            <div>{role.name}</div>
                                <div className="numbers_of_cat_users">{getUserTypeLength(role.name)}</div>
                            </div>
                            {delete_error && <p className="text-center text-danger">{delete_error}</p>}
                            <div className="edit_delete">
                                <button className="admin_edit" onClick={() => {
                                    openModal()
                                    setCategoryId(role._id);
                                    
                                }}><FaEdit />&nbsp;Edit details</button>
                                <button className="admin_remove" onClick={() => {setOpenDeleteModal(true); setItemsDetail(role)}}><BiTrash />&nbsp;Delete</button>
                            </div>
                        </div>
                        <hr />
                       </>
                   )): null}
                </div>
            </div>
            <UpdateModal showModal={showModal} setShowModal={setShowModal} openModal={openModal} category="EDIT ROLE CATEGORY" handleChange={handleChange} name={name} categoryId={categoryId}/>
            <CloseButton showModal={showModal} setShowModal={setShowModal} openModal={openModal} close={"/dashboard/roles?role=list-roles"}/>
        </div>
        {openDeleteModal && <DeleteModal >
                <h3 className="delete-header">Are you sure you want to delete {itemDetails.name}?</h3>
                <div className="cta-btn-delete">
                    <button className="delete-confirm" onClick={() => handleRoleDelete(itemDetails._id)}>{delete_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={delete_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => history.push("/dashboard/roles")}>{delete_role?.message === "Success" ? "CLOSE" : "CANCEL"}</button>
                </div>
            </DeleteModal>}
       
        </>
    )
}

export default ListCategories