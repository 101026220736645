import { useContext, useState, useEffect} from "react";
import {MdDashboard, MdPayment,MdSubscriptions} from "react-icons/md";
import {GoVerified} from "react-icons/go"
import {AiFillDatabase} from "react-icons/ai"
import { FiUserPlus} from "react-icons/fi";
import {IoServerSharp} from "react-icons/io5"
import {IoIosBrowsers} from "react-icons/io";
import {FiArchive} from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import {FaMoneyBill} from "react-icons/fa";
import {CgUserList} from "react-icons/cg";
import { RiCoupon5Line } from "react-icons/ri";
import {  Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { AppContext } from "./AppContext";

const { Sider } = Layout

const SideBar = () => {
    const {setNotificationOpen} = useContext(AppContext);
    const [url, setUrl] = useState("")
    const dataUrl = window.location.href.split("/").pop()

    const activeClass = (path) => {
        return  path !== window.location.pathname ? "ant-menu-item" : "ant-menu-item active-class"
    }
    const categoryPaths = window.location.pathname === "/dashboard/categories/add" ? "/dashboard/categories/add" : window.location.pathname === "/dashboard/categories/edit" ? "/dashboard/categories/edit" :  window.location.pathname  === "/dashboard/categories" ? "/dashboard/categories" : window.location.pathname === "/dashboard/categories/edit" ? "/dashboard/categories/edit" : window.location.pathname === "/dashboard/categories/sub-categories" ? "/dashboard/categories/sub-categories" : null;
    const customerDBPath = window.location.pathname === "/dashboard/customer-database" ? "/dashboard/customer-database" : window.location.pathname === "/dashboard/customer-database/storeowner-details" ? "/dashboard/customer-database/storeowner-details" : window.location.pathname === "/dashboard/customer-database/basic-buyers-details" ? "/dashboard/customer-database/basic-buyers-details" : null;

    const transactionPaths = window.location.pathname === "/dashboard/transactions" ? "/dashboard/transactions" : window.location.pathname === "/dashboard/transactions/history" ? "/dashboard/transactions/history"  : null;
    const validateTransactionsPaths = window.location.pathname === "/dashboard/product/validation" ? "/dashboard/product/validation" : window.location.pathname === "/dashboard/product/validation/new" ? "/dashboard/product/validation/new" : window.location.pathname === "/dashboard/product/validation/approved" ? "/dashboard/product/validation/approved" : window.location.pathname === "/dashboard/product/validation/rejected" ? "/dashboard/product/validation/rejected" : window.location.pathname === "/dashboard/product/validation/all" ? "/dashboard/product/validation/all" : window.location.pathname === `/dashboard/product/new/details/${url}` ? `/dashboard/product/new/details/${url}` : window.location.pathname === `/dashboard/product/approved/details/${url}` ? `/dashboard/product/approved/details/${url}` : window.location.pathname === `/dashboard/product/rejected/details/${url}` ? `/dashboard/product/rejected/details/${url}` : window.location.pathname === `/dashboard/product/all/details/${url}` ? `/dashboard/product/all/details/${url}` : null;
 
const notificationClose = () => {
      setNotificationOpen(false)
}

 useEffect(() => {
        if(dataUrl){
             setUrl(dataUrl)
        } 
   }, [dataUrl]);
  
   return (
    <Sider
    breakpoint="lg"
    collapsedWidth="0"
    onBreakpoint={broken => {
   
    }}
    onCollapse={(collapsed, type) => {
     
    }}
  >
    <div className="logo"><img src="/alpha-logo.png" alt="Gig logo"/></div>
    <Menu theme="dark" mode="inline" defaultSelectedKeys="/dashboard">
      <Menu.Item key="/dashboard" icon={<MdDashboard />}  onClick={notificationClose} className={activeClass("/dashboard")}>
        <span> Dashboard &nbsp;</span>
        <Link to="/dashboard"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/orders" icon={<IoIosBrowsers/>} onClick={notificationClose} className={activeClass("/dashboard/orders")}>
           <span>Order</span>
          <Link to="/dashboard/orders"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/product/validation" icon={<GoVerified/>} onClick={notificationClose} className={activeClass(validateTransactionsPaths)} >
           <span>Upload Validation</span>
          <Link to="/dashboard/product/validation"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/customer-database" icon={<AiFillDatabase />} onClick={notificationClose} className={activeClass(customerDBPath)}>
             <span>Customer Database</span>
            <Link to="/dashboard/customer-database"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/sales" icon={<IoServerSharp />} onClick={notificationClose} className={activeClass("/dashboard/sales")}>
            <span>Sales</span>
            <Link to="/dashboard/sales"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/transactions" icon={<MdPayment/>} onClick={notificationClose} className={activeClass(transactionPaths)}>
          <span>Transactions</span>
          <Link to="/dashboard/transactions"/>
      </Menu.Item>
    
      <Menu.Item key="/dashboard/categories" icon={<FiArchive />}  onClick={notificationClose} className={activeClass(categoryPaths)}>
          <span>Products</span>
          <Link to="/dashboard/categories"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/payment-methods" icon={<BiWallet />}   onClick={notificationClose} className={activeClass("/dashboard/payment-methods")}>
           <span>Payment Method</span>
           <Link to="/dashboard/payment-methods"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/roles" icon={<FiUserPlus />} onClick={notificationClose} className={activeClass("/dashboard/roles")}>
            <span>Roles</span>
            <Link to="/dashboard/roles"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/refund" icon={<FaMoneyBill />} onClick={notificationClose} className={activeClass("/dashboard/refund")}>
            <span>Refund/Complaints</span>
            <Link to="/dashboard/refund"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/subscription" icon={<MdSubscriptions />} onClick={notificationClose} className={activeClass("/dashboard/subscription")}>
            <span>Subscription</span>
            <Link to="/dashboard/subscription"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/waitlist" icon={<CgUserList />} onClick={notificationClose} className={activeClass("/dashboard/waitlist")}>
            <span>Wait List</span>
            <Link to="/dashboard/waitlist"/>
      </Menu.Item>
      <Menu.Item key="/dashboard/coupon" icon={<RiCoupon5Line />} onClick={notificationClose} className={activeClass("/dashboard/coupon")}>
            <span>Coupon</span>
            <Link to="/dashboard/coupon"/>
      </Menu.Item>
    </Menu>
  </Sider>
   )
    
}


export default SideBar