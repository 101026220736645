import {useState, useEffect, useContext} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {IoArrowBackOutline} from "react-icons/io5";
import {AiFillPlusCircle, AiOutlineCloseCircle} from "react-icons/ai";
import {BsChevronDown} from "react-icons/bs"
import { Switch } from 'antd';
import {Multiselect} from "multiselect-react-dropdown"
import TypeModal from "./ProductType";
import SizeModal from './SizeModal';
import GenderModal from './GenderModal';
import { useSelector, useDispatch } from "react-redux";
import { EditCategoryAction, GenderListAction, GetCategoryAction, TypeListAction } from "../../actions/Admin/ProductCategoryAction";

import { Alert } from 'antd';
import Loaders from './../Reusables/Loader';
import { FaPlus } from 'react-icons/fa';
import { AppContext } from "../../AppContext"

const EditProduct = () => {   
    
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const history = useHistory(null)
    const [gender, setGender] = useState(false);
    const [color, setColor] = useState(false);
    const [size, setSize] = useState(false);
    const [sex, setSex] = useState([]);
    const [type, setType] = useState([]);
    const [remittance, setRemittance] = useState("");
    const [name, setName] = useState("");
    const [image_url, setImageUrl] = useState("");
    const [preview, setPreview] = useState("");
    const newSex = sex && sex.join(" ");
    const [newError, setNewError] = useState("");
    const [newSuccess, setNewSuccess] = useState("");
    const [invalidFields, setInvalidFields] = useState("");
    const newType = type && type.join(" ");
    
    const {editProductId} = useContext(AppContext)



   const handleRemittanceChange = (e) => {
         setRemittance(e.target.value)
    }
    function onGenderChange(checked) {
        setGender(checked)
      }
    function onSizeChange(checked) {
        setSize(checked)
      }

      function onColorChange(checked) {
        setColor(checked)
      }



      const dispatch = useDispatch()
      const {gender_list_loading, gender_list_error, list_genders} = useSelector(state=> state.listCategoryGender);
      const {type_loading,type_error,types_docs} = useSelector(state => state.listCategoryTypes);
      const {category} = useSelector(state => state.categoryGet);
      const { error: edited_category_error, category_loading: edited_category_loading, category_success:edit_category_success} = useSelector(state => state.categoryEdit)

      const resetForm = () => {
        setName("");
        setGender(false);
        setColor(false);
        setSize(false);
        setSex("");
        setType("");
        setImageUrl("");
    }
      const search = useLocation().search;
      const typeCheck = new URLSearchParams(search).get("type")

    const handleSexChange = (e) => {
        if(sex.indexOf(e.target.value) === -1){
            setSex([...sex, e.target.value])
        }else {
            return;
        }
  
    }

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0]
        setImageUrl(selectedFile);
        const filePreview = URL.createObjectURL(selectedFile);
        setPreview(filePreview)
    }



    const onSelect =(selectedList, selectedItem) => {
        setType(selectedList.map(({_id}) => _id))
    }
    const onRemove = (selectedList, removedItem) => {
        let filtered = type && type.filter(id => id !== removedItem._id)
        setType(filtered)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append("name", name);
        data.append("gender", gender);
        data.append("color", color);
        data.append("size", size);
        data.append("sex", newSex);
        data.append("type", newType);
        data.append("image_url", image_url);
        data.append("remittance", remittance);
        if(!name || !remittance || !image_url || !type){
            return setInvalidFields("All fields must be valid!")
        }

        dispatch(EditCategoryAction(editProductId, data));
       
    }
 

    useEffect(() => {
          dispatch(GetCategoryAction(editProductId))
    },[dispatch,editProductId])

   useEffect(() => {
        if(edit_category_success){
           resetForm()
        }
   }, [edit_category_success])
   useEffect(() => {
    if(edited_category_error.length > 0){
        return setNewError(edited_category_error?.message)
    }
   }, [edited_category_error, edited_category_error?.message])


  
    useEffect(() => {
        const newError =  setTimeout(() => {
            setNewError("")
        }, 3000);
        return () => clearTimeout(newError)
    });

  
     useEffect(() => {
         const invalidField = setTimeout(() => {
            setInvalidFields("")
        }, 3000);

        return () => clearTimeout(invalidField)
     })

      useEffect(() => {
           dispatch(GenderListAction())
      },[dispatch])


      useEffect(() => {
        const page = 1;
        const limit =  500000;
        const data = {page, limit}
       dispatch(TypeListAction(data))
       
  }, [dispatch]);
  
  useEffect(() => {
      setName(category?.results?.name);
      setColor(category?.results?.color);
      setGender(category?.results?.gender);
      setSize(category?.results?.size)
      setImageUrl(category?.results?.image_url);
      setRemittance(category?.results?.remittance);
      setPreview(category?.results?.image_url);
      setType(category?.results?.type?.map(({_id}) => _id));
      setSex(category?.results?.sex?.map(({_id}) => _id))
  }, [dispatch,category?.results?.name, category?.results?.image_url, category?.results?.gender, category?.results?.size, category?.results?.color, category?.results?.remittance, category?.results?.type, category?.results?.sex])

    return (
        <>
            <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading">
                    <IoArrowBackOutline onClick={() => history.push("/dashboard/categories")} className="back__arrow"/>&nbsp;&nbsp;<div className="header_order_title">EDIT PRODUCT CATEGORY</div>
                 </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="category_name">                                   
                                        <label className="add_product_label">Category Name</label><br />
                                        <input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)} value={name}/>
                                    </div>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label>Gender&nbsp; <Switch onChange={onGenderChange} checked={gender} name="gender" id="gender" value={gender}/></label><br />
                                    <div className="checkboxes mt-2">
                                        {gender_list_error && <p className="text-danger">{gender_list_error.message}</p>}
                                        {gender_list_loading && <p>Loading...</p>}
                                        {gender && 
                                               list_genders?.results ? list_genders?.results.map(sex => (
                                                <div key={sex._id}><label>{sex.name}</label>&nbsp;&nbsp;<input type="checkbox" value={sex._id} name="sex" onChange={handleSexChange}
                                                /></div> 
                                            )): ""
                                        }
                                     
                                        <div onClick={() => history.push("/dashboard/categories/add?type=gender")} className="gender_btn_add"> <AiFillPlusCircle />&nbsp;&nbsp;add option</div>
                                    </div>
                              
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
                                    <div className="category_name">                                   
                                        <label className="add_product_label">Size &nbsp; <Switch onChange={onSizeChange} checked={size} name="size" value={size}/></label><br />
                                       <span className="add_more_size ms-0" onClick={() => history.push("/dashboard/categories/add?type=size")}><AiFillPlusCircle />&nbsp;&nbsp;add more sizes</span>
                                    </div>

                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label className="mt-4">Color&nbsp; &nbsp; <Switch name="color" id="color" onChange={onColorChange} checked={color} value={color}/></label><br />
                                    <span className="add_more_size" onClick={() => history.push("/dashboard/categories/add?type=color")}><AiFillPlusCircle />&nbsp;&nbsp;add more colors</span>
                              
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="category_name">                                   
                                        <label className="add_product_label">Type</label><br /><span className="add_more_size" onClick={() => {history.push("/dashboard/categories/add?type=type"); setErrorMessage(""); setSuccessMessage("")}}><AiFillPlusCircle />&nbsp;&nbsp;add more types</span><br />
                                        {type_error && <p className="text-danger">{type_error.message}</p>}
                                      
                                        <Multiselect options={types_docs && types_docs} displayValue="name" showArrow customArrow={<BsChevronDown />} placeholder="Select sub-categories" onSelect={onSelect} onRemove={onRemove} customCloseIcon={<AiOutlineCloseCircle className="close-icon-select" />} loading={type_loading ? true : false} loadingMessage="Fetching sub-categories..."/>
                                        {/* <div  className="type_listing">
                                        {
                                            fetchedType && fetchedType.map(({name, _id}) => (
                                           
                                                    <ul key={_id}>
                                                        <li>{name}</li>
                                                    </ul>
                                              
                                            ))
                                        }
                                        
                                        </div> */}
                                    </div>
                                    <div className="remittance-section">
                                  
                                        <div className="remittance-text">Remittance</div>
                                        <label className="remittance-label" htmlFor="remittance-one">
                                           
                                                <input type="radio" name="remittance" id="remittance-one" className="remittance_input" value="urgent" onChange={handleRemittanceChange} checked={remittance === "urgent"}/>
                                                <div className="remittance_radio"></div>
                                                <span className="urgent-label">Urgent</span>
                                                
                                        </label> <br />
                                        <label className="remittance-label" htmlFor="remittance-two">
                                           
                                           <input type="radio" name="remittance" id="remittance-two" className="remittance_input" value="non-urgent" onChange={handleRemittanceChange} checked={remittance === "non-urgent"}/>
                                           <div className="remittance_radio"></div>
                                           <span className="urgent-label">Non-Urgent</span>
                                           
                                   </label> 
                                    </div>
                             
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                    
                                       <div className="row">
                                            <div className="col-lg-7">                     
                                                <label className="add_product_label mb-2" htmlFor="image_url">
                                                    <div className="mb-2">Upload Images</div>
                                                    {image_url && <img src={preview} alt={image_url.name} style={{width: "100px",  marginTop: "5px", marginBottom: "5px"}}/>}
                                                    <input type="file" id="image_url" name="image_url" onChange={handleFileUpload}/>
                                                    <div className="product_image ms-1">
                                                         <div><FaPlus /></div>
                                                    </div>
                                                </label>
                                             
                                            </div>
                                            <div className="col-lg-12">
                                                 <label className="add_product_label mb-2" htmlFor="image_url2">
                                                    <input type="file" id="image_url2" name="image_url2" multiple/>
                                                    <div className="banner_image mt-2">
                                                        <div className="add-img"><FaPlus /></div>
                                                        <div  className="ms-2 add-img">Upload Banner here</div>
                                                    </div>
                                                 </label>
                                            </div>
                                        </div>
                                </div>
                              
                                {invalidFields  &&
                                    <Alert message="Failed"
                                    description={invalidFields}
                                    type="error"
                                    closable
                                    className="my-3"
                                    /> 
                                }
                                {newError &&
                                    <Alert message="Failed"
                                    description={newError}
                                    type="error"
                                    closable
                                    className="my-3"
                                    /> 
                                }
                                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3 offset-lg-6">
                                    <button type="submit" className="btn-save">{edited_category_loading ? <Loaders loading={edited_category_loading} color="#ffffff" size={30}/>: "Create"}</button><button type="button" className="cancel-btn"onClick={() => history.push("/dashboard/categories")} >Cancel</button>
                                </div>
                        
                        </div>
                    </form>
                </div>
            </div>
            {typeCheck && typeCheck === "type" ? <TypeModal successMessage={successMessage} setSuccessMessage={setSuccessMessage} errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>: null}
            {typeCheck && typeCheck === "size" ? <SizeModal />: null}
            {typeCheck && typeCheck === "gender" ? <GenderModal />:null}
            
        </>
    )
}

export default EditProduct