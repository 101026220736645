import { useEffect, useState,  useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';

import Loaders from '../Reusables/Loader';
import { deleteAdmin, listAdmins } from '../../actions/Admin/AdminAction';
import DeleteModal from '../Reusables/DeleteModal';
import { AppContext } from '../../AppContext'
import "./admin.css";


const AllAdmins = ({setAdminRoleId}) => {
    const {  itemLimit, setItemLimit} = useContext(AppContext)
    const dispatch = useDispatch();
    const history = useHistory()
    const {loading, error, admins, admins_docs} = useSelector(state => state.fetchAdmin);
    const {error: deleteError, success: delete_success,loading: delete_admin_loading} = useSelector(state => state.deleteAdmin);
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const [adminDeleteId, setAdminDeleteId] = useState("")
    const [adminDeleteError, setAdminDeleteError] = useState("")

    const search = useLocation().search;
    const roleModal = new URLSearchParams(search).get("role");


    useEffect(() => {
        const page = 1;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(listAdmins(data))
       
  }, [dispatch, itemLimit]);
  useEffect(() => {
    if(delete_success){
        setDeleteSuccess(delete_success)
    }
  }, [delete_success])

  useEffect(() => {
    if(deleteError){
        setAdminDeleteError(deleteError)
    }
  }, [deleteError])
  
  const handleNextPage = (page_number) => {
      const page = page_number;
      const limit = +itemLimit;
      const data = { page, limit };
      dispatch(listAdmins(data));
  };
  const handleAdminDelete = (e) => {
    e.preventDefault()
    dispatch(deleteAdmin(adminDeleteId));   
}

useEffect(() => {
    const successDelete = setTimeout(() => {
        setDeleteSuccess(false);
        setAdminDeleteError("")
    }, 3000);
    return () => clearTimeout(successDelete)
}, [adminDeleteError])


  const totalPages = admins && admins.totalPages;
  const page = admins && admins.page;
  const prevPage = admins && admins.prevPage;
  const nextPage = admins && admins.nextPage;
  const hasNextPage = admins && admins.hasNextPage;
  const hasPrevPage = admins && admins.hasPrevPage

    return (
        <>
         {error && error ? <div className="view_admins_error"> <p className="mt-1">{error || error.message}</p></div> :
        <div className="card card_wrapper  seller_table_wrapper mt-4">
                    
        <div className="data_heading">
        <div className="header_order_title role_header">MANAGE ROLES</div>
      
        <button className="admin_add" onClick={() => {history.push("/dashboard/roles?role=list-roles")}}><span>View Category</span></button>
    </div>
        <div className="table-responsive">
          <table className="table">
          <thead className="tborder" align="middle">
                    <tr className="align_student_table">
                        <th scope="col" className="header___id">Admin ID</th>
                        <th scope="col" className="header___name">Name</th>
                        <th scope="col" className="header___email">Email Address</th>
                        <th scope="col" className="header___phone">Phone Number</th>
                        <th scope="col" className="header___role">Role</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
            </thead>

            <tbody>
                {admins_docs && admins_docs.length > 0 ? admins_docs.map(admin => (
                      <tr key={admin._id} className="align_student_table" align="middle">
                      <td className="item">{admin._id}</td>
                      <td className="item">{`${admin.first_name} ${admin.last_name}`}</td>
                      <td className="item">{admin.email}</td>
                      <td className="item">{admin.phone}</td>
                      {/* <td  className="item">{admin.role.name}</td> */}
                      <td className="item"><FaEdit className="admin__edit" onClick={() => {
                          history.push(`/dashboard/roles?role=edit-admin`)
                          setAdminRoleId(admin._id)
                          
                        }}/></td>
                      <td className="item"><FaRegTrashAlt className="trash_admin" onClick={() => {setAdminDeleteId(admin._id); history.push(`/dashboard/roles?role=delete-role`)}}/></td>
                  </tr>
                )): null}
               
            </tbody>
        </table>
      </div>
      <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
        <div className="page_number">{admins_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
            <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
        </div>
        {totalPages > 1 ?
        <div className="paginate_reuse">
            <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{loading ? <div style={{display: "flex", justifyContent: "center", width: "100%", alignContent: "center", height: "auto"}}><Loaders loading={loading} color="#000000" size={10} /></div> : page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
        </div> : null
        }
    </div>   
</div>
}

{roleModal === "delete-role" &&
    <DeleteModal header={"DELETE USER"}>
        <h3 className="delete-header">Are you sure you want to delete this user?</h3>
        {adminDeleteError && <div className="text-center text-danger"><p className="mt-1">{adminDeleteError}</p></div>}
        <div className="cta-btn-delete">
            <button className="delete-confirm" onClick={handleAdminDelete}>{delete_admin_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={delete_admin_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => history.push("/dashboard/roles")}>{deleteSuccess ? "CLOSE" : "CANCEL"}</button>
        </div>
    </DeleteModal>
}
</>
    )
}

export default AllAdmins