import {useState} from "react";
import AllProducts from "./AllProducts";
import "./product.css";

const ListProducts = ({menuSwitch,handleMenuSwitch}) => {
    const [toggle, setToggle] = useState(true)
    const [changeMenu, setChangeMenu] = useState(null);
    function onChange(checked) {
        setToggle(checked)
     
      }

      const handleChangeMenu = (index) => {
           return setChangeMenu(index)
      }

    return (
        <>
            <AllProducts toggle={toggle} setToggle={setToggle} onChange={onChange} handleChangeMenu={handleChangeMenu} changeMenu={changeMenu} handleMenuSwitch={handleMenuSwitch} />    
            
        </>
    )
}

export default ListProducts;