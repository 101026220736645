import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io"

import { AdminRoleList } from "../../actions/Admin/AdminRoleAction";
import Loaders from "../Reusables/Loader";
import { updateAdmin } from "../../actions/Admin/AdminAction";

const Editrole = ({handleActiveTab, loading, error, admin}) => {
     const history = useHistory()
     const [openContent, setOpenContent] = useState(false);
     const [search, setSearch] = useState("");
     const [roleId, setRoleId] = useState("")
     const [first_name, setFirstName] = useState("");
     const [last_name, setLastName] = useState("");
     const [phone, setPhone] = useState("");
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("")
    //  const handleRoleClick = (data) => {
    //      setGetRole(data)
    //  }
  
   
     const dispatch = useDispatch()
     const {loading: listRoleLoading, error: listRoleError, roles} = useSelector(state => state.listRole);
    //  const {error: update_role_error, adminRole} = useSelector(state => state.updateAdminRole);
     const {error: update_admin_error, loading: update_admin_loading} = useSelector(state => state.updateAdmin);
   

     const singleAdmin = admin && admin.results;


   const dataToSubmit = {first_name, last_name, role: roleId, email,phone}

     const setSelected = selected => {
         setSearch(selected)
         setOpenContent(false)
     }

    const handleDataSubmit = (e) => {
         e.preventDefault();
         dispatch(updateAdmin(singleAdmin._id, dataToSubmit));
     }

 
     useEffect(() => {
        dispatch(AdminRoleList())
     }, [dispatch])
     useEffect(() => {
        setRoleId(singleAdmin?.role?._id)
        setSelected(singleAdmin?.role?.name)
        setFirstName(singleAdmin?.first_name)
        setLastName(singleAdmin?.last_name)
        setEmail(singleAdmin?.email)
        setPhone(singleAdmin?.phone)
     }, [singleAdmin?.role?._id, singleAdmin?.role?.name, singleAdmin?.first_name, singleAdmin?.last_name, singleAdmin?.email, singleAdmin?.phone])
    return (
        <>
    
        <div className="role_container mt-4">
        {listRoleLoading && <Loaders loading={listRoleLoading} color="#ffffff" size={20}/>}
        {listRoleError && <h4 className="text-center text-danger">{listRoleError.message}</h4>}
        {loading && <Loaders loading={loading} color="#ffffff" size={20}/>}
        {/* {error && <div className="text-center text-danger">{error.message || error}</div>} */}
        { update_admin_error  && <h4 className="text-center text-danger">{ update_admin_error || update_admin_error.message}</h4>}

           
        <div className="card  create_role_card">
           <div className="data_heading">
             <div className="header_order_title role_header">EDIT ROLE</div>
             <button className="admin_role_add" onClick={() => {handleActiveTab(0); history.push("/dashboard/roles")}}><span>View Roles</span></button>
           </div>
           <form className="create_admin" onSubmit={handleDataSubmit}>
               <div className="admin_firstname"><label>First Name</label><input type="text" value={first_name} onChange={(e) => setFirstName(e.target.value)}/></div>
               <div className="admin_lastname"><label>Last Name</label><input type="text" value={last_name} onChange={(e) => setLastName(e.target.value)}/></div>
               <div className="admin_email"><label>Email address</label><input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/></div>
               <div className="admin_phonenumber"><label>Phone Number</label><input type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/></div>
               <div className="admin_assign_role"><label> Assign Role</label><div className="role__input"  onClick={() => setOpenContent(!openContent)}><input type="text" placeholder="-------- Choose Role --------" onChange={(e) => setSearch(e.target.value)} value={search}/>{openContent ?<IoIosArrowUp  className="arrow_down arrow_up"/>:<IoIosArrowDown className="arrow_down"/>}
               
               </div>
               {openContent ?    <div className="role__content">
                   <p>------- Choose Role --------</p>
                   {
                       roles && roles?.results.filter(({name}) => name.indexOf(search?.toLowerCase()) > -1).map(role => {
                          return  <ul key={role._id}>           
                               <li onClick={() => {
                                   setSelected(role.name)
                                   setRoleId(role._id)
                                }}>{role.name}</li>
                           </ul>
                        
                    })
                   }
                 
                   
               </div>: ""}
            
               </div>
               <div className="admin_password"><label>Assign Password</label><input type="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled/></div>
               <button className="register_admin">{ update_admin_loading ? <Loaders loading={update_admin_loading} color="#ffffff" size={20}/> : "submit"}</button>
             
           </form>
           
        </div>
        </div>
        </>
      
    )
}


export default Editrole 