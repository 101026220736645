import { useHistory } from "react-router-dom";
import "./style.css";
const DeleteItem = ({children, header, fn}) => {
    const history = useHistory()
    return (
        <div className="payment_modal">
            <div className="card payment_add_method delete__item-modal">
                <div className="data_heading">
                    <div className="header_order_title type_header text-center">{header}</div>
                </div>
                <div className="card-body delete__modal__body">
                     {children}
                </div>
            </div>
            <div className="alpha-overflow" onClick={fn}></div>
        </div>
    )
}

export default DeleteItem