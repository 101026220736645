import React, {useContext, useEffect, useState, useMemo} from 'react';
import moment from "moment";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../AppContext';
import { ListProducts, clearProductDelete } from '../../actions/Admin/products/Products';
import { FaTrashAlt } from 'react-icons/fa';
import { useLocation, useHistory, Link } from 'react-router-dom';
import DeleteItem from '../Reusables/DeleteItem';
import Loaders from '../Reusables/Loader';
import { DeleteProduct } from './../../actions/Admin/products/Products';
import { ProductSearch } from '../../actions/Admin/search/SearchAction';

const ListProduct = () => {
    const [id, setId] = useState("")
    const dispatch = useDispatch();
    const {delete_product_loading, delete_error,delete_product_success} = useSelector(state => state.deleteProductReducer)
    const {list_products,list_products_docs,list_error,all_products_length} = useSelector(state => state.listProductReducer);
    const {product_search,product_search_docs,product_search_error} = useSelector(state => state.productSearchReducer);
    const {itemLimit, setItemLimit,pageNumber, setPageNumber, searchTerm} = useContext(AppContext);
    const history = useHistory()
    const search = useLocation().search;
    const delProduct = new URLSearchParams(search).get("product");

    const listProducts = searchTerm !== "" ?  product_search_docs : list_products_docs; 


    const pageData = () => {
        const page = pageNumber;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(ListProducts(data))
    }
    
    const deleteProduct = () => {
        if(id){
            dispatch(DeleteProduct(id))
        }
       
     }
    useEffect(() => {
        const page = pageNumber;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(ListProducts(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
      const page = page_number;
      const limit = +itemLimit;
      const data = { page, limit };
      dispatch(ListProducts(data));
  };


  const totalPages = searchTerm !== "" ? (product_search && product_search.totalPages) : (list_products && list_products.totalPages);
  const page = searchTerm !== "" ? (product_search && product_search.page) : (list_products && list_products.page);
  const prevPage = searchTerm !== "" ? (product_search && product_search.prevPage) : (list_products && list_products.prevPage);
  const nextPage = searchTerm !== "" ? (product_search && product_search.nextPage) : (list_products && list_products.nextPage);
  const limitItem = searchTerm !== "" ? (product_search && product_search.totalDocs) : (list_products && list_products.totalDocs);

  const searchProduct = useMemo(() => {
    const page = pageNumber;
    const limit = limitItem || itemLimit;
    const data = {page, limit, searchTerm}
    if(searchTerm){
        dispatch(ProductSearch(data))
    }
}, [dispatch, searchTerm, itemLimit,limitItem]);
useEffect(() => searchProduct, [searchProduct])


  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
  }, [page])

  return (
    <>
        <div className="mt-4 card card_wrapper  seller_table_wrapper">
            <div className="data_heading">
                <div className="header-title">ALL PRODUCTS  <span>({all_products_length})</span></div>
            </div>
            <div className="card-body sub-category-content-body">
                {list_error && <p className="text-danger mt-2 text-center">{list_error}</p>}
                {listProducts && listProducts?.length <= 0 && !list_error && <h2 className="text-center no-record-found">No Records Found</h2>}
                <div className="table-responsive">
                    <table className="table">
                    {listProducts && listProducts?.length > 0 && product_search_error === "" &&
                        <thead align="middle">
                                <tr className="validate-product__sec">
                                    <th scope="col">Alpha Code</th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Store Name</th>
                                    <th scope="col">Validation Date</th>
                                    <th scope="col">Product Location</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                        </thead>
                        }
                        {
                            product_search_error ? <p className="text-danger mt-2 text-center">{product_search_error}</p> :
                       
                        <tbody align="middle">
                            
                            {
                          listProducts && listProducts?.length > 0 && listProducts.map(({alphaCode,_id,productName,categoryName, store:{name},updatedAt, status, neighborhood, locality}) => (
                                    <tr className="validate-product__body" key={_id}>
                                    
                                        <td>{alphaCode}</td>
                                        <td>{productName}</td>
                                        <td>{categoryName}</td>
                                        <td>{name}</td>
                                        <td>{moment(updatedAt).format("DD/MM/YYYY")}</td>
                                        {neighborhood === "" ?   <td>{locality}</td> : <td>{neighborhood}, {locality}</td>}
                                      
                                        <td className='approved'>{status}</td>
                                        <td><Link to={`/dashboard/product/all/details/${_id}`}>View details</Link></td>
                                        <td className='trash-icon'><FaTrashAlt  onClick={() => {setId(_id); history.push("/dashboard/product/validation/all?product=delete")}}/></td>
                                    </tr>
                                ))         
                        
                            }
                        </tbody>
                         }
                    </table>
                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                                
                                    <div className="page_number">Item per page {" "}{" "}
                                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                                    </div>
                                { totalPages > 1 ? 
                                    <div className="paginate_reuse">
                                        <button className="left-chevron" onClick={() => {handleNextPage(prevPage); }}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                                    </div>: null
                                }
                    </div>  
                </div>
            </div>
        </div>
        {delProduct === "delete" && id && <DeleteItem header={"DELETE THIS PRODUCT"} fn={() => {history.push("/dashboard/product/validation/all"); pageData();}}>
             <h3 className="delete-header">Are you sure you want to delete this Product?</h3>
             {delete_error && <p className='text-center text-danger'>{delete_error}</p>}
                <div className="cta-btn-delete">
                   <button className="delete-confirm d-flex text-center align-items-center justify-content-center" onClick={deleteProduct}>{delete_product_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={delete_product_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => {history.push("/dashboard/product/validation/all"); pageData(); dispatch(clearProductDelete())}}>{delete_product_success ? "CLOSE" : "CANCEL"}</button>
                </div>
            </DeleteItem>}
    </>
  )
}

export default ListProduct