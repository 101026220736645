import { useContext, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux";
import {BsChevronDown, BsChevronUp, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import moment from "moment";
import { AppContext } from './../../AppContext';
import "./tabs.css"
import { newOrdersAction } from "../../actions/Admin/OrderByStatus";
import NewOrderItems from './NewOrderItem';
import { OrderSearch } from "../../actions/Admin/search/SearchAction";

const NewOrders = () => {
    const {itemLimit, setItemLimit, toggleAccordion, selected, productQty, setSelected, clientName, setClientName, setOrderedItems, searchTerm,pageNumber, setPageNumber} = useContext(AppContext);
    const {new_orders,  new_orders_docs, error} = useSelector(state => state.newOrders);
    const {order_search_docs,order_search_error,order_search} = useSelector(state => state.orderSearchReducer);
    const dispatch = useDispatch(null);

    const newOrders = searchTerm !== "" ?  order_search_docs : new_orders_docs; 
    useEffect(() => {
        const offset = pageNumber;
        const limit = +itemLimit;
        const data = {offset, limit}
       dispatch(newOrdersAction(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
      const offset = page_number;
      const limit = +itemLimit;
      const data = {offset, limit };
      dispatch(newOrdersAction(data));
  };


  const totalPages = searchTerm !== "" ? (order_search && order_search.totalPages) : (new_orders && new_orders.totalPages);
  const page = searchTerm !== "" ? (order_search && order_search.page) : (new_orders && new_orders.page);
  const prevPage = searchTerm !== "" ? (order_search && order_search.prevPage) : (new_orders && new_orders.prevPage);
  const nextPage = searchTerm !== "" ? (order_search && order_search.nextPage) : (new_orders && new_orders.nextPage);
  const limitItem = searchTerm !== "" ? (order_search && order_search.totalDocs) : (new_orders && new_orders.totalDocs);


  const search = useMemo(() => {
      const offset = pageNumber;
      const limit = limitItem || itemLimit;
      const data = {offset, limit, searchTerm}
      if(searchTerm){
          dispatch(OrderSearch(data))
      }
  }, [dispatch, searchTerm, itemLimit,limitItem]);
  useEffect(() => search, [search])

  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
   
}, [page])
    return (
        <>
        <div className="card card_wrapper  seller_table_wrapper mt-4">

            <div className="data_heading">
                <div className="header_order_title">New Order</div>
            </div>
            <div className="card-body sub-category-content-body">
                    {error && 
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className="mb-0">{error.message || error}</p>
                        </div>
                    }
                    {
                            order_search_error && <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className="mb-0">{order_search_error || order_search_error.message }</p>
                        </div>
                        }
                    {
                         newOrders &&  newOrders.length > 0 ?  newOrders.map((order, index) => (
               <div key={order._id}>
                    <div className="order_content">
                        <div className="order__id"><strong>Order ID:</strong> {order._id}</div>
                        <div className="order__qty"><strong>Qty: {productQty(order.PreShipmentItems)}</strong>{""} {productQty(order.PreShipmentItems) > 1 ? "Products" : "Product"}</div>
                        <div className="order__date">{moment(order.createdAt).format("D/MM/YYYY h:mm a")}</div>
                        {selected === index ? <BsChevronUp onClick={() => toggleAccordion(index)}/> : <BsChevronDown onClick={() => {toggleAccordion(index); setOrderedItems(order); setClientName(order.ReceiverName)}}/> }
                    </div>
                    <div className={selected === index ? "item_list_content show" : "item_list_content"} >
                         <NewOrderItems items={order.PreShipmentItems} receiverName={clientName} order={newOrders}/>
                    </div>

                </div>))  : <h2 className="text-center no-record-found">No Records Found</h2>
                }
         
               
   
      
        <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
           
            <div className="page_number">Item per page {" "}{" "}
                <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
            </div>
            { totalPages > 1 ? 
                <div className="paginate_reuse">
                    <button className="left-chevron" onClick={() => {handleNextPage(prevPage); setSelected(null)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage); setSelected(null)}}><BsChevronRight /></button>
                </div> : null
            }
        
        </div>  
     
  </div>
</div>
 
</>
    )
}

export default NewOrders