import { localData } from '../../../helpers/auth/auth';
import { ORDER_SEARCH_REQUEST, ORDER_SEARCH_FAIL, ORDER_SEARCH_SUCCESS, PRODUCT_SEARCH_REQUEST, PRODUCT_SEARCH_FAIL, PRODUCT_SEARCH_SUCCESS, CLEAR_PRODUCT_SEARCH, CLEAR_USER_SEARCH, USER_SEARCH_REQUEST, USER_SEARCH_FAIL, USER_SEARCH_SUCCESS, CLEAR_ORDER_SEARCH, STORE_SEARCH_REQUEST, STORE_SEARCH_FAIL, STORE_SEARCH_SUCCESS, CLEAR_STORE_SEARCH } from './../../../constants/searches/searchConstants';
import { getUrl } from './../../../helpers/getUrl';
const token = localData && localData.token
export function OrderSearch({offset,limit, searchTerm}) {

    return async(dispatch,getState) => {
    dispatch({type: ORDER_SEARCH_REQUEST});
    
    try {

        const res = await fetch(`${getUrl("https://order.thealphashops.com/api/v1", "https://orderservice.click/api/v1")}/order/search_order?searchTerm=${searchTerm}&offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "ACCEPT": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
           return dispatch({type: ORDER_SEARCH_FAIL, payload: data.message})
        }
        dispatch({type: ORDER_SEARCH_SUCCESS, payload: data.results})
    } catch (error) {

        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: ORDER_SEARCH_FAIL, payload: message})
    }
  }
}


export function ProductSearch({page,limit, searchTerm}) {

  return async(dispatch,getState) => {
  dispatch({type: PRODUCT_SEARCH_REQUEST});
  try {
      const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/product/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "ACCEPT": "application/json",
              "Authorization": `Bearer ${token}`
          }
      })
      const data = await res.json();
      if(data.error){
         return dispatch({type: PRODUCT_SEARCH_FAIL, payload: data.message})
      }
      dispatch({type: PRODUCT_SEARCH_SUCCESS, payload: data.results})
  } catch (error) {

      const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
        dispatch({type: PRODUCT_SEARCH_FAIL, payload: message})
  }
}
}



export function UserSearch({searchTerm, page, limit}) {

  return async(dispatch,getState) => {
  dispatch({type: USER_SEARCH_REQUEST});
  try {
      const res = await fetch(`${getUrl("https://user.thealphashops.com/api/v1", "https://userservice.click/api/v1")}/user/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "ACCEPT": "application/json",
              "Authorization": `Bearer ${token}`
          }
      })
      const data = await res.json();
      if(data.error){
         return dispatch({type: USER_SEARCH_FAIL, payload: data.message})
      }
      dispatch({type: USER_SEARCH_SUCCESS, payload: data.results})
  } catch (error) {

      const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
        dispatch({type: USER_SEARCH_FAIL, payload: message})
  }
}
}



export function StoreSearch({searchTerm, page, limit}) {

  return async(dispatch,getState) => {
  dispatch({type: STORE_SEARCH_REQUEST});
  try {
      const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/store/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "ACCEPT": "application/json",
              "Authorization": `Bearer ${token}`
          }
      })
      const data = await res.json();
      if(data.error){
         return dispatch({type: STORE_SEARCH_FAIL, payload: data.message})
      }
      dispatch({type: STORE_SEARCH_SUCCESS, payload: data.results})
  } catch (error) {

      const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
        dispatch({type: STORE_SEARCH_FAIL, payload: message})
  }
}
}

export const clearProductSearchState = () => async (dispatch) => {
  dispatch({type: CLEAR_PRODUCT_SEARCH});
  dispatch({type: CLEAR_USER_SEARCH});
  dispatch({type: CLEAR_ORDER_SEARCH});
  dispatch({type: CLEAR_STORE_SEARCH})
}

