import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {BsChevronDown, BsChevronUp, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import moment from "moment";
import { AppContext } from '../../AppContext';
import {shippedOrdersAction} from "../../actions/Admin/OrderByStatus";
import ShippedOrderItems from './ShippedOrderItem';
import { OrderSearch } from '../../actions/Admin/search/SearchAction'
import "./tabs.css"
import "../../datatable.css";

const ShippedOrders = () => {
    const dispatch = useDispatch(null)
    const {setItemLimit, itemLimit, toggleAccordion, productQty, selected, setSelected, clientName, setClientName, searchTerm, pageNumber, setPageNumber,setOrderedItems} = useContext(AppContext);
    const {error,  shipped_orders, shipped_orders_doc} = useSelector(state => state.shippedOrders);
    const {order_search_docs,order_search_error,order_search} = useSelector(state => state.orderSearchReducer);
    const shippedOrders = searchTerm !== "" ?  order_search_docs : shipped_orders_doc; 

    useEffect(() => {
        const offset = pageNumber;
        const limit = +itemLimit;
        const data = {offset, limit}
       dispatch(shippedOrdersAction(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
      const offset = page_number;
      const limit = +itemLimit;
      const data = { offset, limit };
      dispatch(shippedOrdersAction(data));
  };
  

  const totalPages = searchTerm !== "" ? (order_search && order_search.totalPages) : (shipped_orders && shipped_orders.totalPages);
  const page = searchTerm !== "" ? (order_search && order_search.page) : (shipped_orders && shipped_orders.page);
  const prevPage = searchTerm !== "" ? (order_search && order_search.prevPage) : (shipped_orders && shipped_orders.prevPage);
  const nextPage = searchTerm !== "" ? (order_search && order_search.nextPage) : (shipped_orders && shipped_orders.nextPage);
  const limitItem = searchTerm !== "" ? (order_search && order_search.totalDocs) : (shipped_orders && shipped_orders.totalDocs);


  const search = useMemo(() => {
      const offset = pageNumber;
      const limit = limitItem || itemLimit;
      const data = {offset, limit, searchTerm}
      if(searchTerm){
          dispatch(OrderSearch(data))
      }
  }, [dispatch, searchTerm, itemLimit,limitItem]);
  useEffect(() => search, [search])

  useEffect(() => {
    if(page){
        setPageNumber(page)
    }
   
}, [page])

    return (
        <>    
        <div className="card card_wrapper  seller_table_wrapper mt-4">

            <div className="data_heading">
                <div className="header_order_title">Shipped Orders</div>
            </div>
            <div className="card-body sub-category-content-body">
                {error && 
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Error!</h4>
                        <hr/>
                        <p className="mb-0">{error || error.message}</p>
                    </div>
                }
                {
                     order_search_error && <div className="alert alert-danger" role="alert">
                     <h4 className="alert-heading">Error!</h4>
                     <hr/>
                     <p className="mb-0">{order_search_error || order_search_error.message}</p>
                 </div>
                }
                   
                   {
                        shippedOrders &&  shippedOrders.length > 0 ?  shippedOrders.map((order, index) => (
                           <div key={order._id}>
                                <div className="order_content">
                                    <div className="order__id"><strong>Order ID:</strong> {order._id}</div>
                                    <div className="order__qty"><strong>Qty: {productQty(order.PreShipmentItems)}</strong>{""} {productQty(order.PreShipmentItems) > 1 ? "Products" : "Product"}</div>
                                    <div className="order__date">{moment(order.createdAt).format("D/MM/YYYY h:mm a")}</div>
                                    <div className={order.status === "ordered" ? "ordered-status" : order.status === "processing" ? "processing-status": order.status === "delivered" ? "delivered-status" : order.status === "shipped" ? "shipped-status" : order.status === "cancelled" ? "cancelled-status" : ""}>
                                    {order && order.status !== null ? <button>{order.status.slice(0,1).toUpperCase() + order.status.slice(1)}</button> : "-"}
                                    </div>
                                    {selected === index ? <BsChevronUp onClick={() => toggleAccordion(index)}/> : <BsChevronDown onClick={() => {toggleAccordion(index); setClientName(order.ReceiverName);setOrderedItems(order);}}/> }
                                </div>
                                <div className={selected === index ? "item_list_content show" : "item_list_content"} >
                                     <ShippedOrderItems items={order.PreShipmentItems} receiverName={clientName} order={shippedOrders}/>
                                </div>
                            </div>
                       )): <h2 className="text-center no-record-found">No Records Found</h2>
                           
                   }
                  
                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                       
                        <div className="page_number">Item per page {" "}{" "}
                            <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                        </div>
                    { totalPages > 1 ? 
                        <div className="paginate_reuse">
                            <button className="left-chevron" onClick={() => {handleNextPage(prevPage); setSelected(null)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage); setSelected(null)}}><BsChevronRight /></button>
                        </div>: null
                    }
                    </div>  
                 
              </div>
            </div>

</>
    )
}

export default ShippedOrders