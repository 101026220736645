import { CLEAR_ORDER_SEARCH, CLEAR_PRODUCT_SEARCH, CLEAR_STORE_SEARCH, CLEAR_USER_SEARCH, ORDER_SEARCH_FAIL, ORDER_SEARCH_REQUEST, ORDER_SEARCH_SUCCESS, PRODUCT_SEARCH_FAIL, PRODUCT_SEARCH_REQUEST, PRODUCT_SEARCH_SUCCESS, STORE_SEARCH_FAIL, STORE_SEARCH_REQUEST, STORE_SEARCH_SUCCESS, USER_SEARCH_FAIL, USER_SEARCH_REQUEST, USER_SEARCH_SUCCESS } from "../../../constants/searches/searchConstants";

const initialState = {
      order_search_loading: false,
      product_search_loading: false,
      order_search: [],
      product_search: [],
      order_search_docs: [],
      product_search_docs: [],
      order_search_error: "",
      product_search_error: "",
      user_search_loading: false,
      user_search: [],
      user_search_docs: [],
      user_search_error: "",
      store_search_loading: false,
      store_search_docs: [],
      store_search: [],
      store_search_error: ""
}



export const orderSearchReducer = (state=initialState, action) => {
      const {type, payload} = action;
      switch(type){
           case ORDER_SEARCH_REQUEST:
                return {
                    ...state,
                    order_search_loading: true
                }
           case ORDER_SEARCH_SUCCESS:
                return {
                    ...state,
                    order_search_loading: false,
                    order_search: payload,
                    order_search_docs: payload.docs
                }
           case ORDER_SEARCH_FAIL:
                return {
                    ...state,
                    order_search_loading: false,
                    order_search_error: payload
                }
           case CLEAR_ORDER_SEARCH: 
                return {
                    ...state,
                    order_search_loading: false,
                    order_search_error: ""
                }
           default:
               return state;
      }
}


export const productSearchReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
         case PRODUCT_SEARCH_REQUEST:
              return {
                  ...state,
                  product_search_loading: true
              }
         case PRODUCT_SEARCH_SUCCESS:
              return {
                  ...state,
                  product_search_loading: false,
                  product_search: payload,
                  product_search_docs: payload.docs
              }
         case PRODUCT_SEARCH_FAIL:
              return {
                  ...state,
                  product_search_loading: false,
                  product_search_error: payload
              }
         case CLEAR_PRODUCT_SEARCH:
              return {
                   ...state,
                   product_search_error: "",
                   product_search_loading: false
              }
         default:
             return state;
    }
}

export const userSearchReducer = (state=initialState, action) => {
     const {type, payload} = action;
     switch(type){
          case USER_SEARCH_REQUEST:
               return {
                   ...state,
                   user_search_loading: true
               }
               
          case USER_SEARCH_SUCCESS:
               return {
                   ...state,
                   user_search_loading: false,
                   user_search: payload,
                  user_search_docs: payload.docs
               }
          case USER_SEARCH_FAIL:
               return {
                   ...state,
                   user_search_loading: false,
                   user_search_error: payload
               }
          case CLEAR_USER_SEARCH:
               return {
                    ...state,
                    user_search_error: "",
                    user_search_loading: false
               }
          default:
              return state;
     }
 }
 
 

 export const storeSearchReducer = (state=initialState, action) => {
     const {type, payload} = action;
     switch(type){
          case STORE_SEARCH_REQUEST:
               return {
                   ...state,
                   store_search_loading: true
               }
          case STORE_SEARCH_SUCCESS:
               return {
                   ...state,
                   store_search_loading: false,
                   store_search: payload,
                  store_search_docs: payload.docs
               }
          case STORE_SEARCH_FAIL:
               return {
                   ...state,
                   store_search_loading: false,
                   store_search_error: payload
               }
          case CLEAR_STORE_SEARCH:
               return {
                    ...state,
                    store_search_error: "",
                    store_search_loading: false
               }
          default:
              return state;
     }
 }