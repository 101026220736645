import { useEffect, useContext, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {IoArrowBackOutline} from "react-icons/io5";
import {FaEdit, FaTrashAlt} from "react-icons/fa";
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { Switch } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DeleteTypeAction,TypeListAction } from "../../actions/Admin/ProductCategoryAction";
import { AppContext } from "../../AppContext";
import EditTypeModal from "./EditType";
import DeleteModal from "../Reusables/DeleteModal";
import Loaders from "../Reusables/Loader";

const AllSubCategories = () => {   
      const [toggle, setToggle] = useState(false);
      const [id, setId] = useState("");
      const [subCatName, setSubCatName] = useState("")
      const history = useHistory(null)
      const [typeDeleteError, setTypeDeleteError] = useState('')
      const [subDeleteSuccess, setSubDeleteSuccess] = useState("");
      const search = useLocation().search;
      const categoryDelete = new URLSearchParams(search).get("id");
      const dispatch = useDispatch()
      const {types_docs,types, type_error} = useSelector(state => state.listCategoryTypes);
      const { type_success , type_error: type_delete_error, type_loading} = useSelector(state => state.deleteType);
    
      const {itemLimit, setItemLimit} = useContext(AppContext);

      const checkSubCatDel = categoryDelete && categoryDelete.slice(25);
      
      const handleTypeDelete = (subCategoryId) => {
        if(subCategoryId){
            dispatch(DeleteTypeAction(subCategoryId))
        }
    }

    const refetch = () => {
        if(type_success){
            const page = 1;
            const limit = +itemLimit;
            const data = {page, limit}
           dispatch(TypeListAction(data))
         }

     }


    

      useEffect(() => {
        const page = 1;
        const limit = +itemLimit;
        const data = {page, limit}
       dispatch(TypeListAction(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
      const page = page_number;
      const limit = +itemLimit;
      const data = { page, limit };
      dispatch(TypeListAction(data));
  };



  const totalPages = types && types.totalPages;
  const page = types && types.page;
  const prevPage = types && types.prevPage;
  const nextPage = types && types.nextPage;
  const hasNextPage = types && types.hasNextPage;
  const hasPrevPage = types && types.hasPrevPage;

  useEffect(() => {
    setTypeDeleteError(type_delete_error?.message)
}, [type_delete_error]);
useEffect(() => {
    if(type_success){
        setSubDeleteSuccess("Successfully deleted!")
    }
}, [type_success]);
  

    return (
        <>

            <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading">
                    <IoArrowBackOutline onClick={() => history.push("/dashboard/categories")} className="back__arrow"/>&nbsp;&nbsp;<div className="header_order_title">PRODUCT SUB-CATEGORY</div>
                 </div>
             
                <div className="card-body">
                    <div className="all-sub-category-header">
                        <div className="headers">Sub-Category Name</div>
                        <div className="headers">Status</div>
                        <div className="edit-delete">
                            <div className="headers">Edit</div>
                            <div className="headers">Delete</div>
                        </div>
                        
                    </div>
                    {type_error && 
                            <div className="alert alert-danger" role="alert">
                                <h4 className="alert-heading">Error!</h4>
                                <hr/>
                                <p className="mb-0">{type_error.message || type_error}</p>
                            </div>
                        }
                   {
                       types_docs && types_docs.length > 0 ? types_docs.map(type => (
                             <div  key={type._id} className="sub-category-list">
                                   <div className="subcat-name">
                                        {type.name}
                                   </div>
                                   <div className="subcat-active">
                                       <Switch defaultChecked onChange={() => console.log("")}/>
                                   </div>
                                   <div className="subcat-del">
                                       <div className="deactivate toggle-class text-center" onClick={() => {history.push(`/dashboard/categories/sub-categories?id=${type._id}-edit-sub-category`); setId(type._id)}}><FaEdit /></div>
                                       <div className="text-danger text-center" style={{cursor: "pointer"}} onClick={() => {history.push(`/dashboard/categories/sub-categories?id=${type._id}-delete-sub-category`); setId(type._id); setSubCatName(type.name)}}><FaTrashAlt /></div>
                                   </div>
                             </div>
                       )) : <h2 className="text-center no-record-found mt-4">No Records Found</h2>
                   }
                  
                    
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                        <div className="page_number">{types_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                            <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                        </div>
                        {totalPages > 1 ?
                        <div className="paginate_reuse">
                            <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                        </div> : null
                        }
                </div>   
            </div>
           
            {checkSubCatDel && checkSubCatDel === "edit-sub-category" && <EditTypeModal id={id} setId={setId}/>}
            {checkSubCatDel && checkSubCatDel === "delete-sub-category"  &&
                <DeleteModal header={`DELETE CATEGORY - ${subCatName}/${id}`}>
                <h3 className="delete-header">Are you sure you want to delete this sub-category?</h3>
                {subDeleteSuccess && <p className="text-success text-center">{subDeleteSuccess}</p> }
                {typeDeleteError && <p className="text-center text-danger">{typeDeleteError}</p>}
                <div className="cta-btn-delete">
                    <button className="delete-confirm" onClick={() => {handleTypeDelete(id)}}>{type_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={type_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => {history.push("/dashboard/categories/sub-categories"); setSubDeleteSuccess(""); refetch(); setTypeDeleteError("")}}>CANCEL</button>
                </div>
           </DeleteModal> 
           }
         
        </>
    )
}

export default AllSubCategories