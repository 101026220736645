import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {BsInfoCircleFill} from "react-icons/bs"
import Loaders from "../Reusables/Loader";

import { EditTypeAction, GetTypeAction} from "../../actions/Admin/ProductCategoryAction";

const EditTypeModal = ({id}) => {
      const dispatch = useDispatch();
      const history = useHistory();
      const [name, setName] = useState("");
      const [fetchedSize, setFetchedSize] = useState([]) 
      const [sizes, setSizes] = useState("");
      const { type, type_success: get_type_success, type_error: get_type_error} = useSelector(state => state.typeGet)
      const {type_loading: edit_type_loading, type_success: type_edit_success, type_error: type_edit_error, type: edit_type} = useSelector(state => state.editType)

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("")

    const sizeData = sizes  && sizes.trim().split(",") 
    const dataToSubmit = {name, sizes: sizeData};

    const handleTypeEdit= (e) => {
        e.preventDefault();
        dispatch(EditTypeAction(id, dataToSubmit))
    }

    useEffect(() => {
      dispatch(GetTypeAction(id))
  }, [dispatch, id])

    
    useEffect(() => {
         setName(type && type.results && type.results.name);
         setFetchedSize(type && type.results && type.results.sizes)
         setSizes(fetchedSize && fetchedSize.join(","))
    }, [type, type.results, type.results?.name, type.results?.sizes, fetchedSize]);


    useEffect(() => {
         setErrorMessage(type_edit_error)
    }, [type_edit_error])

    useEffect(() => {
      setSuccessMessage(type_edit_success)
 }, [type_edit_success])


    useEffect(() => {
        const editError = setTimeout(() => {
          setErrorMessage("")
        }, 5000);
        return () => clearTimeout(editError)
    }, []);
    useEffect(() => {
      const editSuccess =     setTimeout(() => {
       setSuccessMessage("")
     }, 3000);
     return () => clearTimeout(editSuccess)
   }, []);

   
    return (
        <div className="modal_for_type">
              <div className="card type_modal">
              <div className="data_heading">
                <div className="header_order_title type_header">EDIT TYPE</div>
              </div>
              <form className="add_type_form" onSubmit={handleTypeEdit}>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Type name</label>
                      <input type="text" className="type_input" onChange={(e) => setName(e.target.value)} value={name}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Size(s)</label>
                      <input type="text" value={sizes} className="type_input" onChange={(e) => setSizes(e.target.value)}/>
                      <p className="type-tooltip" title={`separate sizes using comma (,)`} ><BsInfoCircleFill />{""} <span>separate sizes using comma (,)</span></p>
                    </div>
                  </div>
                  {successMessage && <p className="text-success text-center">{successMessage.message}</p>}
                  
                   {errorMessage && <p className="mt-4 remove-bullet-points text-danger text-center">{errorMessage.message}</p>}
                  
                  <div className="cta_btn">
                    <button className="type_button">{edit_type_loading  ?<Loaders loading={edit_type_loading && edit_type_loading} color="#FFFFFF" size={30}/> : "submit" }</button>
                    <button className="type_cancel_button" onClick={() => history.push("/dashboard/categories/sub-categories")}>Cancel</button>
                  </div>
              </form>
           </div>

      
        </div>
    )
}


export default EditTypeModal