import { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import "../Transactions/transaction.css";
import "./customer.css";
import { UserSearch } from '../../actions/Admin/search/SearchAction';
import { AppContext } from '../../AppContext';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { BasicBuyerDetailAction } from '../../actions/Admin/BasicBuyerAction';
import moment from 'moment';
const SingleBuyer = ({buyerId}) => {
  
    const {itemLimit,setItemLimit, setPageNumber, pageNumber, searchTerm} = useContext(AppContext);
    const { basic_buyer_detail, basic_buyer_detail_docs } = useSelector(state => state.basicBuyerDetailsReducer)
    const {user_search_docs,user_search} = useSelector(state => state.userSearchReducer)
    const searchBuyers = searchTerm !== "" ?  user_search_docs : basic_buyer_detail_docs; 
    const dispatch = useDispatch(null);
    const NGN =   new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN'
      });
      useEffect(() => {
        const page = pageNumber;
        const limit = +itemLimit;
        const data = {buyerId, page, limit}
        if(buyerId){
            dispatch(BasicBuyerDetailAction(data))
        }
    
    }, [buyerId,itemLimit, dispatch, pageNumber])
   const handleNextPage = (page_number) => {
       const page = page_number;
       const limit = +itemLimit;
       const data = {buyerId, page, limit };
       dispatch(BasicBuyerDetailAction(data));
   };
   const totalPages = searchTerm !== "" ? (user_search && user_search.totalPages) : (basic_buyer_detail  && basic_buyer_detail.totalPages);
   const page = searchTerm !== "" ? (user_search && user_search.page) : (basic_buyer_detail  && basic_buyer_detail.page);
   const prevPage = searchTerm !== "" ? (user_search && user_search.prevPage) : (basic_buyer_detail  && basic_buyer_detail.prevPage);
   const nextPage = searchTerm !== "" ? (user_search && user_search.nextPage) : (basic_buyer_detail  && basic_buyer_detail.nextPage);
   const limitItem = searchTerm !== "" ? (user_search && user_search.totalDocs) : (basic_buyer_detail  && basic_buyer_detail.totalDocs);
 
 const hasNextPage = basic_buyer_detail && basic_buyer_detail.hasNextPage;
 const hasPrevPage = basic_buyer_detail && basic_buyer_detail.hasPrevPage
 
 
 
   const search = useMemo(() => {
     const page = pageNumber;
     const limit = limitItem || itemLimit;
     const data = {searchTerm, page, limit}
     if(searchTerm){
         dispatch(UserSearch(data))
     }
 }, [dispatch, searchTerm, itemLimit,limitItem, pageNumber]);
   useEffect(() => search, [search])
 
   useEffect(() => {
     if(page){
         setPageNumber(page)
     }
    
 }, [page, setPageNumber])


    return (
        <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading">
                    <div className="header_order_title ">ORDER HISTORY</div>
                </div>
                <div className="card-body">
                    {searchBuyers.length > 0 ?
                <div className="table-responsive">
                  <table className="table seller_table">

                    
                  <thead className="tborder table-header" align="left">
                    <tr className="align_student_table">
                        <th scope="col"  className="header___name">Product Name</th>
                        <th scope="col" className="header___status">Alpha Code</th>
                        <th scope="col" className="header__userId">Store Name</th>
                        <th scope="col" className="header_phone">Item price</th>
                        <th scope="col" className="header_phone">Shipping fee</th>
                        <th scope="col" className="header_email">Date Purchased</th>
                        <th scope="col" className="header_last_transaction">Status</th>
                    </tr>
                  </thead>
                    <tbody align="left">
                        {
                            searchBuyers && searchBuyers.map(data => (
                               data?.PreShipmentItems?.map(detail => (
                                    <tr className="transactions-item" key={detail._id}>
                                    <td className="item">{detail.ItemName}</td>
                                    <td className="item">{detail.alphaCode}</td>
                                    <td className="item">{detail.store_name}</td>
                                    <td className="item">{NGN.format(detail.ItemValue)}</td>
                                    <td className="item">{NGN.format(data.ShippingFee)}</td>
                                    <td  className="item">{moment(data.createdAt).format("dddd, DD/MM/YYYY")}</td> 
                                    <td className="item order-processing">{data.status}</td>
                                   
                                </tr>
                              ))
                            ))
                        }
                        <>
                    
                       </>
                    </tbody>
        </table>
        </div> : <h4 className="text-danger text-center">No orders yet!</h4>
        }
        </div>
        <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                    <div className="page_number">{basic_buyer_detail_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                    </div>
                    {totalPages > 1 ?
                    <div className="paginate_reuse">
                        <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                    </div> : null
                    }
                </div>   
        </div>
    )
}

export default SingleBuyer;

SingleBuyer.propTypes = {
    header_title: PropTypes.string
  };


