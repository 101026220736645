import { useEffect, useState, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FiArrowLeft } from "react-icons/fi";
import { BsFillPlusCircleFill} from "react-icons/bs";
import {BsChevronLeft,BsChevronRight } from "react-icons/bs";
import { HiOutlineTrash,HiOutlineRefresh } from "react-icons/hi";
import { clearEtaState, DeleteEta, ListReasons } from '../../actions/Admin/SettingsAction';
import EditEta from './EditEta';
import DeleteModal from '../Reusables/DeleteModal';
import ScaleLoader from "react-spinners/ScaleLoader";
import { AppContext } from '../../AppContext';


const override = {
    margin: "0",
    borderColor: "none",
  };
  
const Eta = () => {
    const [color, setColor] = useState("#72FFC9");
    const {setEtaDeleteModal, etaDeleteModal} = useContext(AppContext)
    const dispatch = useDispatch();
    const history = useHistory();
    const {create_reasons,list_reasons_error} = useSelector(state => state.listReasonsReducer);
    const {delete_eta_loading,delete_eta_success,delete_eta_error} = useSelector(state => state.deleteEta)
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [getId, setGetId] = useState("");
    const [openEdit,setOpenEdit] = useState(false)
    
    const eta = create_reasons && create_reasons[0] && create_reasons[0].eta;
    const search = useLocation().search;
    const deleteEtaModal = new URLSearchParams(search).get("eta-delete");
  

    const prevPage = (page) => {
        if(page.length === 1 || currentPage === 1){
            return
        }else {
            setCurrentPage(prev => prev - 1)
        }
        
    }
    const nextPage = (page) => {
        if(page.length === 1 || currentPage === page.length){
            return
        }else {
            setCurrentPage(next => next + 1)
        }
    }
  
    const lastDataIndex = currentPage * dataPerPage;
    const firstDataIndex = lastDataIndex - dataPerPage;
    const currentData = eta && eta.slice(firstDataIndex,lastDataIndex);
   

    let pages = [];
        for(let i = 1; i <= Math.ceil(eta?.length/dataPerPage); i++){
            pages.push(i)
     }

     const handleEtaDelete = (id) => {
         if(id){
            dispatch(DeleteEta(id))
         }
         const clear = setTimeout(() => {
            if(delete_eta_error){
                clearEtaState()
            }
         }, 1500)
        return clearTimeout(clear)
     }

    useEffect(() => {
        dispatch(ListReasons())
    }, []);
  return (     
       <>   
         <div className="eta-container">
            <div className="card card_wrapper_eta mt-4  pb-3">
            <div className="payment_title_header">
                <div className="header_order_title"><FiArrowLeft className="back-navigation-set" onClick={() => history.push("/dashboard/settings")}/>VIEW ETAs</div>   
                <div className="btn_add_new" onClick={() => history.push("/dashboard/settings/eta/add-eta")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New</span></div>
                    
            </div>
            <div className="card-body eta-card-body">
                {
                    eta && eta.length > 0 ? currentData.map(({_id, departure_state, destination_state, ETA}) => (
                        <form className="edit-eta-forms" key={_id}>
                        <select className="edit-select" disabled>
                            <option value={departure_state}>{departure_state}</option>
                        </select>
                        <select className="edit-select" disabled>
                            <option value={destination_state}>{destination_state}</option>
                        </select>
                        <input type="text" className="edit-input" value={ETA} disabled/>
                        <div className="eta-actions">
                            <div className="save-icon" onClick={() => {setGetId(_id); setOpenEdit(true)}}><HiOutlineRefresh className="icon-update"/><span>Update</span></div>
                            <div className="trash-can" onClick={() => {setGetId(_id); setEtaDeleteModal(true)}}><HiOutlineTrash /><span>Delete</span></div>
                        </div>
                    </form>
                    )) : <p className="text-center text-danger">No data yet!</p>
                }
                {list_reasons_error &&<p className="text-center text-danger">{list_reasons_error.message}</p>}
                {
                (eta && eta.length > 10) && dataPerPage < eta.length && <div className="paginate_setting mt-4">
                    <button className={pages.length === 1 || currentPage === 1 ? "left-chevron" : "left-chevron bg-dark text-white"} onClick={() => prevPage(pages)} disabled={pages.length === 1 || currentPage === 1}><BsChevronLeft /></button><button className="middle-chevron">{currentPage}</button><button className={pages.length === 1 || currentPage === pages.length ? "right-chevron" : "right-chevron bg-dark text-white"} onClick={() => nextPage(pages)} disabled={pages.length === 1 || currentPage === pages.length}><BsChevronRight /></button>
                </div>
            }
            
                </div> 
        
                
            </div>
                
               {/* }           */}
           
        </div>
        <EditEta id={getId} openEdit={openEdit} setOpenEdit={setOpenEdit} eta={eta}/>
        {etaDeleteModal &&
    <DeleteModal header={"DELETE ETA"}>
        <h3 className="delete-header">Are you sure you want to delete this {getId} eta?</h3>
        {delete_eta_error && <div className="text-center text-danger"><p className="mt-1">{delete_eta_error}</p></div>}
        <div className="cta-btn-delete">
            <button className="delete-confirm" onClick={() => handleEtaDelete(getId)}>{delete_eta_loading ?  <ScaleLoader color={color} loading={delete_eta_loading} cssOverride={override} size={20} /> : "DELETE"}</button><button className="delete-cancel" onClick={() => {dispatch(clearEtaState()); dispatch(ListReasons()); setEtaDeleteModal(false)}}>{delete_eta_success ? "CLOSE" : "CANCEL"}</button>
           
        </div>
    </DeleteModal>
}
    </>
  )
}

export default Eta