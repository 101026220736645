import React, {useState, useMemo,useEffect} from 'react'
import { FaTimes } from 'react-icons/fa';
import { FaChevronDown,FaChevronUp} from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { HiOutlineRefresh } from "react-icons/hi";
import { useSelector, useDispatch } from 'react-redux';
import allState from "./States/State.json"
import { clearEtaState, ListReasons, UpdateEta } from '../../actions/Admin/SettingsAction';
import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
    margin: "0",
    borderColor: "none",
  };
  
const EditEta = ({id, openEdit,setOpenEdit, eta}) => {
    const {eta_update_loading,eta_update_success,eta_update_error,get_eta_update} = useSelector(state => state.updateEta);
    const dispatch = useDispatch();
    const [color, setColor] = useState("#72FFC9");
    const [toggleVisibility, setToggleVisibilty] = useState({visibleTime: false, visibleFrom: false, visibleTo: false});
    const [searchStatesFrom, setSearchStatesFrom] = useState("");
    const [searchStatesTo, setSearchStatesTo] = useState("");
    const [time, setTime] = useState(24);
    const handleVisibiltyTime = () => {
        setToggleVisibilty((prev) => ({...prev, visibleFrom: false, visibleTo: false}))
    }
    const handleVisibiltyFrom = () => {
        setToggleVisibilty((prev) => ({...prev, visibleFrom: !prev.visibleFrom, visibleTo: false}));
    }
    const handleVisibiltyTo = () => {
        setToggleVisibilty((prev) => ({...prev, visibleTo: !prev.visibleTo, visibleFrom : false}));
    }
    const setSelectedFrom = selected => {
        setSearchStatesFrom(selected)
        setToggleVisibilty((prev) => ({...prev, visibleFrom : !prev.visibleFrom}))
    }
    const setSelectedTo = selected => {
        setSearchStatesTo(selected);
        setToggleVisibilty((prev) => ({...prev, visibleTo: !prev.visibleTo}));
    }
    const data = useMemo(() => eta && eta.filter(({_id}) => _id === id), [id]);
    const etaDetail = data && data && data[0]
    const listStates = useMemo(() => allState, []);
    const newData = (search) => search ? listStates && listStates.filter(({name}) => name.toLowerCase().includes(search.toLowerCase())) : listStates;
    const dataToSubmit = {id, departure_state: searchStatesFrom, destination_state: searchStatesTo, ETA: +time}
  
    const handleUpdateEta = (e) => {
        e.preventDefault();
        if(!searchStatesFrom || !searchStatesTo || !time || !id){
            return
        }
        dispatch(UpdateEta(dataToSubmit))
    }
    useEffect(() => {
        setSearchStatesFrom(etaDetail?.departure_state);
        setSearchStatesTo(etaDetail?.destination_state);
        setTime(etaDetail?.ETA)
    },[etaDetail])
    useEffect(() => {
        const timeout = setTimeout(() => {
             if(eta_update_success || eta_update_error){
                 dispatch(clearEtaState());
                 dispatch(ListReasons())
             }
        }, 2000)
        return () => clearTimeout(timeout)
   }, [eta_update_success, eta_update_error]);

 if(!openEdit) return null;
   
  return (
    <div className="bg-edit-eta">
        <div className={openEdit ? "edit-modal isopen" : "edit-modal"}>
            <div className="close-edit-modal" onClick={() => setOpenEdit(false)}><FaTimes /></div>
            <div className="eta-form edit-eta-form-modal">
                         <form>
                         <div className="eta-list">
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="from" placeholder="Abia State" value={searchStatesFrom || ""} onChange={(e) => setSearchStatesFrom(e.target.value)} onClick={handleVisibiltyFrom}/>
                                    <span>{!toggleVisibility.visibleFrom ? <FaChevronDown className="input-toggler"/> : <FaChevronUp className="input-toggler toggler-up"/>}</span>
                                </div>
                                {
                                    toggleVisibility.visibleFrom && <div className="from-state">{
                                       newData(searchStatesFrom).length > 0 ? newData(searchStatesFrom).map(({name, code}) => (
                                            <span key={code} onClick={() => setSelectedFrom(name)}>{name}</span>
                                            
                                        )): <span className="text-center">No Match</span>
                                      }
                                    
                                   </div>
                                }
                            </div>
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="to" placeholder="Enugu State"  value={searchStatesTo || ""} onChange={(e) => setSearchStatesTo(e.target.value)} onFocus={handleVisibiltyTo}/>
                                    
                                    <span>{!toggleVisibility.visibleTo ? <FaChevronDown className="input-toggler"/> : <FaChevronUp className="input-toggler toggler-up"/>}</span>

                                </div>
                                  {
                                      toggleVisibility.visibleTo && <div className="from-state">{
                                        newData(searchStatesTo).length > 0 ? newData(searchStatesTo).map(({name, code}) => (
                                            <span key={code} onClick={() => setSelectedTo(name)}>{name}</span>
                                        )) : <span className="text-center">No Match</span>
                                      }
                                    
                                   </div>
                                }
                                
                            </div>
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="time" placeholder="24" value={time} name="time" onChange={(e) => setTime(e.target.value)} onFocus={handleVisibiltyTime} />
                                </div>
                            </div>
                            <div className="eta-actions">
                                {
                                    eta_update_loading  ? <div className="save-icon"><span><ScaleLoader color={color} loading={eta_update_loading} cssOverride={override} size={20} /></span></div> :
                                    eta_update_success ? <div className="save-icon"><GiCheckMark  className="saved-check"/><span>Done</span></div>: <div className="save-icon" onClick={handleUpdateEta}><HiOutlineRefresh /><span>Update</span></div>
                                }
                                
                                
                            </div>
                            </div>

                         </form>
            </div>
            {eta_update_error &&<p className="text-center text-danger">{eta_update_error.message || eta_update_error}</p>}
            {eta_update_success &&<p className="text-center text-danger">{eta_update_success.message}</p>}
        </div>
    </div>
  )
}

export default EditEta