import { useEffect } from "react";
import Switch from "antd/lib/switch";
import { useHistory, useLocation } from "react-router-dom";
import { BsFillPlusCircleFill,BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import AddReasons from "./CreateReasons";
import { useSelector, useDispatch } from "react-redux";
import { ListReasons } from "../../actions/Admin/SettingsAction";
import { Alert } from "antd";
const Reasons = () => {
    const dispatch = useDispatch();
    const {create_reasons,list_reasons_error} = useSelector(state => state.listReasonsReducer);
    const history = useHistory(null);
    const search = useLocation().search;
    const reason = new URLSearchParams(search).get("reason");

    
    const reasonForCancellation = create_reasons && create_reasons[0] && create_reasons[0].reason_for_cancellation;
   
    useEffect(() => {
        dispatch(ListReasons())
    }, []);
    
     return (
         <div className="reasons">
            <div className="card card_wrapper  seller_table_wrapper mt-4">
            <div className="payment_title_header">
                <div className="header_order_title"><FiArrowLeft className="back-navigation-set" onClick={() => history.push("/dashboard/settings")}/>REASONS FOR CANCELLATION</div>   
                <div className="btn_add_new" onClick={() => history.push("/dashboard/settings/reasons?reason=add-reasons")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New</span></div>
                    
            </div>
            <div className="card-body">
                {
                    list_reasons_error ?  <Alert message="Failed"
                    description={list_reasons_error}
                    type="error"
                    closable
                    className="my-3"
                    />  :
                    
                    
                    <div className="table-responsive">
                        { create_reasons && create_reasons.length > 0 ? 
                        <table className="table seller_table">

                            
                        <thead className="tborder table-header" >
                            <tr className="align_student_table">
                                <th scope="col"  className="header___name" colSpan={10} style={{width: "90%"}}>Reason for cancellation</th>
                                <th scope="col" className="header_phone">Edit</th>
                                <th scope="col" className="header_email">Delete</th>
                            </tr>
                        </thead>
                            
                                <tbody>
                                    {
                                        reasonForCancellation && reasonForCancellation.map(({_id, reason}) => (
                                    <tr key={_id}>
                                    <td className="item" colSpan={10} style={{width: "90%"}}>{reason}</td>
                                    <td className="item">
                                        <div className="deactivate toggle-class">
                                            <FaEdit />
                                        </div>
                                    </td>
                                    <td  className="item">
                                        <div className="text-danger">
                                            <FaTrashAlt className="deletebtn"/>
                                        </div>
                                    </td>
                                </tr>
                                        ))
                                    }
                               
                            </tbody>
                        
                            
                </table> : <h2 className="text-center no-record-found mt-4">No Records Found</h2> }
                </div> 

              
            }
                             
            {/* <div className="paginate_setting">
                <button className="left-chevron"><BsChevronLeft /></button><button className="middle-chevron">1</button><button className="right-chevron"><BsChevronRight /></button>
            </div> */}
            </div>
           
            </div>
            {reason === "add-reasons" && <AddReasons />}
         </div>
     )
}


export default Reasons