import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CreateVehicleType, ListReasons } from "../../actions/Admin/SettingsAction";
const AddVehicles = () => {
    const [addVehicle, setAddVehicle] = useState("")
    const history = useHistory();
    const dispatch = useDispatch();
    const {create_vehicle_type_loading, create_vehicle_type_success, create_vehicle_type_error} = useSelector(state => state.createVehicleType);

    const handleAddVehicle = (e) => {
         e.preventDefault();
         let data = new FormData();
         data.append("vehicleType", addVehicle);
         data.append("image_url", "");
         if(!addVehicle) return;
         dispatch(CreateVehicleType(data))
    }

    useEffect(() => {
        if(create_vehicle_type_success){
             dispatch(ListReasons())
        }
    }, [create_vehicle_type_success])
    
    return (
        <div className="payment_modal">
        <div className="card payment_add_method">
          <div className="data_heading">
            <div className="header_order_title type_header">ADD NEW VEHICLE TYPE</div>
          </div>
         
            <form className="add_payment_form" onSubmit={handleAddVehicle}>
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label htmlFor="category" className="payment_label">Enter vehicle type</label>
                    <input type="text" className="payment_input" value={addVehicle} onChange={(e) => setAddVehicle(e.target.value)}/>
                  </div>
                </div>
                {create_vehicle_type_error && <div className="text-center text-danger">{create_vehicle_type_error}</div>}
                
                <div className="cta_btn">
                  <button className="type_button">{create_vehicle_type_loading ? "Creating..." : "SAVE" }</button>
                  <button className="type_cancel_button" onClick={() => {history.push("/dashboard/settings/vehicle-types")}}>{create_vehicle_type_success ? "CLOSE" : "CANCEL"}</button>
                </div>
            </form>
       
      </div>
</div>
    )
}

export default AddVehicles;