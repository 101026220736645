
import { GET_STORE_FAILED, GET_STORE_REQUEST, GET_STORE_SUCCESS, LIST_STORE_FAILED, LIST_STORE_REQUEST, LIST_STORE_SUCCESS, STORE_OWNER_LIST_FAIL, STORE_OWNER_LIST_REQUEST, STORE_OWNER_LIST_SUCCESS } from '../../../constants/storeConstant';

const initialState = {
    store_loading: false,
    store_success: false,
    store_owner_loading: false,
    basic_buyers_loading: false,
    basic_buyers_success: false,
    store_owner_error: "",
    basic_buyer_error: "",
    basic_buyers_list_docs: [],
    basic_buyers_list: [],
    store_docs: [],
    store_owners_list_docs: [],
    store_owners_list: [],
    stores: [],
    store: {},
    error: "",
}

export const listStores = (state=initialState, action) => {
    switch(action.type){    
       case LIST_STORE_REQUEST: 
           return {
                ...state,
                store_loading: true
           }
       case LIST_STORE_SUCCESS:
           return {
                 ...state,
                 store_loading: false,
                 store_success: true,
                 stores: action.payload,
                 store_docs: action.payload.docs
           }
       case LIST_STORE_FAILED: 
           return {
                ...state,
                store_loading : false,
                store_success: false,
                error: action.payload
           }
       default:
           return state

    }
}


export const getStore = (state=initialState, action) => {
   switch(action.type){
       case GET_STORE_REQUEST: 
           return {
            ...state,
            store_loading: true
           }
       case GET_STORE_SUCCESS:
           return {
               ...state,
               store_loading: false,
               store : action.payload,
               store_success: true
           }
       case GET_STORE_FAILED:
           return {
               ...state,
               store_loading: false,
               error: action.payload,
               store_success: false
           }
       default:
           return state
   }
} 


export const storeOwnersList = (state=initialState, action) => {
      switch(action.type){
          case STORE_OWNER_LIST_REQUEST:
              return {
                  ...state,
                  store_owner_loading: true
              }
          case STORE_OWNER_LIST_SUCCESS:
              return {
                  ...state,
                  store_owner_loading: false,
                  store_owners_list: action.payload,
                  store_owners_list_docs: action.payload.docs
              }
          case STORE_OWNER_LIST_FAIL:
                return {
                    ...state,
                    store_owner_error: action.payload
                }
          default:
                 return state
      }
}


