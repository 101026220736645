export const TOTAL_EARNING_REQUEST = "TOTAL_EARNING_REQUEST";
export const TOTAL_EARNING_SUCCESS = "TOTAL_EARNING_SUCCESS";
export const TOTAL_EARNING_FAIL = "TOTAL_EARNING_FAIL";


export const TRANSACTION_LIST_REQUEST = "TRANSACTION_LIST_REQUEST";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";
export const TRANSACTION_LIST_FAIL = "TRANSACTION_LIST_FAIL";

export const EARNING_LIST_REQUEST = "EARNING_LIST_REQUEST";
export const EARNING_LIST_SUCCESS = "EARNING_LIST_SUCCESS";
export const EARNING_LIST_FAIL = "EARNING_LIST_FAIL";

export const STORE_EARNING_LIST_REQUEST = "STORE_EARNING_LIST_REQUEST";
export const STORE_EARNING_LIST_SUCCESS = "STORE_EARNING_LIST_SUCCESS";
export const STORE_EARNING_LIST_FAIL = "STORE_EARNING_LIST_FAIL";

export const EARNING_CHART_REQUEST = "EARNING_CHART_REQUEST";
export const EARNING_CHART_SUCCESS = "EARNING_CHART_SUCCESS";
export const EARNING_CHART_FAIL = "EARNING_CHART_FAIL";
