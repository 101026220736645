import { useContext, useEffect } from 'react';
import { BsArrowUpRight, BsArrowDownLeft,BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import TransactionDetail from './TransactionDetails';
import { useSelector, useDispatch } from 'react-redux';
import { StoreEarningList } from './../../actions/Admin/TransactionAction';
import { AppContext } from '../../AppContext';
import moment from 'moment';
import "../Transactions/transaction.css";
import "../CustomersDB/customer.css";

const TransactionHistory = () => {
    const {numberSeperator,nFormatter, itemLimit,storeId, setItemLimit,showTransactionModal, setShowTransactionModal,setTransactionInfo} = useContext(AppContext);
    const dispatch = useDispatch()
    const {store_earning_withdraw_transactions,store_earning_lists_docs} = useSelector(state => state.storeEarningList);
    const handleModalOpen = (data) => {
        if(Object.keys(data).length > 0){
            setTransactionInfo(data)
            setShowTransactionModal(true);   
        }
    }
    useEffect(() => {
        const store_id = storeId
        const page = 1;
        const limit = itemLimit;
        const data = {store_id,page, limit}
       dispatch(StoreEarningList(data))
       
  }, [dispatch, itemLimit,storeId]);
  const handleNextPage = (page_number) => {
    const store_id = storeId
    const page = page_number;
    const limit = +itemLimit;
    const data = { store_id,page, limit };
    dispatch(StoreEarningList(data));
    
};
const totalPages = store_earning_withdraw_transactions && store_earning_withdraw_transactions.totalPages;
const page = store_earning_withdraw_transactions && store_earning_withdraw_transactions.page;
const prevPage = store_earning_withdraw_transactions && store_earning_withdraw_transactions.prevPage;
const nextPage = store_earning_withdraw_transactions && store_earning_withdraw_transactions.nextPage;
    
    return (
        <>
        <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading trans_history">
                   <div className="header_order_title ">TRANSACTION HISTORY</div>
                </div>
                    <div className='card-body'>
                          {store_earning_lists_docs && store_earning_lists_docs.length > 0 ?
                            (<div className="table-responsive">
                                <table className="table seller_table">
                                    <thead className="tborder table-header" align="left">
                                        <tr className="align_student_table">
                                            <th scope="col" ></th>
                                            <th scope="col" className="header___status">Amount</th>
                                            <th scope="col" className="header__userId">Date/Time</th>
                                            <th scope="col" className="header_phone">Description</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
        
                                   <tbody align="left">
                                            {
                                                store_earning_lists_docs && store_earning_lists_docs.map(({_id, amount, narrative, trxn_type, date,account_number,bank_name
                                                }) => (
                                                    <tr className="transactions-item" key={_id}>
                                                    <td className="item"><div className={trxn_type === "debit" ? "transaction-type-withdraw" : "transaction-type-sales"}>{trxn_type === "debit" ? <BsArrowDownLeft className="withdraw-icon"/> : <BsArrowUpRight className="sale-icon"/>}</div></td>
                                                    <td className="item">&#x20A6; {amount.length > 7 ? nFormatter(amount) : numberSeperator(amount) }</td>
                                                    <td className="item">{moment(date).format("MMM, D/MM/YYYY")}</td>
                                                    <td className="item">{narrative}</td>
                                                    <td className="item transaction-detail-view" onClick={() => handleModalOpen({date, trxn_type, amount, narrative,account_number,bank_name})}>View details</td>
                                                </tr>
                                                ))
                                            }
                                
                                   
                                    </tbody>
                                </table>
                        </div>) : (<h2 className="text-center no-record-found">No Records Found</h2>)
                        }
                 
                    </div>
                 <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                    
                    <div className="page_number">Item per page {" "}{" "}
                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                    </div>
                { totalPages > 1 ? 
                    <div className="paginate_reuse">
                        <button className="left-chevron" onClick={() => {handleNextPage(prevPage)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                    </div>: null
                }
            </div>
        </div>
        {showTransactionModal ? <TransactionDetail/> : null}
        </>
    )
}

export default TransactionHistory;


