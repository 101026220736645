import { useEffect, useContext, useMemo, useState, useRef } from 'react';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import {BsChevronDown, BsChevronUp, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { clearUpdateOrderStatus, confirmOrderStatusAction, orderListAction } from "../../actions/Admin/OrderAction";
import { AppContext } from "../../AppContext";
import OrderItems from './OrderItems';
import "./tabs.css";
import "../../datatable.css"
import { OrderSearch } from '../../actions/Admin/search/SearchAction';
import Loaders from '../Reusables/Loader'

const AllOrders = () => {
    const [waybill, setWaybill] = useState("")
    const dispatch = useDispatch();
    const {order_docs, orders, error} = useSelector(state => state.listOrders);
    const {order_search_docs,order_search_error,order_search} = useSelector(state => state.orderSearchReducer);
    const {itemLimit, setItemLimit, toggleAccordion, selected, productQty,setOrderedItems, setSelected, clientName, setClientName, searchTerm,pageNumber, setPageNumber} = useContext(AppContext);
    const {update_order_status_loading, update_order_status_success, update_order_status_error} = useSelector(state => state.updateOrderStatus);
    const openDialog = useRef(null)


    const listOrders = searchTerm !== "" ?  order_search_docs : order_docs; 

   
    useEffect(() => {
          const offset = pageNumber;
          const limit = +itemLimit;
          const data = {offset, limit}
            dispatch(orderListAction(data))
    }, [dispatch, itemLimit]);


    useEffect(() => {
        if(waybill){
            dispatch(confirmOrderStatusAction({status: "delivered",
            way_bill: waybill}))
        }
    }, [waybill])


  console.log("orders", listOrders)
   
    
    const handleNextPage = (page_number) => {
        const offset = page_number;
        const limit = +itemLimit;
        const data = { offset, limit };
        dispatch(orderListAction(data));
    };



    const totalPages = searchTerm !== "" ? (order_search && order_search.totalPages) : (orders && orders.totalPages);
    const page = searchTerm !== "" ? (order_search && order_search.page) : (orders && orders.page);
    const prevPage = searchTerm !== "" ? (order_search && order_search.prevPage) : (orders && orders.prevPage);
    const nextPage = searchTerm !== "" ? (order_search && order_search.nextPage) : (orders && orders.nextPage);
    const limitItem = searchTerm !== "" ? (order_search && order_search.totalDocs) : (orders && orders.totalDocs);

    
    const search = useMemo(() => {
        const offset = pageNumber;
        const limit = limitItem || itemLimit;
        const data = {offset, limit, searchTerm}
        if(searchTerm){
            dispatch(OrderSearch(data))
        }
    }, [dispatch, searchTerm, itemLimit,limitItem]);
    useEffect(() => search, [search])

    useEffect(() => {
      if(update_order_status_success){
        const offset = pageNumber;
        const limit = +itemLimit;
        const data = {offset, limit}
          dispatch(orderListAction(data));
          dispatch(clearUpdateOrderStatus())
      }
    }, [dispatch,update_order_status_success])
    
    useEffect(() => {
        if(page){
            setPageNumber(page)
        }
       
    }, [page]);

    useEffect(() => {
        if(update_order_status_loading){
            openDialog && openDialog.current && openDialog.current.showModal()
        }else if(!update_order_status_loading){
            openDialog && openDialog.current && openDialog.current.close()
        }
    }, [update_order_status_loading])


    return (

        <>    
           { 
              update_order_status_loading && <dialog ref={openDialog} style={{width: "8rem", height: "8rem", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", backgroundColor: "gray", border: "1px solid gray"}}>
              <Loaders loading={ update_order_status_loading} color="#FFFFFF" size={30} />
          </dialog>
           }
            
                <div className="card card_wrapper  seller_table_wrapper mt-4">

                    <div className="data_heading">
                        <div className="header_order_title">Order Overview</div>
                    </div>
                    <div className="card-body sub-category-content-body">
                        {error && 
                            <div className="alert alert-danger" role="alert">
                                <h4 className="alert-heading">Error!</h4>
                                <hr/>
                                <p className="mb-0">{error || error.message }</p>
                            </div>
                        }
                          {update_order_status_error && <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className="mb-0">{update_order_status_error || update_order_status_error.message }</p>
                        </div>}
                        {
                            order_search_error && <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className="mb-0">{order_search_error || order_search_error.message }</p>
                        </div>
                        }
                           
                           {
                               listOrders && listOrders.length > 0 ? listOrders.map((order, index) => (
                                   <div key={order._id}>
                                        <div className="order_content align__order-content">
                                            <div className="order__id"><strong>Order ID:</strong> {order._id}</div>
                                            <div className="order__qty"><strong>Qty: {productQty(order.PreShipmentItems)}</strong>{""} {productQty(order.PreShipmentItems) > 1 ? "Products" : "Product"}</div>
                                            <div className="order__qty"><strong>Platform:</strong>{""} {order.device_platform}</div>
                                            <div className="order__date">{moment(order.createdAt).format("D/MM/YYYY h:mm a")}</div>
                                            <>
                                            <div className={order.status === "ordered" ? "ordered-status" : order.status === "processing" ? "processing-status": order.status === "delivered" ? "delivered-status" : order.status === "shipped" ? "shipped-status" : order.status === "cancelled" ? "cancelled-status" : ""}>
                                               {order && order.status !== null ? <button>{order.status?.slice(0,1).toUpperCase() + order.status?.slice(1)}</button> : "-"}
                                               
                                                {order && order.status !== null && order.status !== "delivered" && order.status !== "cancelled" && order.way_bill !== "" && 
                                                <button className="confirm-delivery" onClick={() => setWaybill(order.way_bill)}>Comfirm delivery</button> }           
                                            </div>
                                            
                                            </>
                                            
                                            {selected === index ?  <BsChevronUp onClick={() => toggleAccordion(index)}/> : <BsChevronDown onClick={() => {toggleAccordion(index);  setOrderedItems(order); setClientName(order.ReceiverName)}}/> }
                                        </div>
                                        <div className={selected === index ? "item_list_content show" : "item_list_content"} >
                                             <OrderItems items={order.PreShipmentItems} receiverName={clientName} order={listOrders}/>
                                        </div>
                                    </div>
                               )): <h2 className="text-center no-record-found">No Records Found</h2>
                                   
                           }
                          
                            <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                                <div className="page_number">Item per page {" "}{" "}
                                    <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                                </div>
                            { totalPages > 1 ? 
                                <div className="paginate_reuse">
                                    <button className="left-chevron" onClick={() => {handleNextPage(prevPage); setSelected(null)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage); setSelected(null)}}><BsChevronRight /></button>
                                </div>: null
                            }
                            </div>  
                         
                      </div>
                    </div>

        </>
    )
}

export default AllOrders