import Merchants from "./Merchants";
import DataTable from "./DataTable";
import "./styles/dashboard.css"
const Dashboard = () => {
     return (
         <div className="dashoard_content"> 
            <Merchants />
            <DataTable />
         </div>
     )
}

export default Dashboard;
