import { useEffect } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import Graph from "./Graph";
import { useDispatch, useSelector } from 'react-redux';
import { StoreOwnersAction } from './../../actions/Admin/CustomerDBAction';
import Loaders from './../Reusables/Loader';
import SellerTable from "./SellerTable";

const StoreOwners = ({match}) => {
    const dispatch = useDispatch();
    const {error_store_owners,loading_store_owners,store_owners} = useSelector(state => state.getStoreOwners);

    const getStoreOwnersData = store_owners && store_owners?.results
    const getTotalAccount = getStoreOwnersData && getStoreOwnersData?.total_accounts;
    const getActiveAccount = getStoreOwnersData && getStoreOwnersData?.active_store_owners;
    const getDeactiveAccount = getStoreOwnersData && getStoreOwnersData?.deactivated_accounts;
    const getSuspendeAccount = getStoreOwnersData && getStoreOwnersData?.suspended_account;
    const getChatData = getStoreOwnersData && getStoreOwnersData?.chat_data;

    useEffect(() => {
         dispatch(StoreOwnersAction())
    },[dispatch])

    return (
        <>
          {/* { */}
              {/* sellersDetails ? "" :  */}
              
              <>
            {error_store_owners && <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{error_store_owners || error_store_owners.message }</p>
                </div>}
            {loading_store_owners ?  <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={loading_store_owners} color="#000000" size={80} /></div> : 
            <>
            <div className="row mt-4">
            
            <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12">
                                <div className="card  card_size">
                                <div className="card__header">ACTIVE ACCOUNTS</div>
                                    <div className="card-body">
                                        <div className="update_header">
                                            <h3 className="up  mt-2">{getActiveAccount && getActiveAccount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 ">   
                                <div className="card card_size">
                                <div className="card__header">SUSPENDED ACCOUNTS</div>
                                    <div className="card-body">
                                        
                                        <div className="update_header">
                                            <h3 className="down  mt-2">{getSuspendeAccount && getSuspendeAccount}</h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 mt-4">
                                <div className="card card_size">
                                    <div className="card__header">DEACTIVED ACCOUNTS </div>
                                    <div className="card-body">
                                        <div className="update_header">
                                            <h3 className="up  mt-2">{getDeactiveAccount && getDeactiveAccount}</h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 mt-4 ">
                                <div className="card card_size">
                                    <div className="card__header">TOTAL ACCOUNTS</div>
                                    <div className="card-body">
                                        <div className="update_header">
                                            <h3 className="up mt-2">{getTotalAccount && getTotalAccount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </div>
                    </div> */}
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                <div className="card">
                    <div className="card__header">STORE OWNERS FLOW</div>
                    <div className="card-body">
                        <Graph chat_data={getChatData}/>
                    </div>
                </div>
            </div>
        </div>
        <SellerTable match={match}/>
        </>
       
            }
            
        </>
       {/* } */}
            
    </>
    )
}

export default StoreOwners;