import "./login.css";
import { useState, useEffect } from "react";
import {BiKey} from "react-icons/bi";
import { useSelector, useDispatch } from 'react-redux';
import { adminForgotPassword, adminResetPassword } from "../../actions/Admin/AdminAction";
import Loaders from './../Reusables/Loader';
import { Redirect } from "react-router-dom"

const NewPassword = (props) => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [newPassword, setNewPassword]  = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [unMatchedPassword, setUnMatchedPassword] = useState("");
    const [tokenError, setTokenError] = useState("");
    const [tokenSentSuccess, setTokenSetSuccess] = useState("")
    const {loading_reset_password,  user_reset_password, reset_password_error} = useSelector(state => state.passwordReset);
    const {user_forgot_password} = useSelector(state => state.passwordForgot);

    const dispatch = useDispatch()
   const dataToSubmit = {password: newPassword, otp: otp.join(""), user_type: "admin"}


   const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(adminForgotPassword({email: props.location.state.email, "user_type": "admin"}))
   }

    const handleChange = (elem, index) => {
         if(isNaN(elem.value)) return false;
         setOtp([...otp.map((val, idx) => idx === index ? elem.value : val)]);
         //Focus next input
         if(elem.nextSibling){
             elem.nextSibling.focus()
         }
    }
    const handleResetPassword = (e) => {
        e.preventDefault();
        if(newPassword !== confirmNewPassword){
            setUnMatchedPassword("Password does not match!");
            return;
        }
        dispatch(adminResetPassword(dataToSubmit))
    }
  


    const successFullChange = () => {
        if(user_reset_password?.message && user_reset_password?.message.includes("successfully") && user_reset_password?.error === false){
             return <Redirect to="/login" />
        }else {
            return false
        }
   }
   useEffect(() => {
    if(unMatchedPassword.length !== 0){
        setTimeout(() => {
            setUnMatchedPassword("")
        }, 3000)
    }
}, [unMatchedPassword])
    useEffect(() => {
        if(reset_password_error){
            setTokenError(reset_password_error)
        }
    }, [reset_password_error]);
    useEffect(() => {
        if(reset_password_error){
            setTimeout(() => {
                setTokenError("");
            }, 4000);
        }
       
    }, [reset_password_error]);

    useEffect(() => {
       if(user_forgot_password){
           setTokenSetSuccess(user_forgot_password)
       }
    }, [user_forgot_password])

    useEffect(() => {
        if(user_forgot_password){
            setTimeout(() => {
                setTokenSetSuccess("");
            }, 4000);
        }
       
    }, [user_forgot_password]);
  

    return (
        <div>
            {successFullChange()}
            <div className="login_bg">
                {tokenSentSuccess && <div className="otp_success"><p className="mt-1">{tokenSentSuccess.message}</p></div>}
                {tokenError || unMatchedPassword ?    <div className="error_side">
                  {unMatchedPassword && <p className="mt-1">{unMatchedPassword}</p> }
                  {tokenError && <p className="mt-1">{tokenError.message}</p> }  
                </div> : ""}
             
                <div className="login__form">
                   <div className="card login__container">
                       <div className="card__image"><img src="/alpha-logo.png" alt="GIGLOGO" /></div>
                       <div className="card-body">
                           <p className="login_welcome">RESET PASSWORD</p>
                           <form onSubmit={handleResetPassword}>
                           <p className="otp_reset_pass">ENTER THE CODE SENT TO YOUR EMAIL</p>
                             <div className="form-group-otp">
                                   {otp.map((data, index) => (
                                       <input className="otp-field" type="text" name="otp" maxLength="1" key={index} value={data} onChange={(e) => handleChange(e.target, index)} onFocus={(e) => e.target.select()}/>
                                   ))}
                               </div>
                               <div className="mt-1">Didn't receive code? <strong className="otp-resend" onClick={handleSubmit}>Resend</strong></div>
                               <div className="form-group mt-2">
                                   <h5>NEW PASSWORD</h5>
                                   <div className="form-input">
                                        <div className="user_icon"><BiKey /></div>
                                        <input type="password" placeholder="Enter your new password" className="form_control" autoComplete="false" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                   </div>
                               </div>
                               <div className="form-group form__group">
                                   <h5>CONFIRM NEW PASSWORD</h5>
                                   <div className="form-input">
                                        <div className="user_icon"><BiKey /></div>
                                        <input type="password" placeholder="Enter your new password" className="form_control" autoComplete="false" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)}/>
                                   </div>
                               </div>
                             
                               <button className="button_reset" type="submit">{loading_reset_password ? <Loaders loading={loading_reset_password} color="#ffffff" size={20}/> : "RESET PASSWORD"}</button>
                           </form>
                       </div>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default NewPassword;