import { GET_PAYMENT_GATEWAY_FAIL, GET_PAYMENT_GATEWAY_REQUEST, GET_PAYMENT_GATEWAY_SUCCESS, PAYMENT_GATEWAY_CREATE_FAIL, PAYMENT_GATEWAY_CREATE_REQUEST, PAYMENT_GATEWAY_CREATE_SUCCESS, PAYMENT_GATEWAY_LIST_FAIL, PAYMENT_GATEWAY_LIST_REQUEST, PAYMENT_GATEWAY_LIST_SUCCESS, PAYMENT_GATEWAY_UPDATE_FAIL, PAYMENT_GATEWAY_UPDATE_REQUEST, PAYMENT_GATEWAY_UPDATE_SUCCESS, PAYMENT_GATEWAY_DELETE_REQUEST, PAYMENT_GATEWAY_DELETE_FAIL, PAYMENT_GATEWAY_DELETE_SUCCESS } from "../../constants/paymentConstant";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token
export function createPaymentGatewayAction (dataToSubmit) {
    return async(dispatch,getState) => {
        dispatch({type: PAYMENT_GATEWAY_CREATE_REQUEST});
        
        try {
            

            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/gateway/new`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: dataToSubmit
            });

            const data = await res.json()
            if(data.error){
            return dispatch({type: PAYMENT_GATEWAY_CREATE_FAIL, payload: data.message})
            }
            dispatch({type: PAYMENT_GATEWAY_CREATE_SUCCESS, payload: data});
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: PAYMENT_GATEWAY_CREATE_FAIL, payload: message})
        }
  }
}

export function paymentGatewayListAction(){
    return async(dispatch,getState) => {
        dispatch({type: PAYMENT_GATEWAY_LIST_REQUEST});
        try {
            
        
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/gateway/list`, {
                method: "GET",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    ACCEPT: "application/json"
                }
            })
            const data = await res.json()
           
            if(data.error){
               return dispatch({type: PAYMENT_GATEWAY_LIST_FAIL, payload: data.message})
            }
            dispatch({type: PAYMENT_GATEWAY_LIST_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: PAYMENT_GATEWAY_LIST_FAIL, payload: message})
        }
      } 
    }



    export function getPaymentGatewayAction(gatewayId){
        return async(dispatch,getState) => {
            dispatch({type: GET_PAYMENT_GATEWAY_REQUEST});
            try {
                
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/gateway/details?id=${gatewayId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        ACCEPT: "application/json" 
                    }
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: GET_PAYMENT_GATEWAY_FAIL, payload: data.message})
                }
                dispatch({type: GET_PAYMENT_GATEWAY_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: GET_PAYMENT_GATEWAY_FAIL, payload: message})
            }
        }
    }

    
    export function editPaymentGatewayAction (dataToSubmit){
        return async(dispatch,getState) => {
            dispatch({type: PAYMENT_GATEWAY_UPDATE_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/gateway/update`, {
                    method: "PUT",
                    headers : {
                        Authorization: `Bearer ${token}`
                    },
                    body: dataToSubmit
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: PAYMENT_GATEWAY_UPDATE_FAIL, payload: data.message})
                }
                dispatch({type: PAYMENT_GATEWAY_UPDATE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: PAYMENT_GATEWAY_UPDATE_FAIL, payload: message})
            }
      }
    }
    
    export function deletePaymentGatewayAction (gatewayId) { 
        return async(dispatch,getState) => {
            try {
                dispatch({type: PAYMENT_GATEWAY_DELETE_REQUEST});
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/gateway/delete?id=${gatewayId}`, {
                    method: "DELETE",
                    headers : {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        ACCEPT: "application/json" 
                    }
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: PAYMENT_GATEWAY_DELETE_FAIL, payload: data.message})
                }
                dispatch({type: PAYMENT_GATEWAY_DELETE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: PAYMENT_GATEWAY_DELETE_FAIL, payload: message})
            }
      }
    }


    