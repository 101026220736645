
import {GET_STORE_FAILED, GET_STORE_REQUEST, GET_STORE_SUCCESS, LIST_STORE_FAILED, LIST_STORE_REQUEST, LIST_STORE_SUCCESS, STORE_OWNER_LIST_REQUEST, STORE_OWNER_LIST_SUCCESS } from '../../constants/storeConstant';
import { localData } from '../../helpers/auth/auth';

import { getUrl } from '../../helpers/getUrl';
import { STORE_OWNER_LIST_FAIL } from './../../constants/storeConstant';
const token = localData && localData.token

export function StoreListAction (){
    return async (dispatch, getState) => {
         dispatch({type: LIST_STORE_REQUEST});
         try {
   
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/store/oms/stores`, {
                method: "GET",
                headers: {
                    ACCEPT: "application/json",
                    "Content-Type": "application/json",
                   "Authorization": `Bearer ${token}`
               }
            });
            const data = await res.json();
            if(data.error) return dispatch({type: LIST_STORE_FAILED, payload: data.message});
            dispatch({type: LIST_STORE_SUCCESS, payload: data.results})
         } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: LIST_STORE_FAILED, payload: message})
         }
    }
}

        export function getStoreAction (ownerId) {

        return async(dispatch,getState) => {
            try {
                dispatch({type: GET_STORE_REQUEST});
       
              
                const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/store/customer_store?owner=${ownerId}`, {
                    method: "GET",
                    headers: {
                         ACCEPT: "application/json",
                         "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: GET_STORE_FAILED, payload: data.message})
                }
                dispatch({type: GET_STORE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: GET_STORE_FAILED, payload: message})
            }
    } 
}

export function StoreOwnersList({page,limit}){
      return async (dispatch, getState) => {
              dispatch({type: STORE_OWNER_LIST_REQUEST})
             try {
       
                const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/user/store_owners?page=${page}&limit=${limit}`, {
                    method: "GET",
                    headers: {
                         ACCEPT: "application/json",
                         "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                const data = await res.json()
                if(data.error){
                    return dispatch({type: STORE_OWNER_LIST_FAIL, payload: data.message})
                }
                dispatch({type: STORE_OWNER_LIST_SUCCESS, payload: data.results})
             } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: STORE_OWNER_LIST_FAIL, payload: message})  
             }
      }
}

