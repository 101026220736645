import {useState} from "react";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io"
import "./tabs.css";

const SortDropDown = () => {
    const [sortDropdown, setSortDropdown] = useState(false);
    const handleDropDown = () => {
        setSortDropdown(!sortDropdown)
    }
    return (
        <>
        <div className="sort" onClick={handleDropDown}>
             <span>Sort by</span> <span>{sortDropdown ? <IoMdArrowDropup />:<IoMdArrowDropdown /> }</span>
        </div>
        <div className={sortDropdown ? "activate_sort sortable_dropdown":"sortable_dropdown"}>
             <div>Ascending Order</div>
             <div className="divider"></div>
             <div>Descending Order</div>
        </div>
        </>
    )
}

export default SortDropDown;