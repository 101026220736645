import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {FaArrowLeft} from "react-icons/fa";
import { BiDownload } from "react-icons/bi";
import SingleBuyer from './SingleBuyer';
import DownloadCSVModal from "../Reusables/DownloadCSVModal";
import { AppContext } from "../../AppContext";
import { useParams } from "react-router-dom"
import "./customer.css";
import { BasicBuyerDetailAction } from "../../actions/Admin/BasicBuyerAction";

 const BuyerItem = ({showDownloadModal, openDownloadModal, setShowDownloadModal}) => {
    const { handleActiveTab, itemLimit, pageNumber} = useContext(AppContext);
    const {buyerId} = useParams()
    const history = useHistory();
    const dispatch = useDispatch()
    const { basic_buyer_error, basic_buyer_detail_docs } = useSelector(state => state.basicBuyerDetailsReducer)
    const buyerName = basic_buyer_detail_docs && basic_buyer_detail_docs[0] && basic_buyer_detail_docs[0].ReceiverName;
    const getPreShipmentItems = basic_buyer_detail_docs && basic_buyer_detail_docs.map(item => (item.PreShipmentItems)).flat();
    
  useEffect(() => {
    const page = pageNumber;
    const limit = +itemLimit;
    const data = {buyerId, page, limit}
    if(buyerId){
        dispatch(BasicBuyerDetailAction(data))
    }

}, [buyerId,itemLimit, dispatch, pageNumber])
  
    
     return (
        <div>
            {basic_buyer_error ?  <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{basic_buyer_error || basic_buyer_error.message }</p>
                </div> : 
            <>
            <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div>
                        <div className="data_heading store-owner-page"  onClick={() => {history.push("/dashboard/customer-database"); handleActiveTab(2)}}>
                            <FaArrowLeft style={{cursor: "pointer"}}/><div className="header_order_title store-owner-header">BUYER ORDER HISTORY</div>
                        
                        </div>
                                <div className="card-body buyer-data">
                                <div className="buyer-name">
                                   <div className="store-img-name">
                                       <div className="image-div"><img src={ "/alpha-favicon.png"} alt="store-icon"/></div>
                                       <div className="store-name-div">
                                           <span>Buyer Name</span>
                                           <span>{buyerName && buyerName}</span>
                                       </div>
                                   </div>
                                   <button className="customer-inventory-btn" onClick={openDownloadModal}><BiDownload />Download Inventory</button>
                                </div>
                                <div className="buyer-det">
                                    <div className="card">
                                        <div className="card-body body-of-card customer-total-purchase">
                                            <h6>TOTAL NUMBER OF PRODUCTS PURCHASED</h6><br />
                                            <p>
                                                {getPreShipmentItems?.map(value =>  value.Quantity)?.reduce((total,num) => (total + num),0)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                               
                        </div>
                    
                       
                    {/* <Paginations paginate="paginate"/>   */}
                </div> 
            </div>
            <SingleBuyer buyerId={buyerId}/>
            {/* {showDownloadModal && <DownloadCSVModal setShowDownloadModal={setShowDownloadModal}/>} */}
            </>
            }
        </div>
     )
 }

 export default BuyerItem