import { useState, useEffect } from "react";
import {FiUser } from "react-icons/fi"
import { useSelector, useDispatch } from "react-redux";
import Loaders from "../Reusables/Loader";

import {adminForgotPassword} from "../../actions/Admin/AdminAction"
import "./login.css";
import SentLinkSuccess from "./SentLinkSuccess";

const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const [forgotPasswordError, setForgotPasswordError] = useState("")
    const dispatch = useDispatch()
    const {loading_forgot_password, error,user_forgot_password} = useSelector(state => state.passwordForgot);

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(adminForgotPassword({email, "user_type": "admin"}))
    }
    useEffect(() => {
        if(error){
            setForgotPasswordError(error)
        }
    }, [error]);
    useEffect(() => {
        if(error){
            setTimeout(() => {
                setForgotPasswordError("")
            }, 3000)
        }
       
    }, [error])
    return (
        <>
        {user_forgot_password && user_forgot_password.code === 200 ? <SentLinkSuccess user_forgot_password={user_forgot_password} email={email}/> : <div>
            
            <div className="login_bg">
               {forgotPasswordError && <div className="reset_error error_side"><p className="mt-1">{forgotPasswordError.message}</p></div>}
                <div className="login__form">
               
                   <div className="card login__container">
                       <div className="card__image"><img src="/alpha-logo.png" alt="GIGLOGO" /></div>
                       <div className="card-body">
                           <p className="login_welcome">RESET PASSWORD</p>
                           <h5 className="reset_email">ENTER Email Address</h5>
                           <p className="reset_text_hint">Please provide your given email address as an Admin</p>
                           <form onSubmit={handleSubmit}>
                               <div className="form-group">
                                   <div className="form-input">
                                        <div className="user_icon"><FiUser /></div>
                                        <input type="email" placeholder="tomanderson@giglostics.ng" className="form_control" onChange={(e) => setEmail(e.target.value)}/>
                                   </div>
                               </div>
                               <button className="button_reset">{loading_forgot_password ? <Loaders loading={loading_forgot_password} color="#ffffff" size={20}/> : "RESET PASSWORD"}</button>
                           </form>
                       </div>
                   </div>
                </div>
            </div>
        </div>}
    
        </>
    )
}

export default ResetPassword;