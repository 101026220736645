import {useState} from 'react'

  
const useGenerateColor = () => {
    const [color,setColor] = useState(['#6C0226', '#E6DD13','#148CCF','#8A558B','#BFBFBF','#EC5805', '#B1617C', '#000000', '#1FC64E','#1000FF', '#10FF00', '#FF0010', '#A20239', '#9000FF','#FF9000', '#6660BF','#C3BFFE','#8880FF','#06005F','#03B7D9','#03D990','#D903B7', '#808080', '#F5C0D2','#EC82A6', '#51011C', '#333333', '#606060', '#758C6B', '#38761D', '#1D3876', '#761D38','#1D762E','#64761D','#152C0B','#2E1D76','#761D64','#5B1D76','#CDDDC7','#9CBB8F']);

    function colorRange(range){
          let newColor = color && color.slice(0,range);
          setColor(newColor)
    }
    return {colorRange, color};
      
};
export default useGenerateColor;
