import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import Loaders from "../Reusables/Loader";
import { AdminRoleUpdate, AdminRoleGet } from "../../actions/Admin/AdminRoleAction";
// import { ToastContainer,toast, Bounce } from 'react-toastify';


const UpdateModal = ({showModal,get_role_loading, handleChange, category, setShowModal, categoryId}) => {  
    const [name, setName] = useState("")  
    const {loading, error, role} = useSelector(state => state.UpdateRoleReducer);
    const {role: role_get}  = useSelector(state => state.getRole)
    const dispatch = useDispatch()
    const handleRoleUpdate = (e) => {
        e.preventDefault();
        dispatch(AdminRoleUpdate(categoryId, {name}))
        setShowModal(false)
    }

    useEffect(() => {
        dispatch(AdminRoleGet(categoryId))
    }, [dispatch, categoryId]);
    useEffect(() => {
           setName(role_get?.results?.name)
    }, [role_get?.results?.name])

    return (
        <>
        {showModal ? 
         
              <div className="card category_modal">
              <div className="data_heading">
                <div className="header_order_title role_header">{category}</div>
              </div>
              <form className="add_category_form" onSubmit={handleRoleUpdate}>
                  <label htmlFor="category" className="role_label">Input Category Name</label>
                  <input type="text" id="category" className="add_category_input" onChange={(e) => setName(e.target.value)} value={name}/>
                  <button className="role_button">{get_role_loading ? <Loaders loading={get_role_loading} color="#ffffff" size={20}/> : "submit"}</button>
              </form>
           </div>
          : null
       }

      
        </>
    )
}


export default UpdateModal