import { localData } from '../../../helpers/auth/auth';
import { getUrl } from '../../../helpers/getUrl';
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { GET_SALES_OVERVIEW_FAIL, GET_SALES_OVERVIEW_REQUEST, GET_SALES_OVERVIEW_SUCCESS } from './../../../constants/sales/salesOverviewConstant';
const token = localData && localData.token;
export function GetSalesOverviewAction (query) {

    return async(dispatch,getState) => {
        dispatch({type: GET_SALES_OVERVIEW_REQUEST});
        
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/sales_overview?${query}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_SALES_OVERVIEW_FAIL, payload: data.message})  
            }
            dispatch({type: GET_SALES_OVERVIEW_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_SALES_OVERVIEW_FAIL, payload: message})
        }
    }
}




export const salesOverviewApi = createApi({
    reducerPath: "salesOverviewApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["Sales"],
    endpoints: (builder) => ({
        salesOverview: builder.query({
            query: ({query}) => ({
                url: `/order/sales_overview?${query}`,
                method: "get",
            }),
           providesTags: ["Sales"]
        })
    })
});

export const { useSalesOverviewQuery } = salesOverviewApi


