import { localData } from '../../../helpers/auth/auth';
import { getUrl } from '../../../helpers/getUrl';
import { CREATE_COMPLIANCE_REQUEST, GET_PRODUCT_DETAILS_FAIL, GET_PRODUCT_DETAILS_REQUEST, GET_PRODUCT_DETAILS_SUCCESS, LIST_APPROVED_PRODUCT_FAIL, LIST_APPROVED_PRODUCT_REQUEST, LIST_APPROVED_PRODUCT_SUCCESS, LIST_DISAPPROVED_PRODUCT_FAIL, LIST_DISAPPROVED_PRODUCT_REQUEST, LIST_DISAPPROVED_PRODUCT_SUCCESS, LIST_PRODUCT_FAIL, LIST_PRODUCT_REQUEST, LIST_PRODUCT_SUCCESS, NEW_PRODUCT_LIST_FAIL, NEW_PRODUCT_LIST_REQUEST, NEW_PRODUCT_LIST_SUCCESS, UPDATE_PRODUCT_VIEW_FAIL, UPDATE_PRODUCT_VIEW_REQUEST, UPDATE_PRODUCT_VIEW_SUCCESS, CREATE_COMPLIANCE_FAIL, CREATE_COMPLIANCE_SUCCESS, CLEAR_COMPLIANCE_STATE, LIST_COMPLIANCE_REQUEST, LIST_COMPLIANCE_FAIL, LIST_COMPLIANCE_SUCCESS, CLEAR_COMPLIANCE_UPDATE, CLEAR_COMPLIANCE_DELETE, GET_COMPLIANCE_REQUEST, GET_COMPLIANCE_FAIL, GET_COMPLIANCE_SUCCESS, UPDATE_COMPLIANCE_REQUEST, UPDATE_COMPLIANCE_FAIL, UPDATE_COMPLIANCE_SUCCESS, DELETE_COMPLIANCE_REQUEST, DELETE_COMPLIANCE_SUCCESS, DELETE_COMPLIANCE_FAIL, UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST, UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL, UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS, CLEAR_PRODUCT_COMPLIANCE_STATUS, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_SUCCESS, CLEAR_DELETE_PRODUCT } from './../../../constants/product/productConstant';
const token = localData && localData.token;

export function ListProducts({page,limit}){
     return async(dispatch, getState) => {
        
         dispatch({type: LIST_PRODUCT_REQUEST});
         try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/all?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                     ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: LIST_PRODUCT_FAIL, payload: data.message})  
            }
            dispatch({type: LIST_PRODUCT_SUCCESS, payload: data.results})
         } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: LIST_PRODUCT_FAIL, payload: message})
         }
     }
}


export function GetProductDetails(id){
    return async(dispatch, getState) => {
        dispatch({type: GET_PRODUCT_DETAILS_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/${id}`, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                   "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: GET_PRODUCT_DETAILS_FAIL, payload: data.message})  
           }
           dispatch({type: GET_PRODUCT_DETAILS_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: GET_PRODUCT_DETAILS_FAIL, payload: message})
        }
    }
}

export function DeleteProduct(id){
    return async(dispatch, getState) => {
        dispatch({type: DELETE_PRODUCT_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/${id}`, {
               method: "DELETE",
               headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                   "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: DELETE_PRODUCT_FAIL, payload: data.message})  
           }
           dispatch({type: DELETE_PRODUCT_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: DELETE_PRODUCT_FAIL, payload: message})
        }
    }
}


export function ListApprovedProducts({page,limit}){
    return async(dispatch, getState) => {
        dispatch({type: LIST_APPROVED_PRODUCT_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/approval/status?status=true&page=${page}&limit=${limit}`, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                   "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: LIST_APPROVED_PRODUCT_FAIL, payload: data.message})  
           }
           dispatch({type: LIST_APPROVED_PRODUCT_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: LIST_APPROVED_PRODUCT_FAIL, payload: message})
        }
    }
}

export function ListDisApprovedProducts({page,limit}){
    return async(dispatch, getState) => {
        dispatch({type: LIST_DISAPPROVED_PRODUCT_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/approval/status?status=false&page=${page}&limit=${limit}`, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                   "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: LIST_DISAPPROVED_PRODUCT_FAIL, payload: data.message})  
           }
           dispatch({type: LIST_DISAPPROVED_PRODUCT_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: LIST_DISAPPROVED_PRODUCT_FAIL, payload: message})
        }
    }
}

export function NewProductsList({page,limit}){
    return async(dispatch, getState) => {
        dispatch({type: NEW_PRODUCT_LIST_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/status?status=new&page=${page}&limit=${limit}`, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                   "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: NEW_PRODUCT_LIST_FAIL, payload: data.message})  
           }
           dispatch({type: NEW_PRODUCT_LIST_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: NEW_PRODUCT_LIST_FAIL, payload: message})
        }
    }
}

export function UpdateProductView(dataToSubmit){
    return async(dispatch, getState) => {
        dispatch({type: UPDATE_PRODUCT_VIEW_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/update/status`, {
               method: "PUT",
               headers: {
                   "Authorization": `Bearer ${token}`,
                   "Content-Type": "application/json",
                   ACCEPT: "application/json",
               },
               body: JSON.stringify(dataToSubmit)
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: UPDATE_PRODUCT_VIEW_FAIL, payload: data.message})  
           }
           dispatch({type: UPDATE_PRODUCT_VIEW_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: UPDATE_PRODUCT_VIEW_FAIL, payload: message})
        }
    }
}

export function UpdateProductComplianceStatus(dataToSubmit){
    return async(dispatch, getState) => {
        dispatch({type: UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/product/compliance/status`, {
               method: "PUT",
               headers: {
                   "Authorization": `Bearer ${token}`,
                   "Content-Type": "application/json",
                   ACCEPT: "application/json",
               },
               body: JSON.stringify(dataToSubmit)
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL, payload: data.message})  
           }
           dispatch({type: UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL, payload: message})
        }
    }
}


export function CreateUploadCriteria(dataTosubmit){
    return async(dispatch, getState) => {
        dispatch({type: CREATE_COMPLIANCE_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/compliance/create`, {
               method: "POST",
               headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
               },
               body: JSON.stringify(dataTosubmit)
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: CREATE_COMPLIANCE_FAIL, payload: data.message})  
           }
           dispatch({type: CREATE_COMPLIANCE_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: CREATE_COMPLIANCE_FAIL, payload: message})
        }
    }
}

export function ListUploadCriteria(){
    return async(dispatch, getState) => {
        dispatch({type: LIST_COMPLIANCE_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/compliance/all`, {
               method: "GET",
               headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: LIST_COMPLIANCE_FAIL, payload: data.message})  
           }
           dispatch({type: LIST_COMPLIANCE_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: LIST_COMPLIANCE_FAIL, payload: message})
        }
    }
}

export function GetUploadCriteria(id){
    return async(dispatch, getState) => {
        dispatch({type: GET_COMPLIANCE_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/compliance/details?id=${id}`, {
               method: "GET",
               headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
               }
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: GET_COMPLIANCE_FAIL, payload: data.message})  
           }
           dispatch({type: GET_COMPLIANCE_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: GET_COMPLIANCE_FAIL, payload: message})
        }
    }
} 

export function UpdateUploadCriteria(dataToSubmit){
    return async(dispatch, getState) => {
        dispatch({type: UPDATE_COMPLIANCE_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/compliance/update`, {
               method: "PUT",
               headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
               },
               body: JSON.stringify(dataToSubmit)
           })
           
           const data = await res.json();
           if(data.error){
               return dispatch({type: UPDATE_COMPLIANCE_FAIL, payload: data.message}) 
           }
           dispatch({type: UPDATE_COMPLIANCE_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: UPDATE_COMPLIANCE_FAIL, payload: message})
        }
    }
} 


export function DeleteUploadCriteria(id){
    return async(dispatch, getState) => {
        dispatch({type: DELETE_COMPLIANCE_REQUEST});
        try {
           const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/compliance/delete?id=${id}`, {
               method: "DELETE",
               headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
               }
           })
           const data = await res.json();
           if(data.error){
               return dispatch({type: DELETE_COMPLIANCE_FAIL, payload: data.message})  
           }
           dispatch({type: DELETE_COMPLIANCE_SUCCESS, payload: data.results})
        } catch (error) {
           const message =
           error.response && error.response.data
           ? error.response.data
           : error.message;
           dispatch({type: DELETE_COMPLIANCE_FAIL, payload: message})
        }
    }
} 

export const clearComplianceState = () => async (dispatch) => {
    dispatch({type: CLEAR_COMPLIANCE_STATE});
}
export const clearComplianceUpdate = () => async(dispatch) => {
      dispatch({type: CLEAR_COMPLIANCE_UPDATE})
}
export const clearComplianceDelete = () => async(dispatch) => {
    dispatch({type: CLEAR_COMPLIANCE_DELETE})
}
export const clearComplianceStatus = () => async(dispatch) => {
      dispatch({type: CLEAR_PRODUCT_COMPLIANCE_STATUS})
}
export const clearProductDelete = () => async(dispatch) => {
    dispatch({type: CLEAR_DELETE_PRODUCT})
}