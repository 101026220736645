//Category
export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_FAIL = "CATEGORY_CREATE_FAIL";
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";
export const CATEGORY_EDIT_REQUEST = "CATEGORY_EDIT_REQUEST";
export const CATEGORY_EDIT_SUCCESS = "CATEGORY_EDIT_SUCCESS";
export const CATEGORY_EDIT_FAIL = "CATEGORY_EDIT_FAIL";
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";
export const CATEGORY_GET_REQUEST = "CATEGORY_GET_REQUEST";
export const CATEGORY_GET_SUCCESS = "CATEGORY_GET_SUCCESS";
export const CATEGORY_GET_FAIL = "CATEGORY_GET_FAIL";
export const CATEGORY_STATUS_UPDATE_REQUEST = "CATEGORY_STATUS_UPDATE_REQUEST";
export const CATEGORY_STATUS_UPDATE_SUCCESS = "CATEGORY_STATUS_UPDATE_SUCCESS"
export const CATEGORY_STATUS_UPDATE_FAIL = "CATEGORY_STATUS_UPDATE_FAIL";
export const SUB_CATEGORY_REMOVE_REQUEST = "SUB_CATEGORY_REMOVE_REQUEST";
export const SUB_CATEGORY_REMOVE_SUCCESS = "SUB_CATEGORY_REMOVE_SUCCESS";
export const SUB_CATEGORY_REMOVE_FAIL = "SUB_CATEGORY_REMOVE_FAIL";


//Sizes
export const CATEGORY_SIZE_CREATE_REQUEST = "CATEGORY_SIZE_CREATE_REQUEST";
export const CATEGORY_SIZE_CREATE_SUCCESS = "CATEGORY_SIZE_CREATE_SUCCESS";
export const CATEGORY_SIZE_CREATE_FAIL = "CATEGORY_SIZE_CREATE_FAIL";
export const CATEGORY_SIZE_LIST_REQUEST = "CATEGORY_SIZE_LIST_REQUEST";
export const CATEGORY_SIZE_LIST_SUCCESS = "CATEGORY_SIZE_LIST_SUCCESS";
export const CATEGORY_SIZE_LIST_FAIL = "CATEGORY_SIZE_LIST_FAIL";
export const CATEGORY_SIZE_GET_REQUEST = "CATEGORY_SIZE_GET_REQUEST";
export const CATEGORY_SIZE_GET_SUCCESS = "CATEGORY_SIZE_GET_SUCCESS";
export const CATEGORY_SIZE_GET_FAIL = "CATEGORY_SIZE_GET_FAIL";
export const CATEGORY_SIZE_EDIT_REQUEST = "CATEGORY_SIZE_EDIT_REQUEST";
export const CATEGORY_SIZE_EDIT_SUCCESS = "CATEGORY_SIZE_EDIT_SUCCESS";
export const CATEGORY_SIZE_EDIT_FAIL = "CATEGORY_SIZE_EDIT_FAIL";
export const CATEGORY_SIZE_DELETE_REQUEST = "CATEGORY_SIZE_DELETE_REQUEST";
export const CATEGORY_SIZE_DELETE_SUCCESS = "CATEGORY_SIZE_DELETE_SUCCESS";
export const CATEGORY_SIZE_DELETE_FAIL = "CATEGORY_SIZE_DELETE_FAIL";



//Types
export const CATEGORY_TYPE_CREATE_REQUEST = "CATEGORY_TYPE_CREATE_REQUEST";
export const CATEGORY_TYPE_CREATE_SUCCESS = "CATEGORY_TYPE_CREATE_SUCCESS";
export const CATEGORY_TYPE_CREATE_FAIL = "CATEGORY_TYPE_CREATE_FAIL";
export const CATEGORY_TYPE_LIST_REQUEST = "CATEGORY_TYPE_LIST_REQUEST";
export const CATEGORY_TYPE_LIST_SUCCESS = "CATEGORY_TYPE_LIST_SUCCESS";
export const CATEGORY_TYPE_LIST_FAIL = "CATEGORY_TYPE_LIST_FAIL";
export const CATEGORY_TYPE_GET_REQUEST = "CATEGORY_TYPE_GET_REQUEST";
export const CATEGORY_TYPE_GET_SUCCESS = "CATEGORY_TYPE_GET_SUCCESS";
export const CATEGORY_TYPE_GET_FAIL = "CATEGORY_TYPE_GET_FAIL";
export const CATEGORY_TYPE_EDIT_REQUEST = "CATEGORY_TYPE_EDIT_REQUEST";
export const CATEGORY_TYPE_EDIT_SUCCESS = "CATEGORY_TYPE_EDIT_SUCCESS";
export const CATEGORY_TYPE_EDIT_FAIL = "CATEGORY_TYPE_EDIT_FAIL";
export const CATEGORY_TYPE_DELETE_REQUEST = "CATEGORY_TYPE_DELETE_REQUEST";
export const CATEGORY_TYPE_DELETE_SUCCESS = "CATEGORY_TYPE_DELETE_SUCCESS";
export const CATEGORY_TYPE_DELETE_FAIL = "CATEGORY_TYPE_DELETE_FAIL";


//Gender
export const CATEGORY_GENDER_CREATE_REQUEST = "CATEGORY_GENDER_CREATE_REQUEST";
export const CATEGORY_GENDER_CREATE_SUCCESS = "CATEGORY_GENDER_CREATE_SUCCESS";
export const CATEGORY_GENDER_CREATE_FAIL = "CATEGORY_GENDER_CREATE_FAIL";
export const CATEGORY_GENDER_LIST_REQUEST = "CATEGORY_GENDER_LIST_REQUEST";
export const CATEGORY_GENDER_LIST_SUCCESS = "CATEGORY_GENDER_LIST_SUCCESS";
export const CATEGORY_GENDER_LIST_FAIL = "CATEGORY_GENDER_LIST_FAIL";
export const CATEGORY_GENDER_GET_REQUEST = "CATEGORY_GENDER_GET_REQUEST";
export const CATEGORY_GENDER_GET_SUCCESS = "CATEGORY_GENDER_GET_SUCCESS";
export const CATEGORY_GENDER_GET_FAIL = "CATEGORY_GENDER_GET_FAIL";
export const CATEGORY_GENDER_EDIT_REQUEST = "CATEGORY_GENDER_EDIT_REQUEST";
export const CATEGORY_GENDER_EDIT_SUCCESS = "CATEGORY_GENDER_EDIT_SUCCESS";
export const CATEGORY_GENDER_EDIT_FAIL = "CATEGORY_GENDER_EDIT_FAIL";
export const CATEGORY_GENDER_DELETE_REQUEST = "CATEGORY_GENDER_DELETE_REQUEST";
export const CATEGORY_GENDER_DELETE_SUCCESS = "CATEGORY_GENDER_DELETE_SUCCESS";
export const CATEGORY_GENDER_DELETE_FAIL = "CATEGORY_GENDER_DELETE_FAIL";