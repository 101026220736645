import { CLEAR_PENDING_ORDER, CLEAR_UPDATE_ORDER_STATUS, ORDER_GET_FAILED, ORDER_GET_REQUEST, ORDER_GET_SUCCESS, ORDER_LIST_FAILED, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS,PENDING_PAYMENT_FAIL,PENDING_PAYMENT_REQUEST, PENDING_PAYMENT_SUCCESS, UPDATE_ORDER_STATUS_FAIL, UPDATE_ORDER_STATUS_REQUEST, UPDATE_ORDER_STATUS_SUCCESS, VERIFY_PENDING_ORDER_FAIL, VERIFY_PENDING_ORDER_REQUEST, VERIFY_PENDING_ORDER_SUCCESS } from "../../constants/orderConstants";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token

export function orderListAction({offset,limit}) {
    return async(dispatch,getState) => {
    dispatch({type: ORDER_LIST_REQUEST});
    
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/all?offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "ACCEPT": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
           return dispatch({type: ORDER_LIST_FAILED, payload: data.message})
        }
        dispatch({type: ORDER_LIST_SUCCESS, payload: data.results})
    } catch (error) {

        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: ORDER_LIST_FAILED, payload: message})
    }
  }
}

export function getOrderAction(orderId){ 
    return async(dispatch,getState) => {
    dispatch({type: ORDER_GET_REQUEST});
    try {
      
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/details?order_id=${orderId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
            return dispatch({type: ORDER_GET_FAILED, payload: data.message})
        }
        dispatch({type: ORDER_GET_SUCCESS, payload: data})
    } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: ORDER_GET_FAILED, payload: message})
    }
 }
}




export function pendingPaymentAction({offset,limit}) {
    return async(dispatch,getState) => {
    dispatch({type: PENDING_PAYMENT_REQUEST});
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/pending_payment?offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "ACCEPT": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
           return dispatch({type: PENDING_PAYMENT_FAIL, payload: data.message})
        }
        dispatch({type: PENDING_PAYMENT_SUCCESS, payload: data.results})
    } catch (error) {

        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: PENDING_PAYMENT_FAIL, payload: message})
    }
  }
}


export function verifyPendingOrderAction(dataToSubmit) {
    return async(dispatch,getState) => {
    dispatch({type: VERIFY_PENDING_ORDER_REQUEST});
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/transaction/admin/verify`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "ACCEPT": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(dataToSubmit)
        })
        const data = await res.json();
        if(data.error){
           return dispatch({type: VERIFY_PENDING_ORDER_FAIL, payload: data.message})
        }
        dispatch({type: VERIFY_PENDING_ORDER_SUCCESS, payload: data.results})
    } catch (error) {

        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: VERIFY_PENDING_ORDER_FAIL, payload: message})
    }
  }
}

export function confirmOrderStatusAction(dataToSubmit) {
    return async(dispatch,getState) => {
    dispatch({type: UPDATE_ORDER_STATUS_REQUEST});
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/update/status`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(dataToSubmit)
        })
        const data = await res.json();
        if(data.error){
           return dispatch({type: UPDATE_ORDER_STATUS_FAIL, payload: data.message})
        }
        dispatch({type: UPDATE_ORDER_STATUS_SUCCESS, payload: data.results})
    } catch (error) {

        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: UPDATE_ORDER_STATUS_FAIL, payload: message})
    }
  }
}


export const clearPendingOrderState = () => async (dispatch) => {
    dispatch({type: CLEAR_PENDING_ORDER});
}

export const clearUpdateOrderStatus = () => async (dispatch) =>  {
    dispatch({type: CLEAR_UPDATE_ORDER_STATUS})
}