import {useState, useEffect} from "react"
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useSelector, useDispatch  } from "react-redux";
import Loaders from "../Reusables/Loader";
import { Alert } from 'antd';
import { editPaymentGatewayAction, getPaymentGatewayAction } from "../../actions/Admin/PaymentGateway";
const EditPaymentMethod = ({id}) => {
  const {get_gateway_loading,  get_payment_error, payment_gateway} = useSelector(state => state.paymentGatewayGet);
  const {update_gateway_loading, update_gateway_error, update_payment_gateway} = useSelector(state => state.paymentGatewayEdit)
  const history = useHistory()

  const dispatch = useDispatch(null)
  const [preview, setPreview] = useState("");
  const [name, setName] = useState("");
  const [image_url, setImageUrl] = useState({})
  const [editSuccess, setEditSuccess] = useState("");
  const [editFailed, setEditFailed] = useState("");
  const [invalidFields, setInvalidFields] = useState("");

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0]
    setImageUrl(selectedFile);
    const filePreview = URL.createObjectURL(selectedFile);
    setPreview(filePreview);
  }

  const handleSubmitEdit = (e) => {
       e.preventDefault();
       let data = new FormData();
       data.append("name", name);
       data.append("image_url", image_url);
       data.append("id", id)
       if(!name || !image_url){
          return setInvalidFields("All fields must be valid!")
       }
       
       dispatch(editPaymentGatewayAction(data));
       
  }

  useEffect(() => {
       dispatch(getPaymentGatewayAction(id))
  }, [dispatch, id]);

  useEffect(() => {
    setName(payment_gateway?.results?.name);
    setImageUrl(payment_gateway?.results?.image_url)
    if(!update_gateway_loading && update_gateway_error){
      setEditFailed(update_gateway_error && update_gateway_error.message)
    }
    if(!update_gateway_loading && update_payment_gateway){
        setEditSuccess(update_payment_gateway && update_payment_gateway.message)
    }
  
  }, [payment_gateway?.results?.name, update_payment_gateway, update_gateway_error, update_gateway_loading]);

   
  useEffect(() => {
    if(!update_gateway_loading && update_payment_gateway){
      setName("")
   }
  }, [update_gateway_loading, update_payment_gateway])

  useEffect(() => {
       const success = setTimeout(() => setEditSuccess(""), 3000);
       return () => clearTimeout(success)
  }, [editSuccess]);
  useEffect(() => {
    const failedEdit = setTimeout(() => setEditFailed(""));
    return () => clearTimeout(failedEdit)
  }, [editFailed])
    return (
        <div className="payment_modal">
        <div className="card payment_add_method">
        <div className="data_heading">
          <div className="header_order_title type_header">EDIT PAYMENT METHOD</div>
        </div>
        {get_gateway_loading &&   <div style={{display: "block", textAlign: "center", margin: "3rem auto 5rem auto"}}><Loaders loading={get_gateway_loading} color="#000000" size={50}/></div>}
          {/* {
             get_payment_error  &&  <Alert message="Failed"
             description={get_payment_error}
             type="error"
             closable
             className="my-3"
             />
          } */}

        <form className="add_payment_form">
            <div className="row">
              <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label htmlFor="category" className="payment_label">Display Name</label>
                <input type="text" className="payment_input" value={name} onChange={(e) => setName(e.target.value)}/>
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <form enctype=" multipart /form-data">
                    <input type="file"  id="file" accept="image/*" className="payment-image-upload" name="image_url" onChange={handleFileUpload}/>
                    <label htmlFor="file" className="upload-file-img"><FaPlus/> Replace Display Image</label>  
                    {image_url && <div className="image-preview">
                        <img src={preview} alt={image_url.name}/>
                        <div>{preview}</div>
                    </div>} 
                </form>
                
              </div>
            </div>
            {editFailed && <p className="text-center text-danger">{editFailed}</p>}
            {invalidFields && <p className="text-center text-danger">{invalidFields}</p>}
            {editSuccess && <p className="text-center text-success">{editSuccess}</p>}
           
            <div className="cta_btn">
              <button className="type_button" onClick={handleSubmitEdit}>{update_gateway_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={update_gateway_loading} color="#FFFFFF" size={20}/></div> : "UPDATE"}</button>
              <button className="type_cancel_button" onClick={() => {history.push("/dashboard/payment-methods"); setName(""); setEditFailed(""); setEditSuccess("")}}>CANCEL</button>
            </div>
          
        </form>
     </div>


  </div>
    )
}

export default EditPaymentMethod;