import { useState, useEffect } from "react";
import "./login.css";
import {FiUser } from "react-icons/fi"
import {BiKey} from "react-icons/bi";
import {Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { adminLoginAction } from "../../actions/Admin/AdminAction";
import ScaleLoader from "react-spinners/ScaleLoader";
import { getLocalData } from "./Protected/auth";
const override = {
    margin: "0",
    borderColor: "none",
  };
const Login = () => {
    const [color, setColor] = useState("#72FFC9");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validateInput, setValidateInput] = useState("");
    const [loginError, setLoginError] = useState("");
    const dispatch = useDispatch();
    // const [remember, setRemember] = useState(false)

    const {loading, error} = useSelector(state => state.gigAuth);
    const dataToSubmit = {email, password, user_type: "admin"}



    // const onRememberMe = (e) => {
    //       setRemember(e.target.checked)
    // }


    const handleSubmit = (e) => {
        e.preventDefault();
        if(!email || !password) setValidateInput("All fields are required")
        dispatch(adminLoginAction(dataToSubmit))
    }

//    remember ? localStorage.setItem("rememberMe", JSON.stringify({email, password})) : localStorage.removeItem("rememberMe");

    const redirectTo = () => {
        if(getLocalData()) return window.location.replace("/")
    }
    useEffect(() => {
        if(error){
            setLoginError(error)
        }
    }, [error]);
    useEffect(() => {
        if(error){
            setTimeout(() => {
                setLoginError("")
            }, 3000)
        }
       
    }, [error])
    return (
        
        <div>
              {redirectTo()}

            <div className="login_bg">
                <div className="login__form">
                   <div className="card login__container">
                       <div className="card__image"><img src="/alpha-logo.png" alt="GIGLOGO" /></div>
                       <div className="card-body">
                           <p className="login_welcome">WELCOME BACK!</p>
                           
                           {validateInput && <p className="text-danger text-center">{validateInput}</p>}
                           {loginError && <p className="text-danger text-center">{loginError || loginError.message}</p>}
                           <form onSubmit={handleSubmit}>
                               <div className="form-group">
                                   <h5>Email Address</h5>
                                   <div className="form-input">
                                        <div className="user_icon"><FiUser /></div>
                                        <input type="email" placeholder="tomanderson@giglostics.ng" className="form_control" onChange={(e) => setEmail(e.target.value)} value={email}/>
                                   </div>
                               </div>
                               <div className="form-group form-group-2">
                                   <h5>PASSWORD</h5>
                                   <div className="form-input">
                                        <div className="user_icon"><BiKey /></div>
                                        <input type="password" placeholder="********************" className="form_control"  onChange={(e) => setPassword(e.target.value)} value={password}/>
                                   </div>
                               </div>
                               <div className="form-group form-group-3">
                                   {/* <div className="remeber_me"><input type="checkbox" id="rememberMe" className="check_option" /><label htmlFor="rememberMe" className="rememberMe"> Remember Me</label></div> */}
                                   <Link to="/forgot-password" className="forgot_passord">Reset Password?</Link>
                               </div>
                               <button className="button_submit">{loading ?  <div ><span><ScaleLoader color={color} loading={loading} cssOverride={override}  /></span></div> : "sign in"}</button>
                           </form>
                       </div>
                   </div>
                </div>
            </div>
            
        </div>
    )
}

export default Login;