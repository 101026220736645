import { ADMIN_FORGOT_PASSWORD_FAIL, ADMIN_FORGOT_PASSWORD_REQUEST, ADMIN_FORGOT_PASSWORD_SUCCESS, ADMIN_LOGOUT, ADMIN_RESET_PASSWORD_FAIL, ADMIN_RESET_PASSWORD_REQUEST, ADMIN_RESET_PASSWORD_SUCCESS, CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, DELETE_ADMIN_FAIL, DELETE_ADMIN_REQUEST, DELETE_ADMIN_SUCCESS, FETCH_ADMIN_FAIL, FETCH_ADMIN_REQUEST, FETCH_ADMIN_SUCCESS, GET_ADMIN_FAIL, GET_ADMIN_REQUEST, GET_ADMIN_SUCCESS, LOGIN_ADMIN_FAIL, LOGIN_ADMIN_REQUEST, LOGIN_ADMIN_SUCCESS, UPDATE_ADMIN_FAIL, UPDATE_ADMIN_REQUEST, UPDATE_ADMIN_ROLE_FAIL, UPDATE_ADMIN_ROLE_REQUEST, UPDATE_ADMIN_ROLE_SUCCESS, UPDATE_ADMIN_SUCCESS } from "../../constants/adminConstants";
const initialState = {
     loading: false,
     user: {},
     error: "",
     user_forgot_password : {},
     user_reset_password: {},
     admin: {},
     admins_docs: [],
     admins: [],
     role: {},
     success: false
  


}

export const adminLoginReducer = (state=initialState, action ) => {
    switch(action.type){
        case LOGIN_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case LOGIN_ADMIN_SUCCESS: 
            return {
                ...state,
                loading: false,
                user: action.payload,
                success: true
            }
        case LOGIN_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            }
        case ADMIN_LOGOUT:
                return {};
        default:
            return state

    }
}


export const adminForgotPasswordReducer = (state=initialState, action ) => {
    switch(action.type){
        case ADMIN_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADMIN_FORGOT_PASSWORD_SUCCESS: 
            return {
                ...state,
                loading: false,
                user_forgot_password: action.payload,
                success: true
            }
        case ADMIN_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            }
        default:
            return state

    }
}


export const adminResetPasswordReducer = (state=initialState, action ) => {
    switch(action.type){
        case ADMIN_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADMIN_RESET_PASSWORD_SUCCESS: 
            return {
                ...state,
                loading: false,
                user_reset_password: action.payload,
                success: true
            }
        case ADMIN_RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading_reset_password: false,
                reset_password_error: action.payload,
                success: false
            }
        default:
            return state

    }
}



export const CreateAdminReducer = (state=initialState, action) => {
    switch(action.type){
        case CREATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                admins_docs: state.admins_docs.concat(action.payload),
                loading: false,
                success: true
            }
        case CREATE_ADMIN_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: false

            }
        default:
            return state
    }
}

export const AdminFetchReducer = (state=initialState, action) => {
      switch(action.type){
          case FETCH_ADMIN_REQUEST:
              return {
                  ...state,
                  loading: true
              }
          case FETCH_ADMIN_SUCCESS:
              return {
                  ...state,
                  admins: action.payload.results,
                  admins_docs: action.payload.results.docs,
                  success: true,
                  loading: false
              }
           case FETCH_ADMIN_FAIL:
               return {
                   ...state,
                   error: action.payload,
                   loading: false, 
                   success: false
               }
           default:
               return state
      }
}


export const GetAdminReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                admin: action.payload
            }
        case GET_ADMIN_FAIL:
            return {
                ...state,
                laoding: false,
                error: action.payload
            }
        default: 
            return state
    }
}


export const UpdateAdminReducer = (state=initialState, action) => {
      switch(action.type){
            case UPDATE_ADMIN_REQUEST:
                return {
                    ...state,
                   loading: true
                }
            case UPDATE_ADMIN_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    admin: action.payload,
                    success: true
                }
            case UPDATE_ADMIN_FAIL:
                return {
                    ...state,
                    loading: false,
                    success: false,
                    error: action.payload
                }
            default:
                return state;
      }
}


export const AdminDeleteReducer = (state=initialState, action) => {
      switch(action.type){
          case DELETE_ADMIN_REQUEST:
              return {
                  ...state,
                  loading: true
              }
          case DELETE_ADMIN_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  success: true,
                  admins_docs: state.admins_docs.filter((a) => a._id !== action.payload._id),
              }
          case DELETE_ADMIN_FAIL:
              return {
                  ...state,
                  loading: false,
                  success: false,
                  error: action.payload
              }
          default:
              return state
      }
}

export const UpdateAdminRoleReducer = (state=initialState, action) => {
    switch(action.type){
          case UPDATE_ADMIN_ROLE_REQUEST:
              return {
                  ...state,
                  loading: true
              }
          case UPDATE_ADMIN_ROLE_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  role: action.payload
              }
          case UPDATE_ADMIN_ROLE_FAIL:
              return {
                  ...state,
                  loading: false,
                  error: action.payload
              }
          default:
              return state;
    }
}
