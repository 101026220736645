import { GET_STORE_OWNERS_REQUEST, GET_STORE_OWNERS_SUCCESS,GET_STORE_OWNERS_FAIL, GET_BASIC_BUYER_REQUEST, GET_BASIC_BUYER_SUCCESS, GET_BASIC_BUYER_FAIL } from "../../constants/customerDBConstant";
const initialState = {
    loading_store_owners: false,
    loading_basic_buyer: false,
    store_owners: {},
    basic_buyer: {},
    basic_buyers_users_data: [],
    basic_buyers_users_data_docs: [],
    error_store_owners: "",
    basic_buyer_error: ""

}


export const StoreOwnersReducer = (state=initialState, action) => {
    switch(action.type){
        case GET_STORE_OWNERS_REQUEST:
            return {
                ...state,
                loading_store_owners: true
            }
        case GET_STORE_OWNERS_SUCCESS:
            return {
                ...state,
                loading_store_owners: false,
                store_owners: action.payload,
            }
        case GET_STORE_OWNERS_FAIL:
            return {
                ...state,
                loading_store_owners: false,
                error_store_owners: action.payload
            }
        default:
            return state
    }
}



export const BasicBuyerReducer = (state=initialState, action) => {
    switch(action.type){
        case GET_BASIC_BUYER_REQUEST: 
            return {
                ...state,
                loading_basic_buyer: true
            }
        case GET_BASIC_BUYER_SUCCESS:
            return {
                ...state,
                loading_basic_buyer: false,
                basic_buyer: action.payload,
                basic_buyers_users_data_docs: action.payload.user_table_data.docs,
                basic_buyers_users_data: action.payload.user_table_data
            }
        case GET_BASIC_BUYER_FAIL:
            return {
                ...state,
                loading_basic_buyer: false,
                basic_buyer_error: action.payload
            }
        default:
            return state
    }
}