export const ORDER_SEARCH_REQUEST = "ORDER_SEARCH_REQUEST";
export const ORDER_SEARCH_SUCCESS = "ORDER_SEARCH_SUCCESS";
export const ORDER_SEARCH_FAIL = "ORDER_SEARCH_FAIL";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";

export const USER_SEARCH_REQUEST = "USER_SEARCH_REQUEST";
export const USER_SEARCH_SUCCESS = "USER_SEARCH_SUCCESS";
export const USER_SEARCH_FAIL = "USER_SEARCH_FAIL";

export const STORE_SEARCH_REQUEST = "STORE_SEARCH_REQUEST";
export const STORE_SEARCH_SUCCESS = "STORE_SEARCH_SUCCESS";
export const STORE_SEARCH_FAIL = "STORE_SEARCH_FAIL"

export const CLEAR_PRODUCT_SEARCH = "CLEAR_PRODUCT_SEARCH";
export const CLEAR_USER_SEARCH = "CLEAR_USER_SEARCH";
export const CLEAR_ORDER_SEARCH = "CLEAR_ORDER_SEARCH";
export const CLEAR_STORE_SEARCH = "CLEAR_STORE_SEARCH";