import { GET_TODAYS_TRANSACTIONS_FAIL, GET_TODAYS_TRANSACTIONS_REQUEST, GET_TODAYS_TRANSACTIONS_SUCCESS } from "../../../constants/dashboard/todaysTransactionsConstant";

const initialState = {
    todays_transactions_error: "",
    todays_transactions_loading: false,
    todays_transactions: [],
    todays_transactions_docs: []
}


export const todaysTransactionsReducer = (state=initialState, action) => {
        const {type, payload} = action;
        switch(type){
            case GET_TODAYS_TRANSACTIONS_REQUEST:
                return {
                    ...state,
                    todays_transactions_loading: true
                }
            case GET_TODAYS_TRANSACTIONS_SUCCESS:
                return {
                    ...state,
                    todays_transactions_loading: false,
                    todays_transactions: payload,
                    todays_transactions_docs: payload.docs
                }
            case GET_TODAYS_TRANSACTIONS_FAIL:
                return {
                    ...state,
                    todays_transactions_loading: false,
                    todays_transactions_error: payload
                }
            default:
                return state
        }
}