import { GET_BASIC_BUYER_FAIL, GET_BASIC_BUYER_REQUEST, GET_BASIC_BUYER_SUCCESS, GET_STORE_OWNERS_FAIL, GET_STORE_OWNERS_REQUEST, GET_STORE_OWNERS_SUCCESS } from "../../constants/customerDBConstant";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token


export function StoreOwnersAction(){
    return async(dispatch,getState) => {
    dispatch({type: GET_STORE_OWNERS_REQUEST});
    
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/user/customer_data`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
            return dispatch({type: GET_STORE_OWNERS_FAIL, payload: data.message})
        }
        dispatch({type: GET_STORE_OWNERS_SUCCESS, payload: data})
    } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: GET_STORE_OWNERS_FAIL, payload: message})
    }
}
}


export function BasicBuyersAction({page,limit}){
    return async (dispatch, getState) => {
      dispatch({type: GET_BASIC_BUYER_REQUEST});
        try {

        const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/user/basic_user_data?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await res.json();
        if(data.error){
            return dispatch({type: GET_BASIC_BUYER_FAIL, payload: data.message})
        }
        dispatch({type: GET_BASIC_BUYER_SUCCESS, payload: data.results})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_BASIC_BUYER_FAIL, payload: message})
        }
    }
}