import { PAYMENT_GATEWAY_CREATE_REQUEST, PAYMENT_GATEWAY_CREATE_SUCCESS, PAYMENT_GATEWAY_CREATE_FAIL, PAYMENT_GATEWAY_LIST_REQUEST, PAYMENT_GATEWAY_LIST_SUCCESS, PAYMENT_GATEWAY_LIST_FAIL, GET_PAYMENT_GATEWAY_REQUEST, GET_PAYMENT_GATEWAY_SUCCESS, GET_PAYMENT_GATEWAY_FAIL, PAYMENT_GATEWAY_UPDATE_REQUEST, PAYMENT_GATEWAY_UPDATE_SUCCESS, PAYMENT_GATEWAY_UPDATE_FAIL, PAYMENT_GATEWAY_DELETE_REQUEST, PAYMENT_GATEWAY_DELETE_SUCCESS, PAYMENT_GATEWAY_DELETE_FAIL } from './../../constants/paymentConstant';
const initialState = {
    create_payment_gateway_loading : false,
    payment_list_loading: false,
    update_gateway_loading: false,
    get_gateway_loading: false,
    delete_gateway_loading: false,
    payment_gateway_create: {},
    payment_gateway: {},
    payment_gateways: [],
    error: {}
}

export const listPaymentGateway = (state=initialState, action) => {
     switch(action.type){
        case PAYMENT_GATEWAY_LIST_REQUEST: 
            return {
                     payment_list_loading: true
            }
        case PAYMENT_GATEWAY_LIST_SUCCESS:
            return {
                  ...state,
                  payment_list_loading : false,
                  payment_gateways : action.payload.results,
                  success: true
            }
        case PAYMENT_GATEWAY_LIST_FAIL: 
            return {
                 ...state,
                 payment_list_loading : false,
                 payment_list_error: action.payload,
                 success: false
            }
        default:
            return state

     }
}


export const createPaymentGateway = (state=initialState, action) => {
    
    switch(action.type){
        case PAYMENT_GATEWAY_CREATE_REQUEST:
            return {
                ...state,
                create_payment_gateway_loading: true
            }
        case PAYMENT_GATEWAY_CREATE_SUCCESS:
            return {
                ...state,
                create_payment_gateway_loading: false,
                payment_gateways: state.payment_gateways.concat(action.payload),
                success: true
            }
        case PAYMENT_GATEWAY_CREATE_FAIL:
            return {
                ...state,
                create_payment_gateway_loading: false,
                payment_gateway_error : action.payload,
                success: false
            }
        default:
            return state
    }
 }


export const getPaymentGateway = (state=initialState, action) => {
    switch(action.type){
        case GET_PAYMENT_GATEWAY_REQUEST: 
            return {
                ...state,
                get_gateway_loading: true
            }
        case GET_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                get_gateway_loading: false,
                payment_gateway : action.payload,
                success: true
            }
        case GET_PAYMENT_GATEWAY_FAIL:
            return {
                ...state,
                get_gateway_loading: false,
                get_payment_error: action.payload
            }
        default:
            return state
    }
} 


export const updatePaymentGateway = (state=initialState, action) => {
    switch(action.type){
        case PAYMENT_GATEWAY_UPDATE_REQUEST: 
            return {
                ...state,
                update_gateway_loading: true
            }
        case PAYMENT_GATEWAY_UPDATE_SUCCESS:
            return {
                ...state,
                update_gateway_loading: false,
                update_payment_gateway : action.payload,
                success: true
            }
        case PAYMENT_GATEWAY_UPDATE_FAIL:
            return {
                ...state,
                update_gateway_loading: false,
                update_gateway_error: action.payload,
                success: false
            }
        default:
            return state
    }
} 

export const paymentGatewayDelete = (state=initialState, action) => {
    switch(action.type){
        case PAYMENT_GATEWAY_DELETE_REQUEST: 
            return {
                ...state,
                delete_gateway_loading: true
            }
        case PAYMENT_GATEWAY_DELETE_SUCCESS:
            return {
                ...state,
                delete_gateway_loading: false,
                payment_gateways : state.payment_gateways.filter(f => f._id !== action.payload.results._id),
                success: true
            }
        case PAYMENT_GATEWAY_DELETE_FAIL:
            return {
                ...state,
                delete_gateway_loading: false,
                delete_gateway_error: action.payload,
                success: false
            }
        default:
            return state
    }
} 