import { useContext,useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "../merchants.css"
import ReportButton from "./ReportButton"
import MonthSort from "./MonthSort";
import SortedLineChart from "./SortedLineChart";
import ProductsCard from "./ProductsCard";
import { useDispatch,useSelector  } from 'react-redux';
import { GetBasicMerchant} from './../actions/Admin/GetMerchant';
import { GetClassMerchant } from './../actions/Admin/GetClassMerchant';
import Loaders from "./Reusables/Loader";
import { newOrdersAction } from "../actions/Admin/OrderByStatus";
import { SalesByCategoryAction } from "../actions/Admin/dashboard/SalesByCategoryAction";
import { AppContext } from "../AppContext";
import {AiTwotonePieChart } from "react-icons/ai";



const Merchants = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const {numberSeperator,nFormatter, handleActiveTabOrder,setActiveCustomerTab} =  useContext(AppContext)
     const {sales_by_category,sales_by_category_loading,sales_by_category_error} = useSelector(state => state.salesByCategoryReducer)
     const {basic_merchant_loading, basic_merchant, basic_merchant_error} = useSelector(state => state.getBasicMerchant)
     const {classed_merchant_loading, classed_merchant, classed_merchant_error} = useSelector(state => state.getClassedMerchant)
     const {new_orders} = useSelector(state => state.newOrders);


     const basic_results = basic_merchant && basic_merchant?.results;

     const getAllCustomersLength = basic_results && basic_results?.totalDocs;

     const classed_result = classed_merchant && classed_merchant?.results;

     const getAllSellersLength = classed_result && classed_result.totalDocs;
     const totalSales = sales_by_category && sales_by_category.results && sales_by_category.results.totalSales && sales_by_category.results.totalSales[0] && sales_by_category.results.totalSales[0].totalSales;
     const convertTotalToString = totalSales && String(totalSales);
     const totalSaleLength = convertTotalToString && convertTotalToString.length;

   useEffect(() => {
       dispatch(GetBasicMerchant())
   },[dispatch]);
   useEffect(() => {
       dispatch(GetClassMerchant())
   }, [dispatch]);
   useEffect(() => {
    const offset = 1;
    const limit = 1;
    const data = {offset, limit}
    dispatch(newOrdersAction(data))
   }, [dispatch]);
   useEffect(() => {
       dispatch(SalesByCategoryAction())
   }, [dispatch])
   useEffect(() => {
    if(classed_merchant_error?.length > 0 && classed_merchant_error.includes("Invalid token")){
         history.push("/login")
    }
 }, [classed_merchant_error])

     return (
         <div>
             <div className="row">
                 <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12">
                                    <div className="card m-height">
                                        <div className="card-body">
                                            <div className="users_update">
                                                 <p>BASIC MERCHANT</p>
                                            </div>
                                            <div className="update_header">
                                               {basic_merchant_error && <p className="text-danger text-center" style={{fontSize: 14}}>{basic_merchant_error}</p>}
                                               {basic_merchant_loading ? <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={basic_merchant_loading} color="#000000" size={30} /></div> : <h3 className="up">{getAllCustomersLength}</h3>}
                                               
                                             </div>
                                             <div className="buttons" onClick={() => {history.push("/dashboard/customer-database"); setActiveCustomerTab(2)
                                            }}>
                                                    <button className="btn"><AiTwotonePieChart /> <span>View full report</span></button>
                                             </div>
                                           {/* <ReportButton url="/dashboard/customer-database"   onClick={() => setActiveCustomerTab(2)}/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12">   
                                    <div className="card m-height">
                                        <div className="card-body">
                                            <div className="users_update">
                                                 <p>CLASS MERCHANT</p>
                                            </div>
                                            <div className="update_header">
                                                {classed_merchant_error && <p className="text-danger text-center d-block" style={{fontSize: 14}}>{classed_merchant_error}</p>}
                                                {classed_merchant_loading ? <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={classed_merchant_loading} color="#000000" size={30} /></div> :  <h3 className="down">{getAllSellersLength}</h3>}
                                               
                                             </div>  
                                             <div className="buttons" onClick={() => {history.push("/dashboard/customer-database"); setActiveCustomerTab(1)
                                            }}>
                                                    <button className="btn"><AiTwotonePieChart /> <span>View full report</span></button>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 mt-4">
                                     <div className="card m-height">
                                        <div className="card-body">
                                            <div className="users_update">
                                                <p>NEW ORDERS</p>
                                            </div>
                                            <div className="update_header">
                                                <h3 className="down">{new_orders && new_orders.totalDocs}</h3>
                                             </div>
                                             <div className="buttons" onClick={() => {history.push("/dashboard/orders"); handleActiveTabOrder(2)}}>
                                               <button className="btn"><AiTwotonePieChart /> <span>View full report</span></button>
                                             </div>
                                     
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 mt-4">
                                     <div className="card m-height">
                                        <div className="card-body">
                                           <div className="users_update">
                                                <p>TOTAL TRANSACTIONS</p>
                                            </div>
                                            <div className="update_header">
                                            {sales_by_category_error && <p className="text-danger text-center d-block" style={{fontSize: 14}}>{sales_by_category_error.message}</p>}
                                                {sales_by_category_loading ? <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={classed_merchant_loading} color="#000000" size={30} /></div>:   <h3 className="up">&#8358;{totalSaleLength > 7 ? numberSeperator(nFormatter(totalSales)) : numberSeperator(totalSales)}</h3>}
                                              
                                             </div>       
                                           <ReportButton url="/dashboard/transactions"/>
                                        </div>
                                     </div>
                                </div>
                    </div>
                 </div>
                 <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                    <div className="card" style={{width: "100%", maxHeight: "350px"}}>
                        <div className="card__header">REVENUE CHART</div>
                        <div className="card-body">
                            <div className="card__title_revenue"><span>Sort by</span> <MonthSort /></div>
                             <SortedLineChart/>
                        </div>
                    </div>
                 </div>
             </div>
             <ProductsCard />
         </div>
     )
}

export default Merchants