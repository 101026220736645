import { useEffect } from "react";
import { useSelector } from "react-redux";
// import Chart from "react-apexcharts"
import useGenerateColor from "../../helpers/useGenerateColor";
import { useRecentOrderQuery } from "../../actions/Admin/sales/RecentOrdersAction";
import { handleAlphaError } from "../../utils/handleAlphaError";
import Loaders from "../Reusables/Loader";
import {
    Chart as ChartJs,
    ArcElement,
    Tooltip,
    Legend
} from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJs.register(
    ArcElement,
    Tooltip,
    Legend
)
const DoughnutChart = () => {
    const {color, colorRange} = useGenerateColor();
    const {isError, error, data, isLoading} = useRecentOrderQuery({page: 1, limit: 10})
    // const {list_recent_orders_doughnut_data} = useSelector(state => state.recentOrdersReducer);

    const statusOverview = data && data.results && data.results.doughnutData && data.results.doughnutData.statusOverview;
    const statusLength = statusOverview && statusOverview.length
    
    const errorMessage = handleAlphaError(isError,error, "data");
   

    // const doughnutChartData = list_recent_orders_doughnut_data && list_recent_orders_doughnut_data.statusOverview;

    const getOrderStatusTotal = statusOverview && statusOverview.map(({total}) => {
        return total
    });
    const totalOrderByStatus = getOrderStatusTotal && getOrderStatusTotal.length > 0 && getOrderStatusTotal
    const getOrderStatus = statusOverview && statusOverview.map(({status}) => {
        return status
    }).filter((status) => status !== null);
    const getStatus = getOrderStatus && getOrderStatus.length > 0 && getOrderStatus

   const dataSet = {
       labels: getStatus,
       datasets: [
            {
                label: "Orders",
                data: totalOrderByStatus,
                backgroundColor: color,
                borderColor: color
            }
       ]
    }
    const options = {

        datalabels: {
            
            font: {
              weight: 'bold',
              size:10,
              family: 'poppins',
            },
          },
          responsive: true,
        
    }

  
    useEffect(() => {
        colorRange(statusLength)
    }, [statusLength, colorRange]);
 
    return (
        <div>
              
             { 
                isLoading ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>) :
                 isError ?
                  ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>) : 
                    // <Chart 
                    //     series={totalOrderByStatus}
                    //     options={options}
                   
                    // /> 
                    <div style={{maxWidth: "90%", height: "100%", margin: "auto"}}>
                        <Doughnut 
                            data={dataSet}
                            options={options}
                        />
                    </div>
                    
                }

        </div>
    )
}

export default DoughnutChart;