import "../merchants.css"
import {AiTwotonePieChart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const ReportButton = ({url}) => {
     const history = useHistory()
     return (
        <div className="buttons" onClick={() => {history.push(url)}}>
            <button className="btn"><AiTwotonePieChart /> <span>View full report</span></button>
        </div>
     )
}

export default ReportButton