import React, { useContext, useEffect, useState } from 'react'
import {BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';
import moment from 'moment';
import ScaleLoader from "react-spinners/ScaleLoader";
import { CompleteRefund, RefundList } from '../../actions/Admin/RefundAction';
import { AppContext } from '../../AppContext';

const override = {
    margin: "0",
    borderColor: "none",
  };

const RefundListSection = ({itemId, setItemId}) => {
    const {itemLimit,setItemLimit, numberSeperator } = useContext(AppContext);
    const dispatch = useDispatch();
    const [color] = useState("#72FFC9");

    const {refund_list, refund_list_docs,refund_list_error} = useSelector(state => state.refundList);
    const { refund_complete_loading, refund_complete_success} = useSelector(state => state.refundComplete);
    useEffect(() => {
        const offset = 1;
        const limit = +itemLimit;
        const data = {offset, limit}
       dispatch(RefundList(data))
       
  }, [dispatch,itemLimit]);


  const handleNextPage = (page_number) => {
    const offset = page_number;
    const limit = +itemLimit;
    const data = { offset, limit };
    dispatch(RefundList(data));
    
};

useEffect(() => {
    if(itemId !== ""){
        dispatch(CompleteRefund({item_id: itemId}))
    }
}, [dispatch, itemId]);

// useEffect(() => {
//     const timer = setTimeout(() => {
//         if(refund_complete_success){
//             dispatch(clearRefundState())
//         }
//     }, 1500);
//     return clearTimeout(timer)
// }, [refund_complete_success])
const totalPages = refund_list && refund_list.totalPages;
const page = refund_list && refund_list.page;
const prevPage = refund_list && refund_list.prevPage;
const nextPage = refund_list && refund_list.nextPage;
const hasNextPage = refund_list && refund_list.hasNextPage;
const hasPrevPage = refund_list && refund_list.hasPrevPage;
  return (
    <>
          {refund_list_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{refund_list_error || refund_list_error.message }</p>
                </div>
            }
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                    <div className="header_order_title">REFUND REQUESTS</div>
                </div>
                <div className="card-body">
                {
             refund_list_docs &&  refund_list_docs?.length > 0 ? 
                    <div className="table-responsive" >
                        <table className="table seller_table" align="middle">

                        <thead className="tborder table-header" align="middle">
                            <tr className="align_student_table">
                                <th scope="col" className="header__name"></th>
                                <th scope="col" className="header__name">Name</th>
                                <th scope="col" className="header__status">Emaill Address</th>
                                <th scope="col" className="header__userId">Amount Paid</th>
                                <th scope="col" className="header_phone">Account No.</th>
                                <th scope="col" className="header_email">Bank Name</th>
                                <th scope="col">Date/Time</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                
                                <tbody align="middle">
                                {
                               refund_list_docs &&  refund_list_docs.map((refund, index) => (
                                    <tr key={refund._id}>
                                        <td>{index + 1}.</td>
                                        <td className="item_status">{refund.full_name || "User"}</td>
                                        <td  className="item_email email-underline"><a href={`mailto:${refund.email}`}>{refund.email}</a></td>
                                        {
                                            
                                            refund && refund.amount === undefined ? <td className="item_userId">0</td> : <td className="item_userId">&#x20A6; {numberSeperator(refund.amount)}</td>
                                        }
                                        
                                        <td className="item_phone">{refund.account_number}</td>
                                        <td className="item_phone">{refund.bank_name}</td>
                                        <td className="item_phone item_date">{moment(refund.createdAt).format("DD/MM/YYYY, h:mm a")}</td>
                                        {
                                   
                                        refund.status === "complete" ? <td className="save-icon complete-icon"><GiCheckMark  className="saved-check"/><span>{refund.status}</span></td>: <td className="item item_proceed"><button onClick={() => setItemId(refund.item_id)}>{refund_complete_loading && itemId === refund.item_id ? (<div className="save-icon"><span><ScaleLoader color={color} loading={refund_complete_loading} cssOverride={override} size={20} /></span></div>) : "Process Payment"}</button></td>
                                   }
                                    
                                    </tr>
                                     ))
                                    }
                     </tbody> 
            </table>
            
            </div>
            :  <h2 className="text-center no-record-found mt-4">No Records Found</h2>
        }
        </div>
            <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                <div className="page_number">{refund_list_docs && refund_list_docs.length > 1 ? "Items" : "Item"} per page {" "}{" "}
                    <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                </div>
                {totalPages > 1 ?
                <div className="paginate_reuse">
                    <button className="left-chevron" onClick={() => {hasPrevPage && handleNextPage(prevPage);}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {hasNextPage && handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                </div> : null
                }
            </div>   
        </div>
        </>
  )
}

export default RefundListSection