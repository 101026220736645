import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout} from 'antd';
import {IoMdSettings} from "react-icons/io"
import {FaSearch, FaEnvelope, FaBell, FaCaretDown, FaCaretUp, FaTimes} from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from "../AppContext";
import "./header.css"
import { clearProductSearchState } from "../actions/Admin/search/SearchAction";

const { Header } = Layout;
const Headers = () => {
    const dispatch = useDispatch(null)
    const {first_name, handleNotificationPanel, notificationOpen, handleToggle, toggleDropDown, searchTerm, setSearchTerm, inputElem} = useContext(AppContext);
    const {product_search_docs} = useSelector(state => state.productSearchReducer);
    const {user_search_docs} = useSelector(state => state.userSearchReducer);
    const {order_search_docs} = useSelector(state => state.orderSearchReducer);
   
    const history = useHistory(null)
    const originalUrl = window.location.pathname;
useEffect(() => {
    if((product_search_docs?.length > 0) || (user_search_docs?.length > 0) || (order_search_docs?.length > 0)){
        dispatch(clearProductSearchState())
    }
}, [dispatch,product_search_docs,user_search_docs,order_search_docs])

 
    return (
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }} >
            
                <div className="search__input">
                    <div className="main_form">
                        <div className="form-group has-search">
                            <span className="form-control-feedback"><FaSearch /></span>
                        <input type="text" className="form-control" placeholder="Search for an item..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} ref={inputElem}/>
                         {searchTerm && <span className="clear-search" onClick={() => {setSearchTerm("")}}><FaTimes /></span>}
                        </div>
                   </div>
                    <div className="user_note">
                        <span className={originalUrl.includes("/dashboard/settings") ? "settings-active active" : "setting-page-link"} onClick={() => {history.push(`/dashboard/settings`)}}>
                            <IoMdSettings />
                        </span>
                        <span className="icon"><FaEnvelope /></span>
                        <span className="icon" onClick={handleNotificationPanel}><FaBell className={notificationOpen ? "notification_icon_active" : ""}/></span>
                        <span className="profile_image icon"><img src="/default_avatar.png" alt="profile_image"/></span>
                        <span className="username icon" onClick={handleToggle}>Hey, {first_name}!{toggleDropDown ? <FaCaretUp className="caret_btn"/>: <FaCaretDown className="caret_btn"/>}</span>
                    </div>
                </div>
         </Header>
    )
}

export default Headers