import { CLEAR_COMPLETE_REFUND, COMPLETE_REFUND_FAIL, COMPLETE_REFUND_REQUEST, COMPLETE_REFUND_SUCCESS, REFUND_LIST_FAIL, REFUND_LIST_REQUEST, REFUND_LIST_SUCCESS, RETURN_LIST_FAIL, RETURN_LIST_REQUEST, RETURN_LIST_SUCCESS } from "../../../constants/refundConstant"

const initialState = {
    loading_refund: false,
    refund_complete_loading: false,
    loading_return: false,
    refund_list: [],
    refund_list_docs: [],
    refund_complete: {},
    refund_complete_success: false,
    refund_list_error: "",
    refund_complete_error: ""

}
const returnInitialState = {
     loading_return: false,
     return_list: [],
     return_list_docs: [],
     return_list_error: ""
}


export const refundList = (state=initialState, action) => {
    switch(action.type){
         case REFUND_LIST_REQUEST:
            return {
                ...state,
                loading_refund: true
            }
         case REFUND_LIST_SUCCESS: 
            return {
                ...state,
                loading_refund: false,
                refund_list: action.payload,
                refund_list_docs: action.payload?.docs
            }
         case REFUND_LIST_FAIL: 
            return {
                ...state,
                loading_refund: false,
                refund_list_error: action.payload,
            }
         default:
            return state
    }
}

export const refundComplete = (state=initialState, action) => {
      const {type, payload} = action;
      switch(type){
          case COMPLETE_REFUND_REQUEST:
              return {
                   ...state,
                   refund_complete_loading: true
              }
          case COMPLETE_REFUND_SUCCESS:
              return {
                  ...state,
                  refund_complete_loading: false,
                  refund_complete: payload,
                  refund_complete_success:true
              }
          case COMPLETE_REFUND_FAIL:
              return {
                   ...state,
                   refund_complete_loading: false,
                   refund_complete_error: payload,
                   refund_complete_success: false 
              }
          case CLEAR_COMPLETE_REFUND:
              return {
                   ...state,
                   refund_complete_error: "",
                   refund_complete_loading: false,
                   refund_complete_success: false
              }
            default:
                return state
      }
}


export const returnList = (state=returnInitialState, action) => {
     const {type, payload} = action;
     switch(type){
         case RETURN_LIST_REQUEST:
            return {
                 ...state,
                 loading_return: true
            }
         case RETURN_LIST_SUCCESS:
            return {
                ...state,
                loading_return: false,
                return_list: payload,
                return_list_docs: payload?.docs
            }
         case RETURN_LIST_FAIL:
            return {
                ...state,
                loading_return: false,
                return_list_error: payload
            }
         default:
            return state
     }
}