export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILED = "ORDER_LIST_FAILED";

export const ORDER_GET_REQUEST = "ORDER_GET_REQUEST";
export const ORDER_GET_SUCCESS = "ORDER_GET_SUCCESS";
export const ORDER_GET_FAILED = "ORDER_GET_FAILED";


export const NEW_ORDERS_REQUEST = "NEW_ORDERS_REQUEST";
export const NEW_ORDERS_SUCCESS = "NEW_ORDERS_SUCCESS";
export const NEW_ORDERS_FAILED = "NEW_ORDERS_FAILED";

export const PROCESSING_ORDERS_REQUEST = "PROCESSING_ORDERS_REQUEST";
export const PROCESSING_ORDERS_SUCCESS = "PROCESSING_ORDERS_SUCCESS";
export const PROCESSING_ORDERS_FAILED = "PROCESSING_ORDERS_FAILED";

export const SHIPPED_ORDERS_REQUEST = "SHIPPED_ORDERS_REQUEST";
export const SHIPPED_ORDERS_SUCCESS = "SHIPPED_ORDERS_SUCCESS";
export const SHIPPED_ORDERS_FAILED = "SHIPPED_ORDERS_FAILED";

export const DELIVERED_ORDERS_REQUEST = "DELIVERED_ORDERS_REQUEST";
export const DELIVERED_ORDERS_SUCCESS = "DELIVERED_ORDERS_SUCCESS";
export const DELIVERED_ORDERS_FAILED = "DELIVERED_ORDERS_FAILED";

export const DECLINED_ORDERS_REQUEST = "DECLINED_ORDERS_REQUEST";
export const DECLINED_ORDERS_SUCCESS = "DECLINED_ORDERS_SUCCESS";
export const DECLINED_ORDERS_FAILED = "DECLINED_ORDERS_FAILED";

export const PENDING_PAYMENT_REQUEST = "PENDING_PAYMENT_REQUEST";
export const PENDING_PAYMENT_SUCCESS = "PENDING_PAYMENT_SUCCESS";
export const PENDING_PAYMENT_FAIL = "PENDING_PAYMENT_FAIL";

export const VERIFY_PENDING_ORDER_REQUEST = "VERIFY_PENDING_ORDER_REQUEST";
export const VERIFY_PENDING_ORDER_SUCCESS = "VERIFY_PENDING_ORDER_SUCCESS";
export const VERIFY_PENDING_ORDER_FAIL = "VERIFY_PENDING_ORDER_FAIL";

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAIL = "UPDATE_ORDER_STATUS_FAIL";

export const CLEAR_PENDING_ORDER = "CLEAR_PENDING_ORDER";
export const CLEAR_UPDATE_ORDER_STATUS = "CLEAR_UPDATE_ORDER_STATUS"


