import { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TransactionList } from '../../actions/Admin/TransactionAction';
import { AppContext } from '../../AppContext';
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import moment from "moment";
import "./transaction.css";
const AllTransaction = () => {
    const {transactions_list,transactions_list_docs,transaction_error} = useSelector(state => state.transactionList);
    const dispatch = useDispatch(null);
    const {itemLimit,setItemLimit, numberSeperator, nFormatter } = useContext(AppContext);
    
    useEffect(() => {
        const offset = 1;
        const limit = itemLimit;
        const data = {offset, limit}
       dispatch(TransactionList(data))
       
  }, [dispatch, itemLimit]);
  
  const handleNextPage = (page_number) => {
    const offset = page_number;
    const limit = +itemLimit;
    const data = { offset, limit };
    dispatch(TransactionList(data));
    
};
const totalPages = transactions_list && transactions_list.totalPages;
const page = transactions_list && transactions_list.page;
const prevPage = transactions_list && transactions_list.prevPage;
const nextPage = transactions_list && transactions_list.nextPage;

    return (
        <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading">
                    <div className="header_order_title ">TRANSACTION</div>
                </div>
                {transaction_error && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className="mb-0">{transaction_error || transaction_error.message }</p>
                </div>
            }
            {
                transactions_list_docs && transactions_list_docs.length > 0 ? 
                <div className="table-responsive">
                <table className="table seller_table">

                  
                <thead className="tborder table-header" align="middle">
                  <tr className="align_student_table">
                      <th scope="col"  className="header___name">Payment method</th>
                      <th scope="col" className="header___status">Ref. code</th>
                      <th scope="col" className="header__userId">Date</th>
                      <th scope="col" className="header_phone">Amount</th>
                      <th scope="col" className="header_email">Payment Title</th>
                      <th scope="col" className="header__orders">Status</th>
                      
                  </tr>
                </thead>
  
                  <tbody align="middle">
                     {
                        transactions_list_docs && transactions_list_docs.map(transactions => (
                            <tr className="transactions-item" key={transactions._id}>
                            <td className="item">{transactions.payment_gateway}</td>
                            <td className="item">{transactions.txnRef}</td>
                            <td className="item">{moment(transactions.createdAt).format("D/MM/YYYY")}</td>
                            <td className="item">&#x20A6;{transactions.amount && transactions.amount.length > 7 ? nFormatter(transactions.amount) : numberSeperator(transactions.amount)}</td>
                            <td  className="item">{transactions.title !== "" ? transactions.title : "-"}</td>
                            <td className={`item ${transactions.status === "complete" ? "paid-item" : "unpaid-item"}`}>{transactions.status}</td>
                           
                        </tr>
                        ))
                     }
                    
                  </tbody>
              </table>
              </div> : <h2 className="text-center no-record-found">No Records Found</h2>
            }
               
                  <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                               
                               <div className="page_number">Item per page {" "}{" "}
                                   <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                               </div>
                           { totalPages > 1 ? 
                               <div className="paginate_reuse">
                                   <button className="left-chevron" onClick={() => {handleNextPage(prevPage)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                               </div>: null
                           }
                           </div>  
        </div>
    )
}

export default AllTransaction



