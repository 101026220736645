export const LIST_STORE_REQUEST = "LIST_STORE_REQUEST";
export const LIST_STORE_SUCCESS = "LIST_STORE_SUCCESS";
export const LIST_STORE_FAILED = "LIST_STORE_FAILED";

export const GET_STORE_REQUEST = "GET_STORE_REQUEST";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAILED = "GET_STORE_FAILED";

export const STORE_OWNER_LIST_REQUEST = "STORE_OWNER_LIST_REQUEST";
export const STORE_OWNER_LIST_SUCCESS = "STORE_OWNER_LIST_SUCCESS";
export const STORE_OWNER_LIST_FAIL = "STORE_OWNER_LIST_FAIL";


