import { GET_TOP_SELLING_PRODUCT_FAIL, GET_TOP_SELLING_PRODUCT_REQUEST, GET_TOP_SELLING_PRODUCT_SUCCESS } from "../../../constants/dashboard/topSellingProductConstant";

const initialState = {
    top_selling_loading: false,
    top_selling_product: [],
    top_selling_product_docs: [],
    top_selling_product_error: ""
}


export const topSellingProductReducer = (state=initialState, action) => {
       const {type, payload} = action;
       switch(type){
          case GET_TOP_SELLING_PRODUCT_REQUEST:
              return {
                    ...state,
                    top_selling_loading: true
              }
          case GET_TOP_SELLING_PRODUCT_SUCCESS:
              return {
                   ...state,
                   top_selling_product: payload,
                   top_selling_product_docs: payload.docs,
                   top_selling_loading: false
              }
          case GET_TOP_SELLING_PRODUCT_FAIL:
              return {
                  ...state,
                  top_selling_loading: false,
                  top_selling_product_error: payload
              }
           default:
               return state
       }
}