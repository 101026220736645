import React from 'react';
import moment from "moment"
import { FaCheck } from 'react-icons/fa';
import "./tracker.css";

const Tracker = ({tracked_data, trackOrder, tracked_error}) => {
    const getTrackingStatus = tracked_data && tracked_data.results && tracked_data.results.Object && tracked_data.results.Object.MobileShipmentTrackings;
    const newTrackinStatus = getTrackingStatus && [...getTrackingStatus].reverse();
    const checkDelivered = getTrackingStatus && getTrackingStatus.at(0)
  return (
    <div>
      
  {
      tracked_data && Object.keys(tracked_data).length > 0 && ( trackOrder !== "" ||  trackOrder !== undefined) ?
    <div className="container tracker-section">
            
    <div className="item-tracking-layout">
        
        <section className="track-img">
   
            <div className="track-item track-progress">
                   
                
                <div className="delivery__track--progress-desktop">
                    <nav className="nav__progress-desktop">
                        <ul className='step__wizard-list'>
                            {
                                newTrackinStatus && newTrackinStatus.map((item, index) => (
                                    <li className="step__wizard-item" key={`${item.Status}-${index}`}>
                                        <div className="progress-count"><FaCheck /></div>
                                        <div className="progress-label">
                                            <span>{`${item?.ScanStatus?.Incident} ${item?.Location === null ? "" :  `(${item?.Location})`}`}</span><span>{moment(item?.DateTime).format("ddd, Do MMM YYYY") }</span>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                checkDelivered && checkDelivered?.ScanStatus?.Code === "OKC"  && checkDelivered?.Status === "OKC" ? null :
                                checkDelivered?.ScanStatus?.Code === "OKT" || checkDelivered?.Status === "OKT" ? null : checkDelivered?.ScanStatus?.Code === "MAHD" || checkDelivered?.Status === "MAHD" ? null : checkDelivered?.ScanStatus?.Code === "SRC" || checkDelivered?.Status === "SRC" ? null :  checkDelivered?.ScanStatus?.Code === "SSC" || checkDelivered?.Status === "SSC" ? null : checkDelivered?.ScanStatus?.Code === "DLP" || checkDelivered?.Status === "DLP" ? null :
                                <li className="step__wizard-item alpha__tracker-inprogress">
                                <div className="progress-count"></div>
                                <div className="progress-label">
                                      <span>In progress</span>
                                </div>
                          
                               </li>
                            }
                            
                        </ul>
                    </nav>
            </div>
        </div>
            
        </section>
    </div>
   
</div> :  tracked_error && !tracked_data ? <p>{tracked_error?.message} </p> : null
  }
  

</div>
  )
}

export default Tracker