import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreateUploadCriteria, ListUploadCriteria } from "../../../actions/Admin/products/Products";
import ScaleLoader from "react-spinners/ScaleLoader";
import { clearComplianceState } from './../../../actions/Admin/products/Products';
const override = {
    margin: "0",
    borderColor: "none",
  };
const AddChecklist = () => {
    const [color] = useState("#72FFC9");
    const [name, setName] = useState("");
    const [toolTip, setToolTip] = useState("");
    const [msg, setMsg] = useState("");
    const dispatch = useDispatch(null);
    const history = useHistory()
    const {create_upload_criteria_loading, create_upload_criteria_error, create_upload_criteria_success} = useSelector(state => state.createUploadCriteriaReducer);
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!name || !toolTip){
            setMsg("Fields must not be empty!");
            return
        }else {
            dispatch(CreateUploadCriteria({name: name.trim(), tooltip: toolTip.trim()}))
        }
    }

    const clearCompliance = () => {
         if(create_upload_criteria_error || create_upload_criteria_success){
            dispatch(ListUploadCriteria())
            dispatch(clearComplianceState());
         }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
             if(create_upload_criteria_error || msg){
                 dispatch(clearComplianceState());
                 setMsg("")
             }
        }, 2000)
        return () => clearTimeout(timeout)
   }, [create_upload_criteria_error, msg])
    
    return (
        <div className="alpha-checklist">
        <div className="payment_modal">
            <div className="card payment_add_method">
            <div className="data_heading">
                <div className="header_order_title type_header">ADD NEW PRODUCT UPLOAD CRITERIA</div>
            </div>
           <div className="card-body">
                <form className="add_payment_form" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="category" className="payment_label">Enter Criteria</label>
                        <input type="text" className="payment_input" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                        <label htmlFor="category" className="payment_label">Add Description</label>
                        <textarea type="text" className="payment_input" value={toolTip} onChange={(e) => setToolTip(e.target.value)}></textarea>
                    </div>
                    </div>
                    {msg && <p className="text-danger text-center my-2">{msg}</p>}
                    {create_upload_criteria_error && <p className="text-danger text-center my-2">{create_upload_criteria_error}</p>}
                    <div className="cta_btn">
                        {
                              create_upload_criteria_loading  ? <div className="save-icon"><span><ScaleLoader color={color} loading={create_upload_criteria_loading} cssOverride={override} size={20} /></span></div> : <button className="type_button">SAVE</button>
                        }
                    
                    <button className="type_cancel_button" onClick={() => {clearCompliance(); history.push("/dashboard/settings/product/checklists")}}>{create_upload_criteria_success ? "CLOSE" : "CANCEL"}</button>
                    </div>
                </form>
            </div>
      </div>
      </div>
      <div className="alpha-overflow" onClick={() => {history.push("/dashboard/settings/product/checklists")}}></div>
</div>
    )
}


export default AddChecklist