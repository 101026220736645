export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";

export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";


export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAIL = "LOGIN_ADMIN_FAIL";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";


export const UPDATE_ADMIN_ROLE_REQUEST = "UPDATE_ADMIN_ROLE_REQUEST";
export const UPDATE_ADMIN_ROLE_SUCCESS = "UPDATE_ADMIN_ROLE_SUCCESS";
export const UPDATE_ADMIN_ROLE_FAIL = "UPDATE_ADMIN_ROLE_FAIL";


export const ADMIN_FORGOT_PASSWORD_REQUEST = "ADMIN_FORGOT_PASSWORD_REQUEST";
export const ADMIN_FORGOT_PASSWORD_SUCCESS = "ADMIN_FORGOT_PASSWORD_SUCCESS";
export const ADMIN_FORGOT_PASSWORD_FAIL = "ADMIN_FORGOT_PASSWORD_FAIL";

export const ADMIN_RESET_PASSWORD_REQUEST = "ADMIN_RESET_PASSWORD_REQUEST";
export const ADMIN_RESET_PASSWORD_SUCCESS = "ADMIN_RESET_PASSWORD_SUCCESS";
export const ADMIN_RESET_PASSWORD_FAIL = "ADMIN_RESET_PASSWORD_FAIL";

export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
