import { login } from "../../component/AuthPage/Protected/auth";
import { ADMIN_FORGOT_PASSWORD_FAIL, ADMIN_FORGOT_PASSWORD_REQUEST, ADMIN_FORGOT_PASSWORD_SUCCESS, ADMIN_LOGOUT, ADMIN_RESET_PASSWORD_FAIL, ADMIN_RESET_PASSWORD_REQUEST, ADMIN_RESET_PASSWORD_SUCCESS, CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, DELETE_ADMIN_FAIL, DELETE_ADMIN_REQUEST, DELETE_ADMIN_SUCCESS, FETCH_ADMIN_FAIL, FETCH_ADMIN_REQUEST, FETCH_ADMIN_SUCCESS, GET_ADMIN_FAIL, GET_ADMIN_REQUEST, GET_ADMIN_SUCCESS, LOGIN_ADMIN_FAIL, LOGIN_ADMIN_REQUEST, LOGIN_ADMIN_SUCCESS, UPDATE_ADMIN_FAIL, UPDATE_ADMIN_REQUEST, UPDATE_ADMIN_ROLE_FAIL, UPDATE_ADMIN_ROLE_REQUEST, UPDATE_ADMIN_ROLE_SUCCESS, UPDATE_ADMIN_SUCCESS } from "../../constants/adminConstants"
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token
export function adminLoginAction(dataToSubmit){
    return async dispatch => {
      dispatch({type: LOGIN_ADMIN_REQUEST});
      
        try {
          const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ACCEPT: "application/json"

          },
          body: JSON.stringify(dataToSubmit)
      })
      const data = await res.json();
      if(data.error){
        
           dispatch({type: LOGIN_ADMIN_FAIL, payload: data.message})
      }else{
          login(data.results)
          dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: data });
      }
      
      } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
        dispatch({type: LOGIN_ADMIN_FAIL, payload: message}) 
      }
  }
}



export function adminForgotPassword(dataToSubmit){
     return async dispatch => {
          dispatch({type: ADMIN_FORGOT_PASSWORD_REQUEST});
            try {
              const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/forgot_password`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json"
    
                },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await res.json();
            if(data.error){
              dispatch({type: ADMIN_FORGOT_PASSWORD_FAIL, payload: data.message})
            }else {
              dispatch({ type: ADMIN_FORGOT_PASSWORD_SUCCESS, payload: data });
            }
            } catch (error) {
                const message =
                error.response && error.response.data
                  ? error.response.data
                  : error.message;
                dispatch({type: ADMIN_FORGOT_PASSWORD_FAIL, payload: message})
            }
          
     }
}



export function  adminResetPassword(dataToSubmit){
    return async dispatch => {
        dispatch({type: ADMIN_RESET_PASSWORD_REQUEST});
         try {
          const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/reset_password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "ACCEPT": "application/json"

            },
            body: JSON.stringify(dataToSubmit)
        });
        const data = await res.json();
        if(data.error){
            dispatch({type: ADMIN_RESET_PASSWORD_FAIL, payload: data.message})
        }else {
            dispatch({type: ADMIN_RESET_PASSWORD_SUCCESS, payload: data});
        }
         } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message; 
            dispatch({type: ADMIN_RESET_PASSWORD_FAIL, payload: message})
         }
    }
}




export function createAdmin(dataToSubmit){
    return async (dispatch, getState) => {
      dispatch({type: CREATE_ADMIN_REQUEST})
       try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/admin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
            "Authorization": `Bearer ${token}`

          },
          body: JSON.stringify(dataToSubmit)
      });
      const data = await res.json();
      if(data.error){
          dispatch({type: CREATE_ADMIN_FAIL, payload: data.message})
      }else {
          dispatch({type: CREATE_ADMIN_SUCCESS, payload: data});
      }
         
       } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: CREATE_ADMIN_FAIL, payload: message})
       }
    }
}


export function listAdmins({page, limit}){
      return async (dispatch, getState) => {
          dispatch({type: FETCH_ADMIN_REQUEST})
  
        try {
          const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/admins?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              ACCEPT: "application/json",
              "Authorization": `Bearer ${token}`
  
            }
        });
        const data = await res.json();
        if(data.error){
            dispatch({type: FETCH_ADMIN_FAIL, payload: data.message})
        }else {
            dispatch({type: FETCH_ADMIN_SUCCESS, payload: data});
        }
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: FETCH_ADMIN_FAIL, payload: message})
        }
      }
} 



export function getAdmin(adminId){
     return async (dispatch, getState) => {
      dispatch({type: GET_ADMIN_REQUEST})
       try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/admin/${adminId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
            "Authorization": `Bearer ${token}`

          }
      });
      const data = await res.json();
      if(data.error){
          dispatch({type: GET_ADMIN_FAIL, payload: data.message})
      }else {
          dispatch({type: GET_ADMIN_SUCCESS, payload: data});
      }
       } catch (error) {
        const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
          dispatch({type: GET_ADMIN_FAIL, payload: message})
       }
     }
}


export function updateAdminRole(dataToSubmit){
     return async (dispatch, getState) => {
      dispatch({type: UPDATE_ADMIN_ROLE_REQUEST})

        try {
          const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/role`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              ACCEPT: "application/json",
              "Authorization": `Bearer ${token}`
  
            },
            body: JSON.stringify(dataToSubmit)
         });
         const data = await res.json();
        if(data.error){
            dispatch({type: UPDATE_ADMIN_ROLE_FAIL, payload: data.message})
        }else {
            dispatch({type: UPDATE_ADMIN_ROLE_SUCCESS, payload: data});
        }
          
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: UPDATE_ADMIN_ROLE_FAIL, payload: message})
        }  
     }
}


export function updateAdmin(adminId,dataToSubmit){
     return async (dispatch, getState) => {
          dispatch({type: UPDATE_ADMIN_REQUEST})
  
          try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/admin/${adminId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
    
              },
              body: JSON.stringify(dataToSubmit)
           });
           const data = await res.json();
          if(data.error){
              dispatch({type: UPDATE_ADMIN_FAIL, payload: data.message})
          }else {
              dispatch({type: UPDATE_ADMIN_SUCCESS, payload: data});
          }
          } catch (error) {
                const message =
                error.response && error.response.data
                  ? error.response.data
                  : error.message;
                  dispatch({type: UPDATE_ADMIN_FAIL, payload: message})
              
          }
     }
}

export function deleteAdmin(adminId){
     return async (dispatch, getState) => {
          dispatch({type: DELETE_ADMIN_REQUEST})
  
          try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/auth/delete/${adminId}`, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
    
              }
           });
           const data = await res.json();
          if(data.error){
              dispatch({type: DELETE_ADMIN_FAIL, payload: data.message})
          }else {
              dispatch({type: DELETE_ADMIN_SUCCESS, payload: data});
          }
          } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: DELETE_ADMIN_FAIL, payload: message})
          }

     } 
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem("gigAdminInfo");
  dispatch({type: ADMIN_LOGOUT})
}