import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUploadCriteria, ListUploadCriteria, clearComplianceUpdate, GetUploadCriteria } from "../../../actions/Admin/products/Products";
import ScaleLoader from "react-spinners/ScaleLoader";


const override = {
    margin: "0",
    borderColor: "none",
  };
const EditCheckList = ({id}) => {
    const [color] = useState("#72FFC9");
    const [name, setName] = useState("");
    const [toolTip, setToolTip] = useState("");
    const [msg, setMsg] = useState("");
    const dispatch = useDispatch(null);
    const history = useHistory()
    const {get_criteria,  get_criteria_error,get_criteria_success} = useSelector(state => state.getComplianceCriteriaReducer);
    const {update_criteria_loading, update_criteria_error,update_criteria_success} = useSelector(state => state.updateComplianceCriteriaReducer);
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!name || !toolTip){
            setMsg("Fields must not be empty!");
            return
        }else {
            dispatch(UpdateUploadCriteria({id, name, tooltip: toolTip}))
        }
    }

    const clearCompliance = () => {
         if(update_criteria_error || update_criteria_success){
            dispatch(ListUploadCriteria())
            dispatch(clearComplianceUpdate());
         }
    }

    useEffect(() => {
        if(id){
           dispatch(GetUploadCriteria(id))
        }
    }, [id]);
    useEffect(() => {
        if(get_criteria_success && id && get_criteria){
            setName(get_criteria.name);
            setToolTip(get_criteria.tooltip)
        }
    }, [get_criteria_success, id,get_criteria])
    useEffect(() => {
        const timeout = setTimeout(() => {
             if(update_criteria_error || msg){
                dispatch(clearComplianceUpdate());
                 setMsg("")
             }
        }, 2000)
        return () => clearTimeout(timeout)
   }, [update_criteria_error, msg])
    
    return (
        <div className="alpha-checklist">
        <div className="payment_modal">
            <div className="card payment_add_method">
            <div className="data_heading">
                <div className="header_order_title type_header">UPDATE PRODUCT UPLOAD CRITERIA</div>
            </div>
           <div className="card-body">
                <form className="add_payment_form" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="category" className="payment_label">Update Criteria</label>
                        <input type="text" value={name} className="payment_input" onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                        <label htmlFor="category" className="payment_label">Update Description</label>
                        <textarea type="text" value={toolTip} className="payment_input" onChange={(e) => setToolTip(e.target.value)}></textarea>
                    </div>
                    </div>
                    {msg && <p className="text-danger text-center my-2">{msg}</p>}
                    {update_criteria_error && <p className="text-danger text-center my-2">{update_criteria_error}</p>}
                    {get_criteria_error && <p className="text-danger text-center my-2">{get_criteria_error}</p>}
                    <div className="cta_btn">
                        {
                              update_criteria_loading  ? <div className="save-icon"><span><ScaleLoader color={color} loading={update_criteria_loading} cssOverride={override} size={20} /></span></div> : <button className="type_button">SAVE</button>
                        }
                    
                    <button className="type_cancel_button" onClick={() => {clearCompliance(); history.push("/dashboard/settings/product/checklists")}}>{update_criteria_success ? "CLOSE" : "CANCEL"}</button>
                    </div>
                </form>
            </div>
      </div>
      </div>
      <div className="alpha-overflow" onClick={() => {history.push("/dashboard/settings/product/checklists")}}></div>
</div>
    )
}


export default EditCheckList