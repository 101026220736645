import { DECLINED_ORDERS_FAILED, DECLINED_ORDERS_REQUEST, DECLINED_ORDERS_SUCCESS, DELIVERED_ORDERS_FAILED, DELIVERED_ORDERS_REQUEST, DELIVERED_ORDERS_SUCCESS, NEW_ORDERS_FAILED, NEW_ORDERS_REQUEST, NEW_ORDERS_SUCCESS, PROCESSING_ORDERS_FAILED, PROCESSING_ORDERS_REQUEST, PROCESSING_ORDERS_SUCCESS, SHIPPED_ORDERS_FAILED, SHIPPED_ORDERS_REQUEST, SHIPPED_ORDERS_SUCCESS} from "../../../constants/orderConstants"

const initialState = {
    order_loading: false,
    order_success: false,
    new_orders_docs: [],
    processing_orders_doc: [],
    shipped_orders_doc: [],
    delivered_orders_doc: [],
    declined_orders_doc: [],
    new_orders: [],
    processing_orders: [],
    shipped_orders: [],
    declined_orders: [],
    delivered_orders: [],
    new_order: {},
    processing_order: {},
    shipped_order: {},
    delivered_order: {},
    declined_order: {},
    error: "",
}

export const newOrders = (state=initialState, action) => {
    switch(action.type){
       case NEW_ORDERS_REQUEST: 
           return {
                ...state,
                order_loading: true
           }
       case NEW_ORDERS_SUCCESS:
           return {
                 ...state,
                 order_loading: false,
                 order_success: true,
                 new_orders: action.payload,
                 new_orders_docs: action.payload.docs
           }
       case NEW_ORDERS_FAILED: 
           return {
                ...state,
                order_loading : false,
                order_success: false,
                error: action.payload
           }
       default:
           return state

    }
}

export const processingOrders = (state=initialState, action) => {
    switch(action.type){
       case PROCESSING_ORDERS_REQUEST: 
           return {
                ...state,
                order_loading: true
           }
       case PROCESSING_ORDERS_SUCCESS:
           return {
                 ...state,
                 order_loading: false,
                 order_success: true,
                 processing_orders: action.payload,
                 processing_orders_doc: action.payload.docs
           }
       case PROCESSING_ORDERS_FAILED: 
           return {
                ...state,
                order_loading : false,
                order_success: false,
                error: action.payload
           }
       default:
           return state

    }
}


export const shippedOrders = (state=initialState, action) => {
    switch(action.type){
       case SHIPPED_ORDERS_REQUEST: 
           return {
                ...state,
                order_loading: true
           }
       case SHIPPED_ORDERS_SUCCESS:
           return {
                 ...state,
                 order_loading: false,
                 order_success: true,
                 shipped_orders: action.payload,
                shipped_orders_doc: action.payload.docs
           }
       case SHIPPED_ORDERS_FAILED: 
           return {
                ...state,
                order_loading : false,
                order_success: false,
                error: action.payload
           }
       default:
           return state

    }
}


export const deliveredOrders = (state=initialState, action) => {
    switch(action.type){
       case DELIVERED_ORDERS_REQUEST: 
           return {
                ...state,
                order_loading: true
           }
       case DELIVERED_ORDERS_SUCCESS:
           return {
                 ...state,
                 order_loading: false,
                 order_success: true,
                 delivered_orders: action.payload,
                 delivered_orders_doc: action.payload.docs
           }
       case DELIVERED_ORDERS_FAILED: 
           return {
                ...state,
                order_loading : false,
                order_success: false,
                error: action.payload
           }
       default:
           return state

    }
}



export const declinedOrders = (state=initialState, action) => {
    switch(action.type){
       case DECLINED_ORDERS_REQUEST: 
           return {
                ...state,
                order_loading: true
           }
       case DECLINED_ORDERS_SUCCESS:
           return {
                 ...state,
                 order_loading: false,
                 order_success: true,
                 declined_orders: action.payload,
                 declined_orders_doc: action.payload.docs
           }
       case DECLINED_ORDERS_FAILED: 
           return {
                ...state,
                order_loading : false,
                order_success: false,
                error: action.payload
           }
       default:
           return state

    }
}

