
import { GET_ROLE_FAIL, GET_ROLE_REQUEST, GET_ROLE_SUCCESS, ROLE_CREATE_FAIL, ROLE_CREATE_REQUEST, ROLE_CREATE_SUCCESS, ROLE_DELETE_FAIL, ROLE_DELETE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_LIST_FAIL, ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS, ROLE_UPDATE_FAIL, ROLE_UPDATE_REQUEST, ROLE_UPDATE_SUCCESS } from "../../constants/roleConstant";

export const RoleCreateReducer = (state={}, action) => {
    switch(action.type){
        case ROLE_CREATE_REQUEST:
            return {
                loading: true
            }
        case ROLE_CREATE_SUCCESS:
            return {
                ...state,
                role: action.payload,
                loading: false
            }
        case ROLE_CREATE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state;

    }
}


export const RoleListReducer = (state={roles: []}, action) => {
    switch(action.type){
        case ROLE_LIST_REQUEST:
            return {
                loading: true
            }
        case ROLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }
        case ROLE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const GetRoleReducer = (state={}, action) => {
       switch(action.type){
            case GET_ROLE_REQUEST:
                return {
                    loading: true
                }
            case GET_ROLE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    role: action.payload
                }
            case GET_ROLE_FAIL:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            default:
                return state
       }
}

export const UpdateRoleReducer = (state={}, action) => {
       switch(action.type){
            case ROLE_UPDATE_REQUEST:
                return {
                    loading: true
                }
            case ROLE_UPDATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    role: action.payload
                }
            case ROLE_UPDATE_FAIL:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            default:
                return state
       }
}


export const DeleteRoleReducer = (state={}, action) => {
      switch(action.type){
         case ROLE_DELETE_REQUEST:
             return {
                 loading: true
             }

        case ROLE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                role: action.payload
            }
        case ROLE_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
      }
}