import {useState, useEffect, useContext} from "react"
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAdmin } from "../../actions/Admin/AdminAction";
import AllAdmins from "./AllAdmins";
import CreateRole from "./CreateRole";
import EditRole from "./EditAdmin";
import ListCategories from "./ListCategories"
import { AppContext } from './../../AppContext';
import "./admin.css";

const AdminRole = () => {

    const {showModal, openModal,setShowModal} = useContext(AppContext)
    const [activeTab, setActiveTab] = useState(0);
    const [adminRoleId, setAdminRoleId] = useState(null);
    const [name, setName] = useState("");
    const [categoryId, setCategoryId] = useState("");
 
    const dispatch = useDispatch();
    const history = useHistory();

    const {loading, error, admin} = useSelector(state => state.getAdmin)
    // const {loading: get_role_loading, error: get_role_error, role } = useSelector(state => state.getRole)
  
    const handleActiveTab = (tab) => {
        setActiveTab(tab)
    }
    const data = ["MANAGE ROLES", "CREATE ROLES"];
    const search = useLocation().search;
    const roleModal = new URLSearchParams(search).get("role");

    useEffect(() => {
        dispatch(getAdmin(adminRoleId))
    }, [dispatch, adminRoleId])
    return (
        <>
        <div className="admin_container">
             <ul>
                 {data.map(((data, index) => (
                     <li className={activeTab === index ? "tabs_active" : activeTab === 2 && data !==  "CREATE ROLES" ? "tabs_active" : activeTab === 3 && data !== "CREATE ROLES" ? "tabs_active" : "tab_color"} onClick={() => {handleActiveTab(index); history.push("/dashboard/roles")}} key={index}>{data}</li>
                 )))}
                
             </ul>
        </div>

        {activeTab === 0 && roleModal !== "edit-admin" && roleModal !== "list-roles" && <><AllAdmins handleActiveTab={handleActiveTab} setAdminRoleId={setAdminRoleId} /></>}
        {activeTab === 1 && roleModal !== "edit-admin" && roleModal !== "list-roles" && <CreateRole showModal={showModal} openModal={openModal} setShowModal={setShowModal}/>}
        {roleModal === "list-roles" && activeTab === 0 && <ListCategories handleActiveTab={handleActiveTab} setCategoryId={setCategoryId} categoryId={categoryId} name={name} setName={setName} openModal={openModal} showModal={showModal} setShowModal={setShowModal}/>}
        {roleModal === "edit-admin" && <EditRole handleActiveTab={handleActiveTab} loading={loading} error={error} admin={admin}/>}
        
        </>
    )
}


export default AdminRole;