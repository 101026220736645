import { FaTimes } from "react-icons/fa";
import { BsDownload } from "react-icons/bs"

const DownloadCSVModal = ({setShowDownloadModal}) => {
    return (
        <div className="download-csv-card">
            <div className="card csv_modal">
              <div className="data_heading download_csv">
                <div className="header_order_title csv_download_title">DOWNLOAD TRANSACTION STATEMENT</div>
                <div className="closebtn" onClick={() => setShowDownloadModal(false)}><FaTimes /></div>
              </div>
             <div className="card-body">
                 <div className="select-download-duration">
                     <div className="range-holder">
                        <div className="download-form1">
                            <label htmlFor="start-date">Start Date</label>
                            <input type="date" id="start-date"/>         
                        </div>    
                        <div className="download-form2">
                            <label htmlFor="end-date">End Date</label>
                            <input type="date" id="end-date"/>         
                        </div>  

                     </div> 
                     <div className="download-btn">
                         <button> <BsDownload /><span>Download Statement</span></button>    
                     </div> 
                 </div>   
             </div>   
              
                 
            </div>
        </div>
    )
}


export default DownloadCSVModal;