import { CREATE_COMPLIANCE_REQUEST, GET_PRODUCT_DETAILS_FAIL, GET_PRODUCT_DETAILS_REQUEST, GET_PRODUCT_DETAILS_SUCCESS, LIST_APPROVED_PRODUCT_FAIL, LIST_APPROVED_PRODUCT_REQUEST, LIST_APPROVED_PRODUCT_SUCCESS, LIST_DISAPPROVED_PRODUCT_REQUEST, LIST_DISAPPROVED_PRODUCT_SUCCESS, LIST_PRODUCT_FAIL, LIST_PRODUCT_REQUEST, LIST_PRODUCT_SUCCESS, NEW_PRODUCT_LIST_FAIL, NEW_PRODUCT_LIST_REQUEST, NEW_PRODUCT_LIST_SUCCESS, UPDATE_PRODUCT_VIEW_FAIL, UPDATE_PRODUCT_VIEW_REQUEST, UPDATE_PRODUCT_VIEW_SUCCESS, CREATE_COMPLIANCE_SUCCESS, CREATE_COMPLIANCE_FAIL, CLEAR_COMPLIANCE_STATE, LIST_COMPLIANCE_REQUEST, LIST_COMPLIANCE_SUCCESS, LIST_COMPLIANCE_FAIL, GET_COMPLIANCE_REQUEST, GET_COMPLIANCE_SUCCESS, GET_COMPLIANCE_FAIL, UPDATE_COMPLIANCE_REQUEST, UPDATE_COMPLIANCE_SUCCESS, UPDATE_COMPLIANCE_FAIL, CLEAR_COMPLIANCE_UPDATE, DELETE_COMPLIANCE_REQUEST, DELETE_COMPLIANCE_SUCCESS, DELETE_COMPLIANCE_FAIL, CLEAR_COMPLIANCE_DELETE, UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST, UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS, UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL, CLEAR_PRODUCT_COMPLIANCE_STATUS, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, CLEAR_DELETE_PRODUCT } from './../../../constants/product/productConstant';
const initialState = {
     list_loading: false,
     list_approved_loading: false,
     delete_product_loading: false,
     list_disapproved_loading: false,
     create_upload_criteria_loading: false,
     product_compliance_status_loading: false,
     list_upload_criteria_loading: false,
     get_criteria_loading: false,
     delete_criteria_loading: false,
     update_criteria_loading: false,
     list_new_loading: false,
     update_view_loading: false,
     get_prod_details_loading: false,
     update_view: {},
     product_compliance_status: {},
     list_products: {},
     get_prod_details: {},
     list_new_products: {},
     create_upload_criteria: {},
     list_approved_products: {},
     list_upload_criteria: {},
     list_disapproved_products: {},
     get_criteria: {},
     delete_criteria: {},
     update_criteria: {},
     list_products_docs: [],
     upload_criteria_docs: [],
     list_criteria_docs: [],
     list_approved_products_docs: [],
     list_disapproved_products_docs: [],
     list_new_products_docs: [],
     create_upload_criteria_success: false,
     product_compliance_status_success: false,
     update_view_success: false, 
     get_criteria_success: false,
     delete_criteria_success: false,
     delete_product_success: false,
     update_criteria_success: false,
     get_criteria_error: "",
     delete_criteria_error: "",
     update_criteria_error: "",
     list_error: "",
     delete_error: "",
     create_upload_criteria_error: "",
     list_upload_criteria_error: "",
     list_approved_error: "",
     list_disapproved_error: "",
     list_new_error: "",
     product_compliance_status_error: "",
     update_view_error: "",
     get_prod_details_error: "",
     all_products_length: 0,
     new_products_length: 0,
     approved_product_length: 0,
     disapproved_product_length: 0

}


export const listProductReducer = (state=initialState, action) => {
       const {type, payload} = action;
       switch(type){
           case LIST_PRODUCT_REQUEST:
              return {
                  ...state,
                  list_loading: true
              }
           case LIST_PRODUCT_SUCCESS:
              return {
                    ...state,
                    list_loading: false,
                    list_products: payload,
                    list_products_docs: payload.docs,
                    all_products_length: payload?.totalDocs
              }
            case LIST_PRODUCT_FAIL:
                return {
                    ...state,
                    list_loading: false,
                    list_error: payload
                }
            default:
                return state
       }
}

export const deleteProductReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case DELETE_PRODUCT_REQUEST:
           return {
               ...state,
               delete_product_loading: true
           }
        case DELETE_PRODUCT_SUCCESS:
           return {
                 ...state,
                 delete_product_loading: false,
                 list_products_docs: state?.list_products_docs.filter((a) => a._id !== action.payload._id),
                 delete_product_success: true
           }
         case DELETE_PRODUCT_FAIL:
             return {
                 ...state,
                 delete_product_loading: false,
                 delete_product_success: false,
                 delete_error: payload
             }
         case CLEAR_DELETE_PRODUCT: 
             return {
                ...state,
                delete_product_loading: false,
                delete_product_success: false,
                delete_error: ""
             }
         default:
             return state
    }
}


export const listApprovedProduct = (state=initialState, action) => {
      const {type, payload} = action;
      switch(type){
         case LIST_APPROVED_PRODUCT_REQUEST:
            return {
                ...state,
                list_approved_loading: true
            }
         case LIST_APPROVED_PRODUCT_SUCCESS:
            return {
                ...state,
                list_approved_loading: false,
                list_approved_products: payload,
                list_approved_products_docs: payload.docs,
                approved_product_length: payload?.totalDocs
            }
         case LIST_APPROVED_PRODUCT_FAIL:
            return {
                ...state,
                list_approved_loading: false,
                list_approved_error: payload
            }
         default:
            return state
      }
}

export const listDisApprovedProduct = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
       case LIST_DISAPPROVED_PRODUCT_REQUEST:
          return {
              ...state,
              list_disapproved_loading: true
          }
       case LIST_DISAPPROVED_PRODUCT_SUCCESS:
          return {
              ...state,
              list_disapproved_loading: false,
              list_disapproved_products: payload,
              list_disapproved_products_docs: payload.docs,
              disapproved_product_length: payload?.totalDocs
          }
         
       case LIST_APPROVED_PRODUCT_FAIL:
          return {
              ...state,
              list_disapproved_loading: false,
              list_disapproved_error: payload
          }
       default:
          return state
    }
}

export const getProductDetails = (state=initialState, action) => {
       const {type, payload} = action;
       switch(type){
          case GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                get_prod_details_loading: true
            }
          case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                get_prod_details_loading: false,
                get_prod_details: payload,

            }
          case GET_PRODUCT_DETAILS_FAIL:
            return {
                ...state,
                get_prod_details_loading: false,
                get_prod_details_error: payload
            }
          default:
            return state
       }
}

export const newProductListReducer = (state=initialState, action) => {
     const {type, payload} = action;
     switch(type){
         case NEW_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                list_new_loading: true
            }
         case NEW_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                list_new_loading: false,
                list_new_products: payload,
                list_new_products_docs: payload.docs,
                new_products_length: payload?.totalDocs
            }
         case NEW_PRODUCT_LIST_FAIL:
            return {
                ...state,
                list_new_loading: false,
                list_new_error: payload
            }
         default:
            return state
     }
}


export const updateProductViewReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case UPDATE_PRODUCT_VIEW_REQUEST:
            return {
                ...state,
                update_view_loading: true
            }
        case UPDATE_PRODUCT_VIEW_SUCCESS:
            return {
                ...state,
                update_view_loading: false,
                update_view: payload,
                update_view_success: true
            }
        case UPDATE_PRODUCT_VIEW_FAIL:
            return {
                ...state,
                update_view_loading: false,
                update_view_error: payload,
                update_view_success: false
            }
        default:
            return state
        
    }
}

export const createUploadCriteriaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case CREATE_COMPLIANCE_REQUEST:
            return {
                ...state,
                create_upload_criteria_loading: true
            }
        case CREATE_COMPLIANCE_SUCCESS:
            return {
                ...state,
                create_upload_criteria_loading: false,
                upload_criteria_docs: state.upload_criteria_docs?.concat(payload),
                create_upload_criteria: payload,
                create_upload_criteria_success: true
            }
        case CREATE_COMPLIANCE_FAIL:
            return {
                ...state,
                create_upload_criteria_loading: false,
                create_upload_criteria_error: payload,
                create_upload_criteria_success: false
            }
        case CLEAR_COMPLIANCE_STATE: 
           return {
               ...state,
               create_upload_criteria_loading: false,
               create_upload_criteria_success: false,
               create_upload_criteria_error: "",
           }
        default:
            return state;
    } 
}



export const listUploadCriteriaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case LIST_COMPLIANCE_REQUEST:
            return {
                ...state,
                list_upload_criteria_loading: true
            }
        case LIST_COMPLIANCE_SUCCESS:
            return {
                ...state,
                list_upload_criteria_loading: false,
                list_upload_criteria: payload,
                list_criteria_docs: payload?.docs
            }
        case LIST_COMPLIANCE_FAIL:
            return {
                list_upload_criteria_loading: false,
                list_upload_criteria_error: payload
            }
        default:
            return state
    }
}

export const getComplianceCriteriaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_COMPLIANCE_REQUEST:
            return {
                ...state,
                get_criteria_loading: true
            }
        case GET_COMPLIANCE_SUCCESS:
            return {
                ...state,
                get_criteria_loading: false,
                get_criteria: payload,
                get_criteria_success: true
            }
        case GET_COMPLIANCE_FAIL:
            return {
                get_criteria_loading: false,
                get_criteria_error: payload,
                get_criteria_success: false
            }
        default:
            return state
    }
}

export const deleteComplianceCriteriaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case DELETE_COMPLIANCE_REQUEST:
            return {
                ...state,
                delete_criteria_loading: true
            }
        case DELETE_COMPLIANCE_SUCCESS:
            return {
                ...state,
                delete_criteria_loading: false,
                delete_criteria: payload,
                delete_criteria_success: true
            }
        case DELETE_COMPLIANCE_FAIL:
            return {
                delete_criteria_loading: false,
                delete_criteria_success: false,
                delete_criteria_error: payload
            }
        case CLEAR_COMPLIANCE_DELETE:
            return {
                ...state,
                delete_criteria_loading: false,
                delete_criteria_success: false,
                delete_criteria_error: ""
            }

        default:
            return state
    }
}


export const updateComplianceCriteriaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case UPDATE_COMPLIANCE_REQUEST:
            return {
                ...state,
                update_criteria_loading: true
            }
        case UPDATE_COMPLIANCE_SUCCESS:
            return {
                ...state,
                update_criteria_loading: false,
                update_criteria: payload,
                update_criteria_success: true
            }
        case UPDATE_COMPLIANCE_FAIL:
            return {
                ...state,
                update_criteria_loading: false,
                update_criteria_success: false,
                update_criteria_error: payload,
            }
            case CLEAR_COMPLIANCE_UPDATE:
                return {
                    ...state,
                    update_criteria_loading: false,
                    update_criteria_success: false,
                    update_criteria_error: "",
                }
        default:
            return state
    }
}


export const updateComplianceStatusReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST:
            return {
                ...state,
                product_compliance_status_loading: true
            }
        case UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS:
            return {
                ...state,
                product_compliance_status_loading: false,
                product_compliance_status: payload,
                product_compliance_status_success: true
            }
        case UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL:
            return {
                ...state,
                product_compliance_status_loading: false,
                product_compliance_status_success: false,
                product_compliance_status_error: payload,
            }
            case CLEAR_PRODUCT_COMPLIANCE_STATUS:
                return {
                    ...state,
                    product_compliance_status_loading: false,
                    product_compliance_status_success: false,
                    product_compliance_status_error: "",
                    update_view_error: "",
                    update_view_loading: false,
                    update_view_success: false
                }
        default:
            return state
    }
}


//  product_compliance_status_loading, product_compliance_status_success, product_compliance_status_error
// 

