import { LIST_RECENT_ORDERS_FAIL, LIST_RECENT_ORDERS_REQUEST, LIST_RECENT_ORDERS_SUCCESS } from "../../../constants/sales/recentOrdersConstant";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from "../../../helpers/getUrl";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
const token = localData && localData.token

export function RecentOrdersAction ({offset,limit}) {
    return async(dispatch,getState) => {
        dispatch({type: LIST_RECENT_ORDERS_REQUEST});
        
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/recent_orders?offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: LIST_RECENT_ORDERS_FAIL, payload: data.message})  
            }
            dispatch({type: LIST_RECENT_ORDERS_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: LIST_RECENT_ORDERS_FAIL, payload: message})
        }
    }
}

export const productStatsApi = createApi({
    reducerPath: "productStatsApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["Sales"],
    endpoints: (builder) => ({
        productStats: builder.query({
            query: () => ({
                url: `/product/detail/statistics`,
                method: "get",
            }),
           providesTags: ["Sales"]
        })
    })
});



export const recentOrdersApi = createApi({
    reducerPath: "recentOrdersApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["Sales"],
    endpoints: (builder) => ({
        recentOrder: builder.query({
            query: ({offset,limit}) => ({
                url: `/order/recent_orders?offset=${offset}&limit=${limit}`,
                method: "get",
            }),
           providesTags: ["Sales"]
        })
    })
});

export const {useProductStatsQuery} = productStatsApi;
export const { useRecentOrderQuery } = recentOrdersApi