import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {BsInfoCircleFill} from "react-icons/bs"
import Loaders from "../Reusables/Loader";
import { TypeCreateAction} from "../../actions/Admin/ProductCategoryAction";


const TypeModal = ({successMessage, setSuccessMessage, errorMessage, setErrorMessage}) => {
      const dispatch = useDispatch();
      const history = useHistory(null)
      const [name, setName] = useState("") 
      const [sizes, setSizes] = useState("");
    const {type_loading,  type_error, type_success} = useSelector(state => state.createCategoryType);

    const sizeData = sizes && sizes.split(",")
    const dataToSubmit = {name, sizes: sizeData}

    const handleTypeCreate = (e) => {
        e.preventDefault();
        dispatch(TypeCreateAction(dataToSubmit));
        
        
    }


    useEffect(() => {
        if(type_success){
             setSuccessMessage("Successfully Created!")
              setName("");
              setSizes("")
        }
    }, [type_success, setSuccessMessage]);

    useEffect(() => {
      if(type_error){
           setErrorMessage(type_error)
      }
  }, [type_error, setErrorMessage])

    useEffect(() => {
      let successMsgClr = setTimeout(() => {
        setSuccessMessage("")
      }, 3000);
      return () => clearTimeout(successMsgClr)
    }, [setSuccessMessage])
    useEffect(() => {
      let failMsgClr = setTimeout(() => {
        setErrorMessage("")
      }, 3000)
      return () => clearTimeout(failMsgClr)
    }, [setErrorMessage])
   
    return (
        <div className="modal_for_type">
              <div className="card type_modal">
              <div className="data_heading">
                <div className="header_order_title type_header">CREATE TYPE</div>
              </div>
              <form className="add_type_form" onSubmit={handleTypeCreate}>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Type name</label>
                      <input type="text" className="type_input" onChange={(e) => setName(e.target.value)} value={name}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="type_label">Size(s)</label>
                      <input type="text" value={sizes} className="type_input" onChange={(e) => setSizes(e.target.value)}/>
                      <p className="type-tooltip" title={`separate sizes using comma (,)`} ><BsInfoCircleFill />{""} <span>separate sizes using comma (,)</span></p>
                    </div>
                  </div>
                  {
                      successMessage &&  <p className="text-success text-center">{successMessage}</p>
                  }
                  {
                      errorMessage &&  <div className="text-danger text-center">{errorMessage.message}</div>
                  }
                 
                 
                  
                  <div className="cta_btn">
                    <button className="type_button">{type_loading  ?<Loaders loading={type_loading && type_loading} color="#FFFFFF" size={30}/> : "submit" }</button>
                    <button className="type_cancel_button" onClick={() => {history.push("/dashboard/categories/add"); setErrorMessage(""); setSuccessMessage("")}}>Cancel</button>
                  </div>
              </form>
           </div>

      
        </div>
    )
}


export default TypeModal