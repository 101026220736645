import {useContext} from "react"
import {AiOutlineInfoCircle} from "react-icons/ai"
import {FaRegClock} from "react-icons/fa";
import {GiCancel} from "react-icons/gi";
import { AppContext } from "../AppContext";

const NotificationPanel = () => {
    
    const {notificationOpen} = useContext(AppContext)
    return (
        <div className={notificationOpen ? "notification notification_active ": "notification"}>
             <div className="notification-dropdown">
                <div className="notification-header notification_underline">
                    <span>NOTIFICATIONS</span><span className="underline_link">View All</span>
                </div>
                <div className="notification-body">
                    <div className="notify">
            
                        <div className="notification-recent-user">
                           <span className="icon"><AiOutlineInfoCircle /></span>
                            <span className="notification_label">NEW USER</span>
                        </div>
                        <div className="notification-content">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ut urna purus molestie. Odio condimentum eget proin vivamus. Velit a neque et </span>
                            <p className="time"><FaRegClock /><span className="ms-2 mt-4">1hr ago</span></p>
                        </div>
                    </div>
                    <div className="notify">
                     
                        <div className="notification-recent-order">
                            <span className="icon icon_new_order_recent"><AiOutlineInfoCircle /></span>
                            <span className="notification_label">NEW ORDER</span>
                            
                        </div>
                        <div className="notification-content">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ut urna purus molestie. Odio condimentum eget proin vivamus. Velit a neque et </span>
                            <p className="time"><FaRegClock /><span className="ms-2 mt-4">3hr ago</span></p>
                        </div>
                    </div>
                    <div className="notification-header">
                       <span>Previous Notifications</span><span className="underline_link">Clear All</span>
                    </div>
                    <div className="notify">
                        <div className="notification-text">
                            <div className="notify-icon"><span className="icon icon_new_user_old"><AiOutlineInfoCircle /></span>  <span className="notification_label_old">NEW USER</span></div><span className="cancel"><GiCancel /></span>
                            </div>
                            <div className="notification-content">
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ut urna purus molestie. Odio condimentum eget proin vivamus. Velit a neque et </span>
                                <p className="time"><FaRegClock /><span className="ms-2 mt-4">Yesterday</span></p>
                            </div>
                    </div>
                    <div className="notify">
                    <div className="notification-text">
                           <div className="notify-icon"><span className="icon icon_new_user_old"><AiOutlineInfoCircle /></span>  <span className="notification_label_old">NEW ORDER</span></div><span className="cancel"><GiCancel /></span>
                        </div>
                        <div className="notification-content">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ut urna purus molestie. Odio condimentum eget proin vivamus. Velit a neque et </span>
                            <p className="time"><FaRegClock /><span className="ms-2 mt-4">2 days ago</span></p>
                        </div>
                    </div>
                    <div className="notify">
                        <div className="notification-text">
                           <div className="notify-icon"><span className="icon icon_new_user_old"><AiOutlineInfoCircle /></span> <span className="notification_label_old">NEW USER</span></div><span className="cancel"><GiCancel /></span>
                        </div>
                        <div className="notification-content">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ut urna purus molestie. Odio condimentum eget proin vivamus. Velit a neque et </span>
                            <p className="time"><FaRegClock /><span className="ms-2 mt-4">2 days ago</span></p>
                        </div>
                    </div>
                </div>
             
             </div>
        </div>
    )
}

export default NotificationPanel;