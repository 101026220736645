import {CLEAR_PENDING_ORDER, CLEAR_UPDATE_ORDER_STATUS, ORDER_GET_FAILED, ORDER_GET_REQUEST, ORDER_GET_SUCCESS, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, PENDING_PAYMENT_FAIL, UPDATE_ORDER_STATUS_FAIL, UPDATE_ORDER_STATUS_REQUEST, UPDATE_ORDER_STATUS_SUCCESS, VERIFY_PENDING_ORDER_FAIL, VERIFY_PENDING_ORDER_REQUEST, VERIFY_PENDING_ORDER_SUCCESS } from "../../../constants/orderConstants"
import { ORDER_LIST_FAILED, PENDING_PAYMENT_REQUEST, PENDING_PAYMENT_SUCCESS } from './../../../constants/orderConstants';

const initialState = {
    list_loading: false,
    verify_pending_order_loading: false,
    list_success: false,
    verify_pending_order_success: false,
    pending_payment_loading: false,
    order_docs: [],
    pending_payment_docs: [],
    update_order_status_loading: false,
    update_order_status: {},
    update_order_status_success: false,
    update_order_status_error: "",
    orders: [],
    order: {},
    pending_payment: {},
    error: "",
    pending_payment_error: "",
    verify_pending_order_error: "",

}

export const listOrders = (state=initialState, action) => {
    switch(action.type){
       case ORDER_LIST_REQUEST: 
           return {
                ...state,
                list_loading: true
           }
       case ORDER_LIST_SUCCESS:
           return {
                 ...state,
                 list_loading: false,
                 list_success: true,
                 orders: action.payload,
                 order_docs: action.payload.docs
           }
       case ORDER_LIST_FAILED: 
           return {
                ...state,
                list_loading : false,
                list_success: false,
                error: action.payload
           }
       default:
           return state

    }
}


export const getOrder = (state=initialState, action) => {
   switch(action.type){
       case ORDER_GET_REQUEST: 
           return {
            ...state,
            list_loading: true
           }
       case ORDER_GET_SUCCESS:
           return {
               ...state,
               list_loading: false,
               order : action.payload,
               list_success: true
           }
       case ORDER_GET_FAILED:
           return {
               ...state,
               list_loading: false,
               error: action.payload,
               list_success: false
           }
       default:
           return state
   }
} 


export const pendingPayment = (state=initialState, action) => {
    switch(action.type){
       case PENDING_PAYMENT_REQUEST: 
           return {
                ...state,
                pending_payment_loading: true
           }
       case PENDING_PAYMENT_SUCCESS:
           return {
                 ...state,
                 pending_payment_loading: false,
                 pending_payment_docs: action.payload.docs,
                 pending_payment: action.payload
           }
       case PENDING_PAYMENT_FAIL: 
           return {
                ...state,
                pending_payment_loading: false,
                pending_payment_error: action.payload
           }
       default:
           return state

    }
}


export const pendingOrderVerify = (state=initialState, action) => {
    switch(action.type){
       case VERIFY_PENDING_ORDER_REQUEST: 
           return {
                ...state,
                verify_pending_order_loading: true
           }
       case VERIFY_PENDING_ORDER_SUCCESS:
           return {
                 ...state,
                 verify_pending_order_loading: false,
                 verify_pending_order_success: true
           }
       case VERIFY_PENDING_ORDER_FAIL: 
           return {
                ...state,
                verify_pending_order_loading: false,
                verify_pending_order_success: false,
                verify_pending_order_error: action.payload
           }
       case CLEAR_PENDING_ORDER:
           return {
              ...state,
              verify_pending_order_loading: false,
              verify_pending_order_success: false,
              verify_pending_order_error: ""

           }
       default:
           return state

    }
}


export const updateOrderStatus = (state=initialState, action) => {
    switch(action.type){
       case UPDATE_ORDER_STATUS_REQUEST: 
           return {
                ...state,
                update_order_status_loading: true
           }
       case UPDATE_ORDER_STATUS_SUCCESS:
           return {
                 ...state,
                 update_order_status_loading: false,
                 update_order_status: action.payload,
                 update_order_status_success: true
           }
       case UPDATE_ORDER_STATUS_FAIL: 
           return {
                ...state,
                update_order_status_loading: false,
                update_order_status_success: false,
               update_order_status_error: action.payload
           }
       case CLEAR_UPDATE_ORDER_STATUS:
           return {
              ...state,
              update_order_status_loading: false,
              update_order_status_success: false,
              update_order_status_error: ""

           }
       default:
           return state

    }
}
