import { useContext, useEffect, useState } from "react"

import { AppContext } from "../AppContext";
import { useSelector, useDispatch } from "react-redux";
import { RevenueDataAction } from "../actions/Admin/dashboard/RevenueDataAction";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const override = {
    borderColor: "none",
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px"

  };
const errorStyle = {
    textAlign: "center",
    margin: "10px 0px",
    color: "red"
}
const SortedLineChart = () => {
    const {monthsRange,getMonthData} = useContext(AppContext);
    const [color, setColor] = useState("#72FFC9");
    const dispatch = useDispatch();
    const {revenue_data_loading,revenue_data_error,revenue_data} = useSelector(state => state.revenueDataReducer);
    const revenueResults = revenue_data && revenue_data.results
 useEffect(() => {
       dispatch(RevenueDataAction(monthsRange))
 }, [monthsRange])
    return (
        <div>
            {revenue_data_error && !revenue_data_loading && <p style={errorStyle}>{revenue_data_error || revenue_data_error.message}</p>}
            {revenue_data_loading  && !revenue_data_error ? <ScaleLoader color={color} loading={revenue_data_loading} cssOverride={override}  /> :
       
        <div style={{width: "100%", height: "100%", padding: ".5rem 0"}}>
           <Line data={{
        labels: getMonthData(monthsRange),
        datasets: [{
            label: 'Revenue generated',
            data: revenueResults,
            borderColor: '#00B412',
            backgroundColor: '#00B412',
            borderWidth: 2,
            fill: false,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
           radius: 0,
        }
        ]
        
    }} options={{
        responsive: true,
        plugins: {
            legend: {
                position : "top"
            },
            title: {
                display: true,
                text: "Revenue generated",
                position: "bottom"
            }
        }
    }} />

        </div>}
   </div>   
    )
}

export default SortedLineChart