export const LIST_PRODUCT_REQUEST = "LIST_PRODUCT_REQUEST";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAIL = "LIST_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const LIST_APPROVED_PRODUCT_REQUEST = "LIST_APPROVED_PRODUCT_REQUEST";
export const LIST_APPROVED_PRODUCT_SUCCESS = "LIST_APPROVED_PRODUCT_SUCCESS";
export const LIST_APPROVED_PRODUCT_FAIL = "LIST_APPROVED_PRODUCT_FAIL";

export const LIST_DISAPPROVED_PRODUCT_REQUEST = "LIST_DISAPPROVED_PRODUCT_REQUEST";
export const LIST_DISAPPROVED_PRODUCT_SUCCESS = "LIST_DISAPPROVED_PRODUCT_SUCCESS";
export const LIST_DISAPPROVED_PRODUCT_FAIL = "LIST_DISAPPROVED_PRODUCT_FAIL";

export const NEW_PRODUCT_LIST_REQUEST = "NEW_PRODUCT_LIST_REQUEST";
export const NEW_PRODUCT_LIST_SUCCESS = "NEW_PRODUCT_LIST_SUCCESS";
export const NEW_PRODUCT_LIST_FAIL = "NEW_PRODUCT_LIST_FAIL";


export const UPDATE_PRODUCT_VIEW_REQUEST = "UPDATE_PRODUCT_VIEW_REQUEST";
export const UPDATE_PRODUCT_VIEW_SUCCESS = "UPDATE_PRODUCT_VIEW_SUCCESS";
export const UPDATE_PRODUCT_VIEW_FAIL = "UPDATE_PRODUCT_VIEW_FAIL";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";

export const CREATE_COMPLIANCE_REQUEST = "CREATE_COMPLIANCE_REQUEST";
export const CREATE_COMPLIANCE_SUCCESS = "CREATE_COMPLIANCE_SUCCESS";
export const CREATE_COMPLIANCE_FAIL = "CREATE_COMPLIANCE_FAIL";

export const LIST_COMPLIANCE_REQUEST = "LIST_COMPLIANCE_REQUEST";
export const LIST_COMPLIANCE_SUCCESS = "LIST_COMPLIANCE_SUCCESS";
export const LIST_COMPLIANCE_FAIL = "LIST_COMPLIANCE_FAIL";

export const GET_COMPLIANCE_REQUEST = "GET_COMPLIANCE_REQUEST";
export const GET_COMPLIANCE_SUCCESS = "GET_COMPLIANCE_SUCCESS";
export const GET_COMPLIANCE_FAIL = "GET_COMPLIANCE_FAIL";

export const UPDATE_COMPLIANCE_REQUEST = "UPDATE_COMPLIANCE_REQUEST";
export const UPDATE_COMPLIANCE_SUCCESS = "UPDATE_COMPLIANCE_SUCCESS";
export const UPDATE_COMPLIANCE_FAIL = "UPDATE_COMPLIANCE_FAIL";

export const DELETE_COMPLIANCE_REQUEST = "DELETE_COMPLIANCE_REQUEST";
export const DELETE_COMPLIANCE_SUCCESS = "DELETE_COMPLIANCE_SUCCESS";
export const DELETE_COMPLIANCE_FAIL = "DELETE_COMPLIANCE_FAIL";


export const UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST = "UPDATE_PRODUCT_COMPLIANCE_STATUS_REQUEST";
export const UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS = "UPDATE_PRODUCT_COMPLIANCE_STATUS_SUCCESS";
export const UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL = "UPDATE_PRODUCT_COMPLIANCE_STATUS_FAIL";


export const CLEAR_COMPLIANCE_STATE = "CLEAR_COMPLIANCE_STATE";
export const CLEAR_COMPLIANCE_UPDATE = "CLEAR_COMPLIANCE_UPDATE";
export const CLEAR_COMPLIANCE_DELETE = "CLEAR_COMPLIANCE_DELETE";
export const CLEAR_PRODUCT_COMPLIANCE_STATUS = "CLEAR_PRODUCT_COMPLIANCE_STATUS";
export const CLEAR_DELETE_PRODUCT = "CLEAR_DELETE_PRODUCT";