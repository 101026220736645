import React, { useContext, useEffect, useState } from 'react'
import {BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { useHistory,useLocation } from 'react-router-dom';
import moment from 'moment';
import { AppContext } from '../../AppContext';
import { useSelector, useDispatch } from 'react-redux';
import {ReturnList } from '../../actions/Admin/RefundAction';
import "./refund.css";
import RefundListSection from './RefundListSection';
import ReturnListSection from './ReturnListSection';


const Refund = () => {
    const {refundTab, handleRefundTab, itemLimit,setItemLimit, numberSeperator } = useContext(AppContext);
    const [itemId, setItemId] = useState("");
    const dispatch = useDispatch();
    const history = useHistory(null);
    const search = useLocation().search;
    const return_modal = new URLSearchParams(search).get("return");
    const data = ["REFUND", "RETURN"];
    const {return_list, return_list_docs,  return_list_error} = useSelector(state => state.returnList)

  useEffect(() => {
    const offset = 1;
    const limit = +itemLimit;
    const data = {offset, limit}
   dispatch(ReturnList(data))
   
}, [dispatch, itemLimit]);
const handleNextPageReturn = () => {

}

const totalPagesReturn = return_list && return_list.totalPages;
const pageReturn = return_list && return_list.page;
const prevPageReturn = return_list && return_list.prevPage;
const nextPageReturn = return_list && return_list.nextPage;
const hasNextPageReturn = return_list && return_list.hasNextPage;
const hasPrevPageReturn = return_list && return_list.hasPrevPage;
  return (
    <>
        {
             <div className="customers_container">
                <ul>
                    {data.map((data,index) => (
                        <li key={index} className={refundTab === index ? "tabs_active" : "tab_color"} onClick={() => handleRefundTab(index)}>{data}</li>
                    ))}
                </ul>
             </div>

        }
        {refundTab === 0 &&  <RefundListSection itemId={itemId} setItemId={setItemId}/>}
        {refundTab === 1 &&  <ReturnListSection />}
    </>
  )
}

export default Refund