import { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import "../Transactions/transaction.css";
import "./customer.css";
import { AppContext } from '../../AppContext';

const SingleStore = ({products}) => {
    const {numberSeperator} = useContext(AppContext)
    return (
        <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="data_heading">
                    <div className="header_order_title ">STORE INVENTORY</div>
                </div>
                <div className="card-body">
                    {products?.length > 0  ? <div className="table-responsive">
                    <table className="table seller_table">

                        
                    <thead className="tborder table-header" align="left">
                        <tr className="align_student_table">
                            <th scope="col"  className="header___name">Product Name</th>
                            <th scope="col" className="header___status">Alpha Code</th>
                            <th scope="col" className="header__userId">Date Uploaded</th>
                            <th scope="col" className="header_phone">Price</th>
                            <th scope="col" className="header_email">Qty Sold</th>
                            <th scope="col" className="header__orders">Qty Left</th>
                            <th scope="col" className="header_last_transaction">Status</th>
                        </tr>
                    </thead>
                    
        
                        <tbody align="left">
                            {products && products.map(product => (
                                <tr className="transactions-item" key={product._id}>
                                <td className="item">{product.productName}</td>
                                <td className="item">{product.alphaCode}</td>
                                <td className="item">{moment(product.createdAt).format("ddd, DD/MM/YYYY")}</td>
                                <td className="item">N {numberSeperator(product.price)}</td>
                                <td  className="item">{product.number_of_sales.length}</td>
                                <td className="item">{product.quantity}</td>
                                <td className={product.quantity > 0 ? "item" : "item out-of-stock"}>{product.quantity > 0 ? "In Stock" : "Out of stock"}</td>
                            </tr>
                            ))}
                            
                        </tbody>
            </table>
            </div> :  products === null ? <h4 className="text-danger text-center">No inventories available!</h4>: <h4 className="text-danger text-center">No inventories available!</h4>}
   
        </div>
        {/* <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><Paginations /> </div> */}
        </div>
    )
}

export default SingleStore;

SingleStore.propTypes = {
    header_title: PropTypes.string
  };


