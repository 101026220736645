export const CREATE_REASON_REQUEST = "CREATE_REASON_REQUEST";
export const CREATE_REASON_SUCCESS = "CREATE_REASON_SUCCESS";
export const CREATE_REASON_FAIL = "CREATE_REASON_FAIL";


export const GET_REASON_REQUEST = "GET_REASON_REQUEST";
export const GET_REASON_SUCCESS = "GET_REASON_SUCCESS";
export const GET_REASON_FAIL = "GET_REASON_FAIL";

export const LIST_REASON_REQUEST = "LIST_REASON_REQUEST";
export const LIST_REASON_SUCCESS = "LIST_REASON_SUCCESS";
export const LIST_REASON_FAIL = "LIST_REASON_FAIL";

export const DELETE_REASON_REQUEST = "DELETE_REASON_REQUEST";
export const DELETE_REASON_SUCCESS = "DELETE_REASON_SUCCESS";
export const DELETE_REASON_FAIL = "DELETE_REASON_FAIL";

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

export const CREATE_VEHICLE_REQUEST = "CREATE_VEHICLE_REQUEST";
export const CREATE_VEHICLE_SUCCESS = "CREATE_VEHICLE_SUCCESS";
export const CREATE_VEHICLE_FAIL = "CREATE_VEHICLE_FAIL";

export const LIST_VEHICLE_REQUEST = "LIST_VEHICLE_REQUEST";
export const LIST_VEHICLE_SUCCESS = "LIST_VEHICLE_SUCCESS";
export const LIST_VEHICLE_FAIL = "LIST_VEHICLE_FAIL";


export const CREATE_ETA_REQUEST = "CREATE_ETA_REQUEST";
export const CREATE_ETA_SUCCESS = "CREATE_ETA_SUCCESS";
export const CREATE_ETA_FAIL = "CREATE_ETA_FAIL";


export const UPDATE_ETA_REQUEST = "UPDATE_ETA_REQUEST";
export const UPDATE_ETA_SUCCESS = "UPDATE_ETA_SUCCESS";
export const UPDATE_ETA_FAIL = "UPDATE_ETA_FAIL";

export const DELETE_ETA_REQUEST = "DELETE_ETA_REQUEST";
export const DELETE_ETA_SUCCESS = "DELETE_ETA_SUCCESS";
export const DELETE_ETA_FAIL = "DELETE_ETA_FAIL";

export const CLEAR_ETA_STATE = "CLEAR_ETA_STATE";
export const CLEAR_ETA_UPDATE = "CLEAR_ETA_UPDATE";
export const CLEAR_ETA_DELETE = "CLEAR_ETA_DELETE";


