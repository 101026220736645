import { EARNING_LIST_FAIL, EARNING_LIST_REQUEST, EARNING_LIST_SUCCESS, STORE_EARNING_LIST_REQUEST, STORE_EARNING_LIST_SUCCESS, TRANSACTION_LIST_REQUEST, TRANSACTION_LIST_SUCCESS,TRANSACTION_LIST_FAIL, STORE_EARNING_LIST_FAIL, EARNING_CHART_REQUEST, EARNING_CHART_FAIL, EARNING_CHART_SUCCESS, TOTAL_EARNING_REQUEST, TOTAL_EARNING_FAIL, TOTAL_EARNING_SUCCESS } from "../../constants/transactionsConstant"
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token

export function TransactionList({offset,limit}){
    return async (dispatch, getState) => {
        dispatch({type: TRANSACTION_LIST_REQUEST});
        try {
     
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/transaction/list?offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: TRANSACTION_LIST_FAIL, payload: data.message})
            }
            dispatch({type: TRANSACTION_LIST_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: TRANSACTION_LIST_FAIL, payload: message})
        }
    }
}

export function EarningList({page,limit}){
    return async(dispatch,getState) => {
        dispatch({type: EARNING_LIST_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings/list?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: EARNING_LIST_FAIL, payload: data.message})
            }
            dispatch({type: EARNING_LIST_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: EARNING_LIST_FAIL, payload: message})
        }
    }
}


export function StoreEarningList({store_id,page,limit}){
    return async(dispatch,getState) => {
        dispatch({type: STORE_EARNING_LIST_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings?store_id=${store_id}&page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: STORE_EARNING_LIST_FAIL, payload: data.message})
            }
            dispatch({type: STORE_EARNING_LIST_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: STORE_EARNING_LIST_FAIL, payload: message})
        }
    }
}




export function EarningChart({date}){
    return async(dispatch,getState) => {
        dispatch({type: EARNING_CHART_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings/chart?date=${date}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: EARNING_CHART_FAIL, payload: data.message})
            }
            dispatch({type: EARNING_CHART_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: EARNING_CHART_FAIL, payload: message})
        }
    }
}




export function TotalEarning({startDate,endDate}){
    return async(dispatch,getState) => {
        dispatch({type: TOTAL_EARNING_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings/total?startDate=${startDate}&endDate=${endDate}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: TOTAL_EARNING_FAIL, payload: data.message})
            }
            dispatch({type: TOTAL_EARNING_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: TOTAL_EARNING_FAIL, payload: message})
        }
    }
}