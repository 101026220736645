import {useEffect, useState, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from "react-icons/fi";
import { FaChevronDown,FaChevronUp,FaRegSave } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { useSelector, useDispatch } from 'react-redux';
import allState from "./States/State.json"
import { clearEtaState, CreateEta } from '../../actions/Admin/SettingsAction';
import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
    margin: "0",
    borderColor: "none",
  };
  
const AddEta = () => {
    const [color] = useState("#72FFC9");
    const history = useHistory();
    const dispatch = useDispatch();
    const [toggleVisibility, setToggleVisibilty] = useState({visibleTime: false, visibleFrom: false, visibleTo: false});
    const [searchStatesFrom, setSearchStatesFrom] = useState("");
    const [searchStatesTo, setSearchStatesTo] = useState("");
    const [time, setTime] = useState("");
    const [msg, setMsg] = useState("")
    const {eta_create_loading,eta_create_success,eta_create_error} = useSelector(state => state.createEta)
    const data = useMemo(() => allState, [])

    const handleVisibiltyTime = () => {
        setToggleVisibilty((prev) => ({...prev, visibleFrom: false, visibleTo: false}))
    }
    const handleVisibiltyFrom = () => {
        setToggleVisibilty((prev) => ({...prev, visibleFrom: !prev.visibleFrom, visibleTo: false}));
    }
    const handleVisibiltyTo = () => {
        setToggleVisibilty((prev) => ({...prev, visibleTo: !prev.visibleTo, visibleFrom : false}));
    }
    const setSelectedFrom = selected => {
        setSearchStatesFrom(selected)
        setToggleVisibilty((prev) => ({...prev, visibleFrom : !prev.visibleFrom}))
    }
    const setSelectedTo = selected => {
        setSearchStatesTo(selected);
        setToggleVisibilty((prev) => ({...prev, visibleTo: !prev.visibleTo}));
    }
    const newData = (search) => search ? data && data.filter(({name}) => name.toLowerCase().includes(search.toLowerCase())) : data;
    const handleCreateEta = (e) => {
        e.preventDefault();
        if(!searchStatesFrom || !searchStatesTo || !time){
            setMsg("All field is required");
            return
        }
        dispatch(CreateEta({departure_state: searchStatesFrom, destination_state: searchStatesTo, ETA: +time}))
    }

    useEffect(() => {
         const timeout = setTimeout(() => {
              if(eta_create_success || eta_create_error){
                  dispatch(clearEtaState());
                  setMsg("")
              }
         }, 2000)
         return () => clearTimeout(timeout)
    }, [eta_create_success, eta_create_error])
  
    return (
        <div className="eta-container">
            <div className="card card_wrapper_eta mt-4">
            <div className="payment_title_header">
                <div className="header_order_title"><FiArrowLeft className="back-navigation-set" onClick={() => history.push("/dashboard/settings/eta")}/>CREATE ETAs</div>   
                    
            </div>
            <div className="card-body eta-card-body">
                      <div className="eta-form">
                         <form>
                         <div className="eta-list">
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="from" placeholder="Abia State" value={searchStatesFrom} onChange={(e) => setSearchStatesFrom(e.target.value)} onClick={handleVisibiltyFrom}/>
                                    <span>{!toggleVisibility.visibleFrom ? <FaChevronDown className="input-toggler"/> : <FaChevronUp className="input-toggler toggler-up"/>}</span>
                                </div>
                                {
                                    toggleVisibility.visibleFrom && <div className="from-state">{
                                       newData(searchStatesFrom).length > 0 ? newData(searchStatesFrom).map(({name, code}) => (
                                            <span key={code} onClick={() => setSelectedFrom(name)}>{name}</span>
                                            
                                        )): <span className="text-center">No Match</span>
                                      }
                                    
                                   </div>
                                }
                            </div>
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="to" placeholder="Enugu State"  value={searchStatesTo} onChange={(e) => setSearchStatesTo(e.target.value)} onFocus={handleVisibiltyTo}/>
                                    
                                    <span>{!toggleVisibility.visibleTo ? <FaChevronDown className="input-toggler"/> : <FaChevronUp className="input-toggler toggler-up"/>}</span>

                                </div>
                                  {
                                      toggleVisibility.visibleTo && <div className="from-state">{
                                        newData(searchStatesTo).length > 0 ? newData(searchStatesTo).map(({name, code}) => (
                                            <span key={code} onClick={() => setSelectedTo(name)}>{name}</span>
                                        )) : <span className="text-center">No Match</span>
                                      }
                                    
                                   </div>
                                }
                                
                            </div>
                            <div className="eta-input"> 
                                <div className="inputs">
                                    <input type="text" className="time" placeholder="24" value={time} name="time" onChange={(e) => setTime(e.target.value)} onFocus={handleVisibiltyTime} />
                                </div>
                            </div>
                            <div className="eta-actions">
                                {
                                    eta_create_loading  ? <div className="save-icon"><span><ScaleLoader color={color} loading={eta_create_loading} cssOverride={override} size={20} /></span></div> :
                                    eta_create_success ? <div className="save-icon"><GiCheckMark  className="saved-check"/><span>Saved</span></div>: <div className="save-icon" onClick={handleCreateEta}><FaRegSave /><span>Save</span></div>
                                }
                                
                                
                            </div>
                            </div>

                         </form>
                      </div>
                      {eta_create_error &&<p className="text-center text-danger">{eta_create_error.message}</p>}
                      {msg && <p className="text-center text-danger">{msg}</p>}
                    </div>
               {/* }           */}
          
            

            </div>
         {/* </div> */}
        </div>
    )
}

export default AddEta