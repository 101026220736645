import "./login.css";
import {FaRegCheckCircle} from "react-icons/fa"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const SentLinkSuccess = ({user_forgot_password, email}) => {
    const [reset, setReset] = useState("")
    const history = useHistory();

    useEffect(() => {
        setReset(user_forgot_password)
    }, [user_forgot_password])
   useEffect(() => {
       if(reset && reset.code === 200) {
        setTimeout(() => {
             history.push("/reset", {email});
           }, 3000)
       }  
   }, [reset, reset.code, email, history]);
    return (
        <div>
            <div className="login_bg">
                <div className="login__form">
                   <div className="card password_link_container">
                       <div className="card__image"><img src="/alpha-logo.png" alt="GIGLOGO" /></div>
                       <div className="card-body items_display">
                           <p className="success_link_sent">{reset.message}</p>
                           <p><FaRegCheckCircle  className="checked_image"/></p>
                           <p className="link_hint">Please check your email and use the link to set a new password</p>
                       </div>  
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default SentLinkSuccess;