import {  CATEGORY_CREATE_REQUEST,CATEGORY_CREATE_FAIL, CATEGORY_CREATE_SUCCESS, CATEGORY_DELETE_FAIL, CATEGORY_DELETE_REQUEST, CATEGORY_DELETE_SUCCESS, CATEGORY_GENDER_CREATE_FAIL, CATEGORY_GENDER_CREATE_REQUEST, CATEGORY_GENDER_CREATE_SUCCESS, CATEGORY_GENDER_LIST_FAIL, CATEGORY_GENDER_LIST_REQUEST, CATEGORY_GENDER_LIST_SUCCESS, CATEGORY_GET_FAIL, CATEGORY_GET_REQUEST, CATEGORY_GET_SUCCESS, CATEGORY_LIST_FAIL, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, CATEGORY_SIZE_CREATE_FAIL, CATEGORY_SIZE_CREATE_REQUEST, CATEGORY_SIZE_CREATE_SUCCESS, CATEGORY_SIZE_LIST_FAIL, CATEGORY_SIZE_LIST_REQUEST, CATEGORY_SIZE_LIST_SUCCESS, CATEGORY_TYPE_CREATE_FAIL, CATEGORY_TYPE_CREATE_REQUEST, CATEGORY_TYPE_CREATE_SUCCESS, CATEGORY_TYPE_LIST_FAIL, CATEGORY_TYPE_LIST_REQUEST, CATEGORY_TYPE_LIST_SUCCESS, CATEGORY_EDIT_REQUEST, CATEGORY_EDIT_SUCCESS, CATEGORY_EDIT_FAIL, CATEGORY_STATUS_UPDATE_REQUEST, CATEGORY_STATUS_UPDATE_FAIL, CATEGORY_STATUS_UPDATE_SUCCESS, CATEGORY_TYPE_DELETE_REQUEST, CATEGORY_TYPE_DELETE_SUCCESS, CATEGORY_TYPE_DELETE_FAIL, CATEGORY_TYPE_EDIT_REQUEST, CATEGORY_TYPE_EDIT_FAIL, CATEGORY_TYPE_EDIT_SUCCESS, CATEGORY_TYPE_GET_REQUEST, CATEGORY_TYPE_GET_FAIL, CATEGORY_TYPE_GET_SUCCESS, SUB_CATEGORY_REMOVE_REQUEST, SUB_CATEGORY_REMOVE_SUCCESS, SUB_CATEGORY_REMOVE_FAIL } from "../../constants/productCategoryConstant";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token


export function TypeCreateAction (dataToSubmit) {

   return async(dispatch,getState) => {
        dispatch({type: CATEGORY_TYPE_CREATE_REQUEST});
        
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/type/new`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(dataToSubmit)
            })
            const data = await res.json()
            if(data.error){
                 return dispatch({type: CATEGORY_TYPE_CREATE_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_TYPE_CREATE_SUCCESS, payload: data});
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_TYPE_CREATE_FAIL, payload: message})
        }
    }
}
export function TypeListAction ({page, limit}){
    return async (dispatch,getState) => {
        dispatch({type: CATEGORY_TYPE_LIST_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/type/all?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            const data = await res.json()
            if(data.error){
               return dispatch({type: CATEGORY_TYPE_LIST_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_TYPE_LIST_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
              dispatch({type: CATEGORY_TYPE_LIST_FAIL, payload: message})
        }
       }
    }

    export function GetTypeAction (typeId) {
        return async(dispatch,getState) => {
            dispatch({type: CATEGORY_TYPE_GET_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/type/detail?id=${typeId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: CATEGORY_TYPE_GET_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_TYPE_GET_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: CATEGORY_TYPE_GET_FAIL, payload: message})
            }
      }
    }
    
    export function EditTypeAction (typeId, dataToSubmit) {
        return async(dispatch,getState) => {
            dispatch({type: CATEGORY_TYPE_EDIT_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/type/update?id=${typeId}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(dataToSubmit)
                } )
                const data = await res.json()
                if(data.error){
                    return dispatch({type: CATEGORY_TYPE_EDIT_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_TYPE_EDIT_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: CATEGORY_TYPE_EDIT_FAIL, payload: message})
            }
      } 
    }
    
    export function DeleteTypeAction (typeId) {
        return async(dispatch,getState) => {
            dispatch({type: CATEGORY_TYPE_DELETE_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/type/delete?id=${typeId}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: CATEGORY_TYPE_DELETE_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_TYPE_DELETE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: CATEGORY_TYPE_DELETE_FAIL, payload: message})
            }
        }
    }

    export function RemoveTypeAction (typeId) {
        return async(dispatch,getState) => {
            dispatch({type: SUB_CATEGORY_REMOVE_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/delete_sub?id=${typeId}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                const data = await res.json()
                if(data.error){
                    return dispatch({type: SUB_CATEGORY_REMOVE_FAIL, payload: data.message})
                }
                dispatch({type: SUB_CATEGORY_REMOVE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: SUB_CATEGORY_REMOVE_FAIL, payload: message})
            }
       }
    }


    export function GenderCreateAction (dataToSubmit) {
       return async(dispatch,getState) => {
            try {
                dispatch({type: CATEGORY_GENDER_CREATE_REQUEST});
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/gender/new`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "ACCEPT": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(dataToSubmit)
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: CATEGORY_GENDER_CREATE_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_GENDER_CREATE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: CATEGORY_GENDER_CREATE_FAIL, payload: message})
            }
        }
    }

    export function GenderListAction() {
        return async(dispatch,getState) => {
            dispatch({type: CATEGORY_GENDER_LIST_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/gender/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                const data = await res.json()
                if(data.error){
                    dispatch({type: CATEGORY_GENDER_LIST_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_GENDER_LIST_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                  ? error.response.data
                  : error.message;
                  dispatch({type: CATEGORY_GENDER_LIST_FAIL, payload: message})
            }
        }
    } 
    export function SizeCreateAction (dataToSubmit) {
        return async(dispatch,getState) => {
            try {
                dispatch({type: CATEGORY_SIZE_CREATE_REQUEST});
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/size/new`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(dataToSubmit)
                })
                const data = await res.json();
                if(data.error){
                    dispatch({type: CATEGORY_SIZE_CREATE_FAIL, payload: data.message})
                }
                dispatch({type: CATEGORY_SIZE_CREATE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                  ? error.response.data
                  : error.message;
                  dispatch({type: CATEGORY_SIZE_CREATE_FAIL, payload: message})
            }
        }
    }
        
        
     export function SizeListAction () {
        return async(dispatch,getState) => {
            dispatch({type: CATEGORY_SIZE_LIST_REQUEST});
            try {
                
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/size/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        ACCEPT: "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: CATEGORY_SIZE_LIST_FAIL, payload: data.message})
                }  
                dispatch({type: CATEGORY_SIZE_LIST_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                    ? error.response.data
                    : error.message;
                    dispatch({type: CATEGORY_SIZE_LIST_FAIL, payload: message})
            }
        }
    }         
export function CreateCategoryAction (dataToSubmit) {
    return async(dispatch,getState) => {
        dispatch({type: CATEGORY_CREATE_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/new`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: dataToSubmit
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_CREATE_FAIL, payload: data.message})  
            }
            dispatch({type: CATEGORY_CREATE_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_CREATE_FAIL, payload: message})
        }
    }
}


export function ListCategoryAction ({page, limit}) {
    return async(dispatch,getState) => {
        dispatch({type: CATEGORY_LIST_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/all?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                }

            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_LIST_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_LIST_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_LIST_FAIL, payload: message})
        }
    }
}

export function GetCategoryAction (categoryId) {
    return  async (dispatch,getState) => {
        dispatch({type: CATEGORY_GET_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/${categoryId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_GET_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_GET_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_GET_FAIL, payload: message})
        }
   }
}

export function EditCategoryAction (categoryId, dataToSubmit) {
    return async(dispatch,getState) => {
        dispatch({type: CATEGORY_EDIT_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/${categoryId}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: dataToSubmit
            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_EDIT_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_EDIT_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_EDIT_FAIL, payload: message})
        }
   }
}

export function DeleteCategoryAction (categoryId) {
    return async(dispatch,getState) => {
        try {
            dispatch({type: CATEGORY_DELETE_REQUEST});
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/${categoryId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_DELETE_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_DELETE_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_DELETE_FAIL, payload: message})
        }
   }
}


export function CategoryStatusAction ({categoryId, status}){
    return async(dispatch,getState) => {
        dispatch({type: CATEGORY_STATUS_UPDATE_REQUEST});
        try {
            
        
            const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/category/status?category_id=${categoryId}&status=${status}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    ACCEPT: "application/json",
                    "Authorization": `Bearer ${token}` 
                }
            })
            const data = await res.json();
            if(data.error){
                return dispatch({type: CATEGORY_STATUS_UPDATE_FAIL, payload: data.message})
            }
            dispatch({type: CATEGORY_STATUS_UPDATE_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: CATEGORY_STATUS_UPDATE_FAIL, payload: message})
        }
    }
}