import { useEffect, useContext, useState} from 'react';
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { EarningChart, EarningList, TotalEarning } from '../../actions/Admin/TransactionAction';
import { AppContext } from '../../AppContext';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {DatePicker} from 'antd';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./transaction.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const {RangePicker} = DatePicker;


const Earnings = ({match}) => {
    const dispatch = useDispatch();
    let [startDate, setStartDate] = useState(moment(new Date()).format("MM-DD-YYYY"));
    let [dateRange, setDateRange] = useState([moment(new Date()).format("MM-DD-YYYY"),moment(new Date()).format("MM-DD-YYYY")]);
    const {earning_lists,earning_lists_docs,earning_error} = useSelector(state => state.earningList);
    const {earning_chart} = useSelector(state => state.earningChart);
    const {total_earning,total_earning_error} = useSelector(state => state.totalEarning)
    const {itemLimit,setItemLimit,numberSeperator, setStoreId, nFormatter } = useContext(AppContext);

    const chartData = earning_chart && earning_chart.chart_arr;

    const handleNext = (id) => {
          if(id){
            setStoreId(id)
          }
    }
    useEffect(() => {
        const page = 1;
        const limit = itemLimit;
        const data = {page, limit}
       dispatch(EarningList(data))
       
  }, [dispatch,itemLimit]);

  useEffect(() => {
         const date = {startDate}
         dispatch(EarningChart(date))
  }, [startDate, dispatch])
    
 useEffect(() => {
     const startDate = dateRange.at(0);
     const endDate = dateRange.at(1);
     if(dateRange.length === 2){
        dispatch(TotalEarning({startDate,endDate}))
     }
 }, [dateRange,dispatch])
  const handleNextPage = (page_number) => {
    const page = page_number;
    const limit = +itemLimit;
    const data = { page, limit };
    dispatch(EarningList(data));
    
};
const totalPages = earning_lists && earning_lists.totalPages;
const page = earning_lists && earning_lists.page;
const prevPage = earning_lists && earning_lists.prevPage;
const nextPage = earning_lists && earning_lists.nextPage;
    

    const data = {
        labels: ["Jan", "Feb", "Mar", "April", "May", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                data: chartData,
                backgroundColor: "#1A1A1A",
                borderColor: "#ED3237",
                borderWidth: 2,
                fill: false,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
               radius: 0,
            }
        ]
    }
 
    const options = {
        responsive: true,
      plugins: {
        legend:{
            display: false
        }
        
       }
    }

    return (
        <>
             <div className="card card_wrapper  seller_table_wrapper my-2 p-4 merchant__earning_and-chart">
                  <div className="total__merchants-earning">
                       <h3>TOTAL MERCHANT EARNINGS</h3>
                       <h1>&#x20A6; {total_earning && total_earning.length > 7 ? nFormatter(total_earning) : numberSeperator(total_earning)}</h1>
                        <RangePicker  defaultValue={[ moment(),moment()]} format={"MM-DD-YYYY"} onChange={(values) => setDateRange(values.map((date) => moment(date).format("MM-DD-YYYY")))} 
                    /> 
                    {total_earning_error && <p>{total_earning_error || total_earning_error.message}</p>}
                  </div>
                  <div className="merchant__earning-chart">
                      <div className='choose-date'>
                          <div className='total-header'>Total Merchants Earnings</div>
                          <div className="date-picking"><DatePicker onChange={(value) => setStartDate(moment(value).format("MM-DD-YYYY"))} defaultValue={moment()} format={"MM-DD-YYYY"} /></div>
                      </div>
                      <div>
                         <Line data={data} options={options}></Line>
                      </div>
                  </div>
             </div>
            <div className="card card_wrapper  seller_table_wrapper">
                    <div className="data_heading">
                        <div className="header_order_title ">STORE EARNINGS</div>
                    </div>
                    {
                      earning_error && 
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className="mb-0">{earning_error || earning_error.message }</p>
                        </div>
                    }
                     {
                earning_lists_docs && earning_lists_docs.length > 0 ? 
                    <div className="table-responsive">
                    <table className="table seller_table">

                        
                    <thead className="tborder table-header px-4"  align="middle">
                        <tr className="align_student_table">
                            <th scope="col" className="header___status">Store Name</th>
                            <th scope="col" className="header__userId">Total Earning</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
        
                        <tbody align="middle">
                            {
                                earning_lists_docs && earning_lists_docs.map(({_id, all_time_earning
                                , store_id}) => (
                                    <tr className="transactions-item" key={_id}>
                                    <td className="item">{store_id && store_id.name}</td>
                                    <td className="item">&#x20A6; {all_time_earning.length > 7 ? nFormatter(all_time_earning) : numberSeperator(all_time_earning) }</td>
                                    <td  className="item earning-link" onClick={() => {handleNext(store_id && store_id._id)}}><Link to={`${match.url}/history`}>View Transactions</Link></td>
                                </tr>
                                ))
                            }
                        
                        </tbody>
            </table>
                </div>: <h2 className="text-center no-record-found">No Records Found</h2>
               }
         <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                    
                    <div className="page_number">Item per page {" "}{" "}
                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                    </div>
                { totalPages > 1 ? 
                    <div className="paginate_reuse">
                        <button className="left-chevron" onClick={() => {handleNextPage(prevPage)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage)}}><BsChevronRight /></button>
                    </div>: null
                }
            </div>  
            </div>
        </>
    )
}

export default Earnings


