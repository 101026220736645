export const login = data => {
    localStorage.setItem("gigAdminInfo", JSON.stringify(data))
} 


export const isAuthenticated = () => {
    return localStorage.getItem("gigAdminInfo") && localStorage.getItem("gigAdminInfo")
}

export const getLocalData = () => {
    return localStorage.getItem("gigAdminInfo") && JSON.parse(localStorage.getItem("gigAdminInfo"));
}
