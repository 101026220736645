import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {FaArrowLeft} from "react-icons/fa";
import TransactionHistory from './TransactionHistory';
import DownloadCSVModal from "../Reusables/DownloadCSVModal";
import { AppContext } from '../../AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { StoreEarningList } from './../../actions/Admin/TransactionAction';


 const ViewTransactions = () => {
     const { itemLimit,storeId,nFormatter, numberSeperator} = useContext(AppContext);
     const dispatch = useDispatch();
     const history = useHistory()
     const {store_earning_error,store_earning_lists,store_earning_lists_docs,store_earning_withdraw_transactions} = useSelector(state => state.storeEarningList);

 const storeName = store_earning_lists && store_earning_lists.store_name;
 const storeImg= store_earning_lists && store_earning_lists.store_image;
 const passiveBalance = store_earning_lists && store_earning_lists.passive_balance;
 const totalBalance = store_earning_lists && store_earning_lists.total_balance;
 const totalItemsSold = store_earning_withdraw_transactions && store_earning_withdraw_transactions.totalDocs


     useEffect(() => {
        const store_id = storeId
        const page = 1;
        const limit = itemLimit;
        const data = {store_id,page, limit}
       dispatch(StoreEarningList(data))
       
  }, [dispatch, itemLimit,storeId]);


     return (
        <div>
            
            <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div>
                        <div className="data_heading store-owner-page"  onClick={() => history.push("/dashboard/transactions")}>
                            <FaArrowLeft style={{cursor: "pointer"}}/><div className="header_order_title store-owner-header">STORE EARNINGS</div>
                        
                        </div>
                        <div className="card-body sub-category-content-body">
                            {store_earning_error ?
                                <div className="alert alert-danger" role="alert">
                                    <h4 className="alert-heading">Error!</h4>
                                    <hr/>
                                    <p className="mb-0">{store_earning_error || store_earning_error.message }</p>
                                </div> :
                           
                                store_earning_lists_docs && store_earning_lists_docs.length > 0 ?
                          
                                    (<div className="row">
                                
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                        <div className="store-img-name">
                                            <div className="image-div"><img src={storeImg} alt="store-icon"/></div>
                                            <div className="store-name-div">
                                                <span>Store Name</span>
                                                <span>{storeName}</span>
                                            </div>
                                        </div>
                                        {/* <button className="inventory-btn" onClick={openDownloadModal}><BiDownload />Download Inventory</button> */}
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card">
                                                <div className="card-body body-of-card card-prod-upload">
                                                    <h6>TOTAL NUMBER OF PRODUCTS SOLD</h6><br />
                                                    <p>{nFormatter(totalItemsSold)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card">
                                                <div className="card-body body-of-card card-prod-sold">
                                                    <h6>PASSIVE EARNINGS</h6><br />
                                                    <p>&#x20A6; {passiveBalance.length > 7 ?  nFormatter(passiveBalance) : numberSeperator(passiveBalance)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card">
                                                <div className="card-body body-of-card card-store-visit">
                                                    <h6>TOTAL EARNINGS</h6><br />
                                                    <p>&#x20A6; {totalBalance.length > 7 ?  nFormatter(totalBalance) : numberSeperator(totalBalance)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : (<h2 className="text-center no-record-found">No Records Found</h2>)
                            }
                        </div>
                    
                </div> 
            </div>
            <TransactionHistory/>

            {/* {showDownloadModal && <DownloadCSVModal setShowDownloadModal={setShowDownloadModal}/>} */}
        </div>
     )
 }

 export default ViewTransactions