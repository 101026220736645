import { GET_CATEGORY_SALES_FAIL, GET_CATEGORY_SALES_REQUEST, GET_CATEGORY_SALES_SUCCESS } from "../../../constants/dashboard/salesByCategory";
const initialState = {
    sales_by_category: {},
    sales_by_category_loading: false,
    sales_by_category_error: ""
}


export const salesByCategoryReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_CATEGORY_SALES_REQUEST:
            return {
                ...state,
                sales_by_category_loading: true,
            }
        case GET_CATEGORY_SALES_SUCCESS:
            return {
                ...state,
                sales_by_category_loading: false,
                sales_by_category: payload
            }
        case GET_CATEGORY_SALES_FAIL:
            return {
                ...state,
                sales_by_category_loading: false,
                sales_by_category_error: action
            }
        default:
            return state
    }
}