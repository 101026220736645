import {useState, useEffect} from "react";
import { FaPlus } from 'react-icons/fa';
import { useSelector,useDispatch  } from 'react-redux';
import { useHistory} from 'react-router-dom';
import { createPaymentGatewayAction } from "../../actions/Admin/PaymentGateway";
import Loaders from "../Reusables/Loader";
import { Alert } from 'antd';
const CreatePaymentMethod = ({setShowPaymentModal}) => {
  const history = useHistory()
  const [name, setName] = useState("");
  const [image_url, setImageUrl] = useState("");
  const [preview, setPreview] = useState("");
  const [error, setError] = useState("")
  const [gatewayError, setGatewayError] = useState("");
  const [gatewaySuccess, setGatewaySuccess] = useState("")

  const dispatch = useDispatch();
  const  {create_payment_gateway_loading, payment_gateways: payment_create_success, payment_gateway_error: payment_create_error} =  useSelector(state => state.createPaymentGateway)
  const gateways  = useSelector(state => state.createPaymentGateway)
  const handleChange = (e) => {
      setName(e.target.value)
  }
  //
  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0]
    setImageUrl(selectedFile);
    const filePreview = URL.createObjectURL(selectedFile);
    setPreview(filePreview)
}
  const resetForm = () => {
      setName("")
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("name", name);
    data.append("image_url", image_url);
     if(!name || !image_url){
        setError("The name field is empty");
        return
     }
     dispatch(createPaymentGatewayAction(data));
  }

  useEffect(() => {
    if(payment_create_success && !create_payment_gateway_loading){
      resetForm()
   }

  }, [])

  useEffect(() => {
    if(payment_create_error){
      setGatewayError(payment_create_error)
    }
  }, [payment_create_error])
  useEffect(() => {
      if(payment_create_success){
        setGatewaySuccess("Gateway created")
      }
     
  }, [payment_create_success]);

  useEffect(() => {
      const gatewayErrorText = setTimeout(() => {
      setGatewayError("")
    }, 3000);
    return () => clearTimeout(gatewayErrorText)
  }, [gatewayError])

  useEffect(() => {
    const errorText = setTimeout(() => {
      setError("")
    }, 3000);
    return () => clearTimeout(errorText)
  }, [error]);
  useEffect(() => {
    const gatewaySuccessText = setTimeout(() => {
      setGatewaySuccess("")
    }, 3000);
    return () => clearTimeout(gatewaySuccessText)
  }, [gatewaySuccess])

    return (
        <div className="payment_modal">
          <div className="card payment_add_method">
            <div className="data_heading">
              <div className="header_order_title type_header">ADD NEW PAYMENT METHOD</div>
            </div>
            {gatewayError && gatewayError ?   <Alert message="Failed"
                description={gatewayError}
                type="error"
                closable
                className="my-3"
                /> :
              <form className="add_payment_form">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label htmlFor="category" className="payment_label">Display Name</label>
                      <input type="text" className="payment_input" value={name} onChange={handleChange}/>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <form enctype=" multipart/form-data">
                          <input type="file"  id="file" accept="image/*" className="payment-image-upload" name="image_url" onChange={handleFileUpload}/>
                          <label htmlFor="file" className="upload-file-img"><FaPlus/> Upload Display Image</label>  
                          {image_url && <div className="image-preview">
                              <img src={preview} alt={image_url.name}/>
                              <div>{preview}</div>
                          </div>} 
                      </form>
                     
                    </div>
                  </div>
                  {error && <p className="text-danger text-center">{error}</p>}
                  {gatewaySuccess && gatewaySuccess && <p className="text-success text-center">{gatewaySuccess}</p>}
                  <div className="cta_btn">
                    <button className="type_button" onClick={onSubmit}>{create_payment_gateway_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={create_payment_gateway_loading} color="#FFFFFF" size={20}/></div> : "SAVE"}</button>
                    <button className="type_cancel_button" onClick={() => {history.push("/dashboard/payment-methods"); setGatewayError(""); setError(""); setGatewaySuccess("")}}>CANCEL</button>
                  </div>
              </form>
            }
        </div>
  </div>
    )
}

export default CreatePaymentMethod