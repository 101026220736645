import {useContext} from "react"
import "./customer.css";
import Buyer from "./Buyer";
import StoreOwners from "./StoreOwners";
import { AppContext } from "../../AppContext";
import { useDispatch } from "react-redux";
import { clearProductSearchState } from "../../actions/Admin/search/SearchAction";

const CustomersDB = ({match}) => {
    const dispatch = useDispatch(null);
    const { activeCustomerTab,setActiveCustomerTab} = useContext(AppContext)
    // const [customersDetails, setCustomersDetails] = useState(false);
  
  
    // const handleCustomersDetails = () => {
    //     setCustomersDetails(prev => !prev)
    // }

    
    return (
        <>
        {
             <div className="customers_container">
                <ul>
                    <li className={activeCustomerTab === 1 ? "tabs_active" : "tab_color"} onClick={() => {setActiveCustomerTab(1); dispatch(clearProductSearchState())}}>STORE OWNERS</li>
                    <li className={activeCustomerTab === 2 ? "tabs_active" : "tab_color"} onClick={() => {setActiveCustomerTab(2); dispatch(clearProductSearchState())}}>BASIC BUYERS</li>
                </ul>
             </div>

        }
     
        {activeCustomerTab ===  1 && 
          
               <StoreOwners match={match}/>
        }


        {activeCustomerTab === 2  && <Buyer match={match}/>} 
        {/* {
        <BuyerItem customersDetails={customersDetails} setCustomersDetails={setCustomersDetails} handleCustomersDetails={handleCustomersDetails} handleMenuSwitch={handleMenuSwitch} showDownloadModal={showDownloadModal} openDownloadModal={openDownloadModal} setShowDownloadModal={setShowDownloadModal}/>
        } */}
        
        </>
    )
}


export default CustomersDB;