import { GET_TOP_SELLING_PRODUCT_FAIL, GET_TOP_SELLING_PRODUCT_REQUEST, GET_TOP_SELLING_PRODUCT_SUCCESS } from "../../../constants/dashboard/topSellingProductConstant";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from "../../../helpers/getUrl";
const token = localData && localData.token

export function TopSellingProductAction ({page,limit}) {
    return async(dispatch,getState) => {
        
        dispatch({type: GET_TOP_SELLING_PRODUCT_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/product/top_selling?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_TOP_SELLING_PRODUCT_FAIL, payload: data.message})  
            }
            dispatch({type: GET_TOP_SELLING_PRODUCT_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_TOP_SELLING_PRODUCT_FAIL, payload: message})
        }
    }
}