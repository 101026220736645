import { useLocation } from "react-router-dom";
const Popup = ({children}) => {
    const search = useLocation().search;
    const display = new URLSearchParams(search).get("detail");
    return (
        <div className={(display && display === "new") || (display && display === "all")  || (display && display === "processing") || (display && display === "shipped") || (display && display === "delivered") || (display && display === "declined") ? "modal-pop-up show" : (display && display === "unverified") ? "modal-pop-up show" : "modal-pop-up"}>
            {children}
        </div>
    )
}

export default Popup;
