import { useContext } from "react";
import {HiDatabase} from "react-icons/hi"
import {RiFolderUploadFill} from "react-icons/ri";
import {FaUsers} from "react-icons/fa";
import { FcSalesPerformance } from 'react-icons/fc';
import { AppContext } from "../../AppContext";
import { useProductStatsQuery, useRecentOrderQuery } from "../../actions/Admin/sales/RecentOrdersAction";
import { handleAlphaError } from "../../utils/handleAlphaError";
import Loaders from "../Reusables/Loader";
import "./sale.css";

const SalesAnalytics = () => {
    const {isError: isRecentOrderError, error: recentOrderError, data: recentOrderData, isLoading: isRecentOrderLoading} = useRecentOrderQuery({page: 1, limit: 10})

    const {isError, error, data, isLoading} = useProductStatsQuery()
    const {nFormatter} = useContext(AppContext);

    const errorMessage = handleAlphaError(isError,error, "data");
    const recentOrderErrorMessage = handleAlphaError(isRecentOrderError,recentOrderError, "data");
    const stats = data && data.results
    const totalRevenue = recentOrderData && recentOrderData.results && recentOrderData.results.totalRevenue


    return (
        <div className="row">
            <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="card sales-card">
                    <div className="card-body" title={totalRevenue}>
                    { 
                          isRecentOrderLoading ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}><Loaders loading={isRecentOrderLoading} color="#000000" size={30} /></div>) :
                           isRecentOrderError ?
                            ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(recentOrderErrorMessage)["message"] : JSON.parse(recentOrderErrorMessage)}</p>) : 
                            <>
                                <p className="sale-header-analytics">Revenue</p>
                                <div className="revenue-card"><p className="revenue-number">&#x20A6;{nFormatter(totalRevenue)}</p><p className="revenue-icon"><HiDatabase /></p></div>
                            </>
                        }
                        {/* <p className="revenue-on-item"><AiOutlineArrowUp />{""}<span className="revenue-item">+13 from Last week</span></p> */}
                    </div>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="card sales-card">
                    <div className="card-body" title={stats?.soldProducts}>
                        { 
                          isLoading ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>) :
                           isError ?
                            ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>) : 
                            <>
                                <p className="sale-header-analytics sold-product">Sold Products</p>
                                <div className="revenue-card"><p className="revenue-number">{nFormatter(stats?.soldProducts)}</p><p className="revenue-icon sold-product-icon"><FcSalesPerformance /></p></div>
                            </>
                        }
                        {/* <p className="revenue-on-item sold-revenue-on-item"><AiOutlineArrowDown />{""}<span className="revenue-item">-10 from Last week</span></p> */}
                    </div>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="card sales-card">
                    <div className="card-body" title={stats?.totalProducts}>
                    { 
                  isLoading ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>) :
                 isError ?
                    ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>) : 
               
                        <>
                            <p className="sale-header-analytics sale-product-uploaded">Product Uploaded</p>
                            <div className="revenue-card"><p className="revenue-number">{nFormatter(stats.totalProducts)}</p><p className="revenue-icon product-upload-icon"><RiFolderUploadFill /></p></div>
                        </> 
                       }    
                    </div>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="card sales-card">
                    <div className="card-body" title={stats?.totalVisits}>
                        { 
                            isLoading ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>) :
                            isError ?
                                ( <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>) : 
                                    <>
                                        <p className="sale-header-analytics visit-site">Site Visits</p>
                                        <div className="revenue-card"><p className="revenue-number">{nFormatter(stats.totalVisits)}</p><p className="revenue-icon site-visit-icon"><FaUsers /></p></div>
                                    </>
                        }
                    </div>
            </div>
        </div>
        </div>
    )
}

export default SalesAnalytics;