import { GET_TODAYS_TRANSACTIONS_FAIL, GET_TODAYS_TRANSACTIONS_REQUEST, GET_TODAYS_TRANSACTIONS_SUCCESS } from "../../../constants/dashboard/todaysTransactionsConstant";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from "../../../helpers/getUrl";
const token = localData && localData.token

export function TodaysTransactionsAction ({offset,limit}) {
    return async(dispatch,getState) => {
        
        dispatch({type: GET_TODAYS_TRANSACTIONS_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/today_orders?offset=${offset}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            
            const data = await res.json();
            if(data.error){
                return dispatch({type: GET_TODAYS_TRANSACTIONS_FAIL, payload: data.message})  
            }
            dispatch({type: GET_TODAYS_TRANSACTIONS_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_TODAYS_TRANSACTIONS_FAIL, payload: message})
        }
    }
}