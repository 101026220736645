import { GET_SALES_OVERVIEW_FAIL, GET_SALES_OVERVIEW_REQUEST, GET_SALES_OVERVIEW_SUCCESS } from "../../../constants/sales/salesOverviewConstant";

const initialState = {
    sales_overview_loading: false,
    sales_overview_error: "",
    sales_overview: [],
}

export const salesOverviewReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_SALES_OVERVIEW_REQUEST:
            return {
                ...state,
                sales_overview_loading: true
            }
        case GET_SALES_OVERVIEW_SUCCESS:
            return {
                ...state,
                sales_overview_loading: false,
                sales_overview: payload
            }
        case GET_SALES_OVERVIEW_FAIL:
            return {
                ...state,
                sales_overview_loading: false,
                sales_overview_error: payload
            }
        default:
            return state
    }
}
