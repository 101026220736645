import { CLEAR_ETA_DELETE, CLEAR_ETA_STATE, CLEAR_ETA_UPDATE, CREATE_ETA_FAIL, CREATE_ETA_REQUEST, CREATE_ETA_SUCCESS, CREATE_REASON_FAIL, CREATE_REASON_REQUEST, CREATE_REASON_SUCCESS, CREATE_VEHICLE_FAIL, CREATE_VEHICLE_REQUEST, CREATE_VEHICLE_SUCCESS, DELETE_ETA_FAIL, DELETE_ETA_REQUEST, DELETE_ETA_SUCCESS, LIST_REASON_FAIL, LIST_REASON_REQUEST, LIST_REASON_SUCCESS, UPDATE_ETA_FAIL, UPDATE_ETA_REQUEST, UPDATE_ETA_SUCCESS } from "../../constants/settingsConstant";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from './../../helpers/getUrl';
const token = localData && localData.token
export function ListReasons(){
    return async (dispatch, getState) => {
        dispatch({type: LIST_REASON_REQUEST});

        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/list`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: LIST_REASON_FAIL, payload: data.message})
            }
            dispatch({type: LIST_REASON_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: LIST_REASON_FAIL, payload: message})
        }
    }
}

export function CreateReasons(dataToSubmit){
    return async (dispatch, getState) => {
        dispatch({type: CREATE_REASON_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/create_reason`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: CREATE_REASON_FAIL, payload: data.message})
            }
            dispatch({type: CREATE_REASON_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: CREATE_REASON_FAIL, payload: message})
        }
    }
}



export function CreateVehicleType(dataToSubmit){
    return async (dispatch, getState) => {
        dispatch({type: CREATE_VEHICLE_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/vehicle_type/new`, {
                method: "POST",
                headers: {
                  "Authorization": `Bearer ${token}`
                },
                body: dataToSubmit
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: CREATE_VEHICLE_FAIL, payload: data.message})
            }
            dispatch({type: CREATE_VEHICLE_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: CREATE_VEHICLE_FAIL, payload: message})
        }
    }
}


export function CreateEta(dataToSubmit){
    return async (dispatch, getState) => {
        dispatch({type: CREATE_ETA_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/eta/create`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: CREATE_ETA_FAIL, payload: data})
            }
            dispatch({type: CREATE_ETA_SUCCESS, payload: data})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: CREATE_ETA_FAIL, payload: message})
        }
    }
}
export function UpdateEta(dataToSubmit){
    return async (dispatch, getState) => {
        dispatch({type: UPDATE_ETA_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/eta/update`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: UPDATE_ETA_FAIL, payload: data})
            }
            dispatch({type: UPDATE_ETA_SUCCESS, payload: data});
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: CREATE_ETA_FAIL, payload: message})
        }
    }
}

export function DeleteEta(id){
    return async (dispatch, getState) => {
        dispatch({type: DELETE_ETA_REQUEST})
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/setting/eta/delete?id=${id}`, {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: DELETE_ETA_FAIL, payload: data})
            }
            dispatch({type: DELETE_ETA_SUCCESS, payload: data});
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: DELETE_ETA_FAIL, payload: message})
        }
    }
}


export const clearEtaState = () => async (dispatch) => {
      dispatch({type: CLEAR_ETA_STATE});
      dispatch({type: CLEAR_ETA_UPDATE});
      dispatch({type: CLEAR_ETA_DELETE})
}