import {useState, useEffect,useContext} from "react";
import { useSelector, useDispatch } from "react-redux";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io"
import CloseButton from "./CloseButton";
import RoleModal from "./RoleModal";
import { AdminRoleCreate, AdminRoleList } from "../../actions/Admin/AdminRoleAction";
import Loaders from "../Reusables/Loader";
import { createAdmin } from "../../actions/Admin/AdminAction";
import { AppContext } from "../../AppContext";

const CreateRole= () => {
     const {showModal,openModal,setShowModal} = useContext(AppContext)
     const [openContent, setOpenContent] = useState(false);
     const [getRole, setGetRole] = useState(null)
     const [search, setSearch] = useState("")
     const [first_name, setFirstName] = useState("");
     const [last_name, setLastName] = useState("");
     const [phone, setPhone] = useState("");
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     const [createAdminSuccess, setCreateAdminSuccess] = useState("")
     
     const [name, setName] = useState("");
     const handleChange = (e) => {
         setName(e.target.value)
     }

     const handleRoleClick = (data) => {
         setGetRole(data)
     }
     const handleRoleCreate = (e) => {
         e.preventDefault();
         dispatch(AdminRoleCreate({name}))
     }

     const dispatch = useDispatch()
     const {loading, error, role: userRole} = useSelector(state => state.createRole);
     const {loading: listRoleLoading, error: listRoleError, roles} = useSelector(state => state.listRole);
     const {loading: loadCreateAdmin, error: loadAdminCreateError, admin} = useSelector(state => state.createAdmin);
  

   const dataToSubmit = {first_name, last_name, role: getRole, email, password, phone}


     const setSelected = selected => {
         setSearch(selected)
         setOpenContent(false)
     }

    const handleDataSubmit = (e) => {
         e.preventDefault()
         dispatch(createAdmin(dataToSubmit))
     }
     useEffect(() => {
        if(admin){
            setCreateAdminSuccess(admin)
        }
    }, [admin]);
    useEffect(() => {
        let createCreate;
        if(admin){
            createCreate = setTimeout(() => {
                setCreateAdminSuccess("")
            }, 3000)
        }
       return () => clearTimeout(createCreate)
    }, [admin])
    
     useEffect(() => {
             dispatch(AdminRoleList())
     }, [dispatch])
    return (
        <>
          {listRoleError ? <div className="view_admins_error"> <p className="mt-1">{listRoleError}</p></div> :
                <div className="role_container mt-4">
                <div className="card  create_role_card">
                    {listRoleLoading && <Loaders loading={listRoleLoading} color="#ffffff" size={20}/>}        
                    {loadAdminCreateError &&  <p className="text-danger text-center mt-3">{loadAdminCreateError}</p>}
                    {createAdminSuccess && createAdminSuccess.message && <p className="text-success text-center mt-3">{ createAdminSuccess.message}</p>}
                <div className="data_heading">
                    <div className="header_order_title role_header">ADD NEW ROLE</div>
                    <button className="admin_role_add" onClick={openModal}><span>Add New Category</span></button>
                </div>
                <form className="create_admin" onSubmit={handleDataSubmit}>
                    <div className="admin_firstname"><label>First Name</label><input type="text" value={first_name} onChange={(e) => setFirstName(e.target.value)}/></div>
                    <div className="admin_lastname"><label>Last Name</label><input type="text" value={last_name} onChange={(e) => setLastName(e.target.value)}/></div>
                    <div className="admin_email"><label>Email address</label><input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/></div>
                    <div className="admin_phonenumber"><label>Phone Number</label><input type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/></div>
                    <div className="admin_assign_role"><label> Assign Role</label><div className="role__input"  onClick={() => setOpenContent(!openContent)}><input type="text" placeholder="-------- Choose Role --------" onChange={(e) => setSearch(e.target.value)} value={search}/>{openContent ?<IoIosArrowUp  className="arrow_down arrow_up"/>:<IoIosArrowDown className="arrow_down"/>}
                    
                    </div>
                    {openContent ?    <div className="role__content">
                        <p>------- Choose Role --------</p>
                        {
                            roles?.results && roles?.results.filter(({name}) => name.indexOf(search.toLowerCase()) > -1).map(role => (
                                <ul key={role._id}>             
                                    <li onClick={() => {
                                        handleRoleClick(role._id)
                                        setSelected(role.name)
                                        }}>{role.name}</li>
                                </ul>
                                
                                ))
                        }
                        
                        
                    </div>: ""}
                    
                    </div>
                    <div className="admin_password"><label>Assign Password</label><input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/></div>
                    <button className="register_admin">{loadCreateAdmin ? <Loaders loading={loadCreateAdmin} color="#ffffff" size={20}/>: "submit"}</button>
                    
                </form>
                
                </div>
            
            
            
                    <RoleModal showModal={showModal} setShowModal={setShowModal} openModal={openModal} loading={loading} role={userRole} error={error} handleRoleCreate={handleRoleCreate} name={name} handleChange={handleChange} category="ENTER NEW ROLE CATEGORY"/>
                    <CloseButton showModal={showModal} setShowModal={setShowModal} openModal={openModal} close={"/dashboard/roles"}/>
                </div>
            }
        </>
      
    )
}


export default CreateRole