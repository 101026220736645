import { GET_CLASS_MERCHANT_FAIL, GET_CLASS_MERCHANT_REQUEST, GET_CLASS_MERCHANT_SUCCESS } from "../../constants/getClassMerchant";
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";

const token = localData && localData.token

export function GetClassMerchant() {
    return async(dispatch, getState) => {
    dispatch({type: GET_CLASS_MERCHANT_REQUEST});
    
    try {
        const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/user/merchant`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json",
                "Authorization": `Bearer ${token}`
            }

        })

        const data= await res.json();
        if(data.error){
            return  dispatch({type: GET_CLASS_MERCHANT_FAIL, payload: data.message})
        }

        dispatch({type: GET_CLASS_MERCHANT_SUCCESS, payload: data})
    } catch (error) {
      const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
        dispatch({type: GET_CLASS_MERCHANT_FAIL, payload: message})
    }
}
}