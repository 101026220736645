import { useEffect } from "react"
import Chart from "react-apexcharts"
import useGenerateColor from "../helpers/useGenerateColor"
const DoughnutChart = ({productOverview}) => {
    const categoryName = productOverview && productOverview.map(({categoryName}) => {
        return categoryName
    })
    const categoryAmount = productOverview && productOverview.map(({amount}) => {
        return amount
    })

    const {color, colorRange} = useGenerateColor();
    useEffect(() => {
        colorRange(productOverview && productOverview.length)
    }, []);
    
    return (
        <>
        { 
            categoryAmount?.length > 0 && categoryName?.length > 0 ? 
       
            <Chart 
                 type="donut"
                 series={categoryAmount}
                 options={{
                     labels: categoryName,
                     colors: color,
                     plotOptions: {
                        pie: {
                            donut: {
                                size: '60%',
                                labels: {
                                    show: true,
                                    total: {
                                        show: true,
                                        fontSize: 16
                                    }
                                }
                            },
                            customScale: 1.1
                        }
                     },
                     dataLabels: {
                        enabled: true,
                        
                     },
                     legend: {
                        position: 'bottom',
                      }
                 }}
            /> : <p className="text-center">No data Yet</p>
        }

        </>
    )
}

export default DoughnutChart;