import { useContext } from "react";
import { AppContext } from "../AppContext";
import "../merchants.css"

const MonthSort = () => {
     const {setMonthsRange} = useContext(AppContext)
     return (
     
            <select onChange={(e) => setMonthsRange(e.target.value)} className="month_select">
                 <option value="all">All</option>
                 <option value="0-3">Jan-April</option>
                 <option value="4-7">May-Aug</option>
                 <option value="8-11">Sept-Dec</option>
             </select>
     )
}

export default MonthSort;
