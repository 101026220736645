const DeleteModal = ({children, header}) => {
    return (
        <div className="payment_modal">
            <div className="card payment_add_method">
                <div className="data_heading">
                    <div className="header_order_title type_header text-center">{header}</div>
                </div>
                <div className="card-body delete__modal__body">
                     {children}
                </div>
            </div>
        </div>
    )
}


export default DeleteModal;