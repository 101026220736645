import { GET_BASIC_BUYER_DETAIL_FAIL, GET_BASIC_BUYER_DETAIL_REQUEST, GET_BASIC_BUYER_DETAIL_SUCCESS } from './../../constants/getBasicBuyer';

const initialState =  {
    basic_buyer_loading: true,
    basic_buyer_detail: {},
    basic_buyer_detail_docs: [],
    basic_buyer_error: ""
}

export const basicBuyerDetailsReducer = (state=initialState, action) => {
       switch(action.type){
            case GET_BASIC_BUYER_DETAIL_REQUEST:
                return {
                    ...state,
                    basic_buyer_loading: true
                }
            case GET_BASIC_BUYER_DETAIL_SUCCESS: 
               return {
                   ...state,
                   basic_buyer_loading: false,
                   basic_buyer_detail: action.payload,
                   basic_buyer_detail_docs: action.payload.docs
               }
            case GET_BASIC_BUYER_DETAIL_FAIL:
                return {
                    ...state,
                    basic_buyer_loading: false,
                    basic_buyer_error: action.payload,
                }
            default:
                return state
       }
}