import { CLEAR_ETA_DELETE, CLEAR_ETA_STATE, CLEAR_ETA_UPDATE,  CREATE_ETA_FAIL, CREATE_ETA_REQUEST, CREATE_ETA_SUCCESS, CREATE_REASON_FAIL, CREATE_REASON_REQUEST, CREATE_REASON_SUCCESS, CREATE_VEHICLE_FAIL, CREATE_VEHICLE_REQUEST, CREATE_VEHICLE_SUCCESS, DELETE_ETA_FAIL, DELETE_ETA_REQUEST, DELETE_ETA_SUCCESS, LIST_REASON_FAIL, LIST_REASON_REQUEST, LIST_REASON_SUCCESS, UPDATE_ETA_FAIL, UPDATE_ETA_REQUEST, UPDATE_ETA_SUCCESS, UPDATE_PERMISSION_FAIL, UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS } from "../../../constants/settingsConstant"

const initialState = {
    reasons_loading: false,
    update_permission_loading: false,
    list_reasons_loading: false,
    create_vehicle_type_loading: false,
    list_vehicle_type_loading: false,
    eta_create_loading: false,
    eta_update_loading: false,
    delete_eta_loading: false,
    get_eta_loading: false,
    reason_success: false,
    update_permission_success: false,
    create_vehicle_type_success: false,
    eta_create_success: false,
    eta_update_success: false,
    get_eta_success: false,
    delete_eta_success: false,
    eta_create: [],
    create_reasons_docs: [],
    create_reasons: [],
    create_vehicle_type: [],
    get_reason: {},
    get_eta_update: {},
    update_permission: {},
    create_reasons_error: "",
    list_reasons_error: "",
    get_reasons_error: "",
    update_permission_error: "",
    create_vehicle_type_error: "",
    list_vehicle_error: "",
    eta_update_error: "",
    eta_create_error: "",
    eta_update_clear: "",
    get_eta_error : "",
    delete_eta_error : ""

}

export const createReasons = (state = initialState, action) => {
     switch(action.type){
        case CREATE_REASON_REQUEST:
            return {
                ...state,
                reasons_loading: true,
            }
        case CREATE_REASON_SUCCESS:
            return {
                ...state,
                reasons_loading: false,
                create_reasons : action.payload.results,
                reason_success: true
            }
        case CREATE_REASON_FAIL:
            return {
                ...state,
                reasons_loading: false,
                create_reasons_error: action.payload,
                reason_success: false
            }
        default:
            return state
     }
}

export const listReasonsReducer = (state=initialState, action) => {
     switch(action.type){
        case LIST_REASON_REQUEST:
            return {
                ...state,
                list_reasons_loading: true
            }
        case LIST_REASON_SUCCESS: 
            return {
                ...state,
                list_reasons_loading: false,
                create_reasons: action.payload.results
            }
        case LIST_REASON_FAIL:
            return {
                ...state,
                list_reasons_loading: false,
                list_reasons_error: action.payload
            }
        default:
            return state
     }
}


export const updatePermission = (state = initialState, action) => {
    switch(action.type){
       case UPDATE_PERMISSION_REQUEST:
           return {
               ...state,
               update_permission_loading: true,
           }
       case UPDATE_PERMISSION_SUCCESS:
           return {
               ...state,
               update_permission_loading: false,
               create_reasons : action.payload.results,
               update_permission_success: true
           }
       case UPDATE_PERMISSION_FAIL:
           return {
               ...state,
               update_permission_loading: false,
               update_permission_error: action.payload,
               update_permission_success: false
           }
       default:
           return state
    }
}

export const createVehicleType = (state = initialState, action) => {
    switch(action.type){
       case CREATE_VEHICLE_REQUEST:
           return {
               ...state,
               create_vehicle_type_loading: true,
           }
       case CREATE_VEHICLE_SUCCESS:
           return {
               ...state,
               create_vehicle_type_loading: false,
               create_vehicle_type : action.payload.results,
               create_vehicle_type_success: true
           }
       case CREATE_VEHICLE_FAIL:
           return {
               ...state,
               create_vehicle_type_loading: false,
               create_vehicle_type_error: action.payload,
               create_vehicle_type_success: false
           }
       default:
           return state
    }
}

export const createEta = (state = initialState, action) => {
    switch(action.type){
        case CREATE_ETA_REQUEST:
            return {
                ...state,
                eta_create_loading: true
            }
        case CREATE_ETA_SUCCESS:
            return {
                ...state,
                eta_create_loading: false,
                eta_create: action.payload.results,
                eta_create_success: true
            }
        case CREATE_ETA_FAIL:
            return {
                ...state,
                eta_create_loading: false,
                eta_create_success: false,
                eta_create_error: action.payload
            }
        case CLEAR_ETA_STATE:
            return {
                ...state,
                eta_create_loading: false,
                eta_create_error: "",
                eta_create_success: false
            }
        default:
            return state 
    }
}


export const updateEta = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_ETA_REQUEST:
            return {
                ...state,
                eta_update_loading: true
            }
        case UPDATE_ETA_SUCCESS:
            return {
                ...state,
                eta_update_loading: false,
                eta_update_success: true,
                get_eta_update: action.payload
            }
        case UPDATE_ETA_FAIL:
            return {
                ...state,
                eta_update_loading: false,
                eta_update_success: false,
                eta_update_error: action.payload
            }
        case CLEAR_ETA_UPDATE:
            return {
                ...state,
                eta_update_loading: false,
                eta_update_error: "",
                eta_update_success: false
            }
        default:
            return state 
    }
}

export const deleteEta = (state = initialState, action) => {
    switch(action.type){
        case DELETE_ETA_REQUEST:
            return {
                ...state,
                delete_eta_loading: true
            }
        case DELETE_ETA_SUCCESS:
            return {
                ...state,
                delete_eta_loading: false,
                delete_eta_success: true,
            }
        case DELETE_ETA_FAIL:
            return {
                ...state,
                delete_eta_loading: false,
                delete_eta_success: false,
                delete_eta_error: action.payload
            }
        case CLEAR_ETA_DELETE:
            return {
                ...state,
                delete_eta_loading: false,
                delete_eta_error: "",
                delete_eta_success: false
            }
        default:
            return state 
    }
}