import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Graph from "./Graph";
import { AppContext } from "../../AppContext";
import { BasicBuyersAction } from "../../actions/Admin/CustomerDBAction";
import BuyerTable from "./BuyerTable";



const Buyer = ({match}) => {
    const dispatch = useDispatch();
    const {basic_buyer_error, basic_buyer, basic_buyers_users_data_docs, basic_buyers_users_data } = useSelector(state => state.BasicBuyerReducer);
    const {itemLimit, pageNumber} = useContext(AppContext);
    useEffect(() => {
        const page = pageNumber;
        const limit = +itemLimit;
        const data = {page, limit}
        dispatch(BasicBuyersAction(data));
    
    }, [dispatch, itemLimit, pageNumber]);
 return (
        <>
            <>
            {basic_buyer_error &&  <p className="text-danger">{basic_buyer_error || basic_buyer_error.message}</p>}
             
               <div className="row mt-4">
                <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12">
                                <div className="card  card_size">
                                <div className="card__header">ACTIVE ACCOUNTS</div>
                                    <div className="card-body">
                                        <div className="update_header">
                                            <h3 className="up  mt-2">{basic_buyer && basic_buyer.active_accounts}</h3>
                                            </div>
                                    </div>
                                </div>
                                <div className="card card_size mt-4">
                                <div className="card__header">INACTIVE ACCOUNTS</div>
                                    <div className="card-body">
                                        
                                        <div className="update_header">
                                            <h3 className="down  mt-2">{basic_buyer && basic_buyer.inactive_accounts}</h3>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 col-12 ">   
                                <div className="card card_size_total">
                                <div className="card__header">TOTAL ACCOUNTS</div>
                                    <div className="card-body">
                                        
                                        <div className="update_header">
                                            <h3 className="up  mt-2">{basic_buyer && basic_buyer.total_accounts}</h3>
                                            </div>
                                            <hr />
                                            <p>50% Increase from Last Week</p>
                                            <p>50% Increase from Last Month</p>
                                            <p>26% Increase from Last Year</p>
                                    </div>
                                </div>
                            </div>
                </div>
                </div>
                <div className="col-sm-12 col-md-6 col-12 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card__header">BASIC BUYERS FLOW</div>
                        <div className="card-body">
                            <Graph chat_data={basic_buyer.chart_data}/>
                        </div>
                    </div>
                </div>
             </div>
            <BuyerTable basic_buyers_users_data_docs={basic_buyers_users_data_docs} basic_buyers_users_data={basic_buyers_users_data} basic_buyer_error={basic_buyer_error} match={match}/>
          </>
    </>
    )
}

export default Buyer;