import { GET_ROLE_FAIL, GET_ROLE_REQUEST, GET_ROLE_SUCCESS, ROLE_CREATE_FAIL, ROLE_CREATE_REQUEST, ROLE_CREATE_SUCCESS, ROLE_DELETE_FAIL, ROLE_DELETE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_LIST_FAIL, ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS, ROLE_UPDATE_FAIL, ROLE_UPDATE_REQUEST, ROLE_UPDATE_SUCCESS } from "../../constants/roleConstant"
import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token
export function AdminRoleCreate(dataToSubmit) {
    return async(dispatch, getState) => {
        dispatch({type: ROLE_CREATE_REQUEST});
        
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/role/new`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                },
                body: JSON.stringify(dataToSubmit)
             });
             const data = await res.json();
            if(data.error){
               return  dispatch({type: ROLE_CREATE_FAIL, payload: data.message})
            }
            dispatch({type: ROLE_CREATE_SUCCESS, payload: data})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: ROLE_CREATE_FAIL, payload: message})
        }
  }
}


export function AdminRoleList(){
    return async(dispatch, getState) => {
        dispatch ({type: ROLE_LIST_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/role/all`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                }
             });
             const data = await res.json();
    
            if(data.error){
                return dispatch({type: ROLE_LIST_FAIL, payload: data.message})  
            }
            dispatch({type: ROLE_LIST_SUCCESS, payload: data})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: ROLE_LIST_FAIL, payload: message})
        }
    }
}


export function AdminRoleGet(roleId){
    return async(dispatch, getState) => {
        dispatch({type: GET_ROLE_REQUEST});
        try {
            
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/role/${roleId}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                }
             });
             const data = await res.json();
             if(data.error){
                 return dispatch({type: GET_ROLE_FAIL, payload: data.message})
             }
            dispatch({type: GET_ROLE_SUCCESS, payload: data})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: GET_ROLE_FAIL, payload: message})
        }
    }
} 


export function AdminRoleUpdate (roleId, dataToSubmit){
    return async(dispatch, getState) => {
        dispatch({type: ROLE_UPDATE_REQUEST});
        try {
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/role/${roleId}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                },
                body: JSON.stringify(dataToSubmit)
             });
             const data = await res.json();
    
           if(data.error){
                return dispatch({type: ROLE_UPDATE_FAIL, payload: data.message})
           }
    
            dispatch({type: ROLE_UPDATE_SUCCESS, payload: data})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: ROLE_UPDATE_FAIL, payload: message})
        }
    }
} 



export function AdminRoleDelete(roleId) {
    return async(dispatch, getState) => {
        dispatch({type: ROLE_DELETE_REQUEST});
        try {
          
            const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/role/${roleId}`, {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
      
                }
             });
             const data = await res.json();
             if(data.error){
                 return dispatch({type: ROLE_DELETE_FAIL, payload: data.message})
             }
      
            dispatch({type: ROLE_DELETE_SUCCESS, payload: data})
        } catch (error) {
          const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
            dispatch({type: ROLE_DELETE_FAIL, payload: message})
        }
    }
} 