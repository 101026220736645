export const REFUND_LIST_REQUEST = "REFUND_LIST_REQUEST";
export const REFUND_LIST_SUCCESS = "REFUND_LIST_SUCCESS"
export const REFUND_LIST_FAIL = "REFUND_LIST_FAIL";

export const COMPLETE_REFUND_REQUEST = "COMPLETE_REFUND_REQUEST";
export const COMPLETE_REFUND_SUCCESS = "COMPLETE_REFUND_SUCCESS";
export const COMPLETE_REFUND_FAIL = "COMPLETE_REFUND_FAIL";
export const CLEAR_COMPLETE_REFUND = "CLEAR_COMPLETE_REFUND";

export const RETURN_LIST_REQUEST = "RETURN_LIST_REQUEST";
export const RETURN_LIST_SUCCESS = "RETURN_LIST_SUCCESS";
export const RETURN_LIST_FAIL = "RETURN_LIST_FAIL";