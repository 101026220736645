import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiArrowLeft } from 'react-icons/fi';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import AddChecklist from './AddChecklist';
import { clearComplianceDelete, ListUploadCriteria } from '../../../actions/Admin/products/Products';
import DeleteItem from '../../Reusables/DeleteItem';
import { DeleteUploadCriteria } from './../../../actions/Admin/products/Products';
import Loaders from './../../Reusables/Loader';
import EditCheckList from './EditCheckList';


const Checklists = () => {
      const [id, setId] = useState("")
      const dispatch = useDispatch(null);

      const {list_upload_criteria,list_upload_criteria_error} = useSelector(state => state.listUploadCriteriaReducer);
      const {delete_criteria_loading, delete_criteria_error,delete_criteria_success} = useSelector(state => state.deleteComplianceCriteriaReducer)
      const history = useHistory();
      const search = useLocation().search;
      const checklists = new URLSearchParams(search).get("checklists");
     const deleteUploadCriteria = () => {
        if(id){
            dispatch(DeleteUploadCriteria(id))
        }
       
     }
      useEffect(() => {
         dispatch(ListUploadCriteria())
         
    }, [dispatch]);
    useEffect(() => {
        if(delete_criteria_success){
            dispatch(ListUploadCriteria())
        }
    }, [delete_criteria_success])

  return (
    <>
        <div className="permissions-container">
            
            <div className="reasons">
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                <div className="payment_title_header">
                    <div className="header_order_title"><FiArrowLeft className="back-navigation-set" onClick={() => history.push("/dashboard/settings")}/>PRODUCT UPLOAD CRITERIA</div>   
                    <div className="btn_add_new upload-criteria" onClick={() => history.push("/dashboard/settings/product/checklists?checklists=add-new")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New</span></div>
                        
                </div>
                <div className="card-body">
                {list_upload_criteria_error && <h3 className="text-danger mt-2 text-center">{list_upload_criteria_error}</h3>}
            {list_upload_criteria && list_upload_criteria.length <= 0 && !list_upload_criteria_error && <h2 className="text-center no-record-found">No Records Found</h2>}
                <div className="table-responsive">
                        <table className="table seller_table">
                        {list_upload_criteria && list_upload_criteria.length > 0 &&
                            <thead className="tborder table-header criteria-header" align="middle">
                                <tr className="align_student_table">
                                    <th scope="col"  className="header___name">Criteria</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col" className="header___status">Description</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col" className="header__userId criteria-actions"><div>Edit</div><div>Delete</div></th>
                                    
                                </tr>
                            </thead>
                        }
                        <tbody align="middle">
                        {
                             list_upload_criteria && list_upload_criteria.length > 0 && list_upload_criteria.map(({_id, name, tooltip}) => (
                                <tr className="transactions-item" key={_id}>
                                    <td className="item">{name}</td>
                                    <td></td>
                                    <td></td>
                                    <td className="item">{tooltip}</td>
                                    <td></td>
                                    <td></td>
                                    <td className="item criteria-actions-btn"><div onClick={() => {setId(_id); history.push("/dashboard/settings/product/checklists?checklists=edit")}}><FaEdit  /></div><div onClick={() => {setId(_id); history.push("/dashboard/settings/product/checklists?checklists=delete")}}><FaTrashAlt /></div></td>
                            
                                </tr>
                             ))
                        }
                               
                    
                            
                        </tbody>
                    </table>
              </div>
                </div>
            </div>
            </div>
        </div>
        {checklists === "add-new" && <AddChecklist />}
        {checklists === "delete" &&  id &&
            <DeleteItem header={"DELETE THIS UPLOAD CRITERIA"} fn={() => {history.push("/dashboard/settings/product/checklists")}}>
                <h3 className="delete-header">Are you sure you want to delete this Product upload criteria?</h3>
                {delete_criteria_error && <p className='text-center text-danger'>{delete_criteria_error}</p>}
                <div className="cta-btn-delete">
                   <button className="delete-confirm d-flex text-center align-items-center justify-content-center" onClick={deleteUploadCriteria}>{delete_criteria_loading ? <div style={{display: "block", textAlign: "center"}}><Loaders loading={delete_criteria_loading} color="#000000" size={20}/></div> : "DELETE"}</button><button className="delete-cancel" onClick={() => {history.push("/dashboard/settings/product/checklists"); dispatch(clearComplianceDelete())}}>{delete_criteria_success ? "CLOSE" : "CANCEL"}</button>
                </div>
            </DeleteItem>
        }
        {checklists === "edit" && id && <EditCheckList id={id}/>}
    </>
  )
}

export default Checklists