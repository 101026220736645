export const PAYMENT_GATEWAY_CREATE_REQUEST = "PAYMENT_GATEWAY_CREATE_REQUEST";
export const PAYMENT_GATEWAY_CREATE_SUCCESS = "PAYMENT_GATEWAY_CREATE_SUCCESS";
export const PAYMENT_GATEWAY_CREATE_FAIL = "PAYMENT_GATEWAY_CREATE_FAIL";

export const PAYMENT_GATEWAY_LIST_REQUEST = "PAYMENT_GATEWAY_LIST_REQUEST";
export const PAYMENT_GATEWAY_LIST_SUCCESS = "PAYMENT_GATEWAY_LIST_SUCCESS";
export const PAYMENT_GATEWAY_LIST_FAIL = "PAYMENT_GATEWAY_LIST_FAIL";

export const GET_PAYMENT_GATEWAY_REQUEST = "GET_PAYMENT_GATEWAY_REQUEST";
export const GET_PAYMENT_GATEWAY_SUCCESS = "GET_PAYMENT_GATEWAY_SUCCESS";
export const GET_PAYMENT_GATEWAY_FAIL = "GET_PAYMENT_GATEWAY_FAIL";   

export const PAYMENT_GATEWAY_UPDATE_REQUEST = "PAYMENT_GATEWAY_UPDATE_REQUEST";
export const PAYMENT_GATEWAY_UPDATE_SUCCESS = "PAYMENT_GATEWAY_UPDATE_SUCCESS";
export const PAYMENT_GATEWAY_UPDATE_FAIL = "PAYMENT_GATEWAY_UPDATE_FAIL";

export const PAYMENT_GATEWAY_DELETE_REQUEST = "PAYMENT_GATEWAY_DELETE_REQUEST";
export const PAYMENT_GATEWAY_DELETE_SUCCESS = "PAYMENT_GATEWAY_DELETE_SUCCESS";
export const PAYMENT_GATEWAY_DELETE_FAIL = "PAYMENT_GATEWAY_DELETE_FAIL";