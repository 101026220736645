import { CLEAR_TRACKER_ACTION, TRACK_ITEM_REQUEST, TRACK_ITEM_SUCCESS } from "../../../constants/trackerConstant"
import { TRACK_ITEM_FAILED } from './../../../constants/trackerConstant';

const initialState = {
    tracker_loading: false,
    tracker_success: false,
    error: "",
    tracker_result: []
}

export const trackerReducer = (state=initialState, action) => {
     switch(action.type){
        case TRACK_ITEM_REQUEST:
            return {
                ...state,
                tracker_loading: true
            }
        case TRACK_ITEM_SUCCESS:
            return {
                ...state,
                tracker_success : true,
                tracker_loading: false,
                tracker_result: action.payload
            }
        case TRACK_ITEM_FAILED: 
            return {
                ...state,
                error: action.payload,
                tracker_loading: false,
                tracker_success: false
            }
        case CLEAR_TRACKER_ACTION: 
           return {
             ...state,
             error: "",
             tracker_loading: false,
             tracker_success: false,
             tracker_result: []

           }
        default:
            return state;
     }
}