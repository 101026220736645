import { useEffect, useContext, useState } from "react";
import "../merchants.css";
import DoughnutChart from "./DoughnutChart";
import TopProducts from "./TopProducts";
import { useSelector, useDispatch } from "react-redux";
import { SalesByCategoryAction } from "../actions/Admin/dashboard/SalesByCategoryAction";
import { AppContext } from './../AppContext';

const ProductsCard = () => {
    const dispatch = useDispatch()
    const {sales_by_category} = useSelector(state => state.salesByCategoryReducer);
    const salesByCategory = sales_by_category && sales_by_category.results && sales_by_category.results.sales_by_category;
    const {nFormatter, numberSeperator } = useContext(AppContext);
    const productOverview = sales_by_category && sales_by_category.results && sales_by_category.results.product_overview

    useEffect(() => {
        dispatch(SalesByCategoryAction())
    }, [dispatch]);
    
    return (
        <div>

            <div className="row my-4">
                <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 col-12">
                    <div className="card prod_card">
                    <h5 className="card__header">PRODUCT CATEGORY</h5>
                        <div className="card-body">
                            {salesByCategory ? 
                            <div className="category">
                                {
                                    salesByCategory && salesByCategory.map(({_id,totalSales}) => (
                                        <div className="category__name" key={_id}>
                                          <span>{_id}</span><span className="category_total">&#8358;{totalSales.length > 7 ? nFormatter(totalSales) : numberSeperator(totalSales)}</span>
                                        </div>
                                    )) 
                                }
                               
                            </div> : <p className="text-center">No Sales Yet!</p>
                            }
                        
                             
                        </div>
                       
                    </div>
                </div>
                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 col-12">
                    <div className="card prod_card_donutchart">
                    <h5 className="card__header">PRODUCT OVERVIEW</h5>
                        <div className="card-body donutchart-card">
                            <DoughnutChart productOverview={productOverview}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 col-12">
                    <div className="card prod_card_chart">
                    <h5 className="card__header">TOP SELLING PRODUCTS</h5>
                        <div className="card-body">
                            <TopProducts />
                        </div>
                    </div>
                </div>
            </div>
        </div>
                                                 
                                
        
    )
}

export default ProductsCard