import React, { useEffect, useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
const LandingPage = () => {
    const [redirectNow, setRedirectNow] = useState(false);

    const loaderTimer = useCallback(() => {
         setRedirectNow(true)
      }, []);

    useEffect(() => {
        const timer = setTimeout(loaderTimer, 1000);
        return () => clearTimeout(timer)
      }, [loaderTimer]);
      
    return (
        <>

        <div className="loader-page">
            {redirectNow && <Redirect from="/" to="/dashboard" /> }
            <div className="loader">
                <img src="/alpha-favicon.png" alt="alpha-loader" />
            </div>
        </div>
        
       
        </>
    )
}


export default LandingPage;