import React, {useState, useRef, useEffect,useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Tooltip} from 'antd';
import {AiOutlineArrowLeft,AiFillCloseCircle} from "react-icons/ai";
import {IoIosCloseCircle} from "react-icons/io";
import {BsChevronLeft,BsChevronRight,BsFillInfoCircleFill} from "react-icons/bs";
import {GoVerified} from "react-icons/go"
import {FaPlayCircle} from "react-icons/fa";
import ImageSlider from './ImageSlider';
import Loaders from '../Reusables/Loader';
import ScaleLoader from "react-spinners/ScaleLoader";

import { GetProductDetails, UpdateProductComplianceStatus, UpdateProductView } from '../../actions/Admin/products/Products';
import { AppContext } from '../../AppContext';
import { clearComplianceStatus } from './../../actions/Admin/products/Products';

const override = {
  margin: "0",
  borderColor: "none",
};

const ValidationDetails = ({match: {params: {id}},urlPath}) => {
  const [color] = useState("#72FFC9");
  const [successMg, setSuccessMsg] = useState("")
  const dispatch = useDispatch(null);

  const {get_prod_details_loading,get_prod_details_error,get_prod_details} = useSelector(state => state.getProductDetails);
  const {product_compliance_status_loading,product_compliance_status_success, product_compliance_status_error} = useSelector(state => state.updateComplianceStatusReducer)
const {update_view_error, update_view_loading,update_view_success} = useSelector(state => state.updateProductViewReducer)
  const {pageNumber, setPageNumber, numberSeperator} = useContext(AppContext)
  const videoRef = useRef(null)
  const [openGallery, setOpenGallery] = useState(false);
  const [isPlaying, setIsplaying] = useState(false)

  const history = useHistory();
  const [current,setCurrent] = useState(0);
  const [agree, setAgree] = useState(false);
  const [complianceValue, setComplianceValue] = useState([])

  const {productName,alphaCode,video_url,front_view,rear_view,right_view,left_view, categoryName, category, type, sub_category, description, store, size, weight, price
  } = get_prod_details && get_prod_details;
  const fit = category && category.sex && category.sex.map(({name}) => name).join(",");
  const itemType = type !== "" ? type : sub_category;


  const sliderData = [
    {url: front_view, id: 1},
    {url: rear_view, id: 2},
    {url: right_view, id: 3},
    {url: left_view, id: 4},
    {url: video_url, id: 5}
];
const newImage = sliderData.filter((image) => image.url !== "");
const len = sliderData && sliderData?.length;
  const [disapproval_message, setDisapprovalMsg] = useState("")
  
 const useCriteria = get_prod_details?.compliance;

 const [values, setValues] = useState([]);
 const [dataToSubmit, setDataToSubmit] = useState({})
 const criteria = values && values

  const returnFlattenObject = (arr) => {
    const flatObject = {};
    for(let i=0; i< arr.length; i++){
       for(const property in arr[i]){
          flatObject[`${property}`] = arr[i][property];
       }
    };
    return flatObject;
 }

const newObj = returnFlattenObject(criteria);
delete newObj.tooltip;

newObj["product_id"] = id;
newObj["disapproval_message"] = disapproval_message


 const newItem = (e) => {
    const {name, checked, type, value} = e.target;
    let sel = type === "checkbox" ? checked : value;
    setDataToSubmit({...dataToSubmit, [name]: sel})
}

 const handleApprove = (e) => {
     e.preventDefault();
     if(agree){
         dispatch(UpdateProductComplianceStatus(dataToSubmit));
         dispatch(UpdateProductView({id}))
     }
 }

const prevPage = () => {
  if(urlPath === "new"){
       history.push("/dashboard/product/validation")
  }else if(urlPath === "approved"){
       history.push("/dashboard/product/validation/approved")
  }else if(urlPath === "rejected"){
       history.push("/dashboard/product/validation/rejected");
  }else if(urlPath === "all"){
       setPageNumber(pageNumber)
       history.push("/dashboard/product/validation/all");   
  }
}
const nextSlides = () => {
     setCurrent(current === len - 1 ? 0 : current + 1)
}
const prevSlides = () => {
  setCurrent(current === 0 ? len - 1 : current - 1)
}
const playPause = () => {
  if(videoRef?.current?.paused){
    videoRef.current.play()
  }else {
    videoRef.current.pause()
  }
    
}
const onPlay = () => setIsplaying(true);
const onPause = () => setIsplaying(false);

useEffect(() => {
  if(product_compliance_status_success && update_view_success){
      setSuccessMsg("approval status has been submitted")
  }
}, [product_compliance_status_success, update_view_success]);
useEffect(() => {
    const clear = setTimeout(() => {
       if(product_compliance_status_success || update_view_success || product_compliance_status_error || update_view_error){
           setSuccessMsg("")
           dispatch(clearComplianceStatus())
       }
    }, 2000);
    return () => clearTimeout(clear)
}, [product_compliance_status_success, update_view_success,product_compliance_status_error,update_view_error])
useEffect(() => {
    if(id){
        dispatch(GetProductDetails(id))
    }
}, [id]);

useEffect(() => {
    if(newObj && Object.keys(newObj) && Object.keys(newObj)?.length > 0 && get_prod_details){
      setDataToSubmit({...newObj})
    }
}, [Object.keys(newObj)?.length, get_prod_details]);

useEffect(() => {
     if(dataToSubmit && get_prod_details){
         setComplianceValue(Object.values(dataToSubmit))
     }
}, [dataToSubmit,get_prod_details])

useEffect(() => {
  if(complianceValue && !complianceValue.includes(false)){
    setDisapprovalMsg("");
    dataToSubmit["disapproval_message"] = ""

  }
}, [complianceValue, dataToSubmit])

useEffect(() => {
    if(useCriteria && useCriteria?.length > 0){
        setValues([...useCriteria])   
    }   
    
}, [get_prod_details]);


if(!Array.isArray(sliderData) || sliderData?.length <= 0){
    return null
}

  return (
    <div className="validate-sect">
        <div className="row">
             <div className="col-12 col-md-8 col-lg-8">
                 <div className="card card-validate">
                         <div className="card-header">
                              <div onClick={prevPage}><AiOutlineArrowLeft /></div><div>{productName}<span>({alphaCode})</span></div>
                         </div>
                         <div className="card-body">
                          {get_prod_details_error && <h3 className='text-center text-danger d-flex justify-content-center align-items-center'>{get_prod_details_error}</h3>}
                         {get_prod_details_loading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={get_prod_details_loading} color="#000000" size={30} /></div>}
                         {get_prod_details && !get_prod_details_loading && !get_prod_details_error &&
                            <> 
                             <div className="card-media">
                                <p>Picture(s)</p>
                                <div className="card-images">
                                     <ImageSlider sliderData={sliderData} setOpenGallery={setOpenGallery} current={current}setCurrent={setCurrent} newImage={newImage}/>
                                </div>
                             </div>
                             <div className="product-description my-3">
                                  <p>Product Details</p>
                                  <div className="detail-one">
                                       <div className="category-detail">
                                           <label htmlFor='category'>Category</label>
                                           <input type="text" readOnly value={categoryName} id="category"/>
                                       </div>
                                       <div className="type-detail">
                                           <label htmlFor='type'>Type</label>
                                           <input type="text" readOnly value={itemType} id="type"/>
                                       </div>
                                       <div className="fit-detail">
                                            <label htmlFor='fit'>Fit</label>
                                           <input type="text" readOnly value={fit} id="fit"/>
                                       </div>
                                  </div>
                                  <div className="detail-two mt-2">
                                       <div className="category-detail">
                                           <div>
                                              <label htmlFor='name'>Product Name</label>
                                              <input type="text" readOnly value={productName} id="name"/>
                                           </div>
                                           <div>
                                              <label htmlFor='storename'>Store Name</label>
                                              <input type="text" readOnly value={store?.name} id="storename"/>
                                           </div>
                                       </div>
                                       <div className="description-detail">
                                           <label htmlFor='description'>Description</label>
                                           <textarea type="text" readOnly id="description" value={description}>
                                           
                                           </textarea>
                                       </div>
                                  </div>
                                  <div className="detail-one my-2">
                                       <div className="category-detail">
                                           <label htmlFor='price'>Price</label>
                                           <input type="text" readOnly value={`₦${numberSeperator(price)}`} id="price"/>
                                       </div>
                                       <div className="type-detail">
                                           <label htmlFor='weight'>Weight</label>
                                           <input type="text" readOnly value={`${weight}Kg`} id="weight"/>
                                       </div>
                                  </div>
                                  <div className="detail-three my-3">
                                      {
                                        size && size?.length > 0 && <div className='size-qty'>
                                             {
                                                size.map(({value,quantity, color, _id}) => ( <div className='color-qty-size' key={_id}><div className='first-child'><div>{value}</div><div>{quantity}</div></div><div className='all-color'>
                                                    {
                                                      color?.length > 7 ? color.split(",").map((color, index) => (
                                                        <div style={{backgroundColor: color}} key={`${color}-${index}`}></div>
                                                      )):  (<div style={{backgroundColor: color}}></div>)
                                                    }
                                                </div></div>))
                                             }
                                            
                                        </div>
                                      }
                               
                                  </div>
                             </div>
                             </>
                             }
                         </div>

                 </div>
             </div>
             <div className="col-12 col-md-4 col-lg-4">
                 <div className="card card-validate">
                         <div className="card-header">
                              <div>PRODUCT UPLOAD CHECKLIST</div>
                         </div>
                         <div className="card-body">
                            {get_prod_details_error && <h6 className='text-center text-danger'>{get_prod_details_error}</h6>}
                            {Object.values(values)?.length <= 0 && !get_prod_details_error && <h2 className="text-center no-record-found">No Records Found</h2>}
                            {(urlPath === "new" || urlPath === "all") && <form className="product-checklists" onSubmit={handleApprove}>
                              {
                                values && values?.length > 0 && values.map((val, index) => (
                                  <label htmlFor={`noInscription${index}`} key={index}>
                                          <div className='tooltip-holder'><span>{Object.keys(val)[0]?.split("_").join(" ")}</span>{Object.keys(val).filter(key => key !== "tooltip") && <Tooltip title={val.tooltip}><span><BsFillInfoCircleFill /></span></Tooltip>}</div> { <input type="checkbox"  onChange={newItem} name={Object.keys(val)[0]} id={`noInscription${index}`} defaultChecked={Object.values(val)[0]}/>} 
                                          </label>
                                      ))}
                                    
        
                            
                              {
                                
                                complianceValue && complianceValue?.length > 0 &&      <>
                                {(complianceValue && complianceValue.includes(false)) &&
                                <div className='mt-4 disapproval-message'>
                                   <label>DISAPPROVAL COMMENT</label>
                                   <textarea name="disapproval_message" defaultValue={disapproval_message} onChange={newItem}></textarea>
                                </div>
                                 }
                                   <p className='accept-terms'>Kindly cross-check the checklist and be sure the product meets each requirement marked, also note that all requirements unchecked will be flagged and not published.</p>
                                   <div className='accept-condition'><div><Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)}/></div> <div>I agree that I have checked the products and validated them duly </div></div>
                                   </>
                              }
                              {update_view_error && <p className="text-danger text-center my-2">{update_view_error}</p>}
                              {product_compliance_status_error && <p className="text-danger text-center my-2">{product_compliance_status_error}</p>}
                              {successMg && <p className="text-success text-center my-2">{successMg}</p>}
                               {
                                values && values?.length > 0  &&
                                <>
                                    {(product_compliance_status_loading || update_view_loading)  ? <div className="d-flex justify-content-center"><span><ScaleLoader color={color} loading={product_compliance_status_loading} cssOverride={override} size={20} /></span></div> :    <div className='d-flex justify-content-center mt-5 validate-btn'><button type='submit'>SUBMIT</button></div>}
                                 </>
                                 }
                     
                             </form>}
                             {urlPath === "rejected" && <>
                             
                             <form className="product-checklists disapproved-list">
                            
                              {
                                values && values?.length > 0 && values.map((val, index) => (
                                  <label htmlFor={`noInscription${index}`} key={index + "-disapproved"}>
                                          <div className={Object.values(val)[0] === true ? 'tooltip-holder' : 'tooltip-holder text-danger-alpha'}><span>{Object.keys(val)[0]?.split("_").join(" ")}</span>{Object.keys(val).filter(key => key !== "tooltip") && <Tooltip title={val.tooltip}><span><BsFillInfoCircleFill /></span></Tooltip>}</div> { <input type="checkbox" name={Object.keys(val)[0]} id={`noInscription${index}`} checked={Object.values(val)[0]} className={Object.values(val)[0] === true ? "disabled-check": "danger-check"} readOnly/>} 
                                          </label>
                                      ))}
                     
                             </form>
                             <p className='disapproved-badge'><AiFillCloseCircle className='icon text-danger-alpha'/><span className='text-danger-alpha'>DISAPPROVED</span></p>
                             </>
                             }
                             {urlPath === "approved" && <>
                             
                             <form className="product-checklists disapproved-list">
                            
                              {
                                values && values?.length > 0 && values.map((val, index) => (
                                  <label htmlFor={`noInscription${index}`} key={index + "-approved"}>
                                          <div className='tooltip-holder'><span>{Object.keys(val)[0]?.split("_").join(" ")}</span>{Object.keys(val).filter(key => key !== "tooltip") && <Tooltip title={val.tooltip}><span><BsFillInfoCircleFill /></span></Tooltip>}</div> { <input type="checkbox" name={Object.keys(val)[0]} id={`noInscription${index}`} checked={Object.values(val)[0] || true} readOnly/>} 
                                          </label>
                                      ))}
                     
                             </form>
                             <p className='disapproved-badge'><GoVerified className='icon text-success-alpha'/><span className='text-success-alpha'>APPROVED</span></p>
                             </>
                             }
                         </div>
                 </div>
             </div>
        </div>
        {openGallery && 
          <div className='gallery-media'>
              <div className='gallery-close'  onClick={() => {setOpenGallery(false); setIsplaying(false)}}><IoIosCloseCircle />{" "} <span>close</span></div>
              <section className='gallery__media-container slider'>
                  <div className='prev' onClick={() => {prevSlides(); setIsplaying(false)}}><BsChevronLeft /></div>
                 
                  <>
                     {sliderData.map((image, index) => {
                         return (
                          <div className={index === current ? "slide active" : "slide"}>
                            {index === current &&  <>
                            {image && image.url && (image.url?.endsWith(".jpg") || image.url?.endsWith(".jpeg") || image.url?.includes(".png") || image.url?.includes(".svg") || image.url?.includes(".gif")) ? 
                            <div className='full-img'>
                                <img key={"desktop-image-" + index} src={image.url} alt={"product display"}/>
                            </div> :  
                            <div className='full-video' onClick={playPause}>
                                <video ref={videoRef} onPlay={onPlay} onPause={onPause}>
                                    <source src={image.url} />
                                </video>
                                <div className={`play ${isPlaying ? "control-hidden" : "control-shown"}`}><FaPlayCircle /></div>
                               
                            </div>}
                            </>}
                           
                          </div>
                          
                         )
                     })}
                  </> 
                  <div className='next' onClick={() => {nextSlides(); setIsplaying(false)}}><BsChevronRight /></div>
              </section>
          </div>
        }
    </div>
  )
}

export default ValidationDetails