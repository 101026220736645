import { useEffect, useState } from "react";
import Switch from "antd/lib/switch";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BsFillPlusCircleFill,BsChevronLeft,BsChevronRight} from "react-icons/bs";
import { FiArrowLeft } from "react-icons/fi";
import AddPermission from "./AddPermission";
import { ListReasons } from "../../actions/Admin/SettingsAction";
import { Alert } from "antd";
const Permissions = () => {
    const dispatch = useDispatch();
    const history = useHistory(null);
    const search = useLocation().search;
    const permission = new URLSearchParams(search).get("permission");
    const {create_reasons,list_reasons_error} = useSelector(state => state.listReasonsReducer);
    const allowPermission = create_reasons && create_reasons[0] && create_reasons[0].allow_all;
    const [togglePermission, setTogglePermission] = useState(false);

    const handlePermission = () => {
        if(allowPermission){
            setTogglePermission(false)
        }else {
            setTogglePermission(true)
        }
    }

    
    useEffect(() => {
         dispatch(ListReasons())
    }, [])
    return (
        <div className="permissions-container">
        
         <div className="reasons">
            <div className="card card_wrapper  seller_table_wrapper mt-4">
            <div className="payment_title_header">
                <div className="header_order_title"><FiArrowLeft className="back-navigation-set" onClick={() => history.push("/dashboard/settings")}/>PERMISSIONS</div>   
                <div className="btn_add_new" onClick={() => history.push("/dashboard/settings/permissions?permission=add-permission")}> <BsFillPlusCircleFill className="icon_add"/><span>Add New</span></div>
                    
            </div>
            <div className="card-body">
                {
                    list_reasons_error ?  <Alert message="Failed"
                    description={list_reasons_error}
                    type="error"
                    closable
                    className="my-3"
                    />  :
                    <div className="set-priv-container">
                      <div className="privacy">
                          <div className="privacy-text">Allow all users open/manage a store</div><div><Switch defaultChecked={allowPermission} onChange={handlePermission} />&nbsp; <span>{allowPermission ? "Active": "Inactive"}</span></div> 
                      </div>
                    </div>
               }          
          
            {/* <div className="paginate_setting">
                <button className="left-chevron"><BsChevronLeft /></button><button className="middle-chevron">1</button><button className="right-chevron"><BsChevronRight /></button>
            </div> */}
            </div>
            </div>
            
            {permission === "add-permission" && <AddPermission />}
         </div>
        </div>
    )
}

export default Permissions;