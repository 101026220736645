import { useEffect, useContext } from "react";
import SalesAnalytics from "./SalesAnalytics";
import SalesGraph from "./SalesGraphs";
import SaleTable from "./SaleTable";
import { useSelector,useDispatch } from "react-redux";
import { RecentOrdersAction, useRecentOrderQuery } from "../../actions/Admin/sales/RecentOrdersAction";
import "./sale.css"
import { AppContext } from "../../AppContext";
import Loaders from "../Reusables/Loader";

const Sale = () => {
    const dispatch = useDispatch()
    const {itemLimit} = useContext(AppContext);
    const {list_recent_orders_error,list_recent_orders_docs, list_recent_orders_loading} = useSelector(state => state.recentOrdersReducer);

    const {data} = useRecentOrderQuery({page: 1, limit: 10});
    useEffect(() => {
        const offset = 1;
        const limit = itemLimit;
        const data = {offset, limit}
       dispatch(RecentOrdersAction(data))
       
  }, [dispatch,itemLimit]);
    return (
        <div className="dashoard_content">
            
                 {list_recent_orders_error && <p className="text-danger text-center d-block" style={{fontSize: 14}}>{list_recent_orders_error.message}</p>}
                 {
                    list_recent_orders_loading ? <div style={{display: "flex", justifyContent: "center", alignItems: "center" , width: "100%", height: "100vh"}}><Loaders loading={list_recent_orders_loading} color="#000000" size={50} />
                                              
                    </div> :  list_recent_orders_docs && list_recent_orders_docs.length > 0  ?  <>            
                          <SalesAnalytics />
                         <SalesGraph />
                         <SaleTable />
                    </> : <h2 className="text-center no-record-found">No Records Found</h2>
                 }

                
                   
                
            

        </div>
    )
}

export default Sale;