import {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CreateReasons, ListReasons } from "../../actions/Admin/SettingsAction";
const AddReasons = () => {
    const [reason, setReason] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const {reasons_loading, reason_success, create_reasons_error} = useSelector(state => state.createReasons);
    const handleReasonCreate = (e) => {
         e.preventDefault();
         if(!reason) return;
         dispatch(CreateReasons({ "cancellation_reason" : reason}))
    }
    useEffect(() => {
      if(reason_success){
          dispatch(ListReasons())
      }
    }, [reason_success])
    
    return (
        <div className="payment_modal">
        <div className="card payment_add_method">
          <div className="data_heading">
            <div className="header_order_title type_header">ADD NEW CANCELLATION REASON</div>
          </div>
         
            <form className="add_payment_form" onSubmit={handleReasonCreate}>
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label htmlFor="category" className="payment_label">Enter a new reason for cancellation</label>
                    <input type="text" className="payment_input" onChange={(e) => setReason(e.target.value)} value={reason}/>
                  </div>
                </div>
                {create_reasons_error && <div className="text-center text-danger">{create_reasons_error}</div>}
                <div className="cta_btn">
                  <button className="type_button">{reasons_loading ? "Creating..." : "SAVE"}</button>
                  <button className="type_cancel_button" onClick={() => {history.push("/dashboard/settings/reasons")}}>{reason_success ? "CLOSE" : "CANCEL"}</button>
                </div>
            </form>
       
      </div>
</div>
    )
}

export default AddReasons