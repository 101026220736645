import { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { TopSellingProductAction } from "../actions/Admin/dashboard/TopSellingProductAction";
import "../merchants.css"
import { AppContext } from './../AppContext';

const TopProducts = () => {
    const {numberSeperator, nFormatter} = useContext(AppContext)
    const {top_selling_loading,top_selling_product,top_selling_product_docs,top_selling_product_error } = useSelector(state => state.topSellingProductReducer);
    const dispatch = useDispatch();
    const [items, setItems] = useState(3);
   const totalDocs = top_selling_product && top_selling_product.totalDocs;
    useEffect(() => {
        const page = 1;
        const limit = +items;
        const data = {page, limit}
       dispatch(TopSellingProductAction(data))
       
  }, [dispatch,items]);
  
  const handleNextPage = (data, totalItems) => {
      const checkDataLength = data >= totalItems ?  totalItems : (data + data);
      setItems(checkDataLength);
  };
    
    return (
        <div className="top_products">
            <div className="row_data">
                {
                    top_selling_product_error && <p className="text-center tex-danger">{top_selling_product_error || top_selling_product_error.message}</p>
                }
                <div className="top_selling_prod_details">
                    {
                        top_selling_product_docs && top_selling_product_docs.map(({productName,category,alphaCode,amount,prodImage,storeName,storeImage
                        },index) => (
                          <div className="top_prod" key={index}>
                            <div className="top_selling_img"><img src={prodImage} alt={prodImage}/></div>
                                <div className="top_selling_section">
                                <div className="top-prod-name-price">
                                        <div className="top_prod_alpha_name">
                                            <div className="top_prod_name">{productName}</div>
                                            <div className="top_prod_category">{category}</div>
                                            
                                        </div>
                                        <div className="top_prod_price">&#8358;{amount.length > 7 ? nFormatter(amount) : numberSeperator(amount) }</div>
                                </div>
                                <div className="top-prod-name-price">
                                        <div className="top_prod_alpha_store_sec">
                                            <div className="store-img-cat">
                                                <img src={storeImage} alt={storeImage} />
                                            </div>
                                            <div className="store-name-cat">
                                                <div>Store name</div>
                                                <div>{storeName}</div>
                                            </div>
                                        </div>
                                    <div className="top_prod_alpha_code">Alpha code: {alphaCode}</div>
                                    </div>
                            </div>
                        </div>
                        ))
                    }
                     
                
                </div>
                {
                    items >= totalDocs ? null : <div  className="see_more" onClick={() => handleNextPage(items,totalDocs)}>{top_selling_loading ? "Please wait.." : "SEE MORE"}</div>
                }
                   
                </div>
        </div>
  
    )
}

export default TopProducts;